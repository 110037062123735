import PropTypes from 'prop-types';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import KEY_CODES from './KeyCodes';
import {
  handleListKeyUp,
  handleButtonKeyUp,
  adjustScrollPosition,
} from './Utils';

const Control = styled.div`
  font-family: 'Lato', Arial;
  position: relative;
  border-bottom: 2px solid transparent;

  ${(props) =>
    props.isActive &&
    `
    &:before {
      content: "";
      width: calc(100% - 40px);
      height: 0px;
      left: 4px;
      position: absolute;
      bottom: 0px;
      border: 1px solid ${props.colors.textColor};
    }
    ${ControlList} {
      display: block;
    }
    ${Trigger} {
      border-radius: 2px 2px 0 0;
    }
  `}
`;
const ControlLabel = styled.div`
  label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;
const ControlField = styled.div`
  position: relative;
`;
const ControlList = styled.ul`
  box-sizing: border-box;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  display: none;
  max-height: 240px;
  margin: 0;
  margin-top: 5px;
  overflow-y: auto;
  padding: 0px;
  position: absolute;
  top: 44px;
  width: 115px;
  z-index: 100;
  &:focus {
    outline: 0;
  }
`;
const ListItem = styled.li`
  color: black;
  height: 40px
  line-height: 40px;
  list-style: none;
  margin: 0px;
  padding: 10px 10px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  ${(props) =>
    props.isActive &&
    !props.isSelected &&
    (props.user ?
    `
    background-color:${props.colors.hover}` :
            `background-color:rgb(219, 219, 219);`
    )

}
     ${(props) =>
       props.isSelected &&
    (props.user ?
       `  color:white; background-color:${props.colors.selected} !important;` :
           `  color:white; background-color:rgb(1, 33, 105) !important;`)
}
     ${(props) =>
       props.isActive &&
       props.user &&
       `color: red !important; background-color:${props.colors.hover} `}
      ${(props) =>
        !props.isActive &&
        props.user &&
        `color: red !important; background-color:white `}
`;
const Trigger = styled.button`
  font-weight: 500;
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  display: block;
  font-size: 16px;
  margin-bottom: 0;
  min-height: 40px;
  overflow: hidden;
  padding: 2px 40px 2px 0.5rem;
  position: relative;
  width: 100%;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  position: relative;
  &:focus {
    outline: 0;
    box-shadow: rgba(8, 132, 252, 0.3) 0px 0px 0px 3px;
    z-index: 1;
  }
  ${(props) => `color: ${props.textColor}`}
`;
const TriggerIcon = styled.span`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  &:after {
    content: '';
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    ${(props) => `border-bottom: 2px solid ${props.textColor};`}
    ${(props) => `border-right: 2px solid ${props.textColor};`}
  }
`;

function focusTriggerButton(id) {
  document.getElementById(`${id}-button`).focus();
}

const getActiveItem = (index, options, placeholder) => {
  return index < 0 ? { label: placeholder } : options[index];
};

//My function to return the isSelected
const getSelectedItem = (index, options, placeholder) => {
  return index < 0 ? { label: placeholder } : options[index];
};

const DropdownComponent = function ({
  id,
  label,
  options,
  placeholder,
  onSelect,
  user,
  ...props
}) {
  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [lastSelectedValue, setLastSelectedValue] = useState(activeIndex);
  const [statusOnMouseDown, setStatusOnMouseDown] = useState(false);

  DropdownComponent.handleClickOutside = () => {
    setIsActive(false);
  };

  const keyCode = {
    en: 'English',
    ca: 'Català',
    es: 'Español',
    fr: 'Français',
    'fr-CA': 'Français (Canadien)',
    de: 'Deutsch',
    'zh-CN': '中文（简体)',
    ja: '日本語',
    ko: '한국어',
    sv: 'Svenska',
    da: 'Dansk',
    fi: "Suomi",
    no: "Norsk"
  };

  props.isSelected = selectLocalizationLanguage();

  useEffect(function () {
    setActiveIndex(activeIndex);
    setLastSelectedValue(
      options.findIndex((o) => o.isActive) >= 0
        ? options.findIndex((o) => o.isActive)
        : options.findIndex(
            (el) => el.lang === props.i18n.language.substring(0, 2)
          ) >= 0
        ? options.findIndex(
            (el) => el.lang === props.i18n.language.substring(0, 2)
          )
        : 0
    );
  }, [activeIndex, options, props.i18n.language]);

  useLayoutEffect(function () {
    document.getElementById(`${id}-list`).focus();
    adjustScrollPosition(`${id}-list`, `${id}-item-${activeIndex}`);
  });

  function selectLocalizationLanguage() {
    if(props.i18n.language.length === 2) {
      switch (props.i18n.language.substring(0, 2)) {
        case 'en':
          return keyCode['en']
        case 'es':
          return keyCode['es']
        case 'ca':
          return keyCode['ca']
        case 'fr':
          return keyCode['fr']
        case 'de':
          return keyCode['de']
        case 'ja':
          return keyCode['ja']
        case 'ko':
          return keyCode['ko']
        case 'sv':
          return keyCode['sv']
        case 'da':
          return keyCode['da']
        case 'fi':
          return keyCode['fi']
        case 'no':
          return keyCode['no']
        default:
          return keyCode['en']
      }
    } else {
      switch (props.i18n.language) {
        case 'fr-CA':
          return keyCode['fr-CA']
        case 'zh-CN':
          return keyCode['zh-CN']
        default:
          return keyCode['en']
      }
    }
  }

  function onActivate() {
    setIsActive(true);
  }

  function onOptionClick(o, i) {
    onItemSelect(o);
    setActiveIndex(i);
    if (i >= 0) setLastSelectedValue(i);
  }

  function onItemSelect(item) {
    if (isActive === false) {
      return;
    }

    if (item) {
      setIsActive(false);
      //setTimeout(() => focusTriggerButton(id), 100);
      onSelect(item);
    }
  }

  function onBlur() {
    if (lastSelectedValue !== activeIndex) {
      setActiveIndex(lastSelectedValue);
    }
    reset();
  }

  function setActiveItem(newIndex) {
    let idx = newIndex;
    if (idx >= options.length) {
      idx = 0;
    } else if (idx < 0) {
      idx = options.length - 1;
    }

    adjustScrollPosition(`${id}-list`, `${id}-item-${idx}`);
    setActiveIndex(idx);
  }

  function onKeyDown(e) {
    switch (e.key) {
      case KEY_CODES.RETURN:
        setLastSelectedValue(lastSelectedValue);
        onOptionClick(
          getActiveItem(activeIndex, options, placeholder),
          activeIndex
        );
        break;
      case KEY_CODES.DOWN:
        getSelectedItem(lastSelectedValue, options, placeholder);
        if (!isActive) {
          onActivate();
        } else setActiveItem(activeIndex + 1);
        e.preventDefault();
        break;
      case KEY_CODES.UP:
        setActiveItem(activeIndex - 1);
        getSelectedItem(lastSelectedValue, options, placeholder);
        e.preventDefault();
        break;
      case KEY_CODES.END:
        e.preventDefault();
        setActiveItem(options.length - 1);
        break;
      case KEY_CODES.HOME:
        e.preventDefault();
        setActiveItem(0);

        break;
      case KEY_CODES.ESC:
        e.preventDefault();
        reset(true);
        break;
      default:
        break;
    }
  }

  function reset(isFocusTriggerRequired) {
    const idx = activeIndex;
    adjustScrollPosition(`${id}-list`, `${id}-item-${idx}`);
    setIsActive(false);
    if (isFocusTriggerRequired) {
      setActiveIndex(lastSelectedValue);
      setTimeout(() => focusTriggerButton(id), 100);
    }
  }

  return (
    <Control
        style={{ visibility: props.isDropdownVisible ? 'visible': 'hidden'}}
      isActive={isActive}
      className="control"
      colors={props.colors}
      onMouseDown={() => {
        setStatusOnMouseDown(isActive);
      }}
    >
      {label && (
        <ControlLabel className="control__label" tabIndex="-1">
          <label
            htmlFor={`${id}-label`}
            onClick={() => document.getElementById(`${id}-button`).focus()}
          >
            {label}
          </label>
        </ControlLabel>
      )}
      <ControlField className="control__field">
        <Trigger
          id={`${id}-button`}
          type="button"
          className="control__field-input"
          aria-haspopup="listbox"
          textColor={props.colors.textColor}
          aria-label={
            user
              ? `${props.t(`${id}`)}`
              : `${props.t(`${id}`)}-${props.t(props.isSelected)}${props.t(
                  'dropdownSelected'
                )}`
          }
          aria-expanded={isActive}
          onClick={() => {
            if (!statusOnMouseDown) {
              onActivate();
            }
          }}
          onKeyUp={(e) => handleButtonKeyUp(e)}
          onKeyDown={(e) => onKeyDown(e)}
        >
          {id === 'dropdownUserSetting' ? user : props.isSelected}
          <TriggerIcon
            role="presentation"
            aria-hidden="true"
            textColor={props.colors.textColor}
          />
        </Trigger>
        <ControlList
          tabIndex="-1"
          id={`${id}-list`}
          className="list"
          role="listbox"
          aria-label={`${props.t(`${props.t(`${id}`)}`)}`}
          aria-activedescendant={`${id}-item-${activeIndex}`}
          onKeyUp={(e) => handleListKeyUp(e)}
          onKeyDown={(e) => onKeyDown(e)}
          onBlur={() => onBlur()}
        >
          {options.map((o, i) => {
            return !o.label ? (
              <></>
            ) : (
              <ListItem
                key={o.label + i}
                id={`${id}-item-${i}`}
                className={`list__item ${
                  i === activeIndex ? 'list__item--active' : ''
                }`}
                user={o.logoutItem ? true : false}
                role="option"
                colors={props.colors}
                isActive={i === activeIndex}
                isSelected={user ? i === null : i === lastSelectedValue} //isSelected shall be selected item and activeIndex shall be hovering item
                aria-selected={i === activeIndex}
                onClick={() => onOptionClick(o, i)}
                onMouseEnter={() => setActiveIndex(i)}
              >
                {o.label}
              </ListItem>
            );
          })}
        </ControlList>
      </ControlField>
    </Control>
  );
};

DropdownComponent.defaultProps = {
  label: '',
  onSelect: PropTypes.func.isRequired,
};
DropdownComponent.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default withTranslation()(DropdownComponent);
