import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setSource, setTarget, setEngines } from '../../redux/actions';
import Select, { components } from 'react-select';
import InfoQualityScore from "../../icons/InfoQualityScore";
import Tippy from "@tippyjs/react";

const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
        <span className="triggerIcon"
              role="presentation"
              aria-hidden="true"
        />
    </components.DropdownIndicator>
);

const { Option } = components;

function IconOption(props, tooltip) {
    const {
        data: { label, Icon },
    } = props;

    return (
        <Option {...props}>
            <div className="flex items-center gap-2">
                <span style={{verticalAlign: "super"}}>{label}</span>
                <Tippy placement={"right-start"} content={tooltip}><span style={{float: "right"}}>{Icon && <InfoQualityScore accessibility={tooltip} size={24} color={"white"}/>}</span></Tippy>
            </div>
        </Option>
    );
}

function IconOptionSelectedEngine(props, tooltip) {
    const {
        data: { label, Icon },
    } = props;

    return (
        <Option {...props}>
            <div className="flex items-center gap-2">
                <span style={{verticalAlign: "super"}}>{label}</span>
                <Tippy placement={"right-start"} content={tooltip}><span style={{float: "right"}}>{Icon && <InfoQualityScore accessibility={tooltip} size={24} color={"#012169"}/>}</span></Tippy>
            </div>
        </Option>
    );
}

function EngineSelection(props){
    const tooltip = props.t('qualityTranslationEngineInfo');

    return  (
            <>
                    <Select
                        className="engineSelection"
                        options={props.engineListState}
                        value={props.selectedEngine}
                        name="engine"
                        onChange={props.handleEngineChange}
                        menuShouldScrollIntoView={false}
                        components={props.isDropdownInTextTranslation && props.selectedEngine && props.store.user.organization.uiAssets.qualityScore ? { Option: props.selectedEngine.hasQualityScore ? (props) => IconOption(props, tooltip) :  (props) => IconOptionSelectedEngine(props, tooltip)} : {DropdownIndicator}}
                        noOptionsMessage={() => props.t('langSelect_NoResultsMessage')}
                        menuPlacement={!props.menuPlacement ? "auto" : props.menuPlacement}
                        label="Enginestate"
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: props.dropdownHover,
                                primary: props.dropdownSelected,
                                neutral0: "#ffffff",
                            },
                        })}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                height: '100%',
                                border: (!!props.error ? '2px solid #ff0000' : '1px solid rgb(179, 179, 179)'),
                                borderRadius: '3px',
                            }),
                            valueContainer: () => ({
                                paddingLeft: '5px'
                            }),
                            indicatorSeparator: (provided) => ({
                                ...provided,
                                display: 'none',
                            }),
                            menu: (provided) => ({
                                ...provided,
                                marginTop: "3px"
                            }),
                            menuList: (provided) => ({
                                ...provided,
                                borderRadius: "5px",
                                padding: 0
                            }),
                            option: (provided) => ({
                                ...provided,
                                fontSize: "16px",
                                height: "40px",
                                padding: "10px",
                                fontWeight: "400"
                            }),
                        }}
                    />
            </>
        )
}

const mapStateToProps = (state) => ({
    store: state,
    sourceLanguage: state.globalOptions.sourceLanguage,
    targetLanguage: state.globalOptions.targetLanguage,
    engines: state.globalOptions.engines,
    dropdownSelected: state.user.organization.uiAssets.dropdownSelected,
    dropdownTitleColor: state.user.organization.uiAssets.dropdownTitleColor,
    dropdownHover: state.user.organization.uiAssets.dropdownHover,
});

const mapDispatchToProps = (dispatch) => ({
  setSource: (source) => dispatch(setSource(source)),
  setTarget: (target) => dispatch(setTarget(target)), setEngines: (engines) => dispatch(setEngines(engines)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EngineSelection)
);

