import React, {Component, Fragment, useState} from 'react';
import ModalContent from './modalContent';
import { connect } from 'react-redux';
import {
  setUploading,
  rmUploading,
  setSource,
  setTarget,
} from '../../redux/actions';
import './style.css';
import { GLAI_URL } from '../../utils/config';
import Announcements from '../Announcements';
import { withTranslation } from 'react-i18next';
import translateFile from "../../apiCalls/components/AxeModal/translateFile";

class Modal extends Component {
  state = {
    open: false,
    showUnsupportedFileError: this.props.showUnsupportedFileErrorFromDocument
      ? true
      : false,
    pdfUploaded: false,
    msgOrEmlUploaded: false,
    imagesUploaded: false,
    wavUploaded: false,
    ocr: true,
    submissionConcept: '',
    selectedCategory: '',
    supportEmailTo: "aisupport@transperfect.com",
    supportEmailCc: "",
    supportEmailBody: "",
    error: false,
    errorMessage: undefined,
    warningMessage: undefined,
    srcTrgEqual: false,
    filesContainPDFAndWAV: false,
    mandatoryCategoryError: '',
    isSubmissionNameTooLong: false
  };

  showCategoryError = () => {
    this.setState({ mandatoryCategoryError: this.props.t('mandatoryCategoryError') });
  }

  onOpen = () => {
    this.setState({ open: true, ocr: false }, () =>
      this.closeButtonNode.focus()
    );
    this.toggleScrollLock();
  };
  onClose = () => {
    this.props.onClose();
    this.setState({ showUnsupportedFileError: false, selectedCategory: '' }); // resetting category when closing modal
  };
  onKeyDown = (e) => {
    e.keyCode === 27 && this.onClose();
  };
  onClickAway = (e) => {
    if (this.modalNode && this.modalNode.contains(e.target)) return;
    this.onClose();
  };
  toggleScrollLock = () =>
    document.querySelector('html').classList.toggle('u-lock-scroll');

  swapLanguages = () => {
    let src = this.props.globalOptions.sourceLanguage,
      tgt = this.props.globalOptions.targetLanguage;
    this.props.setSource(tgt);
    this.props.setTarget(src);
  };

  toggleOcr = () => {
    this.setState({ ocr: !this.state.ocr });
  };

  handleSubmissionConcept = (e) => {
    if(e.target.value.length <= 180) { //180 is the char limit
      this.setState({ submissionConcept: e.target.value, error: false, isSubmissionNameTooLong: false, warningMessage: undefined});
    } else {
      this.setState({warningMessage: this.props.t('submissionNameMaxLength'), error: true, isSubmissionNameTooLong: true});
    }
  };

  handleCategoryChange = (option) => {
    this.setState({ selectedCategory: option });
  }

  uploadAndTranslate = () => {
    let formData = new FormData();
    for (let i = 0; i < this.props.uploadingFiles.length; i++) {
      formData.append('files', this.props.uploadingFiles[i]);
    }
    this.setState({
      pdfUploaded: this.props.uploadingFiles.some(
          (el) => el.name.substring(el.name.length - 3).toLowerCase() === 'pdf'
      ),
      imagesUploaded: this.props.uploadingFiles.some(
          (el) => el.name.substring(el.name.length - 3).toLowerCase() === 'png'|| el.name.substring(el.name.length - 3).toLowerCase() === 'jpg' || el.name.substring(el.name.length - 4).toLowerCase() === 'jpeg'
      ),
      msgOrEmlUploaded: this.props.uploadingFiles.some(
          (el) => el.name.substring(el.name.length - 3).toLowerCase() === 'msg'|| el.name.substring(el.name.length - 3).toLowerCase() === 'eml'
      ),
    });
    let url = "";
    let submissionConcept = "";
    let category = "";
    let engineId = "";
    if(this.state.submissionConcept){
      submissionConcept = `&submissionName=${encodeURIComponent(this.state.submissionConcept)}`;
    }
    if(this.state.selectedCategory) {
      category = this.state.selectedCategory.value;
    }
    if(this.props.selectedEngine.value){
      engineId = this.props.selectedEngine.value;
    }
    if (!!this.state.pdfUploaded) {
      url = `${GLAI_URL}/uploadapigateway/upload/documentTranslate?from=${this.props.globalOptions.sourceLanguage}&to=${this.props.globalOptions.targetLanguage}&ocr=${this.state.ocr}&engineId=${engineId}${submissionConcept}&category=${category}`;
    } else if (!!this.state.imagesUploaded) {
      //forcing ocr to true in case there are images uploaded
      url = `${GLAI_URL}/uploadapigateway/upload/documentTranslate?from=${this.props.globalOptions.sourceLanguage}&to=${this.props.globalOptions.targetLanguage}&ocr=true&engineId=${engineId}${submissionConcept}&category=${category}`;
    } else {
      //forcing ocr to false in case there is no pdf uplaoded
      url = `${GLAI_URL}/uploadapigateway/upload/documentTranslate?from=${this.props.globalOptions.sourceLanguage}&to=${this.props.globalOptions.targetLanguage}&ocr=false&engineId=${engineId}${submissionConcept}&category=${category}`;
    }
    return translateFile(url, formData)
      .then((res) => {
        this.setState({submissionConcept: ''}) // resetting submission concept
        this.setState({selectedCategory: ''}) // resetting selected category
        return res.json();
      })
      .catch((err) => console.error('err', err));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.uploadingFiles !== this.props.uploadingFiles) {
      this.setState({
        pdfUploaded: this.props.uploadingFiles.some(
            (el) => el.name.substring(el.name.length - 3).toLowerCase() === 'pdf'
        ),
        imagesUploaded: this.props.uploadingFiles.some(
            (el) => el.name.substring(el.name.length - 3).toLowerCase() === 'png' || el.name.substring(el.name.length - 3).toLowerCase() === 'jpg' || el.name.substring(el.name.length - 4).toLowerCase() === 'jpeg'
        ),
        msgOrEmlUploaded: this.props.uploadingFiles.some(
            (el) => el.name.substring(el.name.length - 3).toLowerCase() === 'msg' || el.name.substring(el.name.length - 3).toLowerCase() === 'eml'
        ),
        wavUploaded: this.props.uploadingFiles.some(
            (el) => el.name.substring(el.name.length - 3).toLowerCase() === 'wav'
        ),
      });
    }

    // checking if source, target or ocr has changed
      if ((prevProps.globalOptions.sourceLanguage !== this.props.globalOptions.sourceLanguage) || (prevProps.globalOptions.targetLanguage !== this.props.globalOptions.targetLanguage) || (prevState.ocr !== this.state.ocr)) {
        if (this.props.globalOptions.sourceLanguage === this.props.globalOptions.targetLanguage) {
          this.setState({srcTrgEqual: true, error: true, errorMessage: this.props.t('WFM-04-SRCTRGSAME')});
        } else {
          this.setState({srcTrgEqual: false, error: false, errorMessage: undefined});
        }

        if(!this.state.isSubmissionNameTooLong) {
          this.setState({warningMessage: undefined});
        } else {
          this.setState({warningMessage: this.props.t('submissionNameMaxLength')});
        }

        if (this.state.pdfUploaded && this.state.wavUploaded) {
          this.setState({filesContainPDFAndWAV: true});
        }

        if ((this.state.pdfUploaded || this.state.imagesUploaded) && this.state.ocr) { //in case of ocr formats
          if (this.props.globalOptions.sourceLanguage === 'auto' || this.props.globalOptions.sourceLanguage === 'multi') {
            this.setState({errorMessage: this.props.t('modalPdfAutodetectOcrError'), error: true});
          } else {
            this.setState({errorMessage: undefined, error: false});
          }
        } else {
          if (this.state.msgOrEmlUploaded) { //in case of msg or eml formats
            if (this.props.globalOptions.sourceLanguage === 'auto' || this.props.globalOptions.sourceLanguage === 'multi') {
              this.setState({errorMessage: this.props.t('modalMsgEmlAutoDetectError'), error: true});
            } else {
              this.setState({errorMessage: undefined, error: false});
            }
          } else if (this.state.filesContainPDFAndWAV) { //in case of pdf or wav formats
            this.setState({errorMessage: this.props.t('FILE-UPLOADS-CONTAIN-PDF-AND-WAV'), error: true});
          } else if (
              this.props.uploadingFiles.some(
                  (file) => file.type.match(/^.+\/(wav)$/gm) && file.size > 200000000
              )
          ) {
            this.setState({errorMessage: this.props.t('STT-DOC-04-SIZE'), error: true});
          } else if (this.state.wavUploaded) { //in case of wav formats
            if (this.props.globalOptions.sourceLanguage === 'auto' || this.props.globalOptions.sourceLanguage === 'multi') {
              this.setState({errorMessage: this.props.t('TT_WAV_ERROR_AUTO_DETECT'), error: true});
            } else {
              this.setState({errorMessage: undefined, error: false});
            }
          }
        }
      }

    if(prevState.selectedCategory !== this.state.selectedCategory) { // check if category has changed
      if(this.state.selectedCategory !== '') {
        this.setState({mandatoryCategoryError: ''}); //reset error when a category is selected
      }
    }
  }

  //COMPONENT WILL UNMOUNT CLEAR UPLOADING

  componentCleanup = () => {
    if (this.props.uploadingFiles.length > 0) {
      this.props.rmUploading(this.props.uploadingFiles);
    }
  };

  componentDidMount() {
    if(this.props.uiAssets.supportEmailTo) {
      this.setState({supportEmailTo: this.props.uiAssets.supportEmailTo, supportEmailCc: this.props.uiAssets.supportEmailCc});
    }
    const emailBody = "Support,\n" +
        "I am unable to upload files on Document Translate. I receive this message: \"We found an error uploading your files. Please, try again\". " +
        "Can I get some assistance?\n" +
        "Regards,\n";
    this.setState({supportEmailBody: encodeURIComponent(emailBody)}); //this function will apply carriage return in email body

    window.addEventListener('beforeunload', this.componentCleanup);
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup); // remove the event handler for normal unmounting
  }

  onChange = (e) => {
    this.props.setUploading(e.target.files);
  };

  render() {
    const {
      ariaLabel,
      role,
      open,
      files,
      startCheckStatus,
      stopCheckStatus,
      showUnsupportedFileErrorFromDocument,
    } = this.props;

    return (
      <Fragment>
        {open && (
          <ModalContent
            modalRef={(n) => (this.modalNode = n)}
            onChange={this.onChange}
            buttonRef={(n) => (this.closeButtonNode = n)}
            ariaLabel={ariaLabel}
            onClose={this.onClose}
            swapLanguages={this.swapLanguages}
            startCheckStatus={startCheckStatus}
            stopCheckStatus={stopCheckStatus}
            onClickAway={this.onClickAway}
            onKeyDown={this.onKeyDown}
            dropRef={this.props.dropRef}
            uploadAndTranslate={this.uploadAndTranslate}
            role={role}
            files={files}
            showUnsupportedFileErrorFromDocument={
              showUnsupportedFileErrorFromDocument
            }
            pdfUploaded={this.state.pdfUploaded}
            imagesUploaded={this.state.imagesUploaded}
            msgOrEmlUploaded={this.state.msgOrEmlUploaded}
            toggleOcr={this.toggleOcr}
            ocr={this.state.ocr}
            selectedEngine={this.props.selectedEngine}
            engineListState={this.props.engineListState}
            handleEngineChange={this.props.handleEngineChange}
            loadingEngines={this.props.loadingEngines}
            disabledEngineDropdown={this.props.disabledEngineDropdown}
            submissionConcept={this.state.submissionConcept}
            handleSubmissionConcept={this.handleSubmissionConcept}
            selectedCategory={this.state.selectedCategory}
            categoriesListState={this.props.categoriesListState}
            handleCategoryChange={this.handleCategoryChange}
            loadingCategories={this.props.loadingCategories}
            disabledCategoryDropdown={this.props.disabledCategoryDropdown}
            isCategoryMandatory={this.props.isCategoryMandatory}
            showCategory={this.props.showCategory}
            supportEmailTo={this.state.supportEmailTo}
            supportEmailCc={this.state.supportEmailCc}
            supportEmailBody={this.state.supportEmailBody}
            error={this.state.error}
            errorMessage={this.state.errorMessage}
            warningMessage={this.state.warningMessage}
            srcTrgEqual={this.state.srcTrgEqual}
            wavUploaded={this.state.wavUploaded}
            showCategoryError={this.showCategoryError}
            mandatoryCategoryError={this.state.mandatoryCategoryError}
            isSubmissionNameTooLong={this.state.isSubmissionNameTooLong}
          ></ModalContent>
        )}
        {this.props.announceExitModal && (
          <Announcements message={this.props.t('AnnounceDialogClosed')} />
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUploading: (files) => dispatch(setUploading(files)),
  rmUploading: (files) => dispatch(rmUploading(files)),
  setSource: (src) => dispatch(setSource(src)),
  setTarget: (tgt) => dispatch(setTarget(tgt)),
});

const mapStateToStore = (state) => ({
  uploadingFiles: state.userStatus.uploading,
  globalOptions: state.globalOptions,
});

export default withTranslation()(
  connect(mapStateToStore, mapDispatchToProps)(Modal)
);
