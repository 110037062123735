import React from "react";
import DocumentButton from "../../../components/DocumentButton";
import DragAndDropIconComponent from "../../../icons/DragAndDropIconComponent";
import ReactHtmlParser from "react-html-parser";

const DropzoneContent = ({ getRootProps, isDragActive, props, getInputProps, state, accept }) => {
    return (
        <>
            <div  className="documentBoxWrapper">

                <div
                    {...getRootProps({
                        tabIndex: '-1',
                    })}
                    onClick={(e) => e.stopPropagation()}
                    className="documentBox"
                    style={{
                        border: isDragActive ? '1px solid #2559c9': 'none'
                    }}
                >
                    <div style={{paddingTop: "20px"}}>
                        <DragAndDropIconComponent accessibility={props.t('dragAndDropTooltip')} size={24}/>
                    </div>
                    <p className="document-dropzone-text">
                        {props.t('documentDroph2')}
                    </p>
                    <div
                        {...getRootProps({
                            tabIndex: '-1',
                        })}
                    >
                        <DocumentButton
                            tabIndex="0"
                            aria-label={props.t('documentDrophSelect')}
                            tippyContent={props.t('documentDrophSelect')}
                        />
                        <input {...getInputProps()}></input>
                    </div>
                    <p className="document-dropzone-file-message" style={{paddingTop: "40px"}}>
                        {ReactHtmlParser(props.t('documentDrophMaxSize'))}
                    </p>
                    <p
                        role={state.showUnsupportedFileError ? 'alert' : null}
                        className="document-dropzone-file-message"
                        style={
                            state.showUnsupportedFileError
                                ? { color: 'red' }
                                : { color: 'rgb(78, 98, 115)' }
                        }
                    >
                        {state.showUnsupportedFileError
                            ? props.t('unsupportedFileMessage')
                            : props.t('documentDropFileMessage') + accept()
                                .replace(/\./g, '')
                                .split(', ')
                                .sort()
                                .join(', ')}
                    </p>
                </div>
            </div>
        </>
    );
};

export default DropzoneContent;