import React from 'react';
import {Form } from "antd";

const QuotaType = ({
                              quotaType,
                              setQuotaType,
                              name,
                              handleQuotaType,
                          }) => {

    const segments= [
            {
                label: 'None',
                value: 0,
            },
            {
                label: 'Monthly',
                value: 1,
            },
            {
                label: 'Yearly',
                value: 2,
            },
            {
                label: 'Absolute',
                value: 3,
            },
    ];


    const onInputChange = (value) => {
        setQuotaType(value);
        handleQuotaType(value);
    }

    return (
        <Form.Item
            label="Org translation limits"
            name="showQuotas"
            initialValue={quotaType}
        >
        <div className="controls-container">
            <div className="controls">
                {segments.map((item, i) => (
                    <div
                        key={item.value}
                        className={`segment ${i === quotaType ? 'active' : 'inactive'}`}
                    >
                        <input
                            type="radio"
                            value={item.value}
                            id={item.label}
                            name={name}
                            onChange={() => {
                                onInputChange(item.value);
                            }}
                            checked={i === quotaType}
                        />
                        <label htmlFor={item.label}>
                            {item.label}
                        </label>
                    </div>
                ))}
            </div>
        </div>
        </Form.Item>
    );
};

export default QuotaType;