export const selectStyle = {
  container: (base, value) => {
    return {
      ...base,
      width: "35%",
      //maxWidth: "45%",
      borderRadius: "1px"

      //border: "1px solid red"
    };
  },
  control: a => ({
    ...a,
    borderRadius: "3px",
    fontSize: "16px",
    height: "2.4em"
  }),
  menuList: a => ({ ...a, borderRadius: "3px" }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  singleValue: a => ({
    ...a,
    height: "2em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  })
};

export const sourceStyle = {
  container: (base, value) => {
    return {
      ...base,
      width: "35%",
      //maxWidth: "45%",
      borderRadius: "1px"

      //border: "1px solid red"
    };
  },
  control: a => ({
    ...a,
    borderRadius: "3px",
    fontSize: "16px",
    height: "2.4em"
  }),
  menuList: a => ({ ...a, borderRadius: "3px" }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  singleValue: a => ({
    ...a,
    height: "2em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  })
};

export const selectStyleDropUp = {
  container: base => ({
    ...base,
    width: "35%",
    backgroundColor: "1px solid rgb(218, 222, 225)",
    animation: "glow 300ms ease-out  alternate"
    //position: "relative"
  }),
  control: a => ({
    ...a,
    borderRadius: "3px",
    fontSize: "18px",
    height: "2.4em"
  }),
  singleValue: a => ({
    ...a,
    height: "2em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 9999
  }),
  menu: a => ({
    ...a,

    padding: "0",
    margin: "0 0 300px 0",
    position: "relative"
  }),
  menuList: a => ({
    ...a,
    borderRadius: "3px",
    position: "absolute",
    bottom: "0%",
    width: "100%",
    margin: "0 0 30% 0",
    backgroundColor: "white"
  })
};

export const sourceStyleDropUp = {
  container: base => ({
    ...base,
    width: "35%",
    backgroundColor: "1px solid rgb(218, 222, 225)",
    animation: "glow 300ms ease-out  alternate"
    //position: "relative"
  }),
  control: a => ({
    ...a,
    borderRadius: "3px",
    fontSize: "18px",
    height: "2.4em"
  }),
  singleValue: a => ({
    ...a,
    height: "2em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 9999
  }),
  menu: a => ({
    ...a,
    padding: "0",
    margin: "0 0 300px 0",
    position: "relative"
  }),
  menuList: a => ({
    ...a,
    borderRadius: "3px",
    position: "absolute",
    bottom: "0%",
    width: "100%",
    margin: "0 0 30% 0",
    backgroundColor: "white"
  })
};

export const textAreaStyle = {
  fontSize: "16px",
  resize: "none",
  borderRadius: "3px",
  width: '100%',
  border: '0',
  MozUserSelect: 'text',
};
