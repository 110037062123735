
const translateFile =  (url, body) => {
    try {
        const config = {
            headers: {
                authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
            method: 'POST',
            body: body,
        };

        return fetch(url, config);

    } catch (error) {
        console.error(error);
    }
};
export default translateFile;