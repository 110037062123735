import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// this component handles the customized textarea. Uses library react-quill

class Editor extends React.Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.quillRef = null;     
    this.reactQuillRef = null;
    this.state = {editorHtml : this.props.value};
  }

  componentDidMount() {
    this.attachQuillRefs()
  }

  componentDidUpdate() {
    this.attachQuillRefs()
  }

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== 'function') return;
    this.quillRef = this.reactQuillRef.getEditor();
  }
  handleChange (value) {
    // limit characters to 200
    var limit = 200;
    var quill = this.quillRef;
    quill.on('text-change', function (delta, old, source) {
      if (quill.getLength() > limit) {
       quill.deleteText(limit, quill.getLength());
      }
    });

    this.setState({ editorHtml: value });

    if (this.state.editorHtml.includes(" </p><p><br></p>")){
       // this is for cleaning up spaces and enter
      let newValue = this.state.editorHtml.replace(/\s+<p><br[/]?><[/]?p>/g,'');
      this.setState({ editorHtml: newValue });
    }
    // this is to avoid images uploaded by inputting text
    if (this.state.editorHtml.includes("<p>&lt;img ")){
      let newValueNoImage = this.state.editorHtml.replace(/<p>&lt;img.*<[/]?p>/g,'');
      this.setState({ editorHtml: newValueNoImage });
    }

    // this is to avoid images uploaded by drag and drop
    if (this.state.editorHtml.includes("<p><img ")){
      let newValueNoImageDropped = this.state.editorHtml.replace(/<p><img.*<[/]?p>/g,'');
      this.setState({ editorHtml: newValueNoImageDropped });
    }

    // need to control that no empty lines are inserted, otherwise the app crashes
    if (!this.state.editorHtml.includes("<p><br></p>")){
      // this is for cleaning up spaces before </p>
      let newValue = this.state.editorHtml.replace(/\s+<\/p>/g,'</p>');
      // saving changes in the db
      let prevOrg = this.props.org;
      prevOrg.uiAssets.footerPermanentMessageContent = newValue;
      this.props.setOrg({ ...prevOrg });
      // this function allows to save the changes in the db
      this.props.setFormChanged(true);
      // we pass the value back to the parent component
      this.props.onChange(newValue);
    }
  }

  render () {
    return  <ReactQuill 
            ref={(el) => { this.reactQuillRef = el }}
            theme="snow"
            onChange={this.handleChange}
            value={this.state.editorHtml  || ''}
            modules={{toolbar: [['bold', 'italic', 'underline'],['link'],]}}
            />
  }
}

export default Editor;
