import {Form, Switch} from "antd";
import React from "react";

const EnginesDropdownSwitch = ({handleEngineSelectionDropdown, engineSelectionDropdown}) => {
    return (
        <Form.Item
            label={<label>Display engine selection dropdown</label>}
            name="engineSelectionDropdown"
            initialValue={engineSelectionDropdown}
            htmlFor={undefined}
        >
            <Switch
                checked={engineSelectionDropdown}
                onChange={handleEngineSelectionDropdown}
                style={{ display:'inline', marginLeft: "20px"}}
            ></Switch>
        </Form.Item>
    );
};

export default EnginesDropdownSwitch;