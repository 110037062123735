import React from "react";
import {Dropdown, Menu, Space} from "antd";
import {DownloadButtonWithColors} from "../Styles";
import DownloadAllIconComponent from "../../../icons/DownloadAllIconComponent"
import DownArrowHeadIconComponent from "../../../icons/DownArrowHeadIconComponent";
import Tippy from "@tippyjs/react";


const TranslateFileDropdown = ({file, props, state, downloadSource, downloadFileAsPdf, downloadTranslatedFile}) => {
    return (
        <>
            <Dropdown.Button
                icon={ 
                <Tippy content={props.t('documentSubRowChildDownloadMoreOptions')}>
                    <div className="svgStyledOnHover" style={{width: "132%"}}>
                        <DownArrowHeadIconComponent accessibility={props.t('documentSubRowChildDownloadMoreOptions')} color="#323232"/>
                    </div>
                </Tippy> }
                overlay={
                    <Menu
                        items={[
                            {
                                key: '1',
                                label: (
                                    <DownloadButtonWithColors className="download-source-btn"  colors={props.colors} onClick={()=>downloadSource(file)}>
                                        {props.t('documentSubRowChildDownloadSourceDropdown')}
                                    </DownloadButtonWithColors>
                                ),
                            },
                            // when file format is convertible to pdf, the button is displayed
                            state.formatsDownloadbleAsPdf.some(
                                (el) => (el === file.fileName.substring(file.fileName.length - 4).toLowerCase() || el === file.fileName.substring(file.fileName.length - 3).toLowerCase())
                            ) && file.status === 'TranslatedConverted' &&
                            {
                                key: '2',
                                label: (
                                    <DownloadButtonWithColors className="download-source-btn"  colors={props.colors}  onClick={()=>downloadFileAsPdf(file)}>
                                        {props.t('documentSubRowChildDownloadPdfDropdown')}
                                    </DownloadButtonWithColors>
                                ),
                                disabled: true,
                            }
                        ]}
                    />
                } placement="bottomRight"
                trigger={['click']}
            >
                <Tippy animation="scale" content={props.t('documentSubRowChildDownloadTippy')}>
                        {((file.status === 'Translated') || file.status === 'TranslatedConverted') &&
                            !state.loadingDownload &&
                            <div className="svgStyledOnHover" width="40px" height="40px" onClick={() => { downloadTranslatedFile(file)}}> 
                                <DownloadAllIconComponent accessibility={props.t('documentSubRowChildDownloadTippy')} disabled={false}/>
                            </div>
                            ||
                            <div className="svgStyledOnHover" width="40px" height="40px"> 
                                <DownloadAllIconComponent accessibility={props.t('documentSubRowChildDownloadTippy')} disabled={true} />
                            </div>
                        }
                </Tippy>
            </Dropdown.Button>
        </>
    );
};

export default TranslateFileDropdown;