import React, {Component} from 'react';
import { GLAI_URL, TS_CLOUD_URL } from '../../utils/config';
import { withTranslation } from 'react-i18next';
import './style.css';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { getLangName } from '../LanguageSelection/LanguageStrings';
import getTransportStatus from "../../apiCalls/components/CHSubChildren/getTransportStatus";
import getTransportFileById from "../../apiCalls/components/CHSubChildren/getTransportFileById";
import downloadFileById from "../../apiCalls/components/CHSubChildren/downloadFileById";
import getTranslatedFileById from "../../apiCalls/components/CHSubChildren/getTranslatedFileById";
import {downloadFileFromBrowser} from "../../utils/downloadFileFromBrowser";
import {style} from './Styles';
import getPdfFileById from "../../apiCalls/components/CHSubChildren/getPdfFileById";
import TransportRow from "./TableComponents/transportRow";
import ErrorRow from "./TableComponents/errorRow";
import DownloadFileCell from "./TableComponents/downloadFileCell";
import CategoryCell from "./TableComponents/categoryCell";
import StatusCell from "./TableComponents/statusCell";
import ActionsCell from "./TableComponents/actionsCell";

export class SubmissionChildren extends Component {
  state = {
    deleting: false,
    loadingDownload: false,
      loadingDownloadSourceFile: false,
      loadingDownloadPdf: false,
    transportOpen: false,
    transportCheckStatusResponse: '',
    transportStatus: '',
    transportLink: '',
    transportLoading: false,
      visibleHumanTranslationBtn: false,
      showInfoMessage: false,
      showCopiedMessage: false,
      hideActionsDropdown: true,
      formatsDownloadbleAsPdf: ['doc','docm','docx','dot','dotm','dotx','odp','ods','odt','ppt','pptx','rtf','rtof', 'xls', 'xlsm', 'xlsx','pdf','jpg','jpeg','png'],
      supportEmailTo: "aisupport@transperfect.com",
      supportEmailCc: "",
      supportEmailBody: ""
  };

  componentDidMount = () => {
      // checking file status as soon as component is mounted, so we'll be able to display the icons
      const { file } = this.props;
      this.checkTransportStatus(file);
      if(this.props.colors.supportEmailTo) {
          this.setState({supportEmailTo: this.props.colors.supportEmailTo, supportEmailCc: this.props.colors.supportEmailCc});
      }
  }

  checkTransportStatus = (file) => {
      // checking status only for orgs with TransPort
      if ((this.props.userClaims && this.props.userClaims.clients.includes('TransPort')) && (this.props.userClaims.directory !== 'TPNYC') && (!this.props.colors.hideTransport))
      {
          this.setState({transportLoading: true});
          let url = `${GLAI_URL}/apigateway/transport/status/${file.fileId}`;
          getTransportStatus(url)
              .then((res) => {
                  if (res.status === 204) { //204 means no content
                      this.setState({transportLoading: false, showTransportCreate: true});
                      return;
                  }
                  return res.json();
              })
              .then((json) => {
                  if (!json) {
                      return;
                  }
                  if (json.success === false) {
                      this.setState({
                          transportError: true,
                          transportCheckStatusResponse: json.message,
                          transportLoading: false,
                          showTransportCreate: false,
                      });
                      return;
                  }
                  this.setState({
                      transportCheckStatusResponse: json.message,
                      transportStatus: json.status,
                      transportLink: json.link,
                      transportLoading: false,
                      transportError: false,
                      showTransportCreate: false,
                  });
              })
              .catch((e) => alert(e));
      }
  };

  startHumanTraslationRequest = (file) => {
        this.setState({ transportLoading: true });
        getTransportFileById(file.fileId)
            .then((res) => res.json())
            .then((json) => {
                if (json.success === false) {
                    this.setState({
                        transportError: true,
                        transportCheckStatusResponse: json.message,
                        transportLoading: false,
                        showTransportCreate: false,
                    });
                } else {
                    this.setState({
                        transportCheckStatusResponse: json.message,
                        transportStatus: json.status,
                        transportLink: json.link,
                        transportLoading: false,
                        showTransportCreate: false,
                    });
                    // opening transport in a new tab
                    window.open(this.state.transportLink, '_blank');
                }
            });
  };

  downloadTranslatedFile = (file) => {
        this.setState({loadingDownload: true});
        getTranslatedFileById(file.fileId)
            .then(async (res) => {
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                let fname = res.headers.get('Filename');
                let decoded = decodeURIComponent(
                    fname.replace(/\+/g, ' ')
                );
                a.download = decoded || file.fileName;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove(); //afterwards we remove the e
            })
            .finally(() => {
                this.props.getStatus();
                this.setState({loadingDownload: false});
            });
  }

  downloadSource = (file) => {
      this.setState({ loadingDownloadSourceFile: true });
      downloadFileById(file.fileId)
      .then(async (res) => {
          if (!res.ok) throw new Error('Download request failed');
          let blob = await res.blob();
          downloadFileFromBrowser(res, blob, file);
      })
      .catch((err) => {
        console.error(err);
      }).finally(() => {
          this.props.getStatus();
          this.setState({ loadingDownloadSourceFile: false });
      });
  };

  downloadFileAsPdf = (file) => {
        this.setState({ loadingDownloadPdf: true });
        getPdfFileById(file.fileId)
            .then(async (res) => {
                if (!res.ok) throw new Error('Download request failed');
                let blob = await res.blob();
                downloadFileFromBrowser(res, blob, file);
            })
            .catch((err) => {
                console.error(err);
            }).finally(() => {
            this.props.getStatus();
            this.setState({ loadingDownloadPdf: false });
        });
  };

    editFileOnTSCloud = (file) => {
        const url = `${TS_CLOUD_URL}/TSC/open/AI?fileId=${file.fileId}&phaseName=PED&url=${GLAI_URL}`;
        window.open(encodeURI(url), '_blank');  //open new tab
    };

  showInfoMessage = () => {
        this.setState(
            { showInfoMessage: !this.state.showInfoMessage });
  }

  checkIfTransportIsAvailable = (file, closeIcon) => {
        this.setState(
            {
                transportOpen: !this.state.transportOpen,
                visibleHumanTranslationBtn: !this.state.visibleHumanTranslationBtn,
            },
            () => this.checkTransportStatus(file)
        )

      if(closeIcon) {
          this.setState({hideActionsDropdown: true});
      } else {
          this.setState({hideActionsDropdown: false});
      }
  }

  openActionsDropdown = () => {
      this.setState({hideActionsDropdown: false});
  }

  buildDate = (date) => {
        const d = new Date(date);
        let day = d.getDate();
        const month = d.getMonth()+1;
        let year = d.getFullYear();
        const hour = d.getHours();
        const minutes = String(d.getMinutes()).padStart(2, "0");

        return month + "/" + day + "/" + year + ", " + hour + ":" + minutes;
    }

  render() {
    const { file } = this.props;
    return (
      <>
                <tr
                  className="subChild"
                  style={{
                    opacity: this.state.deleting ? 0.5 : 1,
                  }}
                >
                  <td
                    tabIndex="0"
                    style={this.props.showCategory ? style.colSizeM : style.colSizeL}
                  >
                    <Tippy content={file.fileName}>
                        <div className="centered-text-cell">
                            <div className="text">
                            <span className="fileName">{file.fileName}</span>
                            </div>
                        </div>
                    </Tippy>
                  </td>
                    {this.props.showCategory &&
                       <CategoryCell file={file}/>
                    }
                  <td
                    tabIndex="0"
                    style={{
                      ...style.colSizeS,
                    }}
                  >
                      <div className="centered-text-cell">
                        {this.buildDate(file.subInfo.date)}
                      </div>
                  </td>
                  <td tabIndex="0" style={style.colSizeM}>
                      <div className="centered-text-cell">
                        {`${getLangName(file.sourceLang)} ${this.props.t(
                          'documentSubRowChildLanguageTo'
                        )} ${getLangName(file.targetLang)}`}
                      </div>
                  </td>

                    <StatusCell file={file} props={this.props} state={this.state} showInfoMessage={() => this.showInfoMessage()}/>

                    {/*empty cell*/}
                    <td
                        className="statusTd"
                        tabIndex="0"
                        style={{
                            ...style.colSizeS,
                            color: file.status === 'Error' ? 'red' : style.color,
                        }}
                    >
                        <div className="centered-text-cell">
                        </div>
                    </td>

                    <DownloadFileCell file={file} props={this.props} state={this.state} downloadSource={(file) => this.downloadSource(file)} downloadFileAsPdf={(file) => this.downloadFileAsPdf(file)} downloadTranslatedFile={(file) => this.downloadTranslatedFile(file)}/>

                    <ActionsCell file={file} {...this.props} state={this.state} checkIfTransportIsAvailable={(file, closeIcon) => this.checkIfTransportIsAvailable(file, closeIcon)} editFileOnTSCloud={() => this.editFileOnTSCloud(file)} openActionsDropdown={() => this.openActionsDropdown()}/>
                </tr>

                  {this.state.showInfoMessage && (
                    <ErrorRow file={file} props={this.props} state={this.state}/>
                  )}

                {this.state.visibleHumanTranslationBtn && this.state.transportOpen && (
                    <TransportRow file={file} startHumanTraslationRequest={(file) => this.startHumanTraslationRequest(file)} props={this.props} state={this.state}/>
                )}
      </>

    );
  }
}

export default withTranslation()(SubmissionChildren);
