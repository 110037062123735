import React from "react";

const FileIconComponent = ({accessibility, size}) => {
    const viewBox = "0 0 "  + size + " " + size;
    return (
        <svg width="24" height={size} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_50_21074)">
                <path d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM6 20V4H13V9H18V20H6Z" fill="#323232"/>
            </g>
            <defs>
                <clipPath id="clip0_50_21074">
                    <rect width={size} height={size} fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default FileIconComponent;