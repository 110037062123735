import {GLAI_URL} from "../../../utils/config";

const getEnginesByOrg =  (orgId) => {
    try {
        let url = `${GLAI_URL}/apigateway/mtengine/organization/information/all`;

        const config = {
            headers: {
                authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
                'organizationId': orgId,
            },
            method: 'GET',
        };

        return fetch(url, config)
    } catch (error) {
        console.error(error);
    }
};

export default getEnginesByOrg;