
const getFAQFile =  (url) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
                fileType: 'faq_file',
            },
        };

        return fetch(url, config);

    } catch (error) {
        console.error(error);
    }
};
export default getFAQFile;