
const getTransliteratedText =  (url, body) => {
    try {
        const config = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
            body: body
        };

        const result =  fetch(url, config);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export default getTransliteratedText;