import {Form, Input } from "antd";
import React from "react";

const OrgNameField = ({org}) => {
    return (
        <Form.Item
            label="Name of Organization"
            name="name"
            initialValue={org.name}
            rules={[
                {
                    required: true,
                    message: 'Please input an organization name!',
                },
            ]}
        >
            <Input/>
        </Form.Item>
    );
};

export default OrgNameField;