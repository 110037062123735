import React from "react";
import {style} from "../Styles";


const CategoryCell = ({file}) => {
    return (
        <>
            <td
                tabIndex="0"
                style={{
                    ...style.colSizeS,
                    justifyContent: 'stretch',
                }}
            >
                <div style={{flexGrow: 1}} className="centered-text-cell">
                    {file.subInfo.category ?  file.subInfo.category : "-"}
                </div>
            </td>
        </>
    );
};

export default CategoryCell;