import React from "react";
import { withTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import {connect} from "react-redux";

const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
        <span className="triggerIcon"
              role="presentation"
              aria-hidden="true"
        />
    </components.DropdownIndicator>
);

function CategorySelection(props){

    return  (
            <>
                    <Select
                        className="categorySelection"
                        options={props.categoriesListState}
                        placeholder={props.t('categoryPlaceholder')}
                        value={!!props.selectedCategory ? props.selectedCategory : props.t('categoryPlaceholder')}
                        name="category"
                        onChange={props.handleCategoryChange}
                        menuShouldScrollIntoView={false}
                        components={{DropdownIndicator}}
                        noOptionsMessage={() => props.t('langSelect_NoResultsMessage')}
                        menuPlacement={!props.menuPlacement ? "auto" : props.menuPlacement}
                        label="category"
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: props.dropdownHover,
                                primary: props.dropdownSelected,
                                neutral0: "#ffffff",
                            },
                        })}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                height: '100%',
                                border: (props.errorMessage === props.t('mandatoryCategoryError') || props.mandatoryCategoryError? '2px solid #ff0000' : '1px solid rgb(179, 179, 179)'),
                                borderRadius: '3px',
                            }),
                            valueContainer: () => ({
                                paddingLeft: '5px'
                            }),
                            indicatorSeparator: (provided) => ({
                                ...provided,
                                display: 'none',
                            }),
                            menu: (provided) => ({
                                ...provided,
                                marginTop: "3px"
                            }),
                            menuList: (provided) => ({
                                ...provided,
                                borderRadius: "5px",
                                padding: 0
                            }),
                            option: (provided) => ({
                                ...provided,
                                fontSize: "16px",
                                height: "40px",
                                padding: "10px",
                                fontWeight: "400"
                            }),
                        }}
                    />
            </>
        )
}

const mapStateToProps = (state) => ({
    store: state,
    dropdownSelected: state.user.organization.uiAssets.dropdownSelected,
    dropdownTitleColor: state.user.organization.uiAssets.dropdownTitleColor,
    dropdownHover: state.user.organization.uiAssets.dropdownHover,
});

export default withTranslation()(
    connect(mapStateToProps)(CategorySelection)
);

