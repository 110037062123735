const KEY_CODES = Object.freeze({
  TAB: "Tab",
  RETURN: "Enter",
  ESC: "Escape",
  SPACE: "Space",
  PAGEUP: "PageUp",
  PAGEDOWN: "PageDown",
  END: "End",
  HOME: "Home",
  LEFT: "ArrowLeft",
  UP: "ArrowUp",
  RIGHT: "ArrowRight",
  DOWN: "ArrowDown"
});

export default KEY_CODES;
