import {GLAI_URL} from "../../../utils/config";

const getPdfFileById =  (fileId) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem(
                    'access_token'
                )}`,
                fileType: 'final_pdf',
            },
        };

        const url = `${GLAI_URL}/apigateway/storage/${fileId}?fileType=final_pdf`;

        return fetch(url, config);

    } catch (error) {
        console.error(error);
    }
};
export default getPdfFileById;