import createQuotaByOrg from "../../../apiCalls/Usermanagement/EditOrgPage/createQuotaByOrg";
import modifyQuotaByOrg from "../../../apiCalls/Usermanagement/EditOrgPage/modifyQuotaByOrg";
// saves the quota for new orgs and old ones
export const saveQuota = (isOrgNew, newOrgId, orgId, quotaActive, overages, quotaType, limitType, counterLimit, hasUserQuota, counterLimitUser, maxWordsPerDocument, hasDemoExpiration, demoExpiration) => {
    if(isOrgNew){
        orgId = newOrgId;
    }
    new Promise((resolve, reject) => {
        const bodyQuota = {
            quotaActive: quotaActive,
            overages: overages,
            quotaType: quotaType,
            limitType: limitType,
            counterLimit: counterLimit,
            hasUserQuota: hasUserQuota,
            userCounterLimit: counterLimitUser,
            expirationDate: demoExpiration,
            hasExpiration: hasDemoExpiration,
            maxWordPerDocument: maxWordsPerDocument,
        };

        if (isOrgNew) {
            bodyQuota.orgID = orgId
            const bodyQuotaJson = JSON.stringify(bodyQuota);
            createQuotaByOrg(bodyQuotaJson) //calling endpoint to create quota
                .then((res) => {
                    if (res.ok) {
                        resolve();
                    } else {
                        throw new Error('Request failed');
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject(err);
                });
        } else {
            const bodyQuotaJson = JSON.stringify(bodyQuota);
            modifyQuotaByOrg(bodyQuotaJson, orgId) //calling endpoint to modify quota
                .then((res) => {
                    if (res.ok) {
                        resolve();
                    } else {
                        throw new Error('Request failed');
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject(err);
                });
        }
    });
}