import modifyOrganizationByIdFromMtengine
    from "../../../apiCalls/Usermanagement/EditOrgPage/modifyOrganizationByIdFromMtengine";
import {Button} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import React from "react";

export const updateProfiles = (profiles, props) =>
    new Promise((resolve, reject) => {
        let body = JSON.stringify({ profiles: profiles });
        modifyOrganizationByIdFromMtengine(body, props.match.params.orgId)
            .then((res) => {
                if (res.ok) {
                    resolve();
                } else {
                    throw new Error('Request failed');
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });

export const buildProfilesTree = (tree, profiles, engineEntities, deleteSource, deleteTarget, deleteEntity, props) => {
    for (const [sourceLang, profile] of Object.entries(profiles)) {
        let sourceNode = {
            sourceLanguage: props.t(sourceLang),
            key: sourceLang,
            children: [],
            delete: (
                <Button size="small" onClick={() => deleteSource(sourceLang)}>
                    <DeleteOutlined />
                </Button>
            ),
        };
        for (const [targetLang, engineArray] of Object.entries(profile)) {
            let targetNode = {
                targetLanguage: props.t(targetLang),
                key: sourceLang + '-' + targetLang,
                children: [],
                delete: (
                    <Button
                        size="small"
                        onClick={() => deleteTarget(sourceLang, targetLang)}
                    >
                        <DeleteOutlined />
                    </Button>
                ),
            };
            for (const [idx, engineObj] of engineArray.entries()) {
                targetNode.children.push({
                    title: idx,
                    engine: engineEntities[engineObj.engine],
                    failover: engineEntities[engineObj.failover],
                    key:
                        '' +
                        sourceLang +
                        '-' +
                        targetLang +
                        '-' +
                        engineObj.engine +
                        '-' +
                        engineObj.failover,
                    delete: (
                        <Button
                            size="small"
                            onClick={() => deleteEntity(sourceLang, targetLang, idx)}
                        >
                            <DeleteOutlined />
                        </Button>
                    ),
                });
            }

            sourceNode.children.push(targetNode);
        }
        tree.push(sourceNode);
    }
}

export const loadProfiles = (profiles) => {
    for (const [src, trgObj] of Object.entries(profiles)) {
        for (const [trg, engineArray] of Object.entries(trgObj)) {
            profiles[src][trg] = engineArray.map((item) => ({
                engine: item.engine.id,
                failover: item.failover.id,
            }));
        }
    }
}