import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import LanguageSelection from '../../../../components/LanguageSelection';
import { Button } from 'antd';

const EditOrgStylePreview = ({ uiAssets, uiAssetsDefault}) => {
  const [exampleBtnColor, setBtnC] = useState(uiAssets.basicButtonBackground ? uiAssets.basicButtonBackground : uiAssetsDefault.basicButtonBackground);

  return (
    <>
      <div
        style={{
          overflow: 'auto',
          height: '100%',
          width: '100%',
          border: '2px solid black',
          background: uiAssets.appBackground ? uiAssets.appBackground : uiAssetsDefault.appBackground,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '64px',
            margin: '0 0 20px',
            background: uiAssets.headerBackground ? uiAssets.headerBackground : uiAssetsDefault.headerBackground,
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 0,
            flexShrink: 0,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <img
            style={{
              height: '50px',
              maxWidth: '270px',
              marginLeft: '2em',
            }}
            src={uiAssets.headerLogo ? uiAssets.headerLogo : uiAssetsDefault.headerLogo}
            alt="Logo"
          />
          <div
            style={{ marginRight: '1em', color: uiAssets.dropdownTitleColor ? uiAssets.dropdownTitleColor : uiAssetsDefault.dropdownTitleColor}}
          >
            Menu
          </div>
        </div>
        <div
          style={{
            width: '85%',
            margin: '0 0 20px',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              width: '260px',
              height: '64px',
              background: uiAssets.navbarActive  ? uiAssets.navbarActive : uiAssetsDefault.navbarActive,
              borderBottom: `5px solid ${uiAssets.navbarBottomBorder ? uiAssets.navbarBottomBorder : uiAssetsDefault.navbarBottomBorder}`,
              borderRadius: '3px 0 0 3px',
              color: uiAssets.navbarActiveText ? uiAssets.navbarActiveText : uiAssetsDefault.navbarActiveText,
              fontSize: '22px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span>Text</span>
          </div>
          <div
            style={{
              width: '260px',
              height: '64px',
              background: uiAssets.navbarIdle  ? uiAssets.navbarIdle : uiAssetsDefault.navbarIdle,
              borderRadius: '0 3px 3px 0',
              color: uiAssets.navbarIdleText ? uiAssets.navbarIdleText : uiAssetsDefault.navbarIdleText,
              fontSize: '22px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span>Document</span>
          </div>
        </div>

        <div
          style={{
            width: '85%',
            display: 'flex',
            margin: '0 0 20px',
          }}
        >
          <div
            style={{
              width: '50%',
            }}
          >
            <LanguageSelection
              source
              style={{
                width: '280px !important',
              }}
            />
          </div>
          <div
            style={{
              width: '50%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <LanguageSelection
              target
              style={{
                width: '280px',
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '85%',
            margin: '0 0 20px',
          }}
        >
          <textarea
            style={{
              fontSize: '16px',
              resize: 'none',
              width: '100%',
              height: '307.6px',
              border: '1px solid #dadee1',
              borderRadius: '3px',
              boxShadow: '0 2px 4px rgb(0 0 0 / 50%)',
            }}
            placeholder="Type or paste your text here..."
          />
        </div>
        <div
          style={{
            display: 'flex',
            width: '85%',
            margin: '0 0 20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '50%',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{
                border: 'none',
                width: '180px',
                height: '40px',
                fontSize: '16px',
                fontWeight: '400',
                borderRadius: '3px',
                verticalAlign: 'middle',
                background: uiAssets.translationButtonBackground  ? uiAssets.translationButtonBackground : uiAssetsDefault.translationButtonBackground,
                color: uiAssets.translationButtonText ? uiAssets.translationButtonText : uiAssetsDefault.translationButtonText,
              }}
            >
              Translate
            </Button>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '85%',
            margin: '0 0 20px',
            justifyContent: 'center',
          }}
        >
          <Button
            style={{
              height: '32px',
              background: exampleBtnColor,
              color: uiAssets.basicButtonText ? uiAssets.basicButtonText : uiAssetsDefault.basicButtonText,
            }}
            onMouseEnter={(e) => setBtnC(uiAssets.basicButtonBackgroundHover ? uiAssets.basicButtonBackgroundHover : uiAssetsDefault.basicButtonBackgroundHover)}
            onMouseLeave={(e) => setBtnC(uiAssets.basicButtonBackground ? uiAssets.basicButtonBackground : uiAssetsDefault.basicButtonBackground)}
          >
            Button Example
          </Button>
          <Button
            style={{
              height: '32px',
              background: uiAssets.basicButtonBackgroundHover ? uiAssets.basicButtonBackgroundHover : uiAssetsDefault.basicButtonBackgroundHover,
              color: uiAssets.basicButtonText  ? uiAssets.basicButtonText : uiAssetsDefault.basicButtonText,
            }}
          >
            Hover Button Example
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            width: '85%',
            margin: '0 0 20px',
            justifyContent: 'center',
          }}
        >
          <span>© 2021 Translations.com All rights reserved.</span>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUserEmail: state.user.email,
});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EditOrgStylePreview)
);
