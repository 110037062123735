import {Form, Input, Radio, Switch} from "antd";
import React from "react";

const QuotaSettings = ({
                           overages,
                           handleQuotaPolicy,
                           limitType,
                           limitTypeOptions,
                           handleLimitType,
                           counterLimit,
                           handleCounterLimit,
                           quotaType
                        }) => {
    return (
        <>

            <p><span style={{color: "red", paddingRight: "5px"}}>*</span>Select amount, unit and flexibility</p>
            <div className="quotaFormWrapper">
                <Form.Item name="counterLimit" style={{width: "130px"}} initialValue={counterLimit}>
                    <Input type={"number"} style={{width: "110px"}} onChange={(e) => handleCounterLimit(e.target.value)} value={counterLimit} min={0} max={Number.MAX_SAFE_INTEGER}/>
                    {/*this empty span needs to be added otherwise min and max won't be recognized*/}
                    <span></span>
                </Form.Item>

                <Form.Item name="limitType" initialValue={limitType}>
                    <Radio.Group
                        options={limitTypeOptions}
                        onChange={(e) => handleLimitType(e.target.value)}
                        value={limitType}
                        optionType="button"
                    />
                </Form.Item>

                {quotaType !== 3 &&
                    <Form.Item
                        label="Allow overages"
                        name="overages"
                        style={{paddingLeft: "20px", paddingTop: "5px"}}
                    >
                        <Switch style={{marginBottom: "5px", marginLeft: "15px"}} checked={overages} onChange={handleQuotaPolicy}/>
                    </Form.Item>
                }
            </div>
        </>
    );
};

export default QuotaSettings;
