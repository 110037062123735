export const setCurrentUser = userData => ({
  type: "SET_CURRENT_USER",
  userData
});

export const logout = () => ({
  type: "LOGOUT"
});

export const setSource = source => ({
  type: "SET_SOURCE",
  source
});

export const setTarget = target => ({
  type: "SET_TARGET",
  target
});

export const setEngines = engines => {
  return {
  type: "SET_ENGINES",
  engines
}};

//global options
export const setUserStatus = status => ({
  type: "SET_USER_STATUS",
  status
});

export const setUploading = uploading => ({
  type: "SET_UPLOADING",
  uploading
});

export const setSubmissions = submissions => ({
  type: "SET_SUBMISSIONS",
  submissions
});

export const rmUploading = files => ({
  type: "RM_UPLOADING",
  files
});

export const selectFile = fileId => ({
  type: "SELECT_FILE",
  fileId
});

export const deselectFile = fileId => ({
  type: "DESELECT_FILE",
  fileId
});

export const selectAll = () => ({
  type: "SELECT_ALL"
});

export const deselectAll = () => ({
  type: "DESELECT_ALL"
});

export const rmSelected = () => ({
  type: "RM_SELECTED_FILES"
});

export const setPid = pid => ({
  type: "SET_PID",
  pid
});
export const rmPid = pid => ({
  type: "RM_PID",
  pid
});
export const setDone = done => ({
  type: "SET_DONE",
  done
});
export const setInProgress = inpr => ({
  type: "SET_INPROGRESS",
  inpr
});
export const setTermsAcknowledged = acknowledged => ({
  type: "SET_TERMS_ACKNOWLEDGED",
  acknowledged
});

export const setAppLanguage = appLanguage => ({
  type: "SET_APP_LANGUAGE",
  appLanguage
});