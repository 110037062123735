import EllipseTranslation from "../../icons/EllipseTranslationComponent";
import React from "react";

const SuccessfulTranslationMessage = ({translate}) => {
    return (
        <>
            <div><EllipseTranslation accessibility={translate('documentSubRowChildStatusTranslatedSuccess')} color={"#36A93F"}/></div>
            <p className="translation-successful-text">{translate('documentSubRowChildStatusTranslatedSuccess')}</p>
        </>
    );
};

export default SuccessfulTranslationMessage;