import {Form} from "antd";
import React from "react";
import CustomLangnameInput from "../../../../components/CustomLangnameInput";
import {LOCALIZATION_LANGUAGES} from "../settingValues";

const CustomLanguagesNameSelection = ({org, isNew, orgSourceLanguages, orgTargetLanguages}) => {
    return (
        <Form.Item
            label={
                <div>
                    <label>Custom Languages Names</label>{' '}
                    <p
                        style={{
                            fontSize: '12px',
                            color: 'grey',
                            textAlign: 'left',
                        }}
                    >
                        {
                           'For each language you pick on website language, you can choose a custom name to have languages displayed on the UI.'
                        }
                    </p>
                </div>
            }
            name="customLangNames"
            initialValue={isNew ? {} : org.uiAssets.customLangNames}
        >
            <CustomLangnameInput
                localizations={LOCALIZATION_LANGUAGES} //getting all localization languages
                availableLangs={orgSourceLanguages.concat(
                    orgTargetLanguages.filter(
                        (trgLang) => !orgSourceLanguages.includes(trgLang)
                    )
                )}
            />
        </Form.Item>
    );
};

export default CustomLanguagesNameSelection;