import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './redux/reducers/rootReducer';
import { defaultState } from './redux/reducers/rootReducer';
import { loadState, saveState } from './localStorage';
import throttle from 'lodash/throttle';
import './index.css';
import App from './App';
import './utils/strings';
import AuthService from './utils/authService';
import { initLangNames } from './components/LanguageSelection/LanguageStrings';
import { initEngineNames } from './components/EngineSelection/getEnginesCustomizedNames';

let persistedState = loadState();
if (
  !persistedState ||
  !persistedState.user.organization ||
  (persistedState.user.organization &&
    !persistedState.user.organization.uiAssets)
) {
  persistedState = defaultState;
}

const store = createStore(
  rootReducer,
  persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const autest = new AuthService();
const domain = document.domain;
store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1500)
);

initLangNames(store);
initEngineNames(store);


ReactDOM.render(
  <Provider store={store}>
    <App a={autest} domain={domain} />
  </Provider>,
  document.getElementById('root')
);
