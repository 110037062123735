import React, { Component } from 'react';
import { LOCAL_STORAGE_KEY } from '../../utils/localStorageKeys';

class SigninComponent extends Component {
  componentDidMount() {
    let tempNotificationAck = localStorage.getItem(
      LOCAL_STORAGE_KEY.NOTIFICATION_ACKNOWLEDGED
    );
    localStorage.clear();
    if (tempNotificationAck !== null && !!tempNotificationAck) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY.NOTIFICATION_ACKNOWLEDGED,
        tempNotificationAck
      );
    }
    if (this.props.a) {
      this.props.a.signinRedirect();
    } else {
      this.props.history.push('/login');
    }
  }
  render() {
    return <div></div>;
  }
}

export default SigninComponent;
