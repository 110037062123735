
export const rtl = ['ar', 'he', 'fa', 'ur'];
export const scriptMap = {
  "ar":"Arab",
  "bn":"Beng",
  "zh-CN":"Hans",
  "zh-TW":"Hant",
  "he":"Hebr",
  "hi":"Deva",
  "ja":"Jpan",
  "ko":"",
  "sr-RS-CYRL":"Cyrl",
  "th":"Thai",
};
