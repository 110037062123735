import React, { useState } from 'react';
import { connect } from 'react-redux';
import { SketchPicker } from 'react-color';
import './style.css';

const ColorPicker = ({isBottom, value = '#000000', onChange, changed }) => {
  const [visible, setVisibility] = useState(false);

  const handleClick = () => {
    setVisibility(!visible);
  };

  const handleClose = () => {
    setVisibility(false);
  };

  const handleChange = (color) => {
    onChange(color.hex);
  };

  return (
    <div>
      <div className="colorPickerSwatch" onClick={handleClick}>
        <div
          className="colorPickerColor"
          style={{
            backgroundColor: value,
            boxShadow: !!changed ? '#FFD900 0 0 3px 3px ' : undefined,
          }}
        />
      </div>
      {visible ? (
        <div className={isBottom ? "colorPickerPopover color-picker-move-up" : "colorPickerPopover" }>
          <div className="colorPickerCover" onClick={handleClose} />
          <SketchPicker
            color={value}
            onChange={handleChange}
            disableAlpha={true}
            width={180}
            presetColors={[]}
          />{' '}
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  uiAssets: state.user.organization.uiAssets,
});

export default connect(mapStateToProps, undefined)(ColorPicker);
