import i18n from "../../utils/strings";

let _store = {}

export function initLangNames(store){
  _store = store
}

export function getLangName(key) {
    let inLanguage = i18n.language.substring(0,2)
    let customLangNamesObj = _store.getState().user.organization.uiAssets.customLangNames
    let customLangName = undefined
    if(!!customLangNamesObj){
      let langList = customLangNamesObj[inLanguage]
      if(!!langList){
        customLangName = langList[key] || undefined
      }
    }
    return !customLangName ? i18n.t(key) : customLangName
}