import {getEngineName} from "./getEnginesCustomizedNames";
import QualityScoreNegativeFace from "../../icons/QualityScoreNegativeFace";
import React from "react";
import InfoQualityScore from "../../icons/InfoQualityScore";

export const searchForProfilesFilteredByLangs = (response, props) => {
    // loop through profiles looking for source lang
    let profilesBySrcLang = []
    for (var profile in response.profiles) {
        (profile === props.sourceLanguage) &&
        profilesBySrcLang.push(response.profiles)
    }
    // loop through profiles looking for target lang
    let profilesByTgtLang = []
    profilesBySrcLang.map(
        (profile) =>
            (profile[props.sourceLanguage][props.targetLanguage]) &&
            profilesByTgtLang.push(profile[props.sourceLanguage][props.targetLanguage])
    );

    // loop through filteredProfiles to get their name
    let filteredProfiles = [];
    for (let i=0; i< profilesByTgtLang.length; i++) {
        for (let x=0; x< profilesByTgtLang[i].length; x++) {
            filteredProfiles.push({
                key: profilesByTgtLang[i][x]["engine"]["id"],
                value: profilesByTgtLang[i][x]["engine"]["id"],
                label: getEngineName(profilesByTgtLang[i][x]["engine"]["id"]) ? getEngineName(profilesByTgtLang[i][x]["engine"]["id"]): profilesByTgtLang[i][x]["engine"]["alias"],
            })
        }
    }
    return filteredProfiles;
}

export const searchForEnginesFilteredByLangs = (response, props) => {
    //loop through the engines of the org and check if each engine support both src and tgt
    let enginesCompatibleWithLangs = [];
    let enginesCompatibleWithLangsNames = [];
    if (!!response.engines) {
    for (let engine of response.engines) {
        let langSupported = false;
        //for each engine check if it supports src lang
        for (let x = 0; x < (engine['supportedLanguages']).length; x++) {
            if ((engine['supportedLanguages'][x]) === props.sourceLanguage) {
                langSupported = true;
                break;
            }
        }
        //in case engine supports src lang, check if it supports tgt lang as well
        if (langSupported === true) {
            for (let x = 0; x < (engine['supportedLanguages']).length; x++) {
                if ((engine['supportedLanguages'][x]) === props.targetLanguage) {
                    langSupported = true;
                    break;
                } else {
                    langSupported = false;
                }
            }
        }
        // if both langs are supported, we'll save the list of engines
        if (langSupported === true) {
            enginesCompatibleWithLangs.push(engine);
        }
    }
    // getting name of engines compatible with the selected src and tgt

    for (let i = 0; i < enginesCompatibleWithLangs.length; i++) {
        enginesCompatibleWithLangsNames.push({
            key: enginesCompatibleWithLangs[i]["id"],
            value: enginesCompatibleWithLangs[i]["id"],
            label: getEngineName(enginesCompatibleWithLangs[i]["id"]) ? getEngineName(enginesCompatibleWithLangs[i]["id"]): enginesCompatibleWithLangs[i]["alias"],
            Icon: enginesCompatibleWithLangs[i]["hasQE"] ? <InfoQualityScore accessibility={"Information"} size={24}/> : "",
            hasQualityScore: enginesCompatibleWithLangs[i]["hasQE"],
        })
    }

}
    return enginesCompatibleWithLangsNames;
}
