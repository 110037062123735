import {Form, Input} from "antd";
import React from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

const DemoSettings = ({
                          handleDemoExpirationDate,
                          demoExpiration,
                        }) => {
    return (
        <>
                <div className="quotaFormWrapper" style={{marginLeft: "7%"}}>
                    <Form.Item
                        label="Date"
                        name="demoExpiration"
                    >
                        <div className="report-date-selection">
                            <DatePicker
                                name="expirationDate"
                                selected={demoExpiration}
                                onChange={handleDemoExpirationDate}
                                className="expirationDate"
                                popperPlacement="top-start"
                            />
                        </div>
                    </Form.Item>
                </div>


        </>
    );
};

export default DemoSettings;
