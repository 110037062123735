
export const deleteOrgEngine = (engineId, engineName, availableEngines, customizedEngines, setCustomizedEngines, setFormChanged, org, onChange, tempEngineObject, setTempEngineObject, setDeletingEngines, updateEngines, orgEngines) => {
    let enginesList = [...customizedEngines];
    let enginesListIds = [];
    for (let i=0; i < enginesList.length; i++) { //deleting element from array
        if (enginesList[i].id === engineId) {
            let engineIndex = enginesList.indexOf(enginesList[i]);
            (enginesList.splice(engineIndex, 1));
        }
    }
    setCustomizedEngines(enginesList);

    setFormChanged(true);

    setDeletingEngines(true);

    for (let i=0; i < enginesList.length; i++) { //building array of ids
        enginesListIds.push(enginesList[i].id)
    }

    updateEngines(enginesListIds); //updating engines in MT engine

    if(orgEngines.length > 0) { //not showing deleted engine as selected (highlighted in blue)
        availableEngines.map((engine) => {
            for (let i=0; i < orgEngines.length; i++) {
                if (engine.id === orgEngines[i]) {
                    engine["selected"] = false;
                }
            }
        })
    }
};

export const addNewOrgEngine = (engineId, availableEngines, customizedEngines, setCustomizedEngines, updateEngines) => {
    let enginesList = [];
    let enginesListIds = [];

    for (let i=0; i < availableEngines.length; i++) {
        if (availableEngines[i].id === engineId) {
            enginesList.push(...customizedEngines, availableEngines[i])
        }
    }
    //removing duplicates
    const uniqueListOfEngines = [...new Map(enginesList.map(item => [item['id'], item])).values()];

    setCustomizedEngines(uniqueListOfEngines)

    for (let i=0; i < uniqueListOfEngines.length; i++) { //building array of ids
        enginesListIds.push(uniqueListOfEngines[i].id)
    }

    updateEngines(enginesListIds); //updating engines in MT engine
};

export const handleEngineNameChange = (engine, value, onChange, org, tempEngineObject, setTempEngineObject) => {
    const clone = {...tempEngineObject};
    setTempEngineObject({...clone, [engine] :value});
};