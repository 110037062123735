import React, { Component } from 'react';

import { connect } from 'react-redux';
import { logout } from '../../redux/actions';
import { withTranslation } from 'react-i18next';
import './style.css';
import { Helmet } from 'react-helmet';

const btnMedium = {
  width: 120,
  border: 0,
  height: 40,
  marginTop: '2em',
  borderRadius: 2,
  fontSize: 16,
  fontWeight: '600',
  fontStyle: 'normal',
  textAlign: 'center',
};

class InactivityLogout extends Component {
  constructor(props) {
    super(props);

    if (props.a.isAuthenticated()) {
      props.reduxLogout();
      props.a.inactivityLogout().then(() => {
        props.history.go(0);
      });
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.t('inactivityLogoutTitle')}</title>
        </Helmet>
        <div className="logout" style={{padding: "24px"}}>
          <h2>{this.props.t('inactivityLogoutMessage')}</h2>
          <button
            aria-label="Log back in"
            style={{
              ...btnMedium,
              color: this.props.uiAssets.basicButtonText,
              backgroundColor: this.props.uiAssets.basicButtonBackground,
            }}
            onClick={() => {
              !!this.props.logoutRedrirectUrl
                ? window.location.replace(this.props.logoutRedrirectUrl)
                : this.props.a.signinRedirect();
            }}
          >
            {this.props.t('inactivityLogoutButtonLabel')}
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
  logoutRedrirectUrl: state.user.organization.uiAssets.logoutRedirectURL,
  uiAssets: state.user.organization.uiAssets,
});

const mapDispatchToProps = (dispatch) => ({
  reduxLogout: () => dispatch(logout()),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(InactivityLogout)
);
