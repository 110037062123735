export const downloadFileFromBrowser = (res, blob, file) => {
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    let fname = res.headers.get('Filename');
    if (fname == null) throw new Error('File is null');
    let decoded = decodeURIComponent(fname.replace(/\+/g, ' '));
    a.download = decoded || file.fileName;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); //afterwards we remove the e
};