const DownloadZippedFolderIconComponent = ({accessibility}) => {
	return(
		<svg title={accessibility} width="24" height="24" version="1.1"  x="0px" y="0px"
			viewBox="0 0 24 24">
			<desc>{accessibility}</desc>
		<g>
			<path  d="M20,5.3h-8l-2-2.2H4c-1.1,0-2,1-2,2.2v13.3c0,1.2,0.9,2.2,2,2.2h16c1.1,0,2-1,2-2.2V7.6 C22,6.3,21.1,5.3,20,5.3z M20,18.7H4V5.3h5.2l2,2.2H20V18.7z"/>
		</g>
		<rect x="11.2" y="7.3" width="2" height="5.6"/>
		<rect x="9.4" y="8.6" width="5.5" height="1.5"/>
		<g>
			<path  d="M13.4,13.2c0,1,0,1.4,0,2.4c-1,0.2-1.6,0.2-2.6,0c0-1,0-1.4,0-2.4C11.9,13.3,12.4,13.3,13.4,13.2 M14.6,11.5
				c-2,0-2.9,0-4.9,0c-0.2,0-0.3,0.1-0.3,0.3c0,1.8,0,2.7,0,4.5c0,0.2,0.1,0.4,0.3,0.5c1.8,1.1,3.1,1.1,5,0c0.2-0.1,0.3-0.3,0.3-0.5
				c0-1.8,0-2.7,0-4.5C14.9,11.6,14.8,11.5,14.6,11.5L14.6,11.5z"/>
		</g>
		</svg>
)
};

export default DownloadZippedFolderIconComponent