import React from "react";
import EllipseTranslation from "../../icons/EllipseTranslationComponent";

const ErrorTranslationMessage = ({translate}) => {
    return (
        <>
            <div style={{paddingRight: "8px"}}><EllipseTranslation accessibility={translate('documentSubRowChildStatusFailed')} color={"#FF0000"}/></div>
            {translate('documentSubRowChildStatusFailed')}
        </>
    );
};

export default ErrorTranslationMessage;