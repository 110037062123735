import {Form, Select} from "antd";
import React from "react";

const CategoriesSelection = ({orgCategories, updateCategories, setFormChanged}) => {
    return (
        <>
        <Form.Item
            label={
                <div>
                    <p
                        style={{
                            fontSize: '12px',
                            color: 'grey',
                            textAlign: 'left',
                        }}
                    >
                        Type in new categories and delete them by pressing the X button.
                    </p>
                </div>
            }
            name="categories"
        >
            <Select
                value={orgCategories}
                mode="tags"
                placeholder="Select categories"
                style={{ width: '100%' }}
                onChange={(s) => {
                    updateCategories(s);
                    setFormChanged(true);
                }}
                optionFilterProp="label"
                tokenSeparators={[',']}
            >

                {orgCategories && orgCategories.map((category, i) => (
                    <Select.Option key={i} value={category} label={category}>
                        {category}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
        </>
    );
};

export default CategoriesSelection;