import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import Spinner from '../spinner';
import { connect } from 'react-redux';
import { setSource, setTarget, rmUploading, setPid } from '../../redux/actions';
import FocusTrap from 'focus-trap-react';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from 'react-i18next';
import EngineSelection from "../EngineSelection";
import ModalContentHeader from "./modalContentComponents/modalContentHeader";
import TranslationsOptions from "./modalContentComponents/translationsOptions";
import UploadArea from "./modalContentComponents/uploadArea";
import ModalContentFooter from "./modalContentComponents/modalContentFooter";
import ReactHtmlParser from "react-html-parser";

let fileInput = React.createRef();

const triggerInputFile = (e) => {
  e.which === 13 && fileInput.current.focus();
};

const ModalContent = ({
  ariaLabel,
  onClose,
  onKeyDown,
  modalRef,
  buttonRef,
  onClickAway,
  files,
  role = 'dialog',
  swapLanguages,
  uploadAndTranslate,
  showUnsupportedFileErrorFromDocument,
  announceExitModal,
  onChange,
  toggleOcr,
  ocr,
                          submissionConcept,
                          handleSubmissionConcept,
                          selectedCategory,
                          categoriesListState,
                          handleCategoryChange,
                          loadingCategories,
                          disabledCategoryDropdown,
                          isCategoryMandatory,
                          showCategory,
                          supportEmailTo,
                          supportEmailCc,
                          supportEmailBody,
                          error,
                          errorMessage,
                          warningMessage,
                          srcTrgEqual,
                          wavUploaded,
                          showCategoryError,
                          mandatoryCategoryError,
                          isSubmissionNameTooLong,

  ...props
}) => {
  const { t } = useTranslation();
  const accept = props.store.user.organization.uiAssets.extraFormats
    ? `${
        props.store.globalOptions.acceptedFormats
      }, ${props.store.user.organization.uiAssets.extraFormats
        .map((eF) => eF.trim())
        .join(', ')}`
    : props.store.globalOptions.acceptedFormats;

  const [loading, setloading] = useState(false);
  const [showUploadedMessage, setShowUploadedMessage] = useState(false);
  const [showUploadFailError, setShowUploadFailError] = useState(false);
  const [uploadFailErrorMessage, setUploadFailErrorMessage] = useState('');
  const [asyncMessage, setAsyncMessage] = useState('');
  const [deactivateEsc] = useState(true);
  const [hover, setHover] = useState(false);

  let filesContainPDFAndWAV = props.pdfUploaded && wavUploaded;

  const fileSize = 50000000; // in bytes 50000000, 50MB
  const zipSize = 536870912; //512MB;

  if ((props.pdfUploaded || props.imagesUploaded) && ocr) {
      if (props.store.globalOptions.sourceLanguage === 'auto' || props.store.globalOptions.sourceLanguage === 'multi') {
          errorMessage = t('modalPdfAutodetectOcrError');
          error = true;
      } else if (
          //checking file size
          files && files.some(
              (file) =>  (file.name.split(".").pop() !== 'zip') && file.size > fileSize
          )
      ) {
          errorMessage = t('STT-fileSIZE');
          error = true;
      } else if (
      files && files.some(
          (file) => (file.name.split(".").pop() === 'zip') && file.size > zipSize
      )
      ) {
          errorMessage = t('STT-fileSIZE-zip');
          error = true;
      }
  } else {
      if (props.msgOrEmlUploaded) {
          if (props.store.globalOptions.sourceLanguage === 'auto' || props.store.globalOptions.sourceLanguage === 'multi') {
              errorMessage = t('modalMsgEmlAutoDetectError');
              error = true;
          }
      } else if (filesContainPDFAndWAV) {
          errorMessage = t('FILE-UPLOADS-CONTAIN-PDF-AND-WAV');
          error = true;
      } else if (
          files && files.some(
              (file) => file.type.match(/^.+\/(wav)$/gm) && file.size > 200000000
          )
      ) {
          errorMessage = t('STT-DOC-04-SIZE');
          error = true;
      } else if (
          files && files.some(
              (file) => (file.name.split(".").pop() === 'zip') && file.size > zipSize
          )
      ) {
          errorMessage = t('STT-fileSIZE-zip');
          error = true;
      } else if (
          files && files.some(
              //checking file size
              (file) => (file.name.split(".").pop() !== 'zip') && file.size > fileSize
          )
      ) {
          errorMessage = t('STT-fileSIZE');
          error = true;
      } else if (wavUploaded) {
          if (props.store.globalOptions.sourceLanguage === 'auto' || props.store.globalOptions.sourceLanguage === 'multi') {
              errorMessage = t('TT_WAV_ERROR_AUTO_DETECT');
              error = true;
          }
      }
  }

  return ReactDOM.createPortal(
    <FocusTrap
      focusTrapOptions={{
        onDeactivate: onClose,
        escapeDeactivates: deactivateEsc,
        allowOutsideClick: true,
        initialFocus: '#addFilesBtn',
      }}
      //active={false}
      tag="aside"
      aria-modal="true"
      role={role}
      tabIndex="-1"
      onKeyDown={onKeyDown}
      aria-label={ariaLabel}
      onClick={onClickAway}
    >
      <div className="c-modal-cover">
        <div className="c-modal" ref={modalRef}>
          <ModalContentHeader onClose={onClose} buttonRef={buttonRef} translate={t} showUploadFailError={showUploadFailError} loading={loading} showUploadedMessage={showUploadedMessage} asyncMessage={asyncMessage}/>
          <div className="c-modal__body">
            <TranslationsOptions ocr={ocr} srcTrgEqual={srcTrgEqual} wavUploaded={wavUploaded} translate={t} swapLanguages={swapLanguages} props={props}/>
            {loading ? (
              <div className="spinDiv">
                <Spinner></Spinner>
              </div>
            ) : (
                <div className="upload-area">
                  <UploadArea showUploadFailError={showUploadFailError} uploadFailErrorMessage={uploadFailErrorMessage} showUploadedMessage={showUploadedMessage}
                              files={files} accept={accept} fileInput={fileInput} showUnsupportedFileErrorFromDocument={showUnsupportedFileErrorFromDocument}
                              translate={t} submissionConcept={submissionConcept} handleSubmissionConcept={handleSubmissionConcept} onChange={onChange} props={props}
                              selectedCategory={selectedCategory} categoriesListState={categoriesListState} handleCategoryChange={handleCategoryChange} loadingCategories={loadingCategories} disabledCategoryDropdown={disabledCategoryDropdown}
                              isCategoryMandatory={isCategoryMandatory} showCategory={showCategory} errorMessage={errorMessage} isSubmissionNameTooLong={isSubmissionNameTooLong}/>

                </div>
            )}
            {!loading ? (
              <div className="modal-footer">
                {!showUploadedMessage ? (
                    <React.Fragment>
                      <ModalContentFooter translate={t} ocr={ocr} toggleOcr={toggleOcr} errorMessage={errorMessage} warningMessage={warningMessage} props={props} mandatoryCategoryError={mandatoryCategoryError} isSubmissionNameTooLong={isSubmissionNameTooLong}/>
                    <div className="footerContentWrapper">
                      <div className="addFilesbtnContainer">
                        <button
                            className="standardBtn"
                            id="addFilesBtn"
                            style={{
                              backgroundColor: hover
                                  ? props.store.user.organization.uiAssets
                                      .basicButtonBackgroundHover
                                  : props.store.user.organization.uiAssets
                                      .basicButtonBackground,
                              color:
                              props.store.user.organization.uiAssets
                                  .basicButtonText,
                            }}
                            tabIndex="0"
                            htmlFor="bob"
                            onKeyDown={triggerInputFile}
                            onMouseOver={() => setHover(true)}
                            onMouseOut={() => setHover(false)}
                            onClick={() => {
                              fileInput.current.click();
                            }}
                        >
                          {t('modalTippyAdd')}
                        </button>
                        <button
                            className="standardBtn"
                            id="translateBtn"
                            style={{
                              backgroundColor:
                                  files.length < 1 ||
                                  ((props.pdfUploaded || props.imagesUploaded) &&
                                      ocr &&
                                  (props.store.globalOptions.sourceLanguage ===
                                      'auto' || props.store.globalOptions.sourceLanguage ===
                                      'multi')) || (isCategoryMandatory && !selectedCategory)
                                      ? '#c4c4c4'
                                      : props.store.user.organization.uiAssets
                                          .translationButtonBackground,
                              color:
                              props.store.user.organization.uiAssets
                                  .translationButtonText,
                            }}
                            disabled={files.length < 1 || (error && !isSubmissionNameTooLong)}
                            onClick={(isCategoryMandatory && !selectedCategory) ? () => showCategoryError() : async () => {
                              setloading(true);
                              setAsyncMessage(t('modalLoading'));
                              let res = await uploadAndTranslate();
                              if (!res || !res.success) {
                                setloading(false);
                                setShowUploadedMessage(true);
                                setShowUploadFailError(true);
                                setAsyncMessage(t('modalUploadErrorMessage'));
                                  if (res.message === "organization reached translation limit" || res.message === "user reached translation limit") {
                                    let errorMessage = t('reachedQuotaLimitError');
                                    setUploadFailErrorMessage(errorMessage);
                                  } else if (res.message === "organization " + props.store.user.organization.id + " expired") {
                                    let errorMessage = t('orgExpiredError');
                                    setUploadFailErrorMessage(errorMessage);
                                  } else {
                                      let errorMessage = ReactHtmlParser(t('modalUploadErrorMessagePartOne') +
                                          " <span style='font-weight: bold'>" + t('modalUploadErrorMessagePartTwo') +
                                          "</span><br/>" +
                                          "<span style='color: red'>" + t('modalUploadErrorMessagePartThree') + "<a style='color: red; text-decoration: underline' href=mailto:" + supportEmailTo + "?cc=" + supportEmailCc + "&subject=Translation%20failed&body=" + supportEmailBody + ">" +
                                          t('modalUploadErrorMessagePartFour') +
                                          "</a></span>");
                                      setUploadFailErrorMessage(errorMessage); //message in case response didn't go through
                                  }
                              } else if (res.success) {
                                setloading(false);
                                onClose(); //closing modal when response is successful
                              }
                            }}
                        >
                          {t('modalTranslate')}
                        </button>
                      </div>
                  </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p style={{ fontSize: '16px' }}></p>
                    <button
                      style={{
                        backgroundColor:
                          props.store.user.organization.uiAssets
                            .basicButtonBackground,
                        color:
                          props.store.user.organization.uiAssets
                            .basicButtonText,
                      }}
                      className="standardBtn closeModalBtn"
                      onClick={() => onClose()}
                    >
                     Close
                    </button>
                  </React.Fragment>
                )
                }
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </FocusTrap>,
    document.getElementById('root-modal')
  );
};

const mapDispatchToProps = (dispatch) => ({
  setSource: (source) => dispatch(setSource(source)),
  setTarget: (target) => dispatch(setTarget(target)),
  rmFile: (files) => dispatch(rmUploading(files)),
  setPid: (pid) => dispatch(setPid(pid)),
});

const mapStateToProps = (state) => ({ store: state });

export default connect(mapStateToProps, mapDispatchToProps)(ModalContent);
