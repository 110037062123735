import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './style.css';
import { ReactComponent as WebsiteTranslatorIcon } from './websiteTranslatorIcon.svg';
import UpArrowHeadIconComponent from "../../icons/UpArrowHeadIconComponent";
import AnnouncementIconComponent from "../../icons/AnnouncementIconComponent";
import DownArrowHeadIconComponent from "../../icons/DownArrowHeadIconComponent";

export class Sidebar extends Component {
  state = {
    text: true,
    document: false,
    websiteTranslator: false,
    settings: false,
    login: false,
    mainTabHover: false,
    secondTabHover: false,
    statHover: false,
    webHover: false,
  };

  componentDidMount() {
    let loc = this.props.location.pathname;
    let place;
    loc === '/'
      ? (this.props.organization.uiAssets.hideTT ? place = 'document' : place = 'text')
      : loc === '/document'
      ? (this.props.organization.uiAssets.hideDT ? place = 'text' : place = 'document')
      : loc === '/statistics'
      ? (place = 'statistics')
      : loc === '/terms'
      ? (place = 'terms')
      : loc === '/website'
      ? (place = 'websiteTranslator')
      : (place = 'login');

    this.setState({
      text: false,
      document: false,
      statistics: false,
      settings: false,
      login: false,
      logout: false,
      terms: false,
      websiteTranslator: false,
      [place]: true,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      switch (this.props.location.pathname) {
        case '/login':
          this.setState({
            text: false,
            document: false,
            statistics: false,
            websiteTranslator: false,
          });
          break;
        case '/':
          this.setState({
            text: this.props.organization.uiAssets.hideTT ? false : true,
            document: this.props.organization.uiAssets.hideTT ? true : false, //in case TT is hidden, document will be the default view
            statistics: false,
            websiteTranslator: false,
          });
          break;
        case '/document':
          this.setState({
            text: this.props.organization.uiAssets.hideDT ? true : false, //in case DT is hidden, text will be the default view
            document: this.props.organization.uiAssets.hideDT ? false : true,
            statistics: false,
            websiteTranslator: false,
          });

          break;
        case '/statistics':
          this.setState({
            text: false,
            document: false,
            statistics: true,
            websiteTranslator: false,
          });
          break;
        case '/website':
          this.setState({
            text: false,
            document: false,
            statistics: false,
            websiteTranslator: true,
          });
          break;
        default:
          this.setState({
            text: false,
            document: false,
            statistics: false,
            websiteTranslator: false,
          });
      }
    }
  }

  clear = () =>
    this.setState({
      text: false,
      document: false,
      statistics: false,
      websiteTranslator: false,
      settings: false,
    });

  setIcon = (icon) =>
    this.setState({
      text: false,
      document: false,
      statistics: false,
      settings: false,
      websiteTranslator: false,
      [icon]: true,
    });

  hideGptTptBanner = (id) => {
    let elem1 = document.getElementById(id);
    elem1.style.transition = "all 0.5s ease-in-out";
    elem1.style.height = "0px";
  }

  showGptTptBanner = (id) => {
    let elem = document.getElementById(id);
    elem.style.transition = "all 0.5s ease-in-out";
    elem.style.height = "370px";
  }

  render() {
    if (
      !this.props.auth.isAuthenticated() ||
      (!this.state.text &&
        !this.state.document &&
        !this.state.statistics &&
        !this.state.websiteTranslator)
    )
      return <></>;
    return (
      <div role="navigation" className="sideBar">
        <div className="links">
          { !this.props.organization.uiAssets.hideTT && ( //in case the text translation is hidden for the org, the title won't be displayed
            <Link
            style={{
              backgroundColor: this.state.text
                ? this.props.organization.uiAssets.navbarActive
                : this.props.organization.uiAssets.navbarIdle,
              borderBottom: this.state.text
                ? this.props.organization.uiAssets.navbarBottomBorder
                : this.state.mainTabHover
                ? this.props.organization.uiAssets.navbarBottomBorder
                : '',
            }}
            onMouseEnter={() => this.setState({ mainTabHover: true })}
            onMouseLeave={() => this.setState({ mainTabHover: false })}
            className="basicSide"
            to="/"
          >
            <Tippy content={this.props.t('sidebarTippyText')}>
              <div tabIndex="-1" style={{ width: '25px', height: '25px' }}>
                <svg
                  className="nav-tile"
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  style={{ marginTop: '5px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill={
                      this.state.text
                        ? this.props.organization.uiAssets.navbarActiveText
                        : this.props.organization.uiAssets.navbarIdleText
                    }
                    d="M2.5 5.5C2.5 6.33 3.17 7 4 7h3.5v10.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5V7H14c.83 0 1.5-.67 1.5-1.5S14.83 4 14 4H4c-.83 0-1.5.67-1.5 1.5zM20 9h-6c-.83 0-1.5.67-1.5 1.5S13.17 12 14 12h1.5v5.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5V12H20c.83 0 1.5-.67 1.5-1.5S20.83 9 20 9z"
                  />
                </svg>
              </div>
            </Tippy>
            <p
              tabIndex="-1"
              className="nav-tile"
              style={{
                marginTop: '5px',
                marginLeft: '5px',
                color: this.state.text
                  ? this.props.organization.uiAssets.navbarActiveText
                  : this.props.organization.uiAssets.navbarIdleText,
                fontSize: '22px',
              }}
            >
              {this.props.t('sidebarText')}
            </p>
          </Link>
            )
          }

          { !this.props.organization.uiAssets.hideDT && (
          <Link
            className="basicSide"
            to="/document"
            onMouseEnter={() => this.setState({ secondTabHover: true })}
            onMouseLeave={() => this.setState({ secondTabHover: false })}
            style={{
              backgroundColor: this.state.document
                ? this.props.organization.uiAssets.navbarActive
                : this.props.organization.uiAssets.navbarIdle,
              borderBottom: this.state.document
                ? this.props.organization.uiAssets.navbarBottomBorder
                : this.state.secondTabHover
                ? this.props.organization.uiAssets.navbarBottomBorder
                : '',
            }}
          >
            <Tippy content={this.props.t('sidebarTippyDocument')}>
              <div tabIndex="-1" style={{ width: '25px', height: '25px' }}>
                <svg
                  tabIndex="-1"
                  xmlns="http://www.w3.org/2000/svg"
                  className="nav-tile"
                  width="25"
                  height="25"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  style={{ marginTop: '5px' }}
                >
                  <path fill="none" d="M0 0h24v24H0V0z" />
                  <path
                    fill={
                      this.state.document
                        ? this.props.organization.uiAssets.navbarActiveText
                        : this.props.organization.uiAssets.navbarIdleText
                    }
                    d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z"
                  />
                </svg>
              </div>
            </Tippy>
            <p
              className="nav-tile"
              style={{
                marginTop: '5px',
                marginLeft: '5px',
                color: this.state.document
                  ? this.props.organization.uiAssets.navbarActiveText
                  : this.props.organization.uiAssets.navbarIdleText,
                fontSize: '22px',
              }}
            >
              {this.props.t('sidebarDocument')}
            </p>
          </Link>
          )}
          {this.props.websiteTranslator && (
            <Link
              style={{
                backgroundColor: this.state.websiteTranslator
                  ? this.props.organization.uiAssets.navbarActive
                  : this.props.organization.uiAssets.navbarIdle,
                borderBottom: this.state.websiteTranslator
                  ? this.props.organization.uiAssets.navbarBottomBorder
                  : this.state.webHover
                  ? this.props.organization.uiAssets.navbarBottomBorder
                  : '',
              }}
              onMouseEnter={() => this.setState({ webHover: true })}
              onMouseLeave={() => this.setState({ webHover: false })}
              className="basicSide"
              to="/website"
            >
              <Tippy content={this.props.t('websiteTranslatorTippy')}>
                <div tabIndex="-1" style={{ width: '25px', height: '25px' }}>
                  <WebsiteTranslatorIcon
                    style={{
                      marginTop: '5px',
                      fill: this.state.websiteTranslator
                        ? this.props.organization.uiAssets.navbarActiveText
                        : this.props.organization.uiAssets.navbarIdleText,
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </div>
              </Tippy>
              <p
                tabIndex="-1"
                className="nav-tile"
                style={{
                  marginTop: '5px',
                  marginLeft: '5px',
                  color: this.state.websiteTranslator
                    ? this.props.organization.uiAssets.navbarActiveText
                    : this.props.organization.uiAssets.navbarIdleText,
                  fontSize: '22px',
                }}
              >
                {this.props.t('websiteTranslator')}
              </p>
            </Link>
          )}
          {this.props.role > 2 && (
            <Link
              style={{
                backgroundColor: this.state.statistics
                  ? this.props.organization.uiAssets.navbarActive
                  : this.props.organization.uiAssets.navbarIdle,
                borderBottom: this.state.statistics
                  ? this.props.organization.uiAssets.navbarBottomBorder
                  : this.state.statHover
                  ? this.props.organization.uiAssets.navbarBottomBorder
                  : '',
              }}
              onMouseEnter={() => this.setState({ statHover: true })}
              onMouseLeave={() => this.setState({ statHover: false })}
              className="basicSide"
              to="/statistics"
            >
              <Tippy content={this.props.t('statistics')}>
                <div tabIndex="-1" style={{ width: '25px', height: '25px' }}>
                  <svg
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginTop: '5px' }}
                  >
                    <g>
                      <rect
                        fill="none"
                        id="canvas_background"
                        height="402"
                        width="582"
                        y="-1"
                        x="-1"
                      />
                    </g>
                    <g>
                      <path
                        id="svg_1"
                        fill="none"
                        d="m0,0l24,0l0,24l-24,0l0,-24z"
                      />
                      <path
                        fill={
                          this.state.statistics
                            ? this.props.organization.uiAssets.navbarActiveText
                            : this.props.organization.uiAssets.navbarIdleText
                        }
                        id="svg_2"
                        d="m21.36,6.28l-0.06,-0.06c-0.39,-0.39 -1.03,-0.37 -1.39,0.04l-2.18,2.45c-2.05,-2.31 -4.9,-3.71 -8.12,-3.71c-2.5,0 -4.83,0.87 -6.75,2.3c-0.47,0.35 -0.52,1.04 -0.11,1.45l0.06,0.06c0.33,0.33 0.86,0.39 1.23,0.11c1.59,-1.2 3.5,-1.92 5.57,-1.92c2.74,0 5.09,1.26 6.77,3.24l-2.88,3.24l-3.29,-3.29c-0.39,-0.39 -1.02,-0.39 -1.41,0l-6.12,6.13c-0.37,0.37 -0.37,0.98 0,1.35l0.15,0.15c0.37,0.37 0.98,0.37 1.35,0l5.32,-5.33l3.25,3.25c0.41,0.41 1.07,0.39 1.45,-0.04l3.35,-3.76c0.62,1.12 1.08,2.39 1.32,3.73c0.08,0.47 0.47,0.82 0.95,0.82l0.09,0c0.6,0 1.05,-0.55 0.94,-1.14c-0.32,-1.85 -0.98,-3.54 -1.89,-5l2.44,-2.75c0.34,-0.38 0.32,-0.96 -0.04,-1.32z"
                      />
                    </g>
                  </svg>
                </div>
              </Tippy>
              <p
                tabIndex="-1"
                className="nav-tile"
                style={{
                  marginTop: '5px',
                  marginLeft: '5px',
                  color: this.state.statistics
                    ? this.props.organization.uiAssets.navbarActiveText
                    : this.props.organization.uiAssets.navbarIdleText,
                  fontSize: '22px',
                }}
              >
                {this.props.t('statistics')}
              </p>
            </Link>
          )}

          {this.props.organization.id === '1' &&
          <div
              style={{borderRadius: "2px"}}
              className="gptTptDiv"
              id="openGptTptDiv"
          >
            <a
                tabIndex="-1"
                className="gptTptContentSmall"
                href="https://globallinkai-dev.translations.com/signin" target="_blank"
            >
             Create anything with AI
            </a>
            <button className={"openGptTptBtn"} onClick={() => this.showGptTptBanner("closeGptTptDiv")}>
              <DownArrowHeadIconComponent accessibility={this.props.t('documentSubRowChildExpand')} color={"#8E2554"}/>
            </button>
          </div>
          }

          {this.props.organization.id === '1' &&
              <div
                  className="gptTptDiv"
                  id="closeGptTptDiv"
              >
                <div style={{display: "flex", width: "100%"}}>
                  <div style={{marginLeft: "auto", paddingLeft: "42px"}}>
                    <AnnouncementIconComponent/>
                  </div>
                  <button style={{marginRight: "22px", marginLeft: "auto", marginTop: "-8px"}} className={"closeGptTptBtn"} onClick={ () => this.hideGptTptBanner("closeGptTptDiv")}>
                    <UpArrowHeadIconComponent accessibility={this.props.t('documentSubRowChildCollapse')} color={"#8E2554"}/>
                  </button>
                </div>
                <h2 style={{color: "#8E2554", margin: "10px"}}>Try something cool!</h2>
                <p tabIndex="-1" className="gptTptContentBig">
                  Let the AI create anything for you.
                  <br/>
                  <span style={{fontWeight: "normal"}}>
                  Speed up your work with our new integration with GPT.
                  </span>
                </p>


                <button className={"gptTptBtn"}>
                  <a href="https://globallinkai-dev.translations.com/signin" target="_blank" className="gptTptLink">
                      TRY TPTGPT
                  </a>
                </button>

              </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organization: state.user.organization,
  role: state.user.roleid,
  websiteTranslator: state.user.organization.uiAssets.websiteTranslator,
});

const SideBarWithRouter = withRouter(Sidebar);

export default withTranslation()(
  connect(mapStateToProps, undefined)(SideBarWithRouter)
);
