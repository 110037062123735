const SwapIconComponent = ({accessibility}) => {
    return(
        <svg title={accessibility} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_456_28957)">
            
                <desc>{accessibility}</desc>
                <path d="M22 8L18 4V7H3V9H18V12L22 8Z" fill="#333333"/>
                <path d="M2 16L6 20V17H21V15H6V12L2 16Z" fill="#333333"/>
            </g>
            <defs>
            <clipPath id="clip0_456_28957">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
};

export default SwapIconComponent;