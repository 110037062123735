import React from 'react';
import './style.css';

import { Upload } from 'antd';

const ImageUploader = ({ value, onChange, background, setFormChanged }) => {

  const beforeUpload = (file) => {
    scaleImage(file)
      .then((img) => {
        return toBase64(img);
      })
      .then((img) =>
      {
          setFormChanged(true);
          onChange(img)
      });
    return false;
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const scaleImage = async (img) => {
    let canvas = await readPhoto(img);

    while (canvas.width >= 2 * 270) {
      canvas = scaleCanvas(canvas, 0.5);
    }

    if (canvas.width > 270) {
      canvas = scaleCanvas(canvas, 270 / canvas.width);
    }

    return new Promise((resolve) => {
      canvas.toBlob(resolve, img.type, 1);
    });
  };

  const readPhoto = async (photo) => {
    const canvas = document.createElement('canvas');
    const img = document.createElement('img');

    // create img element from File object
    img.src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.readAsDataURL(photo);
    });
    await new Promise((resolve) => {
      img.onload = resolve;
    });

    // draw image in canvas element
    canvas.width = img.width;
    canvas.height = img.height;
    canvas.getContext('2d').drawImage(img, 0, 0);

    return canvas;
  };

  const scaleCanvas = (canvas, scale) => {
    const scaledCanvas = document.createElement('canvas');
    scaledCanvas.width = canvas.width * scale;
    scaledCanvas.height = canvas.height * scale;

    scaledCanvas
      .getContext('2d')
      .drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);

    return scaledCanvas;
  };

  return (
    <Upload
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={onChange}
    >
      <div
        style={{
          background: background,
          width: '270px',
          height: '64px',
          margin: '0 0 20px',
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 0,
          flexShrink: 0,
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px dashed black',
        }}
      >
        <img
          style={{
            height: '50px',
            maxWidth: '270px',
            margin: '0 1em',
          }}
          alt="Logo"
          src={value}
        />
      </div>
    </Upload>
  );
};
export default ImageUploader;
