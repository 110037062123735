import React, { useRef } from 'react';
import { Route, Redirect  } from 'react-router-dom';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { LOCAL_STORAGE_KEY } from '../utils/localStorageKeys';
import Spinner from './spinner';
import Notification from './Notification';

const PrivateRoute = ({
  component,
  userid,
  userEmail,
  mustBeRole,
  ...rest
}) => {
  const idleTimer = useRef(null);

  const renderfc = (Component) => (props) => {
    window.injectTranslatedWebsite = undefined;
    if (!rest.a.isAuthenticated()) {
      rest.a.inactivityLogout().then(() => {
        localStorage.setItem(
          LOCAL_STORAGE_KEY.REDIRECT_URI,
          window.location.pathname
        );
        window.location.href = '/login';
      });
      return <Spinner />;
    }
    if (rest.termsPage && rest.termsPage > 0 && !rest.termsAcknowledged) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY.REDIRECT_URI,
        window.location.pathname
      );
      return <Redirect to="/terms" />;
    }
    return (
      <>
        <Component {...props} />
        {rest.notifications && <Notification />}
      </>
    );
  };

  const onUserAction = () =>
    localStorage.setItem(LOCAL_STORAGE_KEY.INACTIVE, false);

  const onInactivity = () => {
    if (!!window.injectTranslatedWebsite) return;
    let fistTokenRefresh = parseInt(
      localStorage.getItem(LOCAL_STORAGE_KEY.FIRST_TOKEN_REFRESH)
    );

    if (fistTokenRefresh < Date.now()) {
      localStorage.setItem(LOCAL_STORAGE_KEY.INACTIVE, true);
      window.location.replace('/inactivity-logout');
    } else {
      idleTimer.current.reset();
    }
  };

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onAction={onUserAction}
        onIdle={onInactivity}
        timeout={1000 * 60 * 15}
        throttle={5000}
        eventsThrottle={500}
        crossTab={{
          emitOnAllTabs: true,
        }}
      />
      <Route {...rest} render={renderfc(component)} />
    </>
  );
};

function mapStateToProps(state) {
  return {
    userid: state.user.id,
    userEmail: state.user.email,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
