import {Form, Switch} from "antd";
import React from "react";

const EngineDropdownSettings = ({org, handleEnginesProfilesOnly}) => {
    return (
        <>
            <div className="quotaFormWrapper">
                <Form.Item
                    label={<label>Show all options available</label>}
                    name="engineDropdownShowAll"
                    initialValue={org.uiAssets.engineDropdownShowAll}
                    htmlFor={undefined}
                >
                    <Switch
                        checked={org.uiAssets.engineDropdownShowAll}
                        onChange={event => handleEnginesProfilesOnly(event, "allEngines")}
                        style={{ display:'inline', marginLeft: "20px" }}
                    ></Switch>
                </Form.Item>
            </div>
            <div className="quotaFormWrapper"style={{marginLeft: "10%"}}>
                <Form.Item
                    label={<label>Show specific profiles only</label>}
                    name="engineDropdownShowProfilesOnly"
                    initialValue={org.uiAssets.engineDropdownShowProfilesOnly}
                    htmlFor={undefined}
                >
                    <Switch
                        checked={org.uiAssets.engineDropdownShowProfilesOnly}
                        onChange={event => handleEnginesProfilesOnly(event, "onlyProfiles")}
                        style={{ display:'inline', marginLeft: "20px" }}
                    ></Switch>
                </Form.Item>
            </div>
        </>
    );
};

export default EngineDropdownSettings;
