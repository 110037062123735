export const createPanelsFromAppLocalization = (localizations, translate) => {
    let arrayOfPanes = [];
    const pairEngineLanguage = {
        title: "",
        key: "",
    };

    for (var i = 0; i < localizations.length; i++) {
        const pair = Object.create(pairEngineLanguage);
        pair.title = translate(localizations[i]);
        pair.key = localizations[i];
        arrayOfPanes.push(pair);
    }

    return arrayOfPanes;
};

export const deleteInput = (uiLang, langKey, value, onChange) => {
    if (Object.keys(value[uiLang]).length >= 2) {
        onChange({
            ...value,
            [uiLang]: Object.fromEntries(
                Object.entries(value[uiLang]).filter(([k, v]) => langKey !== k)
            ),
        });
    } else {
        onChange(
            Object.fromEntries(
                Object.entries(value).filter(([k, v]) => uiLang !== k)
            )
        );
    }
};

export const addNew = (uiLang, langKey, value, i18n, onChange) => {
    onChange({
        ...value,
        [uiLang]: {
            ...value[uiLang],
            [langKey]: i18n.getResource(uiLang, 'translations', langKey),
        },
    });
};

export const handleLangNameChange = (uiLang, langKey, langName, value, onChange) => {
    onChange({
        ...value,
        [uiLang]: {
            ...value[uiLang],
            [langKey]: langName,
        },
    });
};