import {Form, Input, Switch} from "antd";
import React from "react";

const QuotaSettings = ({
                           maxWordsPerDocument,
                           handleMaxWordsPerDocument,
                           showMaxWordsPerDocument,
                           handleShowMaxWordsPerDocument,
                        }) => {
    return (
        <>
            <div className="quotaFormWrapper quotaFormWrapperWithSwitchLongText" style={{height: "45px"}}>
                <Form.Item
                    label="Max words per document"
                    name="showMaxWordsPerDocument"
                    initialValue={showMaxWordsPerDocument}
                >
                    <Switch style={{marginBottom: "5px"}} checked={showMaxWordsPerDocument} onChange={handleShowMaxWordsPerDocument}/>
                </Form.Item>
            </div>

            {showMaxWordsPerDocument &&
                <Form.Item name="maxWordsPerDocument" style={{width: "110px"}} initialValue={maxWordsPerDocument}>
                    <div style={{display: "flex"}}>
                        <Input type={"number"} style={{width: "90px"}} onChange={(e) => handleMaxWordsPerDocument(e.target.value)} value={maxWordsPerDocument} min={0} max={9223372036854775807}/>
                        <span style={{paddingLeft: "5px", paddingTop: "5px"}}>words</span>
                    </div>
                </Form.Item>
            }
        </>
    );
};

export default QuotaSettings;
