import {Form } from "antd";
import React from "react";
import TextArea from "antd/es/input/TextArea";

const NotesTextArea = ({org}) => {
    return (
        <Form.Item
            label={
                <div>
                    <label>Notes</label>{' '}
                    <p
                        style={{
                            fontSize: '12px',
                            color: 'grey',
                            textAlign: 'left',
                        }}
                    >
                        {
                            'Use this space to write down any other useful notes for the team.'
                        }
                    </p>
                </div>
            }
            name="notes"
            initialValue={org.notes}
        >
            <TextArea/>
        </Form.Item>
    );
};

export default NotesTextArea;