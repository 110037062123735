import {Form, Input } from "antd";
import React from "react";

const CustomSupportEmailField = ({org}) => {
    return (
        <>
            <div>
                <label>Custom support email addresses</label>{' '}
                <p
                    style={{
                        fontSize: '12px',
                        color: 'grey',
                        textAlign: 'left',
                    }}
                >
                    {
                        'By default, all organizations should send their support requests to our team and a copy to theirs. ' +
                        'This should be changed only if specified by the client.'
                    }
                </p>
            </div>
            <div className="emailSupportWrapper" style={{marginTop: "24px"}}>
            <Form.Item
                label={<p style={{lineHeight: "2"}}>To<span style={{color: "red"}}>*</span></p> }
                name="supportEmailTo"
                initialValue={org.uiAssets.supportEmailTo ? org.uiAssets.supportEmailTo : "aisupport@transperfect.com"}
            >
                <Input/>
            </Form.Item>
            </div>

            <div className="emailSupportWrapper">
                <Form.Item
                    label={<p style={{lineHeight: "2", marginLeft: "2px"}}>Cc</p> }
                    name="supportEmailCc"
                    initialValue={org.uiAssets.supportEmailCc}
                >
                    <Input placeholder="Clients support email address"/>
                </Form.Item>
            </div>
        </>
    );
};

export default CustomSupportEmailField;