import {Form, Select} from "antd";
import React from "react";

const TimeToLiveDropdown = ({orgTimeToLive, updateTimeToLive, setFormChanged, timeToLive}) => {
    return (
        <Form.Item
            label={
                <div>
                    <label>Time To Live / Document Retention Period</label>{' '}
                    <p
                        style={{
                            fontSize: '12px',
                            color: 'grey',
                            textAlign: 'left',
                        }}
                    >
                        {
                            'The period of time in which we keep client\'s documents on our portal.'
                        }
                    </p>
                </div>
            }
            name="ttl"
            style={{ width: '100%' }}
            initialValue={orgTimeToLive}
        >
            <Select
                placeholder="Select file time to live"
                style={{ width: '100%' }}
                name="ttl"
                value={orgTimeToLive}
                onChange={(s) => {
                    updateTimeToLive(s);
                    setFormChanged(true);
                }}
                optionFilterProp="label"
            >
                {timeToLive.map((item, i) => (
                    <Select.Option
                        key={i}
                        value={item.value}
                        disabled={item.value === "12h" || item.value === "2d" || item.value === "3d" || item.value === "4d" ||
                            item.value === "5d" || item.value === "6d" || item.value === "1mth"
                        }
                    >
                        {item.label}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default TimeToLiveDropdown;