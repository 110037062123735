import React, { Component } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { withTranslation } from 'react-i18next';
import './style.css';
import FileIconComponent from "../../icons/FileIconComponent";
import InformationIconComponent from "../../icons/InformationIconComponent";
import TrashCanIcon from "../../icons/TrashCanIconComponent";

let maxFileSize = 50000000; //50MB
let maxZipSize = 536870912; //512MB

class FileItem extends Component {

  formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  getExtension(filename) {
    let parts = filename.split('.');
    return parts[parts.length - 1];
  }

  isZip(filename) {
    let extIsZip = false;
    let ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'zip':
        extIsZip = true;
    }
    return extIsZip;
  }

  render() {
    return (
      <div
        role="listitem"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', width: '80%' }}>
          {(this.isZip(this.props.file.name) ? this.props.file.size < maxZipSize : (this.props.file.size < maxFileSize)) ?
              (<FileIconComponent accessibility={this.props.t('documentFileIconTippy')} size={24}/>) :
              (<InformationIconComponent accessibility={this.props.t('documentSubRowStatusError')} size={24} color={"red"}/>)
          }
          <p
            tabIndex="0"
            aria-label="file name"
            className="ellipsize-left"
            style={{ marginLeft: '1em', fontSize: '16px', width: '100%', color: (this.isZip(this.props.file.name) ? (this.props.file.size > maxZipSize) : (this.props.file.size > maxFileSize)) ? 'red': '#333333' }}
          >
            {this.props.file.name}
          </p>
        </div>
        <div>
          {/*checking file size*/}
          {(this.isZip(this.props.file.name) ? (this.props.file.size < maxZipSize) : (this.props.file.size < maxFileSize)) ?
              (<span>{this.formatBytes(this.props.file.size)}</span>) :
              (<span style={{fontWeight: "bold", color: "red"}}>{this.formatBytes(this.props.file.size)}</span>)
          }
        </div>
        <Tippy content={this.props.t('documentSubRowChildDeleteTippy')}>
          <button
            className="deleteFileBtn"
            onClick={() => this.props.rmFile([this.props.file])}
            aria-label={this.props.t('deleteModalTitle')}
          >
            <span className="u-hide-visually">Close</span>
            <TrashCanIcon accessibility={"Delete file"} color={(this.isZip(this.props.file.name) ? (this.props.file.size > maxZipSize) : (this.props.file.size > maxFileSize)) ? 'red': '#333333'}/>
          </button>
        </Tippy>
      </div>
    );
  }
}

export default withTranslation()(FileItem);
