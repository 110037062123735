import React from "react";

const EditFile = ({accessibility}) => {
    return (
        <svg title={accessibility} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <desc>{accessibility}</desc>
            <path d="M11.8625 21.1375V18.8212L17.2044 13.4794L19.5207 15.7957L14.1788 21.1375H11.8625ZM2.86255 16.2212V13.9462H10.2272V16.2212H2.86255ZM20.2207 15.0957L17.9044 12.7794L18.6294 12.0544C18.8366 11.8471 19.1019 11.7425 19.425 11.7405C19.7482 11.7385 20.0134 11.8431 20.2207 12.0544L20.9457 12.7794C21.1569 12.9866 21.2616 13.2518 21.2596 13.575C21.2576 13.8982 21.1529 14.1634 20.9457 14.3707L20.2207 15.0957ZM2.86255 12.0359V9.76088H14.1375V12.0359H2.86255ZM2.86255 7.85056V5.57553H14.1375V7.85056H2.86255Z"/>
        </svg>
    );
};

export default EditFile;