import React from "react";
import {
    Button,
    Col,
    Form,
    Row,
    Tooltip,
    Select, Table
} from 'antd';
import {
    ALL_LANGUAGES,
} from '../settingValues';
import {
    DeleteOutlined,
} from '@ant-design/icons';

const LanguageProfileSettings = ({originalProfiles, profiles, onResetProfiles, addMulitpleProfilesForm, addMultiple, engineEntities, createTree, props}) => {
    return (
        <>
        <Row gutter={[16, 16]}>
            <Col span={22}>
                <p style={{fontWeight: "bold", marginBottom: "0"}}>Language profiles</p>
                <p style={{ fontSize: '12px', color: 'grey', marginBottom: "12px"}}>Setting a particular language pair to be translated with a particular engine. Example: all of the translations done from English to French will be translated with Microsoft Main Engine.</p>
            </Col>
            <Col span={2}>
                <Tooltip
                    title={
                        originalProfiles !== profiles
                            ? 'Reset to original values'
                            : 'No changes have been made'
                    }
                    key="0"
                >
                    <Button
                        key="reset"
                        onClick={onResetProfiles}
                        icon={<DeleteOutlined />}
                        disabled={originalProfiles === profiles}
                        style={{float: "right"}}
                    >
                        Erase
                    </Button>
                </Tooltip>
            </Col>
        </Row>
    <Row gutter={[16, 16]}>
        <Col span={8}>
            <Form
                form={addMulitpleProfilesForm}
                name="addProfiles"
                layout="horizontal"
                style={{ marginBottom: '1em' }}
                onFinish={addMultiple}
            >
                <Form.Item
                    name="sourceLangs"
                    label="Source Languages"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        showSearch
                        mode="multiple"
                        placeholder="Add Source Languages"
                        optionFilterProp="label"
                    >
                        {ALL_LANGUAGES.filter((lang) => lang !== 'auto')
                            .sort((l1, l2) =>
                                props.t(l1).localeCompare(props.t(l2))
                            )
                            .map((item) => (
                                <Select.Option
                                    key={item}
                                    value={item}
                                    label={item === '*' ? 'All (*)' : props.t(item)}
                                >
                                    {item === '*' ? 'All (*)' : props.t(item)}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="targetLangs"
                    label="Target Languages"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        showSearch
                        mode="multiple"
                        placeholder="Add Target Languages"
                        optionFilterProp="label"
                    >
                        {ALL_LANGUAGES.filter((lang) => lang !== 'auto')
                            .sort((l1, l2) =>
                                props.t(l1).localeCompare(props.t(l2))
                            )
                            .map((item) => (
                                <Select.Option
                                    key={item}
                                    value={item}
                                    label={item === '*' ? 'All (*)' : props.t(item)}
                                >
                                    {item === '*' ? 'All (*)' : props.t(item)}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="engine"
                    label="Engine"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="Select Engine"
                        optionFilterProp="label"
                    >
                        {Object.entries(engineEntities).map((item) => (
                            <Select.Option
                                key={item[0]}
                                value={item[0]}
                                label={item[1]}
                            >
                                {item[1]}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="failover" label="Failover">
                    <Select
                        showSearch
                        placeholder="Select Failover"
                        optionFilterProp="label"
                    >
                        {Object.entries(engineEntities).map((item) => (
                            <Select.Option
                                key={item[0]}
                                value={item[0]}
                                label={item[1]}
                            >
                                {item[1]}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="primary">
                        Add
                    </Button>
                </Form.Item>
            </Form>
        </Col>
        <Col span={16}>
            <Table
                size="small"
                pagination={false}
                sticky={true}
                scroll={true}
                columns={[
                    {
                        title: 'Source Language',
                        dataIndex: 'sourceLanguage',
                        key: 'sourceLanguage',
                    },
                    {
                        title: 'Target Language',
                        dataIndex: 'targetLanguage',
                        key: 'targetLanguage',
                    },
                    {
                        title: 'Engine',
                        dataIndex: 'engine',
                        key: 'engine',
                    },
                    {
                        title: 'Failover',
                        dataIndex: 'failover',
                        key: 'failover',
                    },
                    {
                        title: 'Delete',
                        dataIndex: 'delete',
                        key: 'delete',
                    },
                ]}
                dataSource={createTree(profiles)}
            />
        </Col>
    </Row>
    </>
    );
};

export default LanguageProfileSettings;