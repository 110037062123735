import React from "react";

function Announcements(props) {
  return (
    <div aria-live="polite" aria-atomic="true" className="visuallyhidden">
      {props.message}
    </div>
  );
}

export default Announcements;
