import { baseUrl } from './config';

export const SSO_URL = window.AUTH_URL;
export const CLIENT_ID = window.IDENTITY_CLIENT_ID;

export const IDENTITY_CONFIG = {
  authority: SSO_URL, //(string): The URL of the OIDC provider.
  client_id: CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: `${baseUrl}/callback`, //The URI of your client application to receive a response from the OIDC provider.
  automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  load_user_info: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  silent_redirect_uri: `${baseUrl}/refresh`, //(string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri: `${baseUrl}/logout`, // (string): The OIDC post-logout redirect URI.
  response_type: 'id_token token', //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  grantType: 'password',
  scope: window.APP_SCOPE, //(string, default: 'openid'): The scope being requested from the OIDC provider.
  webAuthResponseType: 'id_token token',
  silentRequestTimeout: 20000,
  accessTokenExpiringNotificationTime: 125,
  checkSessionInterval: 1500,
};

export const METADATA_OIDC = {
  issuer: SSO_URL,
  jwks_uri: SSO_URL + '/.well-known/openid-configuration/jwks',
  authorization_endpoint: SSO_URL + '/connect/authorize',
  token_endpoint: SSO_URL + '/connect/token',
  userinfo_endpoint: SSO_URL + '/connect/userinfo',
  end_session_endpoint: SSO_URL + '/connect/endsession',
  check_session_iframe: SSO_URL + '/connect/checksession',
  revocation_endpoint: SSO_URL + '/connect/revocation',
  introspection_endpoint: SSO_URL + '/connect/introspect',
};
