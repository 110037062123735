import React, { Component } from 'react';
import './style.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import InformationIconComponent from '../../icons/InformationIconComponent';
import { LOCAL_STORAGE_KEY } from '../../utils/localStorageKeys';
import ReactHtmlParser from 'react-html-parser';
import getNotifications from "../../apiCalls/components/Notification/getNotifications";

class Notification extends Component {
  state = {
    showNotification: false,
    notification: undefined,
  };

  componentDidMount = () => {
    getNotifications()
      .then((notificationsResult) => {
        return notificationsResult.json();
      })
      .then((notificationsResultJson) => {
        if (Array.isArray(notificationsResultJson) && notificationsResultJson.length > 0) {
          let now = Date.now();
          // only showing notification that are not expired
          notificationsResultJson = notificationsResultJson.filter(
            (n) => new Date(n.start) < now && new Date(n.end) > now
          );
          // getting the last not expired notification
          notificationsResultJson = notificationsResultJson[0]; 
          // if there are notifications that are not expired
          if (notificationsResultJson) {
            if (!!notificationsResultJson.start && !!notificationsResultJson.end && !!notificationsResultJson.message) {
              this.setState({ notification: notificationsResultJson });
              let last_acknowledged = localStorage.getItem(
                LOCAL_STORAGE_KEY.NOTIFICATION_ACKNOWLEDGED
              );
              if (!!last_acknowledged) {
                if (
                  last_acknowledged !==
                  `${this.state.notification.start}---${this.state.notification.end}`
                ) {
                  this.setState({ showNotification: true });
                }
              } else {
                this.setState({ showNotification: true });
              }
            }
          }
        }
      })
      .catch((err) => console.error('err', err));
  };

  toggleNotification = () => {
    if (
      localStorage.getItem(LOCAL_STORAGE_KEY.NOTIFICATION_ACKNOWLEDGED) ===
      `${this.state.notification.start}---${this.state.notification.end}`
    ) {
      this.setState({ showNotification: !this.state.showNotification });
    }
  };

  closeNotification = () => {
    this.setState({ showNotification: !this.state.showNotification });
    localStorage.setItem(
      LOCAL_STORAGE_KEY.NOTIFICATION_ACKNOWLEDGED,
      `${this.state.notification.start}---${this.state.notification.end}`
    );
  };

  render() {
    if (!this.state.notification) return <></>;
    return (
      <>
        <button
          style={{
            position: 'fixed',
            bottom: '1em',
            left: '1em',
            background: this.state.showNotification
              ? this.props.uiAssets.basicButtonBackground
              : '0',
            border: this.state.showNotification ? '2px solid black' : '0',
            color: this.state.showNotification
              ? this.props.uiAssets.basicButtonText
              : '#000000',
            borderRadius: '3px',
            width: '40px',
            height: '40px',
              zIndex: 99
          }}
          onClick={this.toggleNotification}
        >
          <InformationIconComponent accessibility={""} size={16}/>
        </button>
        {this.state.showNotification && (
          <div
            className="fade-in"
            style={{
              position: 'fixed',
              width: '550px',
              margin: '0 auto',
              padding: '1em',
              bottom: '4em',
              left: '1em',
              backgroundColor: '#ffffff',
              borderRadius: '3px',
              borderBottomLeftRadius: '0',
              boxShadow: '0px 2px 4px rgb(0 0 0 / 50%)',
              display: 'flex',
              flexDirection: 'column',
                zIndex: 99
            }}
          >
            <div className="notification-message">
              {typeof this.state.notification.message === 'object' &&
              this.state.notification.message !== null
                ? i18next.language in this.state.notification.message
                  ? ReactHtmlParser(
                      this.state.notification.message[i18next.language]
                    )
                  : ReactHtmlParser(this.state.notification.message['en'])
                : ReactHtmlParser(this.state.notification.message)}
            </div>
            <button
              style={{
                width: '120px',
                alignSelf: 'flex-end',
                border: 'none',
                borderRadius: '3px',
                backgroundColor: this.props.uiAssets.basicButtonBackground,
                color: this.props.uiAssets.basicButtonText,
              }}
              onClick={this.closeNotification}
            >
              Ok
            </button>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  uiAssets: state.user.organization.uiAssets,
});

export default withTranslation()(
  connect(mapStateToProps, undefined)(withRouter(Notification))
);
