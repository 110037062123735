import {Form, Input } from "antd";
import React from "react";

const ClientContactField = ({org}) => {
    return (
        <Form.Item
            label="PoC email"
            name="clientContact"
            initialValue={org.clientContact}
        >
            <Input/>
        </Form.Item>
    );
};

export default ClientContactField;