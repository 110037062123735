const TrashCanIcon = ({accessibility, color}) => {
    return (
        <svg title={accessibility} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <desc> {accessibility} </desc>
            <g clipPath="url(#clip0_82_9990)">
                <path d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_82_9990">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default TrashCanIcon;