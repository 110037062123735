import {Form, Switch} from "antd";
import React from "react";

const CustomErrorContactSwitch = ({handleCustomErrorContact, customErrorContact}) => {
    return (
        <Form.Item
            label={
                <div>
                    <label>Custom error contact</label>{' '}
                    <p
                        style={{
                            fontSize: '12px',
                            color: 'grey',
                            textAlign: 'left',
                        }}
                    >
                        {
                            'Activating this feature will change the email address to contact support for failed document translations.'
                        }
                    </p>
                </div>
            }
            name="customErrorContact"
            initialValue={customErrorContact}
        >
            <Switch
                checked={customErrorContact}
                onChange={handleCustomErrorContact}
            ></Switch>
        </Form.Item>
    );
};

export default CustomErrorContactSwitch;