import {GLAI_URL} from "../../utils/config";

const getQuotaByOrg = async (orgId) => {
    try {
        const config = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
                'Content-type': 'Application/json',
            },
        };

        const url = `${GLAI_URL}/apigateway/quotas/getOrganization/${orgId}`;
        const result = await fetch(url, config);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export default getQuotaByOrg;