import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { setTermsAcknowledged } from '../../redux/actions';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import './style.css';
import i18next from 'i18next';
import { LOCAL_STORAGE_KEY } from '../../utils/localStorageKeys';

class Terms extends Component {
  render() {
    let redirectURI = localStorage.getItem(LOCAL_STORAGE_KEY.REDIRECT_URI);
    if (!redirectURI) {
      redirectURI = '/';
    }
    if (
      !this.props.store.user.organization.uiAssets.termsPage ||
      this.props.store.user.organization.uiAssets.termsPage <= 0
    ) {
      return <Redirect to={redirectURI} />;
    }

    if (this.props.store.globalOptions.termsAcknowledged) {
      return <Redirect to={redirectURI} />;
    }

    var terms = this.props.store.user.organization.uiAssets.terms;

    return (
      <>
        <div className="overLay">
          <div
            className="boxContainer"
            style={{
              backgroundColor:
                this.props.store.user.organization.uiAssets.headerBackground,
              color:
                this.props.store.user.organization.uiAssets.navbarActiveText,
            }}
          >
            <div className="termsHeader">
              <img
                alt="organization logo"
                src={this.props.store.user.organization.uiAssets.headerLogo}
              />
              <div className="boxTitle">{terms.header}</div>
            </div>
            <div className="termsBody">
              {terms.title && terms.title !== '' && (
                <div className="termsTitle">{terms.title}</div>
              )}
              <div className="termsContent">
                {ReactHtmlParser(terms.content)}
              </div>
              <button
                className="standardBtn"
                onClick={this.props.setTermsAcknowledged}
                style={{
                  backgroundColor:
                    this.props.store.user.organization.uiAssets.navbarActive,
                  color:
                    this.props.store.user.organization.uiAssets
                      .navbarActiveText,
                }}
              >
                {terms.acknowledgeBtn}
              </button>
            </div>
            <div className="termsFooter">
              <div className="termsCopyright">
                {!this.props.uiAssets.copyright
                  ? this.props.t('copyright')
                  : this.props.uiAssets.copyright === '0' ||
                    this.props.uiAssets.copyright === 'none' ||
                    this.props.uiAssets.copyright === 'hide'
                  ? ''
                  : !!this.props.uiAssets.copyright[
                      i18next.language.substr(0, 2)
                    ]
                  ? this.props.uiAssets.copyright[i18next.language.substr(0, 2)]
                  : this.props.t('copyright')}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
  uiAssets: state.user.organization.uiAssets,
});

const mapDispatchToProps = (dispatch) => ({
  setTermsAcknowledged: () => dispatch(setTermsAcknowledged()),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Terms)
);
