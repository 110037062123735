import React from "react";
import Tippy from "@tippyjs/react";
import TrashCanIconComponent from "../../../icons/TrashCanIconComponent"

const DeleteCell = ({file, props}) => {
    return (
        <>
            <Tippy content={props.t('documentSubRowChildDeleteTippy')}>
                <div
                    tabIndex="0"
                    className= "svgStyledOnHover"
                    onClick={() => {
                        props.toggleConfirmation(file.fileId);
                    }}
                >
                    <TrashCanIconComponent accessibility={props.t('documentSubRowChildDeleteTippy')} color={'#333333'}/>
                </div>
            </Tippy>
        </>
    );
};

export default DeleteCell;