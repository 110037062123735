const WordCountComponent = ({accessibility, color, size}) => {
    const viewBox = "0 0 "  + size + " " + size;
    return (
        <svg title={accessibility} width={size} height={size} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <desc>{accessibility}</desc>
            <path d="M14 10H2V12H14V10ZM14 6H2V8H14V6ZM2 16H10V14H2V16ZM21.5 11.5L23 13L16.01 20L11.5 15.5L13 14L16.01 17L21.5 11.5Z" fill={color}/>
        </svg>
    );
};

export default WordCountComponent;