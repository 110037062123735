import {GLAI_URL} from "../../utils/config";

const zipSubmissionFiles =  (submissionId) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
        };

        const url = `${GLAI_URL}/apigateway/storage/submission/${submissionId}`;

        const result =  fetch(url, config);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export default zipSubmissionFiles;