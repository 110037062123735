import {Form, Select} from "antd";
import React from "react";

const DefaultTargetLanguageDropdown = ({defaultTargetLanguage, orgTargetLanguages, translate}) => {
    return (
        <Form.Item
            label="Default Target Language"
            name="defaultTarget"
            initialValue={defaultTargetLanguage()}
            rules={[
                {
                    required: true,
                    message: 'Default Target is required',
                },
            ]}
        >
            <Select
                showSearch
                placeholder="Select default target language"
                onChange={() => {}}
                style={{ width: '100%' }}
                optionFilterProp="label"
            >
                {orgTargetLanguages.map((item) => (
                    <Select.Option
                        key={item}
                        value={item}
                        label={translate(item)}
                    >
                        {translate(item)}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default DefaultTargetLanguageDropdown;