import {Form, Switch} from "antd";
import React from "react";

const CategoryMandatorySwitch = ({handleMandatoryCategory, mandatoryCategory}) => {
    return (
        <Form.Item
            label={<label>Make category a mandatory requirement</label>}
            name="categorySelectionMandatory"
            initialValue={mandatoryCategory}
        >
            <Switch
                checked={mandatoryCategory}
                onChange={handleMandatoryCategory}
                style={{ display:'inline', marginLeft: '20px' }}
            ></Switch>
        </Form.Item>
    );
};

export default CategoryMandatorySwitch;