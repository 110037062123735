import {
  CLIENT_ID,
  IDENTITY_CONFIG,
  METADATA_OIDC,
  SSO_URL,
} from './authConst';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { LOCAL_STORAGE_KEY } from './localStorageKeys';

export default class AuthService {
  UserManager;
  accessToken;

  constructor() {
    // Testing ---------------------------------

    // To be able to refereve this class from the browser console use:
    //window.auth = this;
    // Logs from oidc-client (has to be importet 'Log')
    //Log.logger = console;
    //Log.level = Log.DEBUG;

    // Setting up a new user manager object
    this.id = new Date();
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    });

    this.UserManager.events.addUserSignedOut(() => {
      if (!!window.injectTranslatedWebsite) return;

      this.inactivityLogout().then((e) => {
        window.location.replace('/logout');
      });
    });

    this.UserManager.events.addUserLoaded((user) => {
      sessionStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, user.access_token);
      sessionStorage.setItem(LOCAL_STORAGE_KEY.ID_TOKEN, user.id_token);
      sessionStorage.setItem(LOCAL_STORAGE_KEY.EXP, user.expires_at);
      sessionStorage.setItem(LOCAL_STORAGE_KEY.INACTIVE, false);

      const base64Url = user.access_token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const data = JSON.parse(window.atob(base64));
      sessionStorage.setItem(LOCAL_STORAGE_KEY.USER_ID, data.sub);

      //this.UserManager.clearStaleState();
    });

    this.UserManager.events.addSilentRenewError((e) => {
      console.error('Silent Renew Error');
      if (!!window.injectTranslatedWebsite) return;
      this.inactivityLogout().then((e) => {
        window.location.replace('/login');
      });
    });

    this.UserManager.events.addAccessTokenExpiring(() => {
      if (!!window.injectTranslatedWebsite) return;
      const inactive =
        localStorage.getItem(LOCAL_STORAGE_KEY.INACTIVE) === 'true';
      if (inactive) {
        window.location.replace('/inactivity-logout');
        return;
      }
      this.signinSilent();
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      if (!!window.injectTranslatedWebsite) return;
      this.inactivityLogout().then((e) => {
        window.location.replace('/login');
      });
    });
  }

  signinRedirectCallback = async () => {
    return this.UserManager.signinRedirectCallback();
  };

  getUser = async () => {
    return this.UserManager.getUser();
  };

  removeUser = () => {
    sessionStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    sessionStorage.removeItem(LOCAL_STORAGE_KEY.ID_TOKEN);
    sessionStorage.removeItem(LOCAL_STORAGE_KEY.USER_ID);
    this.UserManager.removeUser();
  };

  querySessionStatus = () => {
    return this.UserManager.querySessionStatus();
  };

  signinRedirect = () => {
    let avoidRedirectPaths = [
      '/login',
      '/inactivity-logout',
      '/logout',
      '/login-tpauth',
      '/refresh',
    ];
    if (!avoidRedirectPaths.includes(window.location.pathname)) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY.REDIRECT_URI,
        window.location.pathname
      );
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEY.REDIRECT_URI, '/');
    }

    this.UserManager.signinRedirect({});
  };

  setSessionInfo(authResult) {
    localStorage.setItem(
      LOCAL_STORAGE_KEY.ACCESS_TOKEN,
      authResult.accessToken
    );
    localStorage.setItem(LOCAL_STORAGE_KEY.ID_TOKEN, authResult.idToken);
  }

  isAuthenticated = () => {
    const access_token = sessionStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    const expires_at = sessionStorage.getItem(LOCAL_STORAGE_KEY.EXP);
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(`oidc.user:${SSO_URL}:${CLIENT_ID}`)
    );
    return (
      !!access_token &&
      !!oidcStorage &&
      !!oidcStorage.access_token &&
      !!expires_at &&
      parseInt(expires_at) > Date.now() / 1000
    );
  };

  signinSilent = () => {
    if (!!window.injectTranslatedWebsite) return;
    localStorage.setItem(LOCAL_STORAGE_KEY.PRESERVE, true);
    if (!this.isAuthenticated()) {
      this.inactivityLogout().then((e) => {
        window.location.replace('/login');
      });
      return;
    }

    this.UserManager.signinSilent()
      .then((user) => {})
      .catch((err) => {
        console.error('SigninSilent failed', err);
        this.inactivityLogout().then((e) => {
          window.location.replace('/logout');
        });
        console.error(err);
      });
  };

  signinSilentCallback = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY.PRESERVE, false);
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    let tempState = localStorage.getItem(LOCAL_STORAGE_KEY.STATE);

    let tempNotificationAck = localStorage.getItem(
      LOCAL_STORAGE_KEY.NOTIFICATION_ACKNOWLEDGED
    );
    this.UserManager.removeUser();
    this.UserManager.signoutRedirect({
      id_token_hint: sessionStorage.getItem(LOCAL_STORAGE_KEY.ID_TOKEN),
    });
    this.UserManager.clearStaleState();
    localStorage.clear();
    localStorage.setItem(LOCAL_STORAGE_KEY.STATE, tempState);
    if (tempNotificationAck !== null && !!tempNotificationAck) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY.NOTIFICATION_ACKNOWLEDGED,
        tempNotificationAck
      );
    }
  };

  inactivityLogout = async () => {
    this.UserManager.clearStaleState();
    let tempState = localStorage.getItem(LOCAL_STORAGE_KEY.STATE);

    let tempNotificationAck = localStorage.getItem(
      LOCAL_STORAGE_KEY.NOTIFICATION_ACKNOWLEDGED
    );
    localStorage.clear();
    localStorage.setItem(LOCAL_STORAGE_KEY.STATE, tempState);
    if (tempNotificationAck !== null && !!tempNotificationAck) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY.NOTIFICATION_ACKNOWLEDGED,
        tempNotificationAck
      );
    }

    return this.UserManager.removeUser().then(() =>
      this.UserManager.signoutRedirectCallback()
    );
  };

  signoutRedirectCallback = (reduxLogout) => {
    this.UserManager.signoutRedirectCallback().then(() => {
      let tempState = localStorage.getItem(LOCAL_STORAGE_KEY.STATE);
      let tempNotificationAck = localStorage.getItem(
        LOCAL_STORAGE_KEY.NOTIFICATION_ACKNOWLEDGED
      );
      localStorage.clear();
      localStorage.setItem(LOCAL_STORAGE_KEY.STATE, tempState);
      if (tempNotificationAck !== null && !!tempNotificationAck) {
        localStorage.setItem(
          LOCAL_STORAGE_KEY.NOTIFICATION_ACKNOWLEDGED,
          tempNotificationAck
        );
      }
      reduxLogout();
    });
    this.UserManager.clearStaleState();
  };
}
