const ZippingErrorIconComponent = ({accessibility, color}) => {
	return(
		<svg title={accessibility} width="24" height="24" version="1.1" id="Layer_1"  x="0px" y="0px" viewBox="0 0 24 24"  >
			<desc>{accessibility}</desc>
			<clipPath id="SVGID_00000065776688686251969680000015746241611361046431_"></clipPath>
			<path  d="M20,5.3h-8l-2-2.2H4c-1.1,0-2,1-2,2.2v13.3c0,1.2,0.9,2.2,2,2.2h16c1.1,0,2-1,2-2.2v-11 C22,6.3,21.1,5.3,20,5.3z M20,18.7H4V5.3h5.2l2,2.2H20V18.7z" fill={color}/>
			<polygon points="16,10.5 14.6,9.1 12,11.7 9.4,9.1 8,10.5 10.6,13.1 8,15.6 9.4,17.1 12,14.5 14.6,17.1 16,15.6 13.4,13.1 " fill={color}/>
		</svg>
	)
};

export default ZippingErrorIconComponent;