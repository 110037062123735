import React, {useState,useEffect} from "react";
import {ErrorCodeDesc} from "../ErrorCodes";
import { withTranslation } from 'react-i18next';
import Tippy from "@tippyjs/react";


const ErrorRow = ({file, props, state}) => {

    const [supportEmailBody,setSupportEmailBody] = useState("") //
    useEffect(() => {
        let emailBody = props.t('supportEmailBody') + "\r\nFileId " + file.fileId + "\r\nErrorcode " + file.errorCode + "\r\nSubmissionId " + file.subInfo.submissionId +
            "\r\nOrgId " + file.subInfo.orgId + "\r\nUserId " + file.subInfo.userId  + "\r\nInstance " + file.subInfo.instance;
        if (typeof(file.a2aFileId) != 'undefined')  emailBody = emailBody +  "\r\na2a File Id: " + file.a2aFileId
        if (typeof(file.a2aOperationId) != 'undefined') emailBody = emailBody + "\r\na2a Operation Id: " + file.a2aOperationId
        if (typeof(file.abbyTaskId) != 'undefined')  emailBody = emailBody +  "\r\nAbby Task Id: " + file.abbyTaskId
        setSupportEmailBody(encodeURIComponent(emailBody))
    });

    return (
        <>
            <tr className="subChild boxWithArrow" style={{ backgroundColor: '#F6F6F6'}}>
                <td style={{ width: '100%', paddingLeft: "16px"}}>
                    <div className="centered-text-cell">
                        <p className="errorMessageDocumentTranslation">
                            {((file.status === 'Translated' && file.errorCode === 'OCR-003-CVT-FAIL') || (file.status === 'Translated' && file.errorCode === '') || file.status === 'TranslatedConverted') //cases of successful translation
                                ? props.t('documentSubRowChildStatusTranslated')
                                : file.status === 'Error'
                                    ? props.t(props.file.errorCode) !== 'common error'
                                        ? props.file.fileName.endsWith('.pdf')
                                            ? props.t(props.file.errorCode + '-desc-pdf')
                                            : props.t(props.file.errorCode + '-desc')
                                        : ErrorCodeDesc(file.errorCode, props.t)
                                    : props.t('documentSubRowChildStatusTranslating')}<span>. </span>
                            {/*sending email with cc address in case there is one*/}
                            {props.t('helpSentencePartOne')}
                            <Tippy content={props.t('helpSentencePartTwoHover')}>
                            <a href={`mailto:${state.supportEmailTo}${state.supportEmailCc ? (`?cc=${state.supportEmailCc}&subject=Translation%20failed`) : (`?subject=Translation%20failed`)} 
                          &body=${supportEmailBody}`} className="mailToLink">
                                {props.t('helpSentencePartTwo')}
                            </a>
                            </Tippy>
                            {props.t('helpSentencePartThree')}
                        </p>
                    </div>
                </td>
            </tr>
        </>
    );
};

export default withTranslation()(ErrorRow)
