export const LOCAL_STORAGE_KEY = {
  FIRST_TOKEN_REFRESH: 'firstTokenRefresh',
  ACCESS_TOKEN: 'access_token',
  PRESERVE: 'preserve',
  REDIRECT_URI: 'redirectUri',
  ID_TOKEN: 'id_token',
  EXP: 'exp',
  USER_ID: 'userId',
  STATE: 'state',
  INACTIVE: 'inactive',
  NOTIFICATION_ACKNOWLEDGED: 'last_notification_acknowledged',
};
