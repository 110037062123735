import {Form, Select} from "antd";
import React from "react";
import {SPECIAL_FILE_FORMATS} from "../settingValues";

const SpecialFileFormatSelection = ({org, isNew}) => {
    return (
        <>
        <Form.Item
            label={
                <div>
                    <label>Special File Formats</label>{' '}
                    <p
                        style={{
                            fontSize: '12px',
                            color: 'grey',
                            textAlign: 'left',
                        }}
                    >
                        Default formats: doc, docm, docx, dot, dotm, dotx,
                        html, jpeg, jpg, odp, ods, odt, pdf, png, ppt, pptx, rtf, txt, xls, xlsm,
                        xlsx
                    </p>
                </div>
            }
            name="extraFormats"
            initialValue={isNew ? [] : org.uiAssets.extraFormats}
        >
            <Select
                mode="multiple"
                placeholder="Select extra formats"
                style={{ width: '100%' }}
            >
                {SPECIAL_FILE_FORMATS.map((item) => (
                    <Select.Option key={item} value={item}>
                        {item}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
        <p
            style={{
                fontSize: '12px',
                color: 'grey',
                textAlign: 'left',
                marginTop: '1em',
            }}
        >
            To add XML file format for an org, please contact the AI Team
        </p>
        </>
    );
};

export default SpecialFileFormatSelection;