const QualityScorePositiveFace = ({accessibility, color, size}) => {
    const viewBox = "0 0 "  + size + " " + size;
    return (
        <svg title={accessibility} width={size} height={size} viewBox={viewBox} fill={color} xmlns="http://www.w3.org/2000/svg">
            <desc>{accessibility}</desc>
            <path d="M9.99 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 9.99 20C15.51 20 20 15.53 20 10C20 4.47 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM11 7.94L12.06 9L13.12 7.94L14.18 9L15.24 7.94L13.12 5.82L11 7.94ZM6.88 7.94L7.94 9L9 7.94L6.88 5.82L4.76 7.94L5.82 9L6.88 7.94V7.94ZM10 15.5C12.33 15.5 14.31 14.04 15.11 12H4.89C5.69 14.04 7.67 15.5 10 15.5Z" fill="#36A93F"/>
        </svg>
    );
};

export default QualityScorePositiveFace;

