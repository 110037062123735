// This file is to be configured according to the environment

const baseUrl = window.location.origin;

// this is to be used on the server
const GLAI_URL = window.API_URL;

const TS_CLOUD_URL = window.TS_CLOUD_URL;

// this is to be used for local debugging
//const GLAI_URL = 'https://gl-dev-aiportal.transperfect.com';

// this is to be used on instances in China,
// by default the value in the enviroment.js file is an empty string
const CN_FOOTER = window.CN_FOOTER

const webTitle = "GLNow";

const UPLOADED_FILE_CHECK_TIME = window.UPLOADED_FILE_CHECK_TIME;

export { GLAI_URL, baseUrl, webTitle, TS_CLOUD_URL, CN_FOOTER, UPLOADED_FILE_CHECK_TIME};
