const CloseCrossIconComponent = ({accessibility, size, viewBox, color}) => {
    return (
        <svg title={accessibility} viewBox={viewBox} width={size} height={size} fill={color} xmlns="http://www.w3.org/2000/svg">
            <desc>{accessibility}</desc>
            <path d="M4.76643 12.7942L3.70557 11.7333L7.4389 8.00001L3.70557 4.26668L4.76643 3.20581L8.49977 6.93914L12.2331 3.20581L13.294 4.26668L9.56063 8.00001L13.294 11.7333L12.2331 12.7942L8.49977 9.06088L4.76643 12.7942Z" fill={color}/>
        </svg>

    // <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     <path d="M4.76643 12.7942L3.70557 11.7333L7.4389 8.00001L3.70557 4.26668L4.76643 3.20581L8.49977 6.93914L12.2331 3.20581L13.294 4.26668L9.56063 8.00001L13.294 11.7333L12.2331 12.7942L8.49977 9.06088L4.76643 12.7942Z" fill="white"/>
    // </svg>

);
};

export default CloseCrossIconComponent;