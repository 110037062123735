import React from "react";
import Tippy from "@tippyjs/react";
import {DownloadButton, style} from "../Styles";
import Spinner from "../../../icons/SpinnerComponent";
import TranslateFileDropdown from "./translateFileDropdown";


const DownloadFileCell = ({file, props, state, downloadSource, downloadFileAsPdf, downloadTranslatedFile}) => {
    return (
        <>
            <td
                tabIndex="-1"
                style={style.colSizeS}
            >
                <div className="centered-text-cell">

                        <DownloadButton
                            className="downloadSource"
                            colors={props.colors}
                            aria-label={props.t('documentSubRowChildDownloadTippy')}
                            alt={props.t('documentSubRowChildDownloadTippy')}

                        >
                            {state.loadingDownloadSourceFile ? (
                                <Tippy content={props.t('documentSubRowChildDownloadLoadingTippy')}>
                                    <Spinner accessibility={props.t("documentSubRowChildDownloadLoadingTippy")} size={32} color={"black"}/>
                                </Tippy>
                            ) : (
                                <TranslateFileDropdown file={file} props={props} state={state} downloadSource={(file) => downloadSource(file)} downloadFileAsPdf={(file) => downloadFileAsPdf(file)} downloadTranslatedFile={(file) => downloadTranslatedFile(file)}/>
                            )}
                        </DownloadButton>

                </div>
            </td>
        </>
    );
};

export default DownloadFileCell;