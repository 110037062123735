import {GLAI_URL} from "../../../utils/config";

const getTranslatedFileById =  (fileId) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem(
                    'access_token'
                )}`,
                fileType: 'translated_file',
            },
        };

        const url = `${GLAI_URL}/apigateway/storage/${fileId}?fileType=translated_file`;

        return fetch(url, config);

    } catch (error) {
        console.error(error);
    }
};
export default getTranslatedFileById;