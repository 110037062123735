import React from "react";
import {DeleteOutlined} from "@ant-design/icons";
import ImageUploader from "../../../../components/ImageUploader";
import ColorPicker from "../../../../components/ColorPicker";
import EditOrgStylePreview from "../EditStyle/preview";
import {
    Button,
    Col,
    Form,
    Row,
    Tooltip,
} from 'antd';

const formItemLayout = {
    labelAlign: 'right',
    labelCol: { span: 16 },
    wrapperCol: { span: 8 },
};

const StyleSettings = ({org, uiAssets,  uiAssetsDefault, onResetStyle, contextHolder, setUiAssets, setFormChanged}) => {
    return (
        <>
        <p style={{fontWeight: "bold"}}>Style</p>
        <Row gutter={[16, 16]}>
            <Col span={22} className="editOrgColumn">
                <p style={{fontWeight: "bold"}}>Change style</p>
            </Col>
            <Col span={2} className="editOrgColumn">
                <Tooltip
                    key="reset"
                    title={
                        org.uiAssets !== uiAssets
                            ? 'Reset to original values'
                            : 'No changes have been made'
                    }
                >
                    <Button
                        key="reset"
                        onClick={onResetStyle}
                        icon={<DeleteOutlined />}
                        disabled={org.uiAssets === uiAssets}
                        style={{float: "right", marginBottom: "12px"}}
                    >
                        Reset
                    </Button>
                </Tooltip>
            </Col>
        </Row>
        <Row gutter={[16, 16]}>
            <Col flex={'300px'} style={{ marginBottom: '1em' }}>
                {contextHolder}
                <Form
                    {...formItemLayout}
                    layout="horizontal"
                    autoComplete="off"
                >
                    <Form.Item label="Logo" labelCol={10} wrapperCol={14}>
                        <ImageUploader
                            value={uiAssets.headerLogo ? uiAssets.headerLogo: uiAssetsDefault.headerLogo}
                            background={org.uiAssets.headerBackground ? uiAssets.headerBackground: uiAssetsDefault.headerBackground}
                            onChange={(img) =>
                                setUiAssets({ ...org.uiAssets, headerLogo: img })
                            }
                            setFormChanged={setFormChanged}
                        />
                    </Form.Item>
                    <Form.Item style={{flexDirection: 'row'}} label="App background color">
                        <ColorPicker
                            value={uiAssets.appBackground ? uiAssets.appBackground: uiAssetsDefault.appBackground}
                            onChange={value => { setUiAssets({ ...uiAssets, appBackground: value }); setFormChanged(true) }}
                        />
                    </Form.Item>

                    <Form.Item style={{flexDirection: 'row'}} label="Header color">
                        <ColorPicker
                            value={uiAssets.headerBackground ? uiAssets.headerBackground: uiAssetsDefault.headerBackground}
                            onChange={value => { setUiAssets({ ...uiAssets, headerBackground: value }); setFormChanged(true) }}
                            changed={
                                uiAssets.headerBackground &&
                                (uiAssets.headerBackground !== org.uiAssets.headerBackground)
                            }
                        />
                    </Form.Item>

                    <Form.Item style={{flexDirection: 'row'}} label="Header text color">
                        <ColorPicker
                            value={uiAssets.dropdownTitleColor ? uiAssets.dropdownTitleColor: uiAssetsDefault.dropdownTitleColor}
                            onChange={value => { setUiAssets({ ...uiAssets, dropdownTitleColor: value }); setFormChanged(true) }}
                            changed={
                                uiAssets.dropdownTitleColor &&
                                (uiAssets.dropdownTitleColor !== org.uiAssets.dropdownTitleColor)
                            }
                        />
                    </Form.Item>
                    <Form.Item style={{flexDirection: 'row'}} label='Navigation bar "active"'>
                        <ColorPicker
                            value={uiAssets.navbarActive ? uiAssets.navbarActive: uiAssetsDefault.navbarActive}
                            onChange={value => { setUiAssets({ ...uiAssets, navbarActive: value }); setFormChanged(true) }}
                            changed={
                                uiAssets.navbarActive &&
                                (uiAssets.navbarActive !== org.uiAssets.navbarActive)
                            }
                        />
                    </Form.Item>
                    <Form.Item style={{flexDirection: 'row'}} label='Navigation bar "active" text'>
                        <ColorPicker
                            value={uiAssets.navbarActiveText ? uiAssets.navbarActiveText: uiAssetsDefault.navbarActiveText}
                            onChange={value => { setUiAssets({ ...uiAssets, navbarActiveText: value }); setFormChanged(true) }}
                            changed={
                                uiAssets.navbarActiveText &&
                                (uiAssets.navbarActiveText !== org.uiAssets.navbarActiveText)
                            }
                        />
                    </Form.Item>
                    <Form.Item style={{flexDirection: 'row'}} label="Nav bar underline color">
                        <ColorPicker
                            value={uiAssets.navbarBottomBorder ? uiAssets.navbarBottomBorder: uiAssetsDefault.navbarBottomBorder}
                            onChange={value => { setUiAssets({ ...uiAssets, navbarBottomBorder: value }); setFormChanged(true) }}
                            changed={
                                uiAssets.navbarBottomBorder &&
                                (uiAssets.navbarBottomBorder !== org.uiAssets.navbarBottomBorder)
                            }
                        />
                    </Form.Item>
                    <Form.Item style={{flexDirection: 'row'}} label="Nav bar idle color">
                        <ColorPicker
                            value={uiAssets.navbarIdle ? uiAssets.navbarIdle: uiAssetsDefault.navbarIdle}
                            onChange={value => { setUiAssets({ ...uiAssets, navbarIdle: value }); setFormChanged(true) }}
                            changed={
                                uiAssets.navbarIdle &&
                                (uiAssets.navbarIdle !== org.uiAssets.navbarIdle)
                            }
                        />
                    </Form.Item>
                    <Form.Item style={{flexDirection: 'row'}} label="Nav bar idle text color">
                        <ColorPicker
                            value={uiAssets.navbarIdleText ? uiAssets.navbarIdleText: uiAssetsDefault.navbarIdleText}
                            onChange={value => { setUiAssets({ ...uiAssets, navbarIdleText: value }); setFormChanged(true) }}
                            changed={
                                uiAssets.navbarIdleText &&
                                (uiAssets.navbarIdleText !== org.uiAssets.navbarIdleText)
                            }
                        />
                    </Form.Item>
                    <Form.Item style={{flexDirection: 'row'}} label="Translate button color">
                        <ColorPicker
                            value={uiAssets.translationButtonBackground ? uiAssets.translationButtonBackground: uiAssetsDefault.translationButtonBackground}
                            onChange={value => { setUiAssets({ ...uiAssets, translationButtonBackground: value }); setFormChanged(true) }}
                            changed={
                                uiAssets.translationButtonBackground &&
                                (uiAssets.translationButtonBackground !== org.uiAssets.translationButtonBackground)
                            }
                        />
                    </Form.Item>
                    <Form.Item style={{flexDirection: 'row'}} label="Translate button text color">
                        <ColorPicker
                            value={uiAssets.translationButtonText ? uiAssets.translationButtonText: uiAssetsDefault.translationButtonText}
                            onChange={value => { setUiAssets({ ...uiAssets, translationButtonText: value }); setFormChanged(true) }}
                            changed={
                                uiAssets.translationButtonText &&
                                (uiAssets.translationButtonText !== org.uiAssets.translationButtonText)
                            }
                        />
                    </Form.Item>
                    <Form.Item style={{flexDirection: 'row'}} label="Main button color">
                        <ColorPicker
                            isBottom = {true}
                            value={uiAssets.basicButtonBackground ? uiAssets.basicButtonBackground: uiAssetsDefault.basicButtonBackground}
                            onChange={value => { setUiAssets({ ...uiAssets, basicButtonBackground: value }); setFormChanged(true) }}
                            changed={
                                uiAssets.basicButtonBackground &&
                                (uiAssets.basicButtonBackground !== org.uiAssets.basicButtonBackground)
                            }
                        />
                    </Form.Item>
                    <Form.Item style={{flexDirection: 'row'}} label="Main button hover color">
                        <ColorPicker
                            isBottom = {true}
                            value={uiAssets.basicButtonBackgroundHover ? uiAssets.basicButtonBackgroundHover: uiAssetsDefault.basicButtonBackgroundHover}
                            onChange={value => { setUiAssets({ ...uiAssets, basicButtonBackgroundHover: value }); setFormChanged(true) }}
                            changed={
                                uiAssets.basicButtonBackgroundHover &&
                                (uiAssets.basicButtonBackgroundHover !== org.uiAssets.basicButtonBackgroundHover)
                            }
                        />
                    </Form.Item>
                    <Form.Item style={{flexDirection: 'row'}} label="Main button text">
                        <ColorPicker
                            isBottom = {true}
                            value={uiAssets.basicButtonText ? uiAssets.basicButtonText: uiAssetsDefault.basicButtonText}
                            onChange={value => { setUiAssets({ ...uiAssets, basicButtonText: value }); setFormChanged(true) }}
                            changed={
                                uiAssets.basicButtonText &&
                                (uiAssets.basicButtonText !== org.uiAssets.basicButtonText)
                            }
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col flex="auto">
                <EditOrgStylePreview uiAssets={uiAssets} uiAssetsDefault={uiAssetsDefault}/>
            </Col>
        </Row>
    </>
    );
};

export default StyleSettings;