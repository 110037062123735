import React from "react";
import SuccessfulTranslationMessage from "../SuccessfulTranslationMessage";
import ErrorTranslationMessage from "../ErrorTranslationMessage";
import Tippy from "@tippyjs/react";
import InformationIconComponent from "../../../icons/InformationIconComponent";
import CloseCrossIconComponent from "../../../icons/CloseCrossIconComponent"
import TranslationInProgressMessage from "../TranslationInProgressMessage";
import {style} from "../Styles";
import TranslationUploadingMessage from "../TranslationUploadingMessage";


const StatusCell = ({file, props, state, showInfoMessage}) => {
    return (
        <>
            <td
                className="statusTd"
                tabIndex="0"
                style={{
                    ...style.colSizeS,
                    color: file.status === 'Error' ? 'red' : style.color,
                }}
            >
                <div className="centered-text-cell">
                    {((file.status === 'Translated') || file.status === 'TranslatedConverted') ? ( // translation successful
                        <SuccessfulTranslationMessage translate={props.t}/>
                    ) : file.status === 'Error' ? (  // translation with errors
                        <>
                            <ErrorTranslationMessage translate={props.t} />
                            <Tippy
                                animation="scale"
                                content={!state.showInfoMessage ? props.t('documentSubRowChildFailedTranslationHelpTippy') : props.t("documentSubRowChildFailedTranslationHelpCloseTippy")}                            >
                                <div className="svgStyledOnHoverSmall" onClick={() =>showInfoMessage()} style={{cursor: "pointer"}}>
                                    {!state.showInfoMessage ? 
                                        <InformationIconComponent accessibility={props.t('documentSubRowChildFailedTranslationHelpTippy')} size={16}/>
                                        : <CloseCrossIconComponent accessibility={props.t("documentSubRowChildFailedTranslationHelpCloseTippy")} size={16} viewBox={"0 0 16 16"} color={"#323232"}/>
                                    }
                                </div>
                            </Tippy>
                        </>
                    ) : ( // translation in progress
                        (file.status === 'Uploading') ? (
                            <TranslationUploadingMessage translate={props.t} />
                        ) : (
                            <TranslationInProgressMessage translate={props.t} />
                        )
                    )}
                </div>
            </td>
        </>
    );
};

export default StatusCell;