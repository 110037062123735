import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

const style = {
  outside: {
    width: "100%",
    display: "flex",
    fontSize: "16px",
    fontWeight: "bold",
    height: "1em",
    textAlign: "left",
    paddingBottom: "32px",
  },
  colSizeXXL: {
    width: "33.35%",
    paddingLeft: "16px"
  },
  colSizeXL: {
    width: "25%",
    paddingLeft: "16px"
  },
  colSizeXS: {
    width: "8.35%",
    paddingLeft: "16px"
  },
};

function SubHeader(props) {
  useEffect(() => {
    document.body.addEventListener("mousedown", function() {
      document.body.classList.add("using-mouse");
    });
  });
  const { t } = useTranslation();
  return (
    <thead>
      <tr style={style.outside}>
        {/*name taking up all space if category is missing*/}
        <th tabIndex="0" scope="col" style={props.showCategory ? style.colSizeXL : style.colSizeXXL}>
          {t("documentSubHeaderSubName")}
        </th>
        {props.showCategory &&
            <th tabIndex="0" scope="col" style={style.colSizeXS}>
              {t("categoryHeader")}
            </th>
        }
        <th tabIndex="0" scope="col" style={{...style.colSizeXS}}>
          {t("documentSubHeaderDate")}
        </th>
        <th tabIndex="0" scope="col" style={style.colSizeXL}>
          {t("documentSubHeaderSource")}
        </th>
        <th tabIndex="0" scope="col" style={style.colSizeXS }>
          {t("documentSubHeaderStatus")}
        </th>
        <th tabIndex="0" scope="col" style={style.colSizeXS }>
          {/*{t("documentSubHeaderStatus")}*/}
        </th>
        <th
          tabIndex="0"
          scope="colgroup"
          style={style.colSizeXS}
          className="downloadTh"
        >
          {t("documentSubHeaderFiles")}
        </th>
        <th
          tabIndex="0"
          scope="col"
          className="deleteTh"
          style={{...style.colSizeXS}}
        >
          {t("documentSubHeaderActions")}
        </th>
      </tr>
    </thead>
  );
}

export default SubHeader;
