import React from "react";
import Announcements from "../../Announcements";
import FileItem from "../../fileItem";
import CategorySelection from "../../CategorySelection";
import EngineSelection from "../../EngineSelection";

const UploadArea = ({showUploadFailError, uploadFailErrorMessage, showUploadedMessage, files, accept, fileInput, showUnsupportedFileErrorFromDocument,
                        translate, submissionConcept, handleSubmissionConcept, onChange, props, selectedCategory, categoriesListState,
                        handleCategoryChange, loadingCategories, disabledCategoryDropdown, isCategoryMandatory, showCategory, errorMessage, isSubmissionNameTooLong }) => {
    return (
        <>
            <div className="upload-area">
                {showUploadedMessage && showUploadFailError && (
                    <p
                        role="alert"
                        id="upload_error"
                        style={{
                            margin: 'auto',
                            color: "red",
                            fontSize: '16px',
                            fontWeight: 'normal',
                            textAlign: 'center'
                        }}
                    >
                        {uploadFailErrorMessage}
                    </p>
                )}
                {!showUploadedMessage && (
                    <React.Fragment>
                        <div className="fileList" role="list">
                            {files.map((el, i) => (
                                <FileItem
                                    key={el.name + i}
                                    rmFile={props.rmFile}
                                    file={el}
                                ></FileItem>
                            ))}
                        </div>
                    </React.Fragment>
                )}

                <input
                    aria-label={null}
                    tabIndex="-1"
                    type="file"
                    id="bob"
                    multiple
                    className="visuallyhidden"
                    onChange={onChange}
                    accept={accept}
                    ref={fileInput}
                ></input>
                {showUnsupportedFileErrorFromDocument && (
                    <div
                        style={{
                            alignSelf: 'center',
                            width: '100%',
                            height: '2em',
                        }}
                    >
                        <p
                            role="alert"
                            className="format-alert"
                            style={{ textAlign: 'center' }}
                        >
                            {translate('unsupportedFileMessage')}
                        </p>
                        <Announcements message={translate('unsupportedFileMessage')} />{' '}
                    </div>
                )}

                {!showUploadedMessage && (
                    <>
                        {!props.loadingEngines && props.store.user.organization.uiAssets.engineSelectionDropdown && !props.disabledEngineDropdown &&
                            <div style={{paddingLeft: "24px", paddingTop: "24px"}}>
                                <p className="submissionNameTitle">Engine</p>
                                <EngineSelection engineState={props.engine} selectedEngine={props.selectedEngine} engineListState={props.engineListState} handleEngineChange={props.handleEngineChange} isDropdownInTextTranslation={false}/>
                            </div>
                        }

                        <div className="categoryAndSubmissionNameWrapper">
                            {!!showCategory ?
                                (<div className="fileCategoryWrapper">
                                     <p>{translate('categoryHeader')} {!!isCategoryMandatory ? <span style={{color: "red"}}>*</span> : ""}</p>
                                    <CategorySelection selectedCategory={selectedCategory} categoriesListState={categoriesListState} handleCategoryChange={handleCategoryChange} loadingCategories={loadingCategories} disabledCategoryDropdown={disabledCategoryDropdown} isCategoryMandatory={isCategoryMandatory} errorMessage={errorMessage}/>
                                </div>) : (<div style={{paddingRight: "24px"}}></div>)
                            }
                            <div className="submissionNameWrapper">
                                <p>{translate('submissionNameHeader')}</p>
                                <input type="text"
                                       id="submissionConcept"
                                       name="submissionConcept"
                                       autoComplete="off" //avoid showing previous names
                                       placeholder={translate('submissionNamePlaceholder')}
                                       value={submissionConcept}
                                       onChange={handleSubmissionConcept}
                                       style={{border: isSubmissionNameTooLong ? '2px solid #0E23E4' : '1px solid rgb(179, 179, 179)'}}
                                />
                            </div>
                        </div>

                    </>
                )}
            </div>
        </>
    );
};

export default UploadArea;