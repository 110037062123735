import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import '../../ant-style.css';
import '../../style.css';
import './style.css';
import {
  Button,
  Col,
  Form,
  Modal,
  PageHeader,
  Row,
  Select,
  Skeleton,
  Table,
  Tooltip,
} from 'antd';
import {
  ArrowLeftOutlined,
  CheckOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Prompt } from 'react-router';
import { Link } from 'react-router-dom';
import { DEFAULT_LANGUAGES } from '../settingValues';
import getOrganizationById from "../../../../apiCalls/Usermanagement/EditOrgPage/getOrganizationById";
import getOrganizationByIdFromMtengine from "../../../../apiCalls/Usermanagement/EditOrgPage/getOrganizationByIdFromMtengine";
import modifyOrganizationByIdFromMtengine from "../../../../apiCalls/Usermanagement/EditOrgPage/modifyOrganizationByIdFromMtengine";
import getAllEngines from "../../../../apiCalls/Usermanagement/EditOrgPage/getAllEngines";

const EditOrgProfiles = (props) => {
  const [loading, setLoading] = useState(true);
  const [org, setOrg] = useState({});
  const [profiles, setProfiles] = useState({});
  const [originalProfiles, setOriginalProfiles] = useState({});
  const [modal, contextHolder] = Modal.useModal();
  const [engineEntities, setEngineEntities] = useState({
    '60faebdee28d8e7275887001': 'transperfect main engine',
    '61000b5b8978d42fc298f339': 'microsoft main engine',
    '61000bd18978d42fc298f33a': 'google main engine',
    '61000c838978d42fc298f33b': 'amazon main engine',
    '61000cb18978d42fc298f33c': 'deepl main engine',
  });
  const [addMulitpleProfilesForm] = Form.useForm();

  const loadOrg = useCallback(() => {
    setLoading(true);
    getOrganizationById(props.match.params.orgId)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Request failed');
        }
      })
      .then((json) => {
        if (json.success) {
          setOrg({
            ...json.message,
          });
        } else {
          throw new Error(json.message);
        }
        return getOrganizationByIdFromMtengine(props.match.params.orgId);
      })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Request failed');
        }
      })
      .then((json) => {
        if (json.success) {
          if (!!json.message.profiles) {
            for (const [src, trgObj] of Object.entries(json.message.profiles)) {
              for (const [trg, engineArray] of Object.entries(trgObj)) {
                json.message.profiles[src][trg] = engineArray.map((item) => ({
                  engine: item.engine.id,
                  failover: item.failover.id,
                }));
              }
            }
            setProfiles(json.message.profiles);
            setOriginalProfiles(json.message.profiles);
          }
        } else {
          throw new Error(json.message);
        }
        return getAllEngines();
      })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Request failed');
        }
      })
      .then((json) => {
        if (json.success) {
          if (Array.isArray(json.message)) {
            let engines = {};
            for (const engine of json.message) {
              engines[engine.id] = engine.alias;
            }
            setEngineEntities(engines);
          }
          setLoading(false);
        } else {
          throw new Error(json.message);
        }
      })
      .catch((err) => {
        console.error(err);
        Modal.error({
          title: 'Error',
          content: `Organization with id '${props.match.params.orgId}' doesn't exist`,
        });
        props.history.push('/usermanagement');
      });
    // this function will rerender only when these params are updated
  }, [props.history, props.match.params.orgId]);

  const updateOrg = () =>
    new Promise((resolve, reject) => {
      let body = JSON.stringify({ profiles: profiles });
      modifyOrganizationByIdFromMtengine(body, props.match.params.orgId)
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            throw new Error('Request failed');
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });

  const handleSubmit = () => {
    let confirmModal = modal.confirm({
      title: 'Update Organization Style',
      content: 'Are you sure you want to update the organization style?',
      onOk(closeModal) {
        confirmModal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true },
        });
        updateOrg()
          .then(() => {
            confirmModal.update({
              title: 'Success',
              content: `Org profiles updated`,
              okButtonProps: { loading: false },
              cancelButtonProps: { hidden: true },
              onOk() {
                closeModal();
                loadOrg();
              },
            });
          })
          .catch((err) => {
            confirmModal.update({
              title: 'Error',
              content: `Request failed: ${err}`,
              okButtonProps: { loading: false },
              cancelButtonProps: { hidden: true },
              onOk() {
                closeModal();
              },
            });
          });
      },
    });
  };

  const onReset = () => {
    setProfiles(originalProfiles);
  };

  const createTree = (profiles) => {
    if (!profiles) return [];
    let tree = [];
    for (const [sourceLang, profile] of Object.entries(profiles)) {
      let sourceNode = {
        sourceLanguage: props.t(sourceLang),
        key: sourceLang,
        children: [],
        delete: (
          <Button size="small" onClick={() => deleteSource(sourceLang)}>
            <DeleteOutlined />
          </Button>
        ),
      };
      for (const [targetLang, engineArray] of Object.entries(profile)) {
        let targetNode = {
          targetLanguage: props.t(targetLang),
          key: sourceLang + '-' + targetLang,
          children: [],
          delete: (
            <Button
              size="small"
              onClick={() => deleteTarget(sourceLang, targetLang)}
            >
              <DeleteOutlined />
            </Button>
          ),
        };
        for (const [idx, engineObj] of engineArray.entries()) {
          targetNode.children.push({
            title: idx,
            engine: engineEntities[engineObj.engine],
            failover: engineEntities[engineObj.failover],
            key:
              '' +
              sourceLang +
              '-' +
              targetLang +
              '-' +
              engineObj.engine +
              '-' +
              engineObj.failover,
            delete: (
              <Button
                size="small"
                onClick={() => deleteEntity(sourceLang, targetLang, idx)}
              >
                <DeleteOutlined />
              </Button>
            ),
          });
        }

        sourceNode.children.push(targetNode);
      }
      tree.push(sourceNode);
    }
    return tree;
  };

  const deleteSource = (src) => {
    let tempProfiles = { ...profiles };
    delete tempProfiles[src];
    setProfiles(tempProfiles);
  };

  const deleteTarget = (src, trg) => {
    let tempProfiles = { ...profiles };
    delete tempProfiles[src][trg];
    setProfiles(tempProfiles);
  };
  const deleteEntity = (src, trg, idx) => {
    let tempProfiles = { ...profiles };
    tempProfiles[src][trg].splice(idx, 1);
    setProfiles(tempProfiles);
  };

  const addMultiple = (form) => {
    let tempProfiles = { ...profiles };
    for (const srcLang of form.sourceLangs) {
      for (const trgLang of form.targetLangs) {
        if (srcLang !== trgLang) {
          if (!(srcLang in tempProfiles)) {
            tempProfiles[srcLang] = {};
          }
          if (!(trgLang in tempProfiles[srcLang])) {
            tempProfiles[srcLang][trgLang] = [];
          }
          tempProfiles[srcLang][trgLang].push({
            engine: form.engine,
            failover: form.failover,
          });
        }
      }
    }
    setProfiles(tempProfiles);
    addMulitpleProfilesForm.resetFields();
  };

  //this will be called only the first time the component is rendered
  useEffect(() => {
    loadOrg();
  }, [loadOrg]);


  const breadCrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
  };

  return (
    <div className="editOrgWrapper">
      <div className="editOrgContainer userManagementContainer">
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <Prompt
              when={originalProfiles !== profiles}
              message="Changes you made may not be saved."
            />
            <PageHeader
              title={`Change profiles of organization '${org.name}'`}
              className="site-page-header"
              size="small"
              breadcrumb={{
                routes: [
                  {
                    path: 'usermanagement',
                    breadcrumbName: 'User Management',
                  },
                  {
                    path: `org/${org.id}/edit`,
                    breadcrumbName: org.name,
                  },
                  {
                    path: 'profiles',
                    breadcrumbName: 'Profiles',
                  },
                ],
                itemRender: breadCrumbItemRender,
              }}
              backIcon={
                <>
                  <Button icon={<ArrowLeftOutlined />} />
                </>
              }
              onBack={() =>
                props.history.push(
                  `/usermanagement/org/${props.match.params.orgId}/edit`
                )
              }
              extra={[
                <Tooltip
                  title={
                    originalProfiles !== profiles
                      ? 'Reset to original values'
                      : 'No changes have been made'
                  }
                  key="0"
                >
                  <Button
                    key="reset"
                    onClick={onReset}
                    icon={<DeleteOutlined />}
                    disabled={originalProfiles === profiles}
                  >
                    Reset
                  </Button>
                </Tooltip>,
                <Tooltip
                  title={
                    originalProfiles !== profiles
                      ? 'Save organization values'
                      : 'No changes have been made'
                  }
                  key="1"
                >
                  <Button
                    key="submit"
                    type="primary"
                    onClick={handleSubmit}
                    icon={<CheckOutlined />}
                    disabled={originalProfiles === profiles}
                  >
                    Submit
                  </Button>
                </Tooltip>,
              ]}
            />
            {contextHolder}
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form
                  form={addMulitpleProfilesForm}
                  name="addProfiles"
                  layout="horizontal"
                  style={{ marginBottom: '1em' }}
                  onFinish={addMultiple}
                >
                  <Form.Item
                    name="sourceLangs"
                    label="Source Languages"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      mode="multiple"
                      placeholder="Add Source Languages"
                      optionFilterProp="label"
                    >
                      {DEFAULT_LANGUAGES.filter((lang) => lang !== 'auto')
                        .sort((l1, l2) =>
                          props.t(l1).localeCompare(props.t(l2))
                        )
                        .map((item) => (
                          <Select.Option
                            key={item}
                            value={item}
                            label={item === '*' ? 'All (*)' : props.t(item)}
                          >
                            {item === '*' ? 'All (*)' : props.t(item)}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="targetLangs"
                    label="Target Languages"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      mode="multiple"
                      placeholder="Add Target Languages"
                      optionFilterProp="label"
                    >
                      {DEFAULT_LANGUAGES.filter((lang) => lang !== 'auto')
                        .sort((l1, l2) =>
                          props.t(l1).localeCompare(props.t(l2))
                        )
                        .map((item) => (
                          <Select.Option
                            key={item}
                            value={item}
                            label={item === '*' ? 'All (*)' : props.t(item)}
                          >
                            {item === '*' ? 'All (*)' : props.t(item)}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="engine"
                    label="Engine"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Engine"
                      optionFilterProp="label"
                    >
                      {Object.entries(engineEntities).map((item) => (
                        <Select.Option
                          key={item[0]}
                          value={item[0]}
                          label={item[1]}
                        >
                          {item[1]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="failover" label="Fail over">
                    <Select
                      showSearch
                      placeholder="Select Failover"
                      optionFilterProp="label"
                    >
                      {Object.entries(engineEntities).map((item) => (
                        <Select.Option
                          key={item[0]}
                          value={item[0]}
                          label={item[1]}
                        >
                          {item[1]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit" type="primary">
                      Add
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={16}>
                <Table
                  size="small"
                  pagination={false}
                  sticky={true}
                  scroll={true}
                  columns={[
                    {
                      title: 'Source Language',
                      dataIndex: 'sourceLanguage',
                      key: 'sourceLanguage',
                    },
                    {
                      title: 'Target Language',
                      dataIndex: 'targetLanguage',
                      key: 'targetLanguage',
                    },
                    {
                      title: 'Engine',
                      dataIndex: 'engine',
                      key: 'engine',
                    },
                    {
                      title: 'Failover',
                      dataIndex: 'failover',
                      key: 'failover',
                    },
                    {
                      title: 'Delete',
                      dataIndex: 'delete',
                      key: 'delete',
                    },
                  ]}
                  dataSource={createTree(profiles)}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUserEmail: state.user.email,
});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EditOrgProfiles)
);
