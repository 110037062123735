import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import LanguageSelection from './languageSelection';
import qs from 'qs';
import './style.css';
import { ReactComponent as ProcessingSVG } from './processing.svg';
import { ReactComponent as SpinnerSmall } from './spinner.svg';
import { ReactComponent as ClearSVG } from './clearSVG.svg';
import {baseUrl, GLAI_URL, webTitle} from '../../utils/config';
import Tippy from '@tippyjs/react';
import { Helmet } from 'react-helmet';
import WebsiteTranslationLoader from './WebsiteTranslationLoader';
import getWebsiteTranslation from "../../apiCalls/WebsiteTranslator/getWebsiteTranslation";

class WebsiteTranslator extends Component {
  constructor(props) {
    super(props);

    let params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    let url = '',
      loadingWebsite = false,
      errorMessage = null,
      translatedInitWebsite = '';
    if (params.url) {
      url = params.url;
      if (params.from && params.to) {
        loadingWebsite = true;
        params.from = params.from.replaceAll('_', '-');
        params.to = params.to.replaceAll('_', '-');
        this.translateWebsite(params.from, params.to, params.url)
          .then((website) => {
            if (website != null) {
              window.injectTranslatedWebsite(website, null);
            } else {
              throw new Error('Website could not be opened');
            }
          })
          .catch((err) => {
            window.injectTranslatedWebsite = undefined;
            console.error(err);
            errorMessage = err.message;
          })
          .finally(() => {
            loadingWebsite = false;
            if (this.state) this.setState({ loadingWebsite: false });
          });
      } else {
        errorMessage =
          "Missing parameters 'from' and 'to' for website translation call";
      }
    }

    this.state = {
      urlToTranslate: url,
      typingTimeout: null,
      previouslyMadeRequests:
        JSON.parse(localStorage.getItem('previouslyMadeRequest')) || [],
      validated: false,
      errorMessage: errorMessage,
      loadingWebsite: loadingWebsite,
      loadingWebsiteInit: false,
      validating: false,
      translatedInitURL: '',
      translatedInitWebsite: translatedInitWebsite,
      translatedInitURLPath: '',
      translatedWebsiteTab: null,
      srcTrgEqual: false,
    };
  }

  componentDidMount() {
    if (this.state.urlToTranslate !== '') {
      if (this.state.errorMessage) {
        this.setState({ validated: true });
      } else {
        this.validateURL();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.srcTrgEqual && this.state.srcTrgEqual) {
      this.setState({
        unsupportedFileMessage: this.props.t('WFM-04-SRCTRGSAME'),
      });
    } else if (prevState.srcTrgEqual && !this.state.srcTrgEqual) {
      this.setState({ unsupportedFileMessage: '' });
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      srcTrgEqual:
        props.store.globalOptions.sourceLanguage ===
        props.store.globalOptions.targetLanguage,
    };
  }

  handleChange = (e) => {
    this.state.typingTimeout && clearTimeout(this.state.typingTimeout);
    let urlInputValue = e.target.value;
    this.setState({
      urlToTranslate: urlInputValue,
      validated: false,
      validating: true,
      typingTimeout: setTimeout(this.validateURL, 800),
    });
  };

  handleKeyPress = (e) => {
    if (
      e.key === 'Enter' &&
      !this.state.validating &&
      !this.state.srcTrgEqual
    ) {
      this.translateWebsiteHandler();
    }
  };

  translateWebsiteHandler = () => {
    if (!this.state.loadingWebsiteInit && this.validateURL()) {
      let query = {
        from: this.props.store.globalOptions.sourceLanguage,
        to: this.props.store.globalOptions.targetLanguage,
        url: this.state.urlToTranslate,
      };

      this.saveWebsiteInHistory(query.url);

      this.setState({
        loadingWebsiteInit: true,
        loadingWebsite: true,
      });

      //`${baseUrl}/website`
      let translatedURLPath =
        `${baseUrl}/website` +
        qs.stringify(query, { encodeValuesOnly: true, addQueryPrefix: true });

      let win = window.open(`/website/translation-loader`, '', '');
      win.addEventListener('load', () => {
        win.history.pushState({}, null, translatedURLPath);
      });
      this.translateWebsite(query.from, query.to, query.url)
        .then((website) => {
          if (website != null) {
            if (win && !win.closed) {
              //this.props.a.UserManager._sessionMonitor._checkSessionIFrame._frame
              setTimeout(() => {
                win.injectTranslatedWebsite(website, translatedURLPath);
                win.focus();
              }, 2000);
            }
            this.setState({
              translatedInitURL: query.url,
              translatedInitWebsite: website,
              translatedInitURLPath: translatedURLPath,
              translatedWebsiteTab: win,
              urlToTranslate: '',
            });
          } else {
            throw new Error('Website could not be opened');
          }
        })
        .catch((err) => {
          window.injectTranslatedWebsite = undefined;
          win.close();
          console.error(err);
          this.setState({
            errorMessage: err.message,
          });
        })
        .finally(() => {
          this.setState({ loadingWebsiteInit: false, loadingWebsite: false });
        });
    }
  };

  translateWebsite = async (src, trg, url) => {
    let query = {
      from: src,
      to: trg,
      url: url,
    };

    let translateRequestURL =
      `${GLAI_URL}/apigateway/websitetranslator` +
      qs.stringify(query, { encodeValuesOnly: true, addQueryPrefix: true });

    const res = await getWebsiteTranslation(translateRequestURL);

    if (res.ok) {
      return (await res).text();
    } else {
      throw new Error(
        'The website could not be translated. HTTP Error: ' +
          res.status +
          ' - ' +
          res.statusText
      );
    }
  };

  saveWebsiteInHistory = (url) => {
    let tempPreviouslyMadeRequests = [
      url,
      ...this.state.previouslyMadeRequests,
    ];
    this.setState({
      previouslyMadeRequests: tempPreviouslyMadeRequests,
    });
    localStorage.setItem(
      'previouslyMadeRequest',
      JSON.stringify(tempPreviouslyMadeRequests)
    );
  };

  validateURL = () => {
    this.state.typingTimeout && clearTimeout(this.state.typingTimeout);
    this.setState({ validating: true });
    let url = this.state.urlToTranslate;
    if (url.trim() === '') {
      this.setState({
        validated: false,
        errorMessage: null,
        validating: false,
      });
      return false;
    }
    if (!url.startsWith('http') && !url.startsWith('https')) {
      this.setState((state) => {
        return { urlToTranslate: 'https://' + state.urlToTranslate };
      });
      return this.validateURL();
    }

    this.setState({ validated: true, validating: false });
    //URL Pattern
    const urlPattern = /\./g;
    let match = urlPattern.test(url);
    if (!match) {
      this.setState({
        errorMessage: this.props.t('invalidURL'),
      });
      return false;
    }
    this.setState({
      errorMessage: null,
    });

    return true;
  };

  handleOnClickPreviouslyRequestURL = (url) => {
    this.setState(
      {
        urlToTranslate: url,
      },
      this.validateURL
    );
  };

  flushPreviousWebsiteTranslateRequest = () => {
    localStorage.removeItem('previouslyMadeRequest');
    this.setState({
      previouslyMadeRequests: [],
    });
  };

  openWebsiteInNewTab = () => {
    if (
      this.state.translatedWebsiteTab &&
      !this.state.translatedWebsiteTab.closed
    ) {
      this.state.translatedWebsiteTab.focus();
    } else {
      try {
        let win = window.open(`/website/translation-loader`, '', '');
        win.addEventListener('load', () => {
          win.history.pushState({}, null, this.state.translatedInitURLPath);
        });
        win.addEventListener('load', (e) => {
          win.injectTranslatedWebsite(
            this.state.translatedInitWebsite,
            this.state.translatedInitURLPath
          );
          win.focus();
        });

        this.setState({
          translatedWebsiteTab: win,
        });
      } catch (e) {
        window.injectTranslatedWebsite = undefined;
        this.setState({
          errorMessage: e.message,
        });
      }
    }
  };

  render() {
    if (this.state.loadingWebsite && !this.state.loadingWebsiteInit) {
      return (
        <WebsiteTranslationLoader
          website={this.state.translatedInitWebsite}
          a={this.props.a}
          expirationCheckerInterval={this.props.expirationCheckerInterval}
        />
      );
    } else {
      return (
        <>
          <Helmet>
            <title>{webTitle}</title>
          </Helmet>
          <div className="pageHeader" />
          <div className="websitetranslatorContainer">
            <LanguageSelection
              {...this.props}
              srcTrgEqual={this.state.srcTrgEqual}
              validateURL={this.validateURL}
            />
            <div className="websiteTranslatorInputsContainer">
              <div className="urlInputFieldContainer">
                <input
                  type="url"
                  className="urlInputField"
                  value={this.state.urlToTranslate}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                  placeholder={this.props.t('urlInputPlaceHolder')}
                />
                <div className="urlInputActions">
                  {this.state.validating && (
                    <ProcessingSVG className="urlInputSpinner" fill="#000000" />
                  )}
                  {this.state.urlToTranslate !== '' && (
                    <Tippy content={this.props.t('ClearURLLabelandTippy')}>
                      <button
                        className="coffeeButton"
                        aria-label={this.props.t('ClearURLLabelandTippy')}
                        onClick={() => {
                          this.setState({ urlToTranslate: '' });
                        }}
                      >
                        <ClearSVG />
                      </button>
                    </Tippy>
                  )}
                </div>
              </div>
              {this.state.urlToTranslate.trim() !== '' && (
                <button
                  disabled={
                    !this.state.validated ||
                    this.state.errorMessage ||
                    this.state.srcTrgEqual
                  }
                  onClick={this.translateWebsiteHandler}
                  className="standardBtn"
                  style={{
                    backgroundColor:
                      this.props.store.user.organization.uiAssets.navbarActive,
                    color:
                      this.props.store.user.organization.uiAssets
                        .navbarActiveText,
                  }}
                >
                  {this.state.loadingWebsiteInit ? (
                    <SpinnerSmall
                      width="32px"
                      height="32px"
                      fill={
                        this.props.store.user.organization.uiAssets
                          .navbarActiveText
                      }
                    />
                  ) : (
                    this.props.t('websiteTranslateAction')
                  )}
                </button>
              )}
            </div>
            {this.state.errorMessage && (
              <div className="websiteTranslationerrorMessage">
                {this.state.errorMessage}
              </div>
            )}
            {this.state.translatedInitWebsite &&
              this.state.translatedInitWebsite !== '' && (
                <div className="lastRequestLoader">
                  <div>{this.props.t('lastWTRequestURL')}</div>
                  {this.state.translatedInitURL}
                  <button
                    onClick={this.openWebsiteInNewTab}
                    className="standardBtn"
                    style={{
                      backgroundColor:
                        this.props.store.user.organization.uiAssets.navbarIdle,
                      color:
                        this.props.store.user.organization.uiAssets
                          .navbarIdleText,
                      width: 'auto',
                      height: 'auto',
                    }}
                  >
                    {this.props.t('openWebsiteBtnLabel')}
                  </button>
                </div>
              )}

            {this.state.previouslyMadeRequests.length > 0 && (
              <div className="previousWebsiteTranslateRequests">
                <div>
                  {this.props.t('previousWebsiteTranslateRequestTitle')}
                </div>
                <ul>
                  {this.state.previouslyMadeRequests.map((request, i) => {
                    return (
                      <li
                        key={i}
                        className="previousWebsiteTranslateRequest"
                        onClick={() => {
                          this.handleOnClickPreviouslyRequestURL(request);
                        }}
                      >
                        {request}
                      </li>
                    );
                  })}
                </ul>

                <button
                  onClick={this.flushPreviousWebsiteTranslateRequest}
                  className="standardBtn"
                  style={{
                    backgroundColor:
                      this.props.store.user.organization.uiAssets.navbarIdle,
                    color:
                      this.props.store.user.organization.uiAssets
                        .navbarIdleText,
                    width: 'auto',
                    height: 'auto',
                    fontSize: '12px',
                  }}
                >
                  {this.props.t('removeAllPreviousWebsiteTranslateRequests')}
                </button>
              </div>
            )}
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withTranslation()(connect(mapStateToProps)(WebsiteTranslator));
