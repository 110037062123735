import React from "react";
import EllipseTranslation from "../../icons/EllipseTranslationComponent";

const TranslationUploadingMessage = ({translate}) => {
    return (
        <>
            <div><EllipseTranslation accessibility={translate('documentSubRowChildStatusUploading')} color={"#5F5F5F"}/></div>
            <p className="translation-successful-text">{translate('documentSubRowChildStatusUploading')}</p>
        </>
    );
};

export default TranslationUploadingMessage;