import {Form, Switch} from "antd";
import React from "react";

const ShowDemoSwitch = ({handleDemoExpiration, hasDemoExpiration}) => {
    return (
        <Form.Item
            label="Trial / Expiration date"
            name="hasDemoExpiration"
        >
            <Switch className="switchButton" checked={hasDemoExpiration} onChange={handleDemoExpiration}/>
        </Form.Item>
    );
};

export default ShowDemoSwitch;