const AnnouncementIconComponent = ({accessibility, size}) => {
    const viewBox = "0 0 "  + size + " " + size;
    return (
        <svg width="204" height="150" viewBox="0 0 204 188" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M73.0354 160.512C47.8586 156.907 29.8466 129.967 30.6804 102.125C31.5143 74.282 53.3297 33.9998 84.6793 24.6091C116.029 15.2185 152.479 40.239 164.625 71.5145C176.771 102.79 167.063 118.525 149.434 136.996C131.805 155.466 98.2123 164.117 73.0354 160.512Z" fill="#FFECF5"/>
            <g clip-path="url(#clip0_486_17104)">
                <path d="M70.5854 123.252L98.0242 157.926L109.611 155.218C110.452 155.021 111.183 154.512 111.651 153.796C112.12 153.081 112.29 152.214 112.126 151.378L110.631 143.721C110.506 143.09 110.184 142.514 109.71 142.073C109.237 141.633 108.635 141.35 107.989 141.264L104.513 140.795C103.857 140.704 103.244 140.422 102.752 139.984C102.261 139.546 101.913 138.972 101.755 138.338L97.6998 121.744C97.2518 119.939 96.0998 118.382 94.4942 117.41C92.8887 116.439 90.9592 116.131 89.1251 116.555L74.0037 120.121L70.5854 123.252Z" fill="#FFD9EB"/>
                <path d="M70.5854 123.252L98.0242 157.926L109.611 155.218C110.452 155.021 111.183 154.512 111.651 153.796C112.12 153.081 112.29 152.214 112.126 151.378L110.631 143.721C110.506 143.09 110.184 142.514 109.71 142.073C109.237 141.633 108.635 141.35 107.989 141.264L104.513 140.795C103.857 140.704 103.244 140.422 102.752 139.984C102.261 139.546 101.913 138.972 101.755 138.338L97.6998 121.744C97.2518 119.939 96.0998 118.382 94.4942 117.41C92.8887 116.439 90.9592 116.131 89.1251 116.555L74.0037 120.121L70.5854 123.252Z" stroke="#CE4080"/>
                <g opacity="0.4">
                    <path d="M99.6464 129.938L97.6418 121.744C97.1938 119.939 96.0418 118.382 94.4362 117.41C92.8307 116.439 90.9012 116.131 89.0672 116.555L73.9458 120.121L70.5854 123.252L79.6004 134.681L99.6464 129.938Z" fill="#CE4080"/>
                </g>
                <path d="M153.933 94.771C160.141 94.771 165.173 89.8078 165.173 83.6853C165.173 77.5628 160.141 72.5996 153.933 72.5996C147.726 72.5996 142.693 77.5628 142.693 83.6853C142.693 89.8078 147.726 94.771 153.933 94.771Z" fill="#CE4080"/>
                <path d="M70.0177 123.96C69.9124 123.956 69.8093 123.929 69.7165 123.88C69.59 123.797 69.4992 123.67 69.4616 123.525L60.3192 85.7196C60.2843 85.5726 60.3099 85.418 60.3902 85.2895C60.4705 85.1611 60.5991 85.0692 60.7479 85.0339L100.145 75.7539H100.284C100.395 75.7542 100.503 75.7859 100.597 75.8453C100.661 75.8841 100.717 75.9356 100.761 75.9965C100.805 76.0574 100.836 76.1265 100.852 76.1996L109.982 113.994C110.001 114.067 110.005 114.143 109.994 114.217C109.982 114.291 109.956 114.362 109.916 114.426C109.876 114.49 109.823 114.546 109.761 114.589C109.699 114.633 109.628 114.664 109.554 114.68L70.1568 123.96H70.0177Z" fill="#FFD9EB"/>
                <path d="M100.342 76.3151L109.472 114.109L70.0756 123.389L60.8869 85.6409L100.284 76.3609L100.342 76.3151ZM100.284 75.218H100.006L60.6088 84.4866C60.4602 84.5214 60.3201 84.585 60.1965 84.6734C60.0728 84.7619 59.9682 84.8736 59.8885 85.0021C59.8088 85.1306 59.7556 85.2734 59.7321 85.4223C59.7086 85.5711 59.7151 85.7231 59.7513 85.8694L68.8821 123.664C68.9433 123.919 69.0919 124.146 69.3029 124.307C69.514 124.467 69.7746 124.551 70.0409 124.544C70.1293 124.556 70.2189 124.556 70.3074 124.544L109.704 115.275C109.853 115.24 109.993 115.177 110.117 115.088C110.24 115 110.345 114.888 110.425 114.76C110.504 114.631 110.557 114.488 110.581 114.339C110.604 114.191 110.598 114.039 110.562 113.892L101.442 76.0523C101.381 75.7966 101.233 75.5695 101.022 75.4092C100.811 75.2489 100.55 75.1653 100.284 75.1723V75.218Z" fill="#CE4080"/>
                <path d="M166.957 125.846C166.833 125.841 166.712 125.801 166.609 125.732C153.064 115.549 136.865 113.412 125.66 113.412C118.744 113.371 111.848 114.138 105.116 115.697C105.066 115.708 105.015 115.708 104.965 115.697C104.859 115.7 104.754 115.672 104.664 115.617C104.532 115.537 104.437 115.411 104.397 115.263L95.2664 77.4574C95.2483 77.3841 95.2452 77.308 95.2571 77.2335C95.269 77.1589 95.2958 77.0874 95.3359 77.0232C95.4244 76.8977 95.5562 76.8083 95.7067 76.7717C96.0775 76.6917 131.94 69.1146 146.019 40.486C146.068 40.3907 146.142 40.3104 146.234 40.254C146.326 40.1975 146.432 40.1671 146.54 40.166H146.633C146.751 40.1792 146.862 40.228 146.952 40.3057C147.041 40.3834 147.103 40.4862 147.131 40.6003L167.49 125.137C167.516 125.253 167.507 125.373 167.464 125.483C167.42 125.592 167.345 125.687 167.247 125.755C167.159 125.807 167.059 125.838 166.957 125.846Z" fill="#FFFFFE"/>
                <path d="M146.54 40.7829L166.957 125.274C153.689 115.297 137.989 112.84 125.66 112.84C118.694 112.784 111.746 113.551 104.965 115.126L95.8225 77.3315C95.8225 77.3315 132.16 69.96 146.54 40.76V40.7829ZM146.54 39.6172C146.323 39.6168 146.11 39.6765 145.926 39.7897C145.741 39.9029 145.593 40.0648 145.497 40.2572C131.593 68.6458 95.95 76.1657 95.5908 76.2343C95.4394 76.2651 95.2958 76.3255 95.1684 76.4118C95.041 76.4982 94.9325 76.6088 94.8492 76.7372C94.7655 76.8664 94.7091 77.0109 94.6832 77.1621C94.6573 77.3133 94.6625 77.468 94.6985 77.6172L103.829 115.411C103.89 115.667 104.039 115.894 104.25 116.055C104.461 116.215 104.722 116.298 104.988 116.291H105.289C111.972 114.736 118.817 113.969 125.683 114.006C136.795 114.006 152.867 116.12 166.239 126.2C166.438 126.352 166.682 126.437 166.934 126.44C167.159 126.436 167.379 126.373 167.571 126.257C167.772 126.129 167.928 125.943 168.017 125.724C168.106 125.505 168.124 125.264 168.069 125.034L147.676 40.52C147.622 40.2881 147.495 40.0786 147.315 39.9208C147.134 39.7631 146.908 39.6649 146.668 39.64L146.54 39.6172Z" fill="#CE4080"/>
                <g opacity="0.26">
                    <path d="M103.076 107.354L104.965 115.137C104.965 115.137 140.758 105.548 166.957 125.24L164.813 116.348C138.614 96.6911 103.076 107.354 103.076 107.354Z" fill="#CE4080"/>
                </g>
                <path d="M166.239 128.921C165.848 128.921 165.469 128.792 165.162 128.553C164.855 128.315 164.638 127.981 164.547 127.607L143.319 39.7779C143.214 39.3356 143.291 38.8703 143.531 38.4831C143.772 38.0958 144.158 37.8178 144.605 37.7094C144.74 37.6932 144.876 37.6932 145.011 37.7094C145.402 37.707 145.783 37.8357 146.09 38.0745C146.397 38.3133 146.613 38.6479 146.703 39.0237L167.931 126.784C168.035 127.226 167.959 127.691 167.718 128.079C167.477 128.466 167.092 128.744 166.644 128.852C166.511 128.886 166.376 128.909 166.239 128.921Z" fill="#FFD9EB"/>
                <path d="M145.011 38.2347C145.277 38.2276 145.538 38.3113 145.749 38.4716C145.96 38.6319 146.108 38.859 146.17 39.1147L167.386 126.943C167.456 127.237 167.405 127.546 167.244 127.803C167.083 128.06 166.826 128.244 166.528 128.315H166.262C165.996 128.322 165.735 128.238 165.524 128.078C165.313 127.917 165.164 127.69 165.103 127.435L143.875 39.6061C143.806 39.3122 143.857 39.0029 144.017 38.746C144.178 38.489 144.435 38.3052 144.733 38.2347H144.999H145.011ZM144.999 37.0918C144.819 37.0921 144.64 37.1152 144.466 37.1604C143.871 37.3015 143.357 37.6691 143.035 38.183C142.714 38.6969 142.612 39.3154 142.751 39.9033L164.014 127.732C164.134 128.233 164.423 128.68 164.833 129C165.243 129.319 165.751 129.493 166.274 129.492C166.453 129.491 166.633 129.468 166.806 129.423C167.404 129.282 167.921 128.913 168.242 128.396C168.564 127.88 168.664 127.258 168.521 126.669L147.305 38.8518C147.185 38.3501 146.896 37.9032 146.486 37.5837C146.076 37.2642 145.568 37.0909 145.046 37.0918H144.999Z" fill="#CE4080"/>
                <path d="M66.7848 124.338C63.5253 124.345 60.3592 123.264 57.8027 121.269C55.2461 119.275 53.4497 116.485 52.7062 113.355L50.0179 102.247C49.5765 100.422 49.5039 98.5287 49.8042 96.676C50.1046 94.8233 50.772 93.0471 51.7683 91.4489C52.7647 89.8506 54.0705 88.4616 55.6112 87.3611C57.1519 86.2606 58.8974 85.4703 60.7478 85.0352H60.8868C61.0172 85.034 61.1442 85.0762 61.2473 85.155C61.3503 85.2338 61.4233 85.3446 61.4546 85.4695L70.5854 123.264C70.6035 123.337 70.6066 123.413 70.5947 123.488C70.5827 123.562 70.556 123.634 70.5159 123.698C70.4315 123.825 70.3042 123.918 70.1567 123.961C69.0506 124.211 67.9196 124.337 66.7848 124.338Z" fill="#FFFFFE"/>
                <path d="M60.887 85.64L70.0178 123.354C68.9586 123.603 67.8737 123.729 66.785 123.731C63.66 123.728 60.6273 122.686 58.1761 120.775C55.7249 118.863 53.9981 116.193 53.2741 113.194L50.5859 102.074C49.7568 98.5486 50.3744 94.8426 52.304 91.7642C54.2336 88.6857 57.3188 86.4844 60.887 85.64ZM60.887 84.4971C60.7986 84.4853 60.7089 84.4853 60.6205 84.4971C56.7346 85.4111 53.3759 87.8101 51.2833 91.1664C49.1907 94.5228 48.5355 98.5615 49.4619 102.394L52.1386 113.514C53.0704 117.343 55.5049 120.65 58.9072 122.709C62.3096 124.768 66.4015 125.411 70.2843 124.497C70.5819 124.427 70.8391 124.243 70.9997 123.986C71.1604 123.729 71.2114 123.42 71.1417 123.126L62.0457 85.32C62.0103 85.1739 61.946 85.0361 61.8565 84.9145C61.7671 84.7929 61.6541 84.6899 61.5243 84.6114C61.3259 84.5183 61.1059 84.4788 60.887 84.4971Z" fill="#CE4080"/>
                <g opacity="0.4">
                    <path d="M102.958 106.772L68.0164 114.988L70.0318 123.326L104.973 115.11L102.958 106.772Z" fill="#CE4080"/>
                </g>
                <g opacity="0.26">
                    <path d="M53.0308 112.258L53.2741 113.229C54.1371 116.76 56.3845 119.808 59.5234 121.706C62.6623 123.605 66.4363 124.198 70.0178 123.355L68.0016 115.012L65.7537 115.549C63.5223 116.079 61.1926 116.055 58.9727 115.481C56.7527 114.907 54.7113 113.799 53.0308 112.258Z" fill="#CE4080"/>
                </g>
                <path d="M177.608 63.9206L169.617 69.6958C169.338 69.8972 169.277 70.2834 169.482 70.5584C169.686 70.8333 170.077 70.8929 170.356 70.6914L178.348 64.9162C178.627 64.7148 178.687 64.3286 178.483 64.0537C178.278 63.7787 177.887 63.7191 177.608 63.9206Z" fill="#CE4080"/>
                <path d="M174.509 86.5867L184.111 88.1579C184.296 88.1882 184.461 88.2897 184.571 88.44C184.68 88.5904 184.724 88.7774 184.693 88.9598C184.662 89.1452 184.557 89.3107 184.402 89.4201C184.247 89.5294 184.055 89.5736 183.867 89.5428L174.265 87.9716C174.08 87.9413 173.915 87.8398 173.805 87.6894C173.696 87.5391 173.652 87.3521 173.683 87.1697C173.714 86.9873 173.817 86.8244 173.969 86.7168C174.122 86.6093 174.311 86.5658 174.496 86.5961L174.509 86.5867Z" fill="#CE4080"/>
                <path d="M183.616 76.0751L173.979 78.3413C173.643 78.4203 173.435 78.7531 173.515 79.0847C173.596 79.4162 173.933 79.6209 174.269 79.5419L183.906 77.2757C184.242 77.1966 184.45 76.8638 184.37 76.5322C184.29 76.2007 183.952 75.996 183.616 76.0751Z" fill="#CE4080"/>
            </g>
            <defs>
                <clipPath id="clip0_486_17104">
                    <rect width="154.366" height="144" fill="white" transform="translate(49.0447 17)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default AnnouncementIconComponent;