import React, {useCallback, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { withTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import '../ant-style.css';
import '../style.css';
import {
  Col,
  Form,
  Modal,
  Row,
  Skeleton,
  Tabs,
} from 'antd';
import {
  SOURCE_LANGUAGES,
  DEFAULT_LANGUAGES,
  DEFAULT_CSS,
  TARGET_LANGUAGES, DEFAULT_ENGINES_IDS, DEFAULT_LINKED_PRODUCTS, TTL,
} from './settingValues';
import { Link } from 'react-router-dom';
import saveNewOrg from "../../../apiCalls/Usermanagement/EditOrgPage/saveNewOrg";
import getAllEngines from "../../../apiCalls/Usermanagement/EditOrgPage/getAllEngines";
import getOrganizationById from "../../../apiCalls/Usermanagement/EditOrgPage/getOrganizationById";
import getOrganizationByIdFromMtengine
  from "../../../apiCalls/Usermanagement/EditOrgPage/getOrganizationByIdFromMtengine";
import modifyOrganizationById from "../../../apiCalls/Usermanagement/EditOrgPage/modifyOrganizationById";
import modifyOrganizationByIdFromMtengine
  from "../../../apiCalls/Usermanagement/EditOrgPage/modifyOrganizationByIdFromMtengine";
import saveNewOrgMtengine from "../../../apiCalls/Usermanagement/EditOrgPage/saveNewOrgMtengine";
import getQuotaByOrg from "../../../apiCalls/Statistics/getQuotaByOrg";
import {saveQuota} from "./saveQuota";
import QuotaSettings from "./FormItems/QuotaSettings";
import OrgNameField from "./FormItems/OrgNameField";
import EnginesDropdownSwitch from "./FormItems/EnginesDropdownSwitch";
import SourceLanguagesSelection from "./FormItems/SourceLanguagesSelection";
import DefaultSourceLanguageDropdown from "./FormItems/DefaultSourceLanguageDropdown";
import SpecialFileFormatSelection from "./FormItems/SpecialFileFormatsSelection";
import TransliterationLanguagesSelection from "./FormItems/TransliterationLanguagesSelection";
import CustomLanguagesNameSelection from "./FormItems/CustomLanguagesNameSelection";
import StaticMessageField from "./FormItems/StaticMessageField";
import TargetLanguagesSelection from "./FormItems/TargetLanguagesSelection";
import DefaultTargetLanguageDropdown from "./FormItems/DefaultTargetLanguageDropdown";
import TimeToLiveDropdown from "./FormItems/TimeToLiveDropdown";
import DeleteAfterDownloadSwitch from "./FormItems/DeleteAfterDownloadSwitch";
import EngineDropdownSettings from "./FormItems/EngineDropdownSettings";
import TransportSwitch from "./FormItems/TransportSwitch";
import TransactionRecordSwitch from './FormItems/TransactionRecordSwitch';
import DemoSettings from "./FormItems/DemoSettings";
import ShowDemoSwitch from "./FormItems/ShowDemoSwitch";
import {calculateExpirationDate} from "./expirationDateHelper";
import TextTranslationSwitch from "./FormItems/TextTranslationSwitch";
import DocumentTranslationSwitch from "./FormItems/DocumentTranslationSwitch";
import CategoriesSelection from "./FormItems/CategoriesSelection";
import CategoryDropdownSwitch from "./FormItems/CategoryDropdownSwitch";
import CategoryMandatorySwitch from "./FormItems/CategoryMandatorySwitch";
import CustomErrorContactSwitch from "./FormItems/CustomErrorContactSwitch";
import CustomSupportEmailField from "./FormItems/CustomSupportEmailField";
import SalesPersonContactField from "./FormItems/SalesPersonContactField";
import ClientsIdField from "./FormItems/ClientsIdField";
import ClientContactField from "./FormItems/ClientContactField";
import SalesTicketField from "./FormItems/SalesTicketField";
import LinkedProductsField from "./FormItems/LinkedProductsField";
import NotesTextArea from "./FormItems/NotesTextArea";
import LanguageProfileSettings from "./FormItems/LanguageProfileSettings";
import StyleSettings from "./FormItems/StyleSettings";
import {loadStyle} from "./StyleSettingsHelper";
import {updateOrgStyle} from "./StyleSettingsHelper";
import {buildProfilesTree, loadProfiles, updateProfiles} from "./ProfilesSettingsHelper";
import EditOrgHeader from "./EditOrgHeader";
import {prepareCreation, settingUpAllOrgValues} from "./settingUpAllOrgValues";
import getTimeToLiveByOrg from "../../../apiCalls/Usermanagement/EditOrgPage/getTimeToLiveByOrg";
import modifyTimeToLive from "../../../apiCalls/Usermanagement/EditOrgPage/modifyTimeToLive";
import CustomEnginesSelection from "./FormItems/CustomEnginesSelection";
import QuotaType from "./FormItems/QuotaTypeControl";
import ReactHtmlParser from "react-html-parser";
import MaxWordsPerDocument from "./FormItems/MaxWordsPerDocument";
import TsCloudSwitch from "./FormItems/TsCloudSwitch";
import UserQuota from "./FormItems/UserQuota";

const EditOrg = (props) => {
  const isNew = props.match.params.orgId === 'new';
  const [loading, setLoading] = useState(false);
  const [org, setOrg] = useState({ uiAssets: { ...DEFAULT_CSS } });
  const [form] = Form.useForm();
  const [orgSourceLanguages, setOrgSourceLanguages] = useState(
    SOURCE_LANGUAGES.filter((e) => DEFAULT_LANGUAGES.includes(e))
  );
  const [orgTargetLanguages, setOrgTargetLanguages] = useState(
    TARGET_LANGUAGES.filter((e) => DEFAULT_LANGUAGES.includes(e))
  );
  const [formChanged, setFormChanged] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const [availableEngineEntities, setAvailableEngineEntities] = useState([]);
  const [orgEngines, setOrgEngines] = useState([]);
  const [orgCategories, setOrgCategories] = useState([]);
  const [linkedProducts, setLinkedProducts] = useState(DEFAULT_LINKED_PRODUCTS);
  const [orgLinkedProducts, setOrgLinkedProducts] = useState([]);
  const [hasDemoExpiration, setHasDemoExpiration] = useState(false);
  const [demoExpiration, setDemoExpiration] = useState((() => {
    return calculateExpirationDate(new Date())
  })());
  const [overages, setOverages] = useState(false); //quota policy soft-hard
  const [quotaActive, setQuotaActive] = useState(false); //initial state in none (index 0)
  const [quotaType, setQuotaType] = useState(0); //initial state in none (index 0)
  const [limitType, setLimitType] = useState(2); //initial state is chars (index 2)
  const [counterLimit, setCounterLimit] = useState(0);
  const [showMaxWordsPerDocument, setShowMaxWordsPerDocument] = useState(org.uiAssets.showMaxWordsPerDocument);
  const [hasUserQuota, setHasUserQuota] = useState(false);
  const [counterLimitUser, setCounterLimitUser] = useState(0);
  const [maxWordsPerDocument, setMaxWordsPerDocument] = useState(0);
  const [profiles, setProfiles] = useState({});
  const [originalProfiles, setOriginalProfiles] = useState({});
  const [engineEntities, setEngineEntities] = useState({DEFAULT_ENGINES_IDS});
  const [engineSelectionDropdown, setEngineSelectionDropdown] = useState(org.uiAssets.engineSelectionDropdown);
  const [customizedEngines, setCustomizedEngines] = useState([]);
  const [deletingEngines, setDeletingEngines] = useState(false);
  const [showCategory, setShowCategory] = useState(org.uiAssets.showCategory);
  const [mandatoryCategory, setMandatoryCategory] = useState(org.uiAssets.mandatoryCategory);
  const [hideTT, setHideTT] = useState(org.uiAssets.hideTT);
  const [hideDT, setHideDT] = useState(org.uiAssets.hideDT);
  const [hideTransport, setHideTransport] = useState(org.uiAssets.hideTransport);
  const [showTsCloud, setShowTsCloud] = useState(org.uiAssets.showTsCloud);
  const [hasTransactionRecord, setHasTransactionRecord] = useState(org.uiAssets.hasTransactionRecord);
  const [deleteAfterDownload, setDeleteAfterDownload] = useState(org.uiAssets.deleteAfterDownload);
  const [customErrorContact, setCustomErrorContact] = useState(org.uiAssets.customErrorContact);
  const [timeToLive, setTimeToLive] = useState(TTL);
  const [staticMessage, setStaticMessage] = useState(org.uiAssets.footerPermanentMessage);
  const [staticMessageContent, setStaticMessageContent] = useState(org.uiAssets.footerPermanentMessageContent);
  const [orgTimeToLive, setOrgTimeToLive] = useState("1wk");
  const [uiAssetsStyle, setUiAssetsStyle] = useState({...DEFAULT_CSS});
  const [forceRender, setForceRender] = useState(false);
  const [addMulitpleProfilesForm] = Form.useForm();
  const [activeKey, setActiveKey] = useState();
  const { TabPane } = Tabs;

  const limitTypeOptions = [
    { label: "Characters", value: 2 },
    { label: "Words", value: 1 },
    { label: "Pages", value: 3 }
  ];

  const addMultiple = (form) => {
    let tempProfiles = { ...profiles };
    for (const srcLang of form.sourceLangs) {
      for (const trgLang of form.targetLangs) {
        if (srcLang !== trgLang) {
          if (!(srcLang in tempProfiles)) {
            tempProfiles[srcLang] = {};
          }
          if (!(trgLang in tempProfiles[srcLang])) {
            tempProfiles[srcLang][trgLang] = [];
          }
          tempProfiles[srcLang][trgLang].push({
            engine: form.engine,
            failover: form.failover,
          });
        }
      }
    }
    setProfiles(tempProfiles);
    addMulitpleProfilesForm.resetFields();
    setFormChanged(true);
  };

  const createTree = (profiles) => {
    if (!profiles) return [];
    let tree = [];
    buildProfilesTree(tree, profiles, engineEntities, deleteSource, deleteTarget, deleteEntity, props);
    return tree;
  };

  const deleteSource = (src) => {
    let tempProfiles = { ...profiles };
    delete tempProfiles[src];
    setProfiles(tempProfiles);
    setFormChanged(true);
  };

  const deleteTarget = (src, trg) => {
    let tempProfiles = { ...profiles };
    delete tempProfiles[src][trg];
    setProfiles(tempProfiles);
    setFormChanged(true);
  };
  const deleteEntity = (src, trg, idx) => {
    let tempProfiles = { ...profiles };
    tempProfiles[src][trg].splice(idx, 1);
    setProfiles(tempProfiles);
    setFormChanged(true);
  };

  const handleDeleteAfterDownload = (value) => {
    let prevOrg = org;
    setDeleteAfterDownload(value);
    setOrg({ ...prevOrg });
  };

  const handleShowCategory = (value) => {
    let prevOrg = org;
    setShowCategory(value);
    setOrg({ ...prevOrg });
    if(!value) { //resetting value of mandatory category
      setMandatoryCategory(false);
    }
    setOrg({ ...prevOrg });
  };

  const handleMandatoryCategory = (value) => {
    let prevOrg = org;
    setMandatoryCategory(value);
    setOrg({ ...prevOrg });
  }

  const handleQuotaType = (value) => {
    setQuotaType(value);
    if (value === 0) { //resetting all values when none
      setQuotaActive(false);
      setOverages(false);
      setHasUserQuota(false);
      setLimitType(0); //initial state is chars (index 2)
      setCounterLimit(0);
      setCounterLimitUser(0)
      form.resetFields(["counterLimit"]);
      form.resetFields(["counterLimitUser"]);
    } else {
      if(quotaType === 0) {
        setCounterLimit(0);
        setCounterLimitUser(0)
        form.resetFields(["counterLimit"]);
        form.resetFields(["counterLimitUser"]);
      }
      setQuotaActive(true);
    }
  };

  const handleShowMaxWordsPerDocument = (value) => {
    setShowMaxWordsPerDocument(value);
    if (value === false) { //resetting values when none
      setMaxWordsPerDocument(0);
      form.resetFields(["maxWordsPerDocument"]);
    }
    if(!quotaActive) { //in case quota is inactive, the limit type must be 0
      setLimitType(0);
    }
  };

  const handleCounterLimit = (value) => {
    if(value) {
      let isnum = /^\d+$/.test(value); //check that value contains numbers only
      if(isnum && value !== 0 && value <= Number.MAX_SAFE_INTEGER) { //maximum INT to be saved in db
        setCounterLimit(parseInt(value));
        setFormChanged(true);
      }
    } else {
      setCounterLimit(0);
      setFormChanged(true);
    }
  };

  const handleCounterLimitUser = (value) => {
    if(value) {
      let isnum = /^\d+$/.test(value); //check that value contains numbers only
      if(isnum && value !== 0 && value <= Number.MAX_SAFE_INTEGER) { //maximum INT to be saved in db
        setCounterLimitUser(parseInt(value));
        setFormChanged(true);
      }
    } else {
      setCounterLimitUser(0);
      setFormChanged(true);
    }
  };

  const handleUserQuota = (value) => {
    setHasUserQuota(value);
    if (value === false) { //resetting values when none
      setCounterLimitUser(0);
      form.resetFields(["counterLimitUser"]);
    }
  };

  const handleQuotaPolicy = (value) => {
    setOverages(value);
  };

  const handleLimitType = (value) => {
    if(value === 0) {
      setLimitType(2);
    }
    setLimitType(value);
  };

  const handleMaxWordsPerDocument = (value) => {
    if(value) {
      setMaxWordsPerDocument(parseInt(value));

      if (!quotaActive) { //in case quota is inactive, the limit type must be 0
        setLimitType(0);
      }
    } else {
      setMaxWordsPerDocument(0);
    }
  };

  const handleDemoExpiration = (value) => {
    setHasDemoExpiration(value);
    if(value) { // in case the org demo expiration is set to true
      setDemoExpiration(calculateExpirationDate(new Date())); //the expiration date is set to 16 days from today
    }
  }

  const handleDemoExpirationDate = (date) => {
    let tempd = date.setUTCHours(0,0,0,0);
    let UTCDate = new Date(tempd);
    setDemoExpiration(UTCDate);
    setFormChanged(true);
  };

  const handleTransport = (value) => {
    let prevOrg = org;
    setHideTransport(value);
    setOrg({ ...prevOrg });
  };

  const handleTsCloud = (value) => {
    let prevOrg = org;
    setShowTsCloud(value);
    setOrg({ ...prevOrg });
  };

  const handleTransactionRecord = (value) => {
    let prevOrg = org;
    setHasTransactionRecord(value);
    setOrg({ ...prevOrg });  
  };

  const handleCustomErrorContact = (value) => {
    let prevOrg = org;
    setCustomErrorContact(value);
    if(!value) { // resetting emails in case there is no customized error contact
      prevOrg.uiAssets.supportEmailTo = "aisupport@transperfect.com";
      prevOrg.uiAssets.supportEmailCc = "";
    }
    setOrg({ ...prevOrg });
  };

  const handleFooterMessage = (value) => {
    let prevOrg = org;
    setStaticMessage(value);
    setOrg({ ...prevOrg });
  };

  const handleEngineSelectionDropdown = (value) => {
    let prevOrg = org;
    if(value) {
      prevOrg.uiAssets.engineDropdownShowAll = false;
      prevOrg.uiAssets.engineDropdownShowProfilesOnly = true;
    } else {
      prevOrg.uiAssets.engineDropdownShowAll = false;
      prevOrg.uiAssets.engineDropdownShowProfilesOnly = false;
    }
    setEngineSelectionDropdown(value);
    setOrg({ ...prevOrg });
  };

  const handleEnginesProfilesOnly = (value, className) => {
    let prevOrg = org;

    if (className === "allEngines") {
      if (value) {
        prevOrg.uiAssets.engineDropdownShowAll = true;
        prevOrg.uiAssets.engineDropdownShowProfilesOnly = false;
      }
      else {
        prevOrg.uiAssets.engineDropdownShowAll = false;
        prevOrg.uiAssets.engineDropdownShowProfilesOnly = true;
      }
    }
    if(className ==="onlyProfiles") {
      if (value) {
        prevOrg.uiAssets.engineDropdownShowProfilesOnly = true;
        prevOrg.uiAssets.engineDropdownShowAll = false;
      }else {
        prevOrg.uiAssets.engineDropdownShowAll = true;
        prevOrg.uiAssets.engineDropdownShowProfilesOnly = false;
      }
    }
    setOrg({ ...prevOrg });
  }

  const handleTextAndDocumentView = (value, className) => {
    let prevOrg = org;
    // hideText and hideDocument cannot be both true at the same time
    if (className === "hideTextView") {
      if (value) {
        setHideDT(false);
        setHideTT(true);
      } else {
        setHideDT(false);
        setHideTT(false);
      }
    }
    if(className ==="hideDocView") {
      if (value) {
        setHideDT(true);
        setHideTT(false);
      } else {
        setHideDT(false);
        setHideTT(false);
      }
    }
    setOrg({ ...prevOrg });
  }

  const loadEngineEntities = useCallback(() => {
    getAllEngines()
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Request failed');
        }
      })
      .then((json) => {
        if (json.success) {
          if (Array.isArray(json.message)) {
            let engines = [];
            let defaultProfiles = {...engineEntities.DEFAULT_ENGINES_IDS}; //default profiles for new org
            for (const engine of json.message) {
              engines.push(engine);
              defaultProfiles = {...defaultProfiles, [engine.id] : engine.alias};
            }
            setEngineEntities({...defaultProfiles});
            setAvailableEngineEntities(engines);
          }
          setTimeout(() => setLoading(false), 1000);
        } else {
          throw new Error(json.message);
        }
      })
      .catch((err) => {
        console.error(err);
        Modal.error({
          title: 'Error',
          content: `Failed to fetch engines`,
        });
        props.history.push('/usermanagement');
      });
    // this function will rerender only when these params are updated
  }, [props.history]);


  const loadTimeToLive = useCallback(() => {
    getTimeToLiveByOrg(props.match.params.orgId)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error('Request failed');
          }
        })
        .then((json) => {
          if (json.Carfs_Header_Value) {
            setOrgTimeToLive(json.Carfs_Header_Value);
            setForceRender(true); // this will allow to get ttl before the tab is loaded
          }
        })
        .catch((err) => {
          console.error(err);
          Modal.error({
            title: 'Error',
            content: `Failed to fetch time to live`,
          });
          props.history.push('/usermanagement');
        });
    // this function will rerender only when these params are updated
  }, [props.history, props.match.params.orgId]);

  const saveTimeToLive = (isOrgNew, newOrgId, orgId, timeToLive) => {
    if(isOrgNew){
      orgId = newOrgId;
    }
    new Promise((resolve, reject) => {
      let body = JSON.stringify({"ttl": timeToLive});
      modifyTimeToLive(body, orgId)
          .then((res) => {
            if (res) {
              return res;
            } else {
              throw new Error('Request failed');
            }
          })
          .then((res) => {
            if (res) {
              resolve();
            } else {
              throw new Error('Request failed');
            }
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
    });
  }

  const updateTimeToLive = (timeToLive) => {
    setOrgTimeToLive(timeToLive);
  };

  const loadQuota = useCallback(() => {
    getQuotaByOrg(props.match.params.orgId)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error('Request failed');
          }
        })
        .then((json) => {
          if (json.success) {
            setOverages(json.message.overages);
            setQuotaType(json.message.quotaType);
            if(json.message.quotaType === 0) {
              setQuotaActive(false);
            } else {
              setQuotaActive(true);
            }
            if(json.message.limitType === 0) {
              setLimitType(2);
            } else {
              setLimitType(json.message.limitType);
            }
            setCounterLimit(parseInt(json.message.counterLimit));
            setHasUserQuota(json.message.hasUserQuota);
            setCounterLimitUser(parseInt(json.message.userCounterLimit));
            setHasDemoExpiration(json.message.hasExpiration);
            setDemoExpiration(new Date(json.message.expirationDate));
            setMaxWordsPerDocument(json.message.maxWordPerDocument)
             //radio buttons settings
            // if(json.message.hasWordLimit){
            //   setRadioDataType('words');
            // } else if (json.message.hasCharacterLimit) {
            //   setRadioDataType('characters');
            // }
          } else {
            throw new Error(json.message);
          }
        })
        .catch((err) => {
          console.error(err);
          Modal.error({
            title: 'Error',
            content: `Failed to fetch quota`,
          });
          props.history.push('/usermanagement');
        });
    // this function will rerender only when these params are updated
  }, [props.history, props.match.params.orgId]);

  const loadOrg = useCallback(() => {
    setLoading(true);
    getOrganizationById(props.match.params.orgId)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Request failed');
        }
      })
      .then((json) => {
        if (json.success) {
          let p = JSON.parse(json.message.uiAssets);
          let parsed = JSON.parse(p);
          loadStyle(parsed);
          if (!!json.message.uiAssets.extraFormats) {
            json.message.uiAssets.extraFormats =
              json.message.uiAssets.extraFormats.map((eF) => eF.trim());
          }
          setOrg({ ...json.message, uiAssets: parsed });
          setUiAssetsStyle(parsed);
          json.message.uiAssets = JSON.parse(JSON.parse(json.message.uiAssets));
          setOrgSourceLanguages(json.message.sourceLanguages);
          setOrgTargetLanguages(json.message.targetLanguages);
          setOrgLinkedProducts(json.message.linkedProducts);
          setEngineSelectionDropdown(json.message.uiAssets.engineSelectionDropdown);
          setShowCategory(json.message.uiAssets.showCategory);
          setMandatoryCategory(json.message.uiAssets.mandatoryCategory);
          setHideDT(json.message.uiAssets.hideDT);
          setHideTT(json.message.uiAssets.hideTT);
          setHideTransport(json.message.uiAssets.hideTransport);
          setShowTsCloud(json.message.uiAssets.showTsCloud);
          setHasTransactionRecord(json.message.uiAssets.hasTransactionRecord);
          setDeleteAfterDownload(json.message.uiAssets.deleteAfterDownload);
          setCustomErrorContact(json.message.uiAssets.customErrorContact);
          setStaticMessage(json.message.uiAssets.footerPermanentMessage);
          setStaticMessageContent(json.message.uiAssets.footerPermanentMessageContent);
          setShowMaxWordsPerDocument(json.message.uiAssets.showMaxWordsPerDocument);
          if(json.message.categories === null) {
            setOrgCategories([]);
          } else {
            setOrgCategories(json.message.categories);
          }
          loadQuota();
          loadTimeToLive();
        } else {
          throw new Error(json.message);
        }
        return getOrganizationByIdFromMtengine(props.match.params.orgId);
      })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Request failed');
        }
      })
      .then((json) => {
        if (json.success) {
          if (!!json.message.engines) {
            setOrgEngines(json.message.engines.map((engine) => engine.id));
          }
          if (!!json.message.profiles) {
            loadProfiles(json.message.profiles);
            setProfiles(json.message.profiles);
            setOriginalProfiles(json.message.profiles);
          }
        return getAllEngines();
        } else {
          throw new Error(json.message);
        }
        setLoading(false);
      })
        .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Request failed');
        }
        })
        .then((json) => {
          if (json.success) {
            if (Array.isArray(json.message)) {
              let engines = {};
              for (const engine of json.message) {
                engines[engine.id] = engine.alias;
              }
              setEngineEntities(engines);
            }
            setLoading(false);
          } else {
            throw new Error(json.message);
          }
        })
      .catch((err) => {
        console.error(err);
        Modal.error({
          title: 'Error',
          content: `Organization with id '${props.match.params.orgId}' doesn't exist`,
        });
        props.history.push('/usermanagement');
      });
    // this function will rerender only when these params are updated
  }, [props.history, props.match.params.orgId, loadQuota]);


  const updateOrg = (values) =>
    new Promise((resolve, reject) => {
      const body = prepareUpdate(values, uiAssetsStyle);
      modifyOrganizationById(body, props.match.params.orgId)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error('Request failed');
          }
        })
        .then((json) => {
          if (!json.success) {
            throw new Error(json.message);
          }

          if(originalProfiles !== profiles){
            updateProfiles(profiles, props)
          }
          if(org.uiAssets !== uiAssetsStyle){
            updateOrgStyle(org, uiAssetsStyle, props)
          }

          saveTimeToLive(false, "", props.match.params.orgId, orgTimeToLive);
          saveQuota(false, "", props.match.params.orgId, quotaActive, overages, quotaType, limitType, counterLimit, hasUserQuota, counterLimitUser, maxWordsPerDocument, hasDemoExpiration, demoExpiration);
          return saveEngines(props.match.params.orgId);
        })
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            throw new Error('Request failed');
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });

  const saveEngines = (orgId) => {
    // the engine is sent here, in the request to /apigateway/organization/${props.match.params.orgId} is sent as N/A because not needed there
    const mtengineOrgBody = {
      id: orgId,
      engines: orgEngines,
    };

    const body = JSON.stringify(mtengineOrgBody);

    return modifyOrganizationByIdFromMtengine(body, orgId);
  }

  const createOrg = (values) =>
    new Promise((resolve, reject) => {
      const body = prepareCreation(values, org, orgSourceLanguages, orgTargetLanguages,
          engineSelectionDropdown, showCategory, mandatoryCategory, hideDT, hideTT, hideTransport, showTsCloud,
          hasTransactionRecord, deleteAfterDownload, customErrorContact, orgLinkedProducts, showMaxWordsPerDocument, customizedEngines, deletingEngines, uiAssetsStyle, quotaType);
      saveNewOrg(body)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error('Request failed');
          }
        })
        .then((json) => {
          if (!json.success) {
            throw new Error(json.message);
          }

          const mtengineOrgBody = {
            id: json.message,
            engines: orgEngines,
            profiles: profiles,
          };

          const body = JSON.stringify(mtengineOrgBody);

          saveQuota(true, json.message, "", quotaActive, overages, quotaType, limitType, counterLimit, hasUserQuota, counterLimitUser, maxWordsPerDocument, hasDemoExpiration, demoExpiration);
          saveTimeToLive(true, json.message, "", orgTimeToLive);
          return saveNewOrgMtengine(body);
        })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error('Request failed');
          }
        })
        .then((json) => {
          if (!json.success) {
            throw new Error(json.message);
          }
          resolve(json);
          setFormChanged(false);
          props.history.push(`/usermanagement/org/${json.message}/edit`);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });

  //this will be called only the first time the component is rendered
  useEffect(() => {
    loadEngineEntities();
    if (!isNew) {
      loadOrg();
    }
  }, [isNew, loadOrg, loadEngineEntities]);

  useEffect(() => {
    let customizedEnginesIds = [];
    //getting org engines
    if(orgEngines.length > 0) {
      customizedEnginesIds = [...orgEngines];
    }

    if(org.uiAssets.customEnginesNames){
        //getting customized engines name from db and adding it to the engine object
        customizedEnginesIds.concat(Object.keys(org.uiAssets.customEnginesNames));
    }

    //get only ids that have been saved in the db
    let customizedEnginesList = [];
    for (let i=0; i < customizedEnginesIds.length; i++) {
      for (let x=0; x < availableEngineEntities.length; x++) {
        if (customizedEnginesIds[i] === availableEngineEntities[x].id) {
          customizedEnginesList.push(availableEngineEntities[x])
        }
      }
    }

    // adding customizedName field to object
    if(org.uiAssets.customEnginesNames) {
      for (let i = 0; i < customizedEnginesList.length; i++) {
        for (let x = 0; x < Object.keys(org.uiAssets.customEnginesNames).length; x++) {
          if (customizedEnginesList[i].id === Object.keys(org.uiAssets.customEnginesNames)[x]) {
            customizedEnginesList[i]["customizedName"] = Object.values(org.uiAssets.customEnginesNames)[x];
          }
        }
      }
    }

    setCustomizedEngines(customizedEnginesList)

  }, [orgEngines]);

  const validateCustomErrorEmail = (supportEmail) => {
    return String(supportEmail)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
  }

  const handleSubmit = (values) => {
    let customContactEmailIsValid = true;
    let customContactEmailIsComplete = true;
    // in case there is customErrorContact, need to validate email is valid
    if (values.customErrorContact) {
      customContactEmailIsValid = validateCustomErrorEmail(values.supportEmailTo);
      // check if TO email is aisupport@transperfect.com
      if(values.supportEmailTo.includes("transperfect")){
        // in case it is, there must be another email address in CC
        if(!values.supportEmailCc){
          customContactEmailIsComplete = false;
        } else {
          customContactEmailIsValid = validateCustomErrorEmail(values.supportEmailCc);
          // check that the cc email is not a transperfect one
          if(values.supportEmailCc.includes("transperfect")){
            customContactEmailIsComplete = false;
          }
        }
      }
    }
    //check if the category dropdown is to be shown and categories list is not empty
    let areCategoriesNeeded = false;
    if(showCategory) { //checking if categories are enabled but no category was added
      if(orgCategories.length === 0) {
        areCategoriesNeeded = true;
      }
    }
    if (customContactEmailIsValid) {
      if (customContactEmailIsComplete) {
        if (orgEngines.length > 0) {
          if(!areCategoriesNeeded) {
              if((quotaActive && counterLimit !== 0) || (!quotaActive && counterLimit === 0)) {
                if(counterLimit >= counterLimitUser) {
                  if(counterLimit >= counterLimitUser) {
                    if((hasUserQuota && counterLimitUser !== 0) || (!hasUserQuota && counterLimitUser === 0)) {
                    if(isNew) {
                      let confirmModal = modal.confirm({
                        title: quotaActive ? 'Create Organization' : 'You are about to create an org without quotas.',
                        content: (
                            <div>
                              <p>
                                {quotaActive
                                    ? 'Are you sure you want to create the organization?'
                                    : 'Are you sure you want to do this?'}
                              </p>
                            </div>
                        ),
                        onOk(closeModal) {
                          confirmModal.update({
                            okButtonProps: {loading: true},
                            cancelButtonProps: {disabled: true},
                          });
                          let promise = createOrg;
                          promise(values)
                              .then(() => {
                                confirmModal.update({
                                  title: 'Success',
                                  content: `Org created'}`,
                                  okButtonProps: {loading: false},
                                  cancelButtonProps: {hidden: true},
                                  onOk() {
                                    closeModal();
                                  },
                                });
                              })
                              .catch((err) => {
                                confirmModal.update({
                                  title: 'Error',
                                  content: `Request failed: ${err}`,
                                  okButtonProps: {loading: false},
                                  cancelButtonProps: {hidden: true},
                                  onOk() {
                                    closeModal();
                                  },
                                });
                              });
                        },
                      });
                    } else {
                      let confirmModal = modal.confirm({
                        title: quotaActive ? 'Update Organization' : 'You are about to update an org without quotas.',
                        content: (
                            <div>
                              <p>
                                {quotaActive
                                    ? 'Are you sure you want to update the organization?'
                                    : 'Are you sure you want to do this?'}
                              </p>
                            </div>
                        ),
                        onOk(closeModal) {
                          confirmModal.update({
                            okButtonProps: {loading: true},
                            cancelButtonProps: {disabled: true},
                          });
                          let promise = updateOrg;
                          promise(values)
                              .then(() => {
                                confirmModal.update({
                                  title: 'Success',
                                  content: `Org updated`,
                                  okButtonProps: {loading: false},
                                  cancelButtonProps: {hidden: true},
                                  onOk() {
                                    closeModal();
                                      loadOrg();
                                  },
                                });
                              })
                              .catch((err) => {
                                confirmModal.update({
                                  title: 'Error',
                                  content: `Request failed: ${err}`,
                                  okButtonProps: {loading: false},
                                  cancelButtonProps: {hidden: true},
                                  onOk() {
                                    closeModal();
                                  },
                                });
                              });
                        },
                      });
                    }
                  } else {
                    Modal.error({
                      title: 'User level quota incomplete',
                      content: `User quota has been activated but has no value. Please complete or deactivate feature.`,
                    });
                  }
                } else {
                  Modal.error({
                    title: 'Max words per document incomplete',
                    content: `Max words per document has been activated but has no value. Please complete or deactivate.`,
                  });
                }
              } else {
                Modal.error({
                  title: 'User level quota cannot be higher than organization quota',
                  content: ReactHtmlParser(`Please review quotas.`),
                });
              }
            } else {
              Modal.error({
                title: 'Quota field incomplete',
                content: ReactHtmlParser(`Mandatory (<span style="color:red">*</span>) fields on quotas are incomplete. Please review and complete or disable quotas.`),
              });
            }
        } else {
          Modal.error({
            title: 'Error',
            content: `Please add categories`,
          });
        }
        } else {
          Modal.error({
            title: 'Error',
            content: `Please select an engine`,
          });
        }
      } else {
        Modal.error({
          title: 'Error',
          content: `You have activated custom error contact but haven't specified client's support email address. Uncheck this option or complete with the correct information.`,
        });
      }
    } else {
      Modal.error({
        title: 'Error',
        content: `You have added an invalid email address to custom error message. Please correct it.`,
      });
    }
  };

  const prepareUpdate = (values) => {
    let updateOrg = { ...org };
    settingUpAllOrgValues(updateOrg, org, values, orgSourceLanguages, orgTargetLanguages,
        engineSelectionDropdown, showCategory, mandatoryCategory, hideDT, hideTT, hideTransport, showTsCloud,
        hasTransactionRecord, deleteAfterDownload, customErrorContact, orgLinkedProducts, showMaxWordsPerDocument, customizedEngines, deletingEngines, quotaType);
    updateOrg.uiAssets = JSON.stringify(JSON.stringify(updateOrg.uiAssets));
    let body = JSON.stringify(updateOrg);
    setFormChanged(false);
    return body;
  };

  const onReset = () => {
    form.resetFields();
    isNew
      ? setOrgSourceLanguages(
          SOURCE_LANGUAGES.filter((e) => DEFAULT_LANGUAGES.includes(e))
        )
      : setOrgSourceLanguages(org.sourceLanguages);

    isNew
      ? setOrgTargetLanguages(
          TARGET_LANGUAGES.filter((e) => DEFAULT_LANGUAGES.includes(e))
        )
      : setOrgTargetLanguages(org.targetLanguages);

    setProfiles(originalProfiles);
    setUiAssetsStyle(org.uiAssets);
    setEngineSelectionDropdown(org.uiAssets.engineSelectionDropdown);
    setShowCategory(org.uiAssets.showCategory);
    setMandatoryCategory(org.uiAssets.mandatoryCategory);
    setHideTT(org.uiAssets.hideTT);
    setHideDT(org.uiAssets.hideDT);
    setHideTransport(org.uiAssets.hideTransport);
    setShowTsCloud(org.uiAssets.showTsCloud);
    setHasTransactionRecord(org.uiAssets.hasTransactionRecord);
    setDeleteAfterDownload(org.uiAssets.deleteAfterDownload);
    setCustomErrorContact(org.uiAssets.customErrorContact);
    setStaticMessage(org.uiAssets.footerPermanentMessage);
    setStaticMessageContent(org.uiAssets.footerPermanentMessageContent);
    setShowMaxWordsPerDocument(org.uiAssets.showMaxWordsPerDocument);
  };

  const onResetProfiles = () => {
    setProfiles(originalProfiles);
  };

  const onResetStyle = () => {
    setUiAssetsStyle(org.uiAssets);
  };

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const defaultSourceLanguage = () => (isNew ? 'auto' : org.defaultSource);
  const defaultTargetLanguage = () => (isNew ? 'en' : org.defaultTarget);

  const breadCrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
  };

  const updateSourceLanguages = (srcLangs) => {
    setOrgSourceLanguages(srcLangs);
    const currentDefaultSrcLang = form.getFieldValue('defaultSource');
    if (!!currentDefaultSrcLang && !srcLangs.includes(currentDefaultSrcLang))
      form.setFields([
        {
          name: 'defaultSource',
          value: undefined,
          errors: [`Please select a new default source language`],
        },
      ]);
  };

  const updateTargetLanguages = (trgLangs) => {
    setOrgTargetLanguages(trgLangs);
    const currentDefaultSrcLang = form.getFieldValue('defaultTarget');
    if (!!currentDefaultSrcLang && !trgLangs.includes(currentDefaultSrcLang))
      form.setFields([
        {
          name: 'defaultTarget',
          value: undefined,
          errors: [`Please select a new default target language`],
        },
      ]);
  };

  const updateEngines = (engines) => {
    setOrgEngines(engines);
  };

  const updateLinkedProducts = (linkedProducts) => {
    setOrgLinkedProducts(linkedProducts);
  };

  const updateCategories = (categories) => {
    setOrgCategories(categories);
  };

  const handleChange = (key) => {
    setActiveKey(key);
  };

  return (
    <div className="editOrgWrapper">
      <div className="editOrgContainer userManagementContainer">
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <Prompt
              when={formChanged}
              message="Changes you made may not be saved."
            />
            <EditOrgHeader isNew={isNew} org={org} breadCrumbItemRender={breadCrumbItemRender} formChanged={formChanged} onReset={onReset} form={form} props={props}/>
            <Form
              {...layout}
              layout="vertical"
              name="Edit Organization"
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
              onFinishFailed={() => {}}
              onValuesChange={() => setFormChanged(true)}
              form={form}
              autoComplete="off"
              fields={[ // this is needed in order to sava categories properly
                {
                  name: ["categories"],
                  value: orgCategories,
                },
              ]}
            >
              <Tabs onChange={handleChange} activeKey={activeKey}>
                {/*forceRender here makes this tab render even if user doesn't open it, otherwise values from other tabs won't be saved*/}
                <TabPane tab="Basic" key="1" forceRender={forceRender}>
                  {contextHolder}
                  <Row gutter={[16, 16]}>
                    <Col span={12} className="editOrgColumn">
                      <p style={{fontWeight: "bold"}}>General Information</p>
                      <OrgNameField org={org}/>
                      <ClientsIdField org={org}/>
                      <SalesTicketField org={org}/>
                    </Col>
                    <Col span={12} className="editOrgColumn">
                      <p style={{color: "transparent"}}>General Information</p>
                      <SalesPersonContactField org={org}/>
                      <ClientContactField org={org}/>
                      <LinkedProductsField orgLinkedProducts={orgLinkedProducts} updateLinkedProducts={updateLinkedProducts} setFormChanged={setFormChanged} linkedProducts={linkedProducts}/>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={24} className="editOrgColumn">
                      <NotesTextArea org={org}/>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={12} className="editOrgColumn">
                      <p style={{fontWeight: "bold"}}>Basic Language Settings</p>
                        <SourceLanguagesSelection orgSourceLanguages={orgSourceLanguages} updateSourceLanguages={updateSourceLanguages} setFormChanged={setFormChanged} updateTargetLanguages={updateTargetLanguages} translate={props.t}/>
                    </Col>

                    <Col span={12} className="editOrgColumn">
                      <p style={{color: "transparent"}}>Basic Language Settings</p>
                        <TargetLanguagesSelection orgTargetLanguages={orgTargetLanguages} updateSourceLanguages={updateSourceLanguages} setFormChanged={setFormChanged} updateTargetLanguages={updateTargetLanguages} translate={props.t}/>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={12} className="editOrgColumn">
                      <DefaultSourceLanguageDropdown defaultSourceLanguage={defaultSourceLanguage} orgSourceLanguages={orgSourceLanguages} translate={props.t}/>
                    </Col>
                    <Col span={12} className="editOrgColumn">
                      <DefaultTargetLanguageDropdown defaultTargetLanguage={defaultTargetLanguage} orgTargetLanguages={orgTargetLanguages} translate={props.t}/>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={24} className="editOrgColumn">
                      <p style={{fontWeight: "bold"}}>Engines</p>
                      <div className="quotaFormWrapper">
                        <EnginesDropdownSwitch handleEngineSelectionDropdown={handleEngineSelectionDropdown} engineSelectionDropdown={engineSelectionDropdown}/>
                      </div>
                      {engineSelectionDropdown &&
                          <div className="quotaFormWrapper">
                            <EngineDropdownSettings org={org} handleEnginesProfilesOnly={handleEnginesProfilesOnly}/>
                          </div>
                      }
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={12} className="editOrgColumn">
                      <div style={{paddingLeft: "10px"}}>
                        <CustomEnginesSelection org={org} isNew={isNew} engines={availableEngineEntities} setFormChanged={setFormChanged} orgEngines={orgEngines} customizedEngines={customizedEngines} setCustomizedEngines={setCustomizedEngines} setDeletingEngines={setDeletingEngines} updateEngines={updateEngines}/>
                      </div>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={24} className="editOrgColumn">
                        <p style={{fontWeight: "bold"}}>Service life</p>
                        <div className="quotaFormWrapper">
                          <ShowDemoSwitch handleDemoExpiration={handleDemoExpiration} hasDemoExpiration={hasDemoExpiration} />
                          {hasDemoExpiration &&
                              <DemoSettings handleDemoExpirationDate={handleDemoExpirationDate} demoExpiration={demoExpiration} />
                          }
                        </div>
                      <p style={{fontWeight: "bold"}}>Quota</p>

                      <QuotaType
                          quotaType={quotaType !== undefined ? quotaType : 0}
                          setQuotaType={setQuotaType}
                          name="quotaType"
                          handleQuotaType={handleQuotaType}
                      />

                      {quotaType !== 0 && quotaType !== undefined &&
                          <div>
                            <QuotaSettings overages={overages} handleQuotaPolicy={handleQuotaPolicy} limitType={limitType}
                            limitTypeOptions={limitTypeOptions} handleLimitType={handleLimitType} counterLimit={counterLimit} handleCounterLimit={handleCounterLimit} quotaType={quotaType}/>
                            <UserQuota counterLimitUser={counterLimitUser} handleCounterLimitUser={handleCounterLimitUser} hasUserQuota={hasUserQuota} handleUserQuota={handleUserQuota} limitType={limitType} counterLimit={counterLimit}/>
                          </div>
                      }
                      <MaxWordsPerDocument maxWordsPerDocument={maxWordsPerDocument} handleMaxWordsPerDocument={handleMaxWordsPerDocument}
                                           showMaxWordsPerDocument={showMaxWordsPerDocument} handleShowMaxWordsPerDocument={handleShowMaxWordsPerDocument}/>
                      </Col>
                  </Row>
                </TabPane>

                <TabPane tab="Advanced" key="2" forceRender={forceRender}>
                  <p style={{fontWeight: "bold"}}>General</p>
                  <Row gutter={[16, 16]}>
                    <Col span={12} className="editOrgColumn">
                      <p style={{fontWeight: "bold"}}>Categories</p>
                      <div>
                        <CategoryDropdownSwitch handleShowCategory={handleShowCategory} showCategory={showCategory}/>
                      </div>
                      {showCategory &&
                          <div className="quotaFormWrapper">
                            <CategoryMandatorySwitch handleMandatoryCategory={handleMandatoryCategory} mandatoryCategory={mandatoryCategory}/>
                          </div>
                      }
                      <CategoriesSelection orgCategories={orgCategories} updateCategories={updateCategories} setFormChanged={setFormChanged}/>
                      <div className="quotaFormWrapper quotaFormWrapperWithSwitch">
                        <TextTranslationSwitch handleTextAndDocumentView={handleTextAndDocumentView} hideTT={hideTT}/>
                      </div>
                      <div className="quotaFormWrapper quotaFormWrapperWithSwitch">
                        <DocumentTranslationSwitch handleTextAndDocumentView={handleTextAndDocumentView} hideDT={hideDT}/>
                      </div>
                      <div className="quotaFormWrapper quotaFormWrapperWithSwitch">
                        <TransportSwitch handleTransport={handleTransport} hideTransport={hideTransport}/>
                      </div>
                      <div className="quotaFormWrapper quotaFormWrapperWithSwitch">
                        <TransactionRecordSwitch handleTransactionRecord={handleTransactionRecord} hasTransactionRecord={hasTransactionRecord}/>
                      </div>
                      <div className="quotaFormWrapper quotaFormWrapperWithSwitch">
                        <TsCloudSwitch handleTsCloud={handleTsCloud} showTsCloud={showTsCloud}/>
                      </div>
                    </Col>

                    <Col span={12} className="editOrgColumn">
                      <CustomErrorContactSwitch handleCustomErrorContact={handleCustomErrorContact} customErrorContact={customErrorContact}/>
                      {customErrorContact &&
                          <CustomSupportEmailField org={org}/>
                      }
                    </Col>
                  </Row>

                  <p style={{fontWeight: "bold"}}>Document translate</p>
                  <Row gutter={[16, 16]}>
                    <Col span={12} className="editOrgColumn">
                      <TimeToLiveDropdown orgTimeToLive={orgTimeToLive} updateTimeToLive={updateTimeToLive} setFormChanged={setFormChanged} timeToLive={timeToLive}/>
                      <div className="quotaFormWrapper">
                        <DeleteAfterDownloadSwitch handleDeleteAfterDownload={handleDeleteAfterDownload} deleteAfterDownload={deleteAfterDownload}/>
                      </div>
                    </Col>
                    <Col span={12} className="editOrgColumn">
                      <SpecialFileFormatSelection org={org} isNew={isNew} />
                    </Col>
                  </Row>

                  <p style={{fontWeight: "bold", marginTop: "14px"}}>Advanced language settings</p>
                  <Row gutter={[16, 16]}>
                    <Col span={12} className="editOrgColumn">
                      <CustomLanguagesNameSelection org={org} isNew={isNew} orgSourceLanguages={orgSourceLanguages} orgTargetLanguages={orgTargetLanguages} />
                    </Col>
                    <Col span={12} className="editOrgColumn">
                      <TransliterationLanguagesSelection org={org} isNew={isNew} orgSourceLanguages={orgSourceLanguages} orgTargetLanguages={orgTargetLanguages} translate={props.t} />
                    </Col>
                  </Row>
                  <LanguageProfileSettings originalProfiles={originalProfiles} profiles={profiles} onResetProfiles={onResetProfiles} addMulitpleProfilesForm={addMulitpleProfilesForm} addMultiple={addMultiple} engineEntities={engineEntities} createTree={createTree} props={props}/>
                </TabPane>

                <TabPane tab="Styling" key="3">
                  <p style={{fontWeight: "bold"}}>General</p>
                  <Row gutter={[16, 16]}>
                    <Col span={12} className="editOrgColumn">
                      <StaticMessageField org={org} handleFooterMessage={handleFooterMessage} setOrg={setOrg} setFormChanged={setFormChanged} staticMessageContent={staticMessageContent} staticMessage={staticMessage}/>
                    </Col>
                  </Row>
                  <StyleSettings org={org} uiAssets={uiAssetsStyle} uiAssetsDefault={isNew ? DEFAULT_CSS : uiAssetsStyle['DEFAULT_CSS']} onResetStyle={onResetStyle} contextHolder={contextHolder} setUiAssets={setUiAssetsStyle} setFormChanged={setFormChanged} />
                </TabPane>
              </Tabs>
            </Form>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUserEmail: state.user.email,
});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EditOrg)
);
