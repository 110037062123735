import {Form, Switch} from "antd";
import React from "react";
const TransactionRecord = ({handleTransactionRecord, hasTransactionRecord}) => {
    return (
        <Form.Item
            label="Display Transaction Record"
            name="hasTransactionRecord"
            initialValue={hasTransactionRecord}
        >
            <Switch
                className="switchButton"
                checked={hasTransactionRecord}
                onChange={handleTransactionRecord}
            ></Switch>
        </Form.Item>
    );
};
export default TransactionRecord;