import React, {useCallback, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import '../../ant-style.css';
import '../../style.css';
import './style.css';
import {
  Button,
  Col,
  Form,
  Modal,
  PageHeader,
  Row,
  Skeleton,
  Tooltip,
} from 'antd';
import ColorPicker from '../../../../components/ColorPicker';
import EditOrgStylePreview from './preview';
import {
  ArrowLeftOutlined,
  CheckOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import ImageUploader from '../../../../components/ImageUploader';
import { Prompt } from 'react-router';
import { Link } from 'react-router-dom';
import getOrganizationById from "../../../../apiCalls/Usermanagement/EditOrgPage/getOrganizationById";
import modifyOrganizationById from "../../../../apiCalls/Usermanagement/EditOrgPage/modifyOrganizationById";

const RGBA_REGEX = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/g;
const HEX_REGEX = /#([A-Fa-f0-9]{3}){1,2}/g;

const EditOrgStyle = (props) => {
  const [loading, setLoading] = useState(true);
  const [org, setOrg] = useState({});
  const [uiAssets, setUiAssets] = useState({
    appBackground: '#000000',
    basicButtonBackground: '#000000',
    basicButtonBackgroundHover: '#000000',
    basicButtonText: '#000000',
    dropdownSelected: '#000000',
    dropdownTitleColor: '#000000',
    headerBackground: '#000000',
    navbarActive: '#000000',
    navbarActiveText: '#000000',
    navbarBottomBorder: '#000000',
    navbarIdle: '#000000',
    navbarIdleText: '#000000',
    translationButtonBackground: '#000000',
    translationButtonText: '#000000',
  });
  const [modal, contextHolder] = Modal.useModal();

  function RGBToHex(r, g, b) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);

    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;

    return '#' + r + g + b;
  }

  const loadOrg = useCallback(() => {
    setLoading(true);
    getOrganizationById(props.match.params.orgId)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Request failed');
        }
      })
      .then((json) => {
        if (json.success) {
          let p = JSON.parse(json.message.uiAssets);
          let parsed = JSON.parse(p);
          for (const [key, value] of Object.entries(parsed)) {
            if (
              !!value &&
              (typeof value === 'string' || value instanceof String)
            ) {
              let valueNoWS = value.replace(/\s+/g, '');
              let hexMatch = valueNoWS.match(HEX_REGEX);
              if (!!hexMatch) {
                if (hexMatch.length === 1) {
                  parsed[key] = hexMatch[0];
                } else {
                  parsed[key] = value;
                }
              } else {
                let rgba = valueNoWS.match(RGBA_REGEX);
                if (!!rgba && rgba.length >= 3) {
                  parsed[key] = RGBToHex(rgba[0], rgba[1], rgba[2]);
                } else {
                  parsed[key] = value;
                }
              }
            }
          }
          setOrg({ ...json.message, uiAssets: parsed });
          setUiAssets(parsed);
        } else {
          throw new Error(json.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        Modal.error({
          title: 'Error',
          content: `Organization with id '${props.match.params.orgId}' doesn't exist`,
        });
        props.history.push('/usermanagement');
      });
  }, [props.history, props.match.params.orgId]);

  const updateOrg = () =>
    new Promise((resolve, reject) => {
      let tempOrg = { ...org };
      let tempUiAssets = { ...uiAssets };
      tempUiAssets.navbarBottomBorder = `5px solid ${tempUiAssets.navbarBottomBorder}`;
      tempOrg.uiAssets = JSON.stringify(JSON.stringify(tempUiAssets));
      let body = JSON.stringify(tempOrg);
     modifyOrganizationById(body, props.match.params.orgId)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error('Request failed');
          }
        })
        .then((json) => {
          if (json.success) {
            resolve(json);
          } else {
            throw new Error('Request failed');
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });

  const handleSubmit = () => {
    let confirmModal = modal.confirm({
      title: 'Update Organization Style',
      content: 'Are you sure you want to update the organization style?',
      onOk(closeModal) {
        confirmModal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true },
        });
        updateOrg()
          .then(() => {
            confirmModal.update({
              title: 'Success',
              content: `Org style is updated`,
              okButtonProps: { loading: false },
              cancelButtonProps: { hidden: true },
              onOk() {
                closeModal();
                loadOrg();
              },
            });
          })
          .catch((err) => {
            confirmModal.update({
              title: 'Error',
              content: `Request failed: ${err}`,
              okButtonProps: { loading: false },
              cancelButtonProps: { hidden: true },
            });
          });
      },
    });
  };

  const onReset = () => {
    setUiAssets(org.uiAssets);
  };

  const formItemLayout = {
    labelAlign: 'right',
    labelCol: { span: 16 },
    wrapperCol: { span: 8 },
  };

  useEffect(() => {
    loadOrg();
  }, [loadOrg]);

  useEffect(() => {
    if (org.uiAssets !== uiAssets) {
      window.addEventListener('beforeunload', alertUser);
    }
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [uiAssets, org.uiAssets]);

  const alertUser = (e) => {
    e.preventDefault();
    return (e.returnValue = 'Hellooo');
  };

  const breadCrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
  };

  return (
    <div className="editOrgWrapper">
      <div className="editOrgContainer userManagementContainer">
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <Prompt
              when={org.uiAssets !== uiAssets}
              message="Changes you made may not be saved."
            />
            <PageHeader
              title={`Change style of organization '${org.name}'`}
              className="site-page-header"
              size="small"
              breadcrumb={{
                routes: [
                  {
                    path: 'usermanagement',
                    breadcrumbName: 'User Management',
                  },
                  {
                    path: `org/${org.id}/edit`,
                    breadcrumbName: org.name,
                  },
                  {
                    path: 'style',
                    breadcrumbName: 'Style',
                  },
                ],
                itemRender: breadCrumbItemRender,
              }}
              backIcon={
                <>
                  <Button icon={<ArrowLeftOutlined />} />
                </>
              }
              onBack={() =>
                props.history.push(
                  `/usermanagement/org/${props.match.params.orgId}/edit`
                )
              }
              extra={[
                <Tooltip
                    key="reset"
                    title={
                      org.uiAssets !== uiAssets
                        ? 'Reset to original values'
                        : 'No changes have been made'
                    }
                >
                  <Button
                    key="reset"
                    onClick={onReset}
                    icon={<DeleteOutlined />}
                    disabled={org.uiAssets === uiAssets}
                  >
                    Reset
                  </Button>
                </Tooltip>,
                <Tooltip
                    key="resetOrNoChanges"
                    title={
                      org.uiAssets !== uiAssets
                        ? 'Save organization values'
                        : 'No changes have been made'
                    }
                >
                  <Button
                    key="submit"
                    type="primary"
                    onClick={handleSubmit}
                    icon={<CheckOutlined />}
                    disabled={org.uiAssets === uiAssets}
                  >
                    Submit
                  </Button>
                </Tooltip>,
              ]}
            />
            <Row gutter={[8, 8]}>
              <Col flex={'300px'} style={{ marginBottom: '1em' }}>
                {contextHolder}
                <Form
                  {...formItemLayout}
                  layout="horizontal"
                  autoComplete="off"
                >
                  <Form.Item label="Logo" labelCol={10} wrapperCol={14}>
                    <ImageUploader
                      value={uiAssets.headerLogo}
                      background={uiAssets.headerBackground}
                      onChange={(img) =>
                        setUiAssets({ ...uiAssets, headerLogo: img })
                      }
                    />
                  </Form.Item>
                  <Form.Item label="App background color">
                    <ColorPicker
                      value={uiAssets.appBackground}
                      onChange={(value) =>
                        setUiAssets({ ...uiAssets, appBackground: value })
                      }
                    />
                  </Form.Item>

                  <Form.Item label="Header color">
                    <ColorPicker
                      value={uiAssets.headerBackground}
                      onChange={(value) =>
                        setUiAssets({ ...uiAssets, headerBackground: value })
                      }
                      changed={
                        org.uiAssets.headerBackground !==
                        uiAssets.headerBackground
                      }
                    />
                  </Form.Item>

                  <Form.Item label="Header text color">
                    <ColorPicker
                      value={uiAssets.dropdownTitleColor}
                      onChange={(value) =>
                        setUiAssets({ ...uiAssets, dropdownTitleColor: value })
                      }
                      changed={
                        org.uiAssets.dropdownTitleColor !==
                        uiAssets.dropdownTitleColor
                      }
                    />
                  </Form.Item>
                  <Form.Item label='Navigation bar "active"'>
                    <ColorPicker
                      value={uiAssets.navbarActive}
                      onChange={(value) =>
                        setUiAssets({ ...uiAssets, navbarActive: value })
                      }
                      changed={
                        org.uiAssets.navbarActive !== uiAssets.navbarActive
                      }
                    />
                  </Form.Item>
                  <Form.Item label='Navigation bar "active" text'>
                    <ColorPicker
                      value={uiAssets.navbarActiveText}
                      onChange={(value) =>
                        setUiAssets({ ...uiAssets, navbarActiveText: value })
                      }
                      changed={
                        org.uiAssets.navbarActiveText !==
                        uiAssets.navbarActiveText
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Nav bar underline color">
                    <ColorPicker
                      value={uiAssets.navbarBottomBorder}
                      onChange={(value) =>
                        setUiAssets({ ...uiAssets, navbarBottomBorder: value })
                      }
                      changed={
                        org.uiAssets.navbarBottomBorder !==
                        uiAssets.navbarBottomBorder
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Nav bar idle color">
                    <ColorPicker
                      value={uiAssets.navbarIdle}
                      onChange={(value) =>
                        setUiAssets({ ...uiAssets, navbarIdle: value })
                      }
                      changed={org.uiAssets.navbarIdle !== uiAssets.navbarIdle}
                    />
                  </Form.Item>
                  <Form.Item label="Nav bar idle text color">
                    <ColorPicker
                      value={uiAssets.navbarIdleText}
                      onChange={(value) =>
                        setUiAssets({ ...uiAssets, navbarIdleText: value })
                      }
                      changed={
                        org.uiAssets.navbarIdleText !== uiAssets.navbarIdleText
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Translate button color">
                    <ColorPicker
                      value={uiAssets.translationButtonBackground}
                      onChange={(value) =>
                        setUiAssets({
                          ...uiAssets,
                          translationButtonBackground: value,
                        })
                      }
                      changed={
                        org.uiAssets.translationButtonBackground !==
                        uiAssets.translationButtonBackground
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Translate button text color">
                    <ColorPicker
                      value={uiAssets.translationButtonText}
                      onChange={(value) =>
                        setUiAssets({
                          ...uiAssets,
                          translationButtonText: value,
                        })
                      }
                      changed={
                        org.uiAssets.translationButtonText !==
                        uiAssets.translationButtonText
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Main button color">
                    <ColorPicker
                      value={uiAssets.basicButtonBackground}
                      onChange={(value) =>
                        setUiAssets({
                          ...uiAssets,
                          basicButtonBackground: value,
                        })
                      }
                      changed={
                        org.uiAssets.basicButtonBackground !==
                        uiAssets.basicButtonBackground
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Main button hover color">
                    <ColorPicker
                      value={uiAssets.basicButtonBackgroundHover}
                      onChange={(value) =>
                        setUiAssets({
                          ...uiAssets,
                          basicButtonBackgroundHover: value,
                        })
                      }
                      changed={
                        org.uiAssets.basicButtonBackgroundHover !==
                        uiAssets.basicButtonBackgroundHover
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Main button text">
                    <ColorPicker
                      value={uiAssets.basicButtonText}
                      onChange={(value) =>
                        setUiAssets({ ...uiAssets, basicButtonText: value })
                      }
                      changed={
                        org.uiAssets.basicButtonText !==
                        uiAssets.basicButtonText
                      }
                    />
                  </Form.Item>
                </Form>
              </Col>
              <Col flex="auto">
                <EditOrgStylePreview uiAssets={uiAssets} />
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUserEmail: state.user.email,
});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EditOrgStyle)
);
