import { combineReducers } from 'redux';
import baseLogo from './baselogo';
import {LOCALIZATION_LANGUAGES} from "../../containers/Usermanagement/EditOrgPage/settingValues";

export const defaultState = {
  user: {
    organization: {
      uiAssets: {
        headerLogo: baseLogo,
        headerBackground: 'linear-gradient(-180deg, #002f65 0%, #005c9e 100%)',
        headerTitle: '',
        navbarActive: '#005c9e',
        navbarActiveText: 'white',
        navbarIdle: 'red',
        navbarIdleText: 'black',
        appBackground: '#f3f6f8',
        translationButtonBackground: '#66b945',
        translationButtonText: 'black',
        basicButtonBackground: 'rgb(31, 49, 99)',
        basicButtonBackgroundHover: '#669dc5',
        basicButtonText: '#ffffff',
        dropdownTitleColor: 'white',
        dropdownSelected: 'black',
        termsPage: false,
        showLogoutScreen: false,
        initialSessionDuration: 30 * 60 * 1000,
        faqFileIds: undefined,
        statisticsCharts: true,
        localizations: LOCALIZATION_LANGUAGES,
      },
    },
  },
  globalOptions: {
    sourceLanguage: '',
    targetLanguage: '',
    engines: [],
    termsAcknowledged: false,
    acceptedFormats:
      '.dita, .doc, .docm, .docx, .dot, .dotm, .dotx, .eml, .html, .idml, .jpeg, .jpg, .msg, .odp, .ods, .odt, .pdf, .png, .ppt, .pptx, .rtf, .sdlxliff, .sdxliff, .srt, .txt, .xliff, .xlf, .xls, .xlsm, .xlsx, .zip',

    appLanguage: 'en'
  },
  userStatus: {
    uploading: [],
    uploaded: [],
    selected: [],
    submissions: [],
    pid: '',
    inProgress: [],
    done: [],
  },
};

const user = (state = defaultState.user, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      let uia = action.userData.organization.uiAssets;
      let p = JSON.parse(uia);
      let parsed = JSON.parse(p);
      return {
        ...action.userData,
        organization: {
          ...action.userData.organization,
          uiAssets: { ...defaultState.user.organization.uiAssets, ...parsed, localizations: LOCALIZATION_LANGUAGES },
        },
      };
    case 'LOGOUT':
      let preserveUIAssets = defaultState.user;
      preserveUIAssets.organization.uiAssets = state.organization.uiAssets;
      return preserveUIAssets;
    default:
      return state;
  }
};

const globalOptions = (state = defaultState.globalOptions, action) => {
  switch (action.type) {
    case 'SET_SOURCE':
      return {
        ...state,
        sourceLanguage: action.source,
      };
    case 'SET_TARGET':
      return {
        ...state,
        targetLanguage: action.target,
      };
    case 'SET_ENGINES':
      return {
        ...state,
        engines: action.engines,
      };

    case 'SET_APP_LANGUAGE':
      return {
        ...state,
        appLanguage: action.appLanguage,
      };

    case 'LOGOUT':
      let preserveAppLanguage = defaultState.globalOptions;
      preserveAppLanguage.appLanguage = state.appLanguage;
      return preserveAppLanguage;

    case 'SET_TERMS_ACKNOWLEDGED':
      return {
        ...state,
        termsAcknowledged: true,
      };

    default:
      return state;
  }
};

const userStatus = (state = defaultState.userStatus, action) => {
  switch (action.type) {
    case 'SET_UPLOADING':
      let newFiles = new Set([...state.uploading, ...action.uploading]);
      return {
        ...state,
        uploading: Array.from(newFiles),
      };
    case 'RM_UPLOADING':
      let newState = [...state.uploading];

      for (let i = 0; i < action.files.length; i++) {
        newState = newState.filter((el) => el !== action.files[i]);
      }
      return {
        ...state,
        uploading: newState,
      };
    case 'SET_SUBMISSIONS':
      return {
        ...state,
        submissions: [...action.submissions].reverse(),
      };
    case 'SET_USER_STATUS':
      return {
        ...state,
        uploaded: [...action.status.uploaded],
        submissions: [...action.status.submissions],
      };
    case 'SELECT_FILE':
      if (!state.selected.includes(action.fileId)) {
        return {
          ...state,
          selected: [...state.selected, action.fileId],
        };
      } else {
        return { ...state };
      }
    case 'DESELECT_FILE':
      return {
        ...state,
        selected: [...state.selected].filter((el) => el !== action.fileId),
      };
    case 'SELECT_ALL':
      return {
        ...state,
        selected: [...state.uploaded.map((el) => el.fileId)],
      };
    case 'DESELECT_ALL':
      return {
        ...state,
        selected: [],
      };
    case 'RM_SELECTED_FILES':
      return {
        ...state,
        selected: [],
      };
    case 'CLEAR_USER_STATUS':
      return state;
    case 'LOGOUT':
      return defaultState.userStatus;
    case 'SET_PID':
      return {
        ...state,
        pid: action.pid,
      };
    case 'RM_PID':
      return {
        ...state,
        pid: '',
      };
    case 'SET_DONE':
      return {
        ...state,
        done: [...state.done, action.done],
      };
    case 'SET_INPROGRESS':
      return {
        ...state,
        inProgress: [...state.inProgress, action.inpr],
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({ user, globalOptions, userStatus });

export default rootReducer;
