import React, { Component } from 'react';
import Spinner from '../spinner';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { withTranslation } from 'react-i18next';
import './style.css';
import FocusTrap from 'focus-trap-react';
import Announcements from '../Announcements';
import styled from 'styled-components';
import deleteFileById from "../../apiCalls/components/ConfirmDelete/deleteFileById";

const DButton = styled.button`
  text-align: center;
  width: 180px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  ${(props) => `background-color: ${props.colors.background};
  color:${props.colors.text};
  &:hover {
    background-color:${props.colors.hover};
  }
  `}
`;
class ConfirmDelete extends Component {
  state = { deleting: false, asyncMessage: '' };

  onKeyDown = (e) => e.keyCode === 27 && this.props.toggle();

  deleteFile = (fileId) => {
    this.setState({ deleting: true, error: false });
    deleteFileById(fileId)
      .then((res) => {
        if (res.status !== 200 || !res) {
          this.setState({
            error: true,
            deleting: false,
            asyncMessage: this.props.t('deleteModalProblem'),
          });
          return;
        }
        this.setState();
        this.props.getStatus();
        this.props.toggle();
      })
      .catch((e) => {
        this.setState({
          error: true,
          deleting: false,
          asyncMessage: this.props.t('deleteModalProblem'),
        });
      });
  };

  render() {
    return (
      <FocusTrap
        focusTrapOptions={{
          onDeactivate: this.props.config,
          allowOutsideClick: () => true,
        }}
        tag="aside"
        aria-modal="true"
        tabIndex="-1"
      >
        <div className="c-modal-cover">
          <div className="deleteModal" role="dialog" onKeyDown={this.onKeyDown}>
            <Announcements message={this.state.asyncMessage} />
            {this.state.deleting ? (
              <Spinner></Spinner>
            ) : (
              <React.Fragment>
                <Tippy content={this.props.t('AnnounceModalCloseButton')}>
                  <button
                    onClick={this.props.toggle}
                    aria-labelledby="close-modal"
                    className="closeDelete"
                  >
                    <svg
                      className="c-modal__close-icon"
                      viewBox="0 0 40 40"
                      aria-hidden="true"
                    >
                      <path d="M 10,10 L 30,30 M 30,10 L 10,30"></path>
                    </svg>
                  </button>
                </Tippy>

                <div className="modalTitle">
                  <h1
                    style={{
                      fontWeight: '500',
                      fontSize: '22px',
                    }}
                  >
                    {this.state.error
                      ? 'Error'
                      : this.props.t('deleteModalTitle')}
                  </h1>
                </div>

                <div className="confButtons">
                  {this.state.error ? (
                    <p>{this.props.t('deleteModalProblem')}</p>
                  ) : (
                    <p>{this.props.t('deleteModalCheck')}</p>
                  )}
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    {this.state.error ? (
                      <>
                        <button className="greyBtn" onClick={this.props.toggle}>
                          {this.props.t('textOk')}
                        </button>
                      </>
                    ) : (
                      <>
                        <DButton
                          colors={this.props.colors}
                          onClick={this.props.toggle}
                        >
                          {this.props.t('deleteModalCancel')}
                        </DButton>
                        <DButton
                          colors={this.props.colors}
                          onClick={() => this.deleteFile(this.props.confId)}
                        >
                          {this.props.t('deleteModalDelete')}
                        </DButton>
                      </>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </FocusTrap>
    );
  }
}
export default withTranslation()(ConfirmDelete);
