
const getWebsiteTranslation = async (url) => {
    try {
        const config = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },

        };

        return await fetch(url, config);
    } catch (error) {
        console.error(error);
    }
};

export default getWebsiteTranslation;
