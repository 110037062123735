import React from "react";
import Tippy from "@tippyjs/react";
import {ReactComponent as CLOSE_SVG} from "../c-modal__close-icon.svg";

const openHelpPages = () => {
    const url = "https://onlinehelp.translations.com/AIPortal/GlobalLink_AIPortal_Help.htm#Translate_a_document_2_28_1_d9a7117d-0b1e-465b-91e4-92bc649bd8bd"; // general online help pages
    window.open(url);
}

const ModalContentFooter= ({translate, ocr, toggleOcr, errorMessage, warningMessage, props, mandatoryCategoryError, isSubmissionNameTooLong}) => {
    if(mandatoryCategoryError) {
        errorMessage = mandatoryCategoryError;
    }

    return (
        <>
            {!!props.pdfUploaded && //the ocr button will be displayes only when pdf formats (png, jpg and pdf are uploaded)
                <div style={{display: "flex"}}>
                    <Tippy content={translate('ocrCheckboxTippy')} placement="right">
                        <label className="ocrFlagContainer">
                            {translate('ocrCheckboxLabel')}
                            <input
                                name="ocrFlag"
                                type="checkbox"
                                className="ocrFlag"
                                checked={ocr}
                                onChange={toggleOcr}
                            />
                            <div className="ocrMark">
                                <CLOSE_SVG
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            </div>
                        </label>
                    </Tippy>
                    <p className="ocrHelp" onClick={openHelpPages}>{translate('ocrHelpText')}</p>
                </div>
            }
            {/*displaying error messages in red, submission name too long warning in blue*/}
            {

                (errorMessage) ? (<div className="errorArea" style={{height: "20px"}}>{errorMessage}</div>) :
                ((!!isSubmissionNameTooLong && warningMessage) && <div className="warningArea" style={{height: "20px"}}>{warningMessage}</div>)
            }
        </>
    );
};

export default ModalContentFooter;