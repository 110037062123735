import {GLAI_URL} from "../../../utils/config";

const getAllEngines =  () => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
        };

        const url = `${GLAI_URL}/apigateway/mtengine/engine/all`;

        return fetch(url, config);
    } catch (error) {
        console.error(error);
    }
};

export default getAllEngines;