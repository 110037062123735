import {Form, Switch} from "antd";
import React from "react";

const TsCloudSwitch = ({handleTsCloud, showTsCloud}) => {
    return (
        <Form.Item
            label="Show TsCloud"
            name="showTsCloud"
            initialValue={showTsCloud}
        >
            <Switch
                className="switchButton"
                checked={showTsCloud}
                onChange={handleTsCloud}
            ></Switch>
        </Form.Item>
    );
};

export default TsCloudSwitch;