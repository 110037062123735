import {Form} from "antd";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import CustomEngineNameInput from "../../../../components/CustomEngineNameInput";

const CustomEnginesSelection = ({org, isNew, engines, setFormChanged, orgEngines, customizedEngines, setCustomizedEngines, setDeletingEngines, updateEngines}) => {
    return (
        <Form.Item
            label={
                <div>
                    <label>Engines for all translations</label>{' '}
                    <p
                        style={{
                            fontSize: '12px',
                            color: 'grey',
                            textAlign: 'left',
                        }}
                    >
                        {
                            ReactHtmlParser('Here you can see, select, delete and rename the engine that this org has available for any translation.<br/> <strong>Important: the order you select the engines matters. The first engine becomes the default engine for this organization.</strong>')
                        }
                    </p>
                </div>
            }
            name="customEnginesNames"
            initialValue={isNew ? {} : org.uiAssets.customEnginesNames}
        >
            <CustomEngineNameInput
                availableEngines={engines}
                setFormChanged={setFormChanged}
                org={org}
                orgEngines={orgEngines}
                customizedEngines={customizedEngines}
                setCustomizedEngines={setCustomizedEngines}
                setDeletingEngines={setDeletingEngines}
                updateEngines={updateEngines}
            />
        </Form.Item>
    );
};

export default CustomEnginesSelection;