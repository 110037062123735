import {GLAI_URL} from "../../../utils/config";

const deleteFileById =  (fileId) => {
    try {
        const config = {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
        };

        const url = `${GLAI_URL}/apigateway/storage/${fileId}`;

        return fetch(url, config);

    } catch (error) {
        console.error(error);
    }
};
export default deleteFileById;