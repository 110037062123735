import React, { Component } from "react";
import { connect } from "react-redux";

class DocumentButton extends Component {
  state = {
    hover: false
  };

  squareButton = {
    height: "40px",
    borderRadius: "3px",
    border: "none",
    fontSize: "14px",
    fontWeight: "600",
    color: "#003162",
    backgroundColor: "transparent",
    textDecoration: "underline",
    paddingBottom: "20px"
  };

  onMouseOver = () => {
    this.setState({ hover: true });
  };

  onMouseOut = () => {
    this.setState({ hover: false });
  };

  render() {
    return (
      <button
        onClick={() => {
          if (this.props.history) {
            this.props.history.push("/document", { triggerInput: true });
          }
        }}
        aria-label={this.props.tippyContent}
        style={{
          ...this.squareButton
        }}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
      >
        {this.props.tippyContent}
      </button>
    );
  }
}

const mapStateToProps = state => ({
  uiAssets: state.user.organization.uiAssets
});

export default connect(mapStateToProps, undefined)(DocumentButton);
