import React from "react";
import Tippy from "@tippyjs/react";
import {ReactComponent as CLOSE_SVG} from "../c-modal__close-icon.svg";
import Announcements from "../../Announcements";

const ModalContentHeader = ({onClose, buttonRef, translate, showUploadFailError, loading, showUploadedMessage, asyncMessage}) => {
    return (
        <>
        <Tippy content={translate('AnnounceModalCloseButton')}>
            <button
                className="c-modal__close"
                onClick={onClose}
                aria-labelledby="close-modal"
                ref={buttonRef}
            >
              <span id="close-modal" className="u-hide-visually">
                {translate('AnnounceModalCloseButton')}
              </span>
                <CLOSE_SVG />
            </button>
        </Tippy>
        <div className="c-modal__header">
            <h1>
                {showUploadFailError
                    ? 'Error'
                    : loading
                        ? translate('modalLoading')
                        : !showUploadFailError
                            && translate('modalH1')}
            </h1>
            <Announcements message={asyncMessage} />
        </div>
        </>
    );
};

export default ModalContentHeader;