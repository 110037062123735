import {Form, Switch} from "antd";
import React from "react";

const CategoryDropdownSwitch = ({handleShowCategory, showCategory}) => {
    return (
        <Form.Item
            label={<label>Display category selection dropdown</label>}
            name="categorySelectionDropdownSwitch"
            initialValue={showCategory}
        >
            <Switch
                checked={showCategory}
                onChange={handleShowCategory}
                style={{ display:'inline' }}
            ></Switch>
        </Form.Item>
    );
};

export default CategoryDropdownSwitch;