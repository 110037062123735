import {Form, Switch} from "antd";
import React from "react";

const TransportSwitch = ({handleTransport, hideTransport}) => {
    return (
        <Form.Item
            label="Hide TransPort"
            name="hideTransport"
            initialValue={hideTransport}
        >
            <Switch
                className="switchButton"
                checked={hideTransport}
                onChange={handleTransport}
            ></Switch>
        </Form.Item>
    );
};

export default TransportSwitch;