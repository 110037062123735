import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  setSource,
  setTarget,
  setUserStatus,
  setUploading,
  rmUploading,
  selectFile,
  deselectFile,
  rmSelected,
  setSubmissions,
  selectAll,
  deselectAll,
  setDone,
  setInProgress,
  rmPid,
} from '../../redux/actions';
import './style.css';
import Dropzone from 'react-dropzone';
import ConfirmDelete from '../../components/ConfirmDelete';
import Modal from '../../components/axeModal/modal';
import Announcements from '../../components/Announcements';
import { Helmet } from 'react-helmet';
import getSubmission from "../../apiCalls/Document/getSubmission";
import getEnginesByOrg from "../../apiCalls/components/EngineSelection/getEnginesByOrg";
import {
  searchForEnginesFilteredByLangs,
  searchForProfilesFilteredByLangs
} from "../../components/EngineSelection/searchForProfilesAndEngines";
import {eliminateDuplicates} from "../../utils/eliminateDuplicates";
import DocumentTable from "./documentComponents/documentTable";
import DropzoneContent from "./documentComponents/dropzone";
import {webTitle, UPLOADED_FILE_CHECK_TIME} from "../../utils/config";

const dropRef = React.createRef();

class Document extends Component {
  state = {
    open: false,
    reversIcon: false,
    checkStatus: false,
    showUnsupportedFileErrorInModal: false,
    showUnsupportedFileError: false,
    announceExitModal: false,
    announceTitle: '',
    showConfirmation: false,
    confId: '',
    loadingEngines: true,
    disabledEngineDropdown: false,
    engineListState: [],
    selectedEngine: "",
    categoriesListState: [],
    loadingCategories: true,
    disabledCategoryDropdown: false,
    isCategoryMandatory: this.props.store.user.organization.uiAssets.mandatoryCategory,
    showCategory: this.props.store.user.organization.uiAssets.showCategory
  };

  x = null;
  interval = null;

  accept = () =>
    this.props.store.user.organization.uiAssets.extraFormats
      ? `${
          this.props.store.globalOptions.acceptedFormats
        }, ${this.props.store.user.organization.uiAssets.extraFormats
          .map((eF) => eF.trim())
          .join(', ')}`
      : this.props.store.globalOptions.acceptedFormats;

  SortOrderNewToOld = (...argument) => {
    return argument.sort((a, b) => {
      return b.date - a.date;
    });
  };

  toggleConfirmation = (cfid) => {
    this.setState(
      {
        showConfirmation: !this.state.showConfirmation,
        confId: cfid,
      },
      () => this.toggleScrollLock()
    );
  };

  toggleScrollLock = () =>
    document.querySelector('body').classList.toggle('u-lock-scroll');

  getStatus = () =>
      getSubmission()
          .then((res) => res.json())
          .then((json) => {
        if (json.submissions) {
          this.props.setSubmissions(json.submissions);
          let files = json.submissions
            .map((el) => el.files)
            .flat()
            .every(
              (el) =>
                el.status !== 'Uploading' &&
                el.status !== 'Processing' &&
                el.status !== 'DocumentTranscribing' &&
                el.status !== 'DocumentTranscribed'

                  &&
                  ((!!this.props.store.user.organization.uiAssets.convertToPdf && ((el.status !== 'Translated' && el.errorCode === '')  ||// request stops when TranslatedConverted and error ''
                      (el.status === 'Translated' && el.errorCode === 'OCR-003-CVT-FAIL') || // request stops when Translated and error 'OCR-003-CVT-FAIL'
                      el.status === 'Error'))
                  ||

                  (this.props.store.user.organization.uiAssets.convertToPdf === undefined && (((el.status === 'Translated' || el.status === 'TranslatedConverted')  && el.errorCode === '')  ||// request stops when Translated and error ''
                      el.status === 'Error')))
            );
          if (files) {
            clearInterval(this.x);
          }
        } else {
          this.props.setSubmissions([]);
          clearInterval(this.x);
        }
      })
      .catch((e) => clearInterval(this.x));

  checkIfSubmissionContainsOnlyTranscriptions = () => {
    let filesAreTranscribed = false;
    let transcribedFileForEachSub = [];
    let transcribedFiles = [];

    if (this.props.store.userStatus.submissions.length > 0 ) {
      this.props.store.userStatus.submissions.map((el) => {
      transcribedFileForEachSub = el.files.filter(
          (file) =>
              file.status === 'TextTranscribed' ||
              file.status === 'TextTranscribing' ||
              file.errorCode.startsWith('STT-TXT')
      )

        if(transcribedFileForEachSub.length > 0){
          transcribedFiles.push(transcribedFileForEachSub[0].fileId) //only 1 wav can be uploaded in TT
        }
      });

      if (transcribedFiles.length === this.props.store.userStatus.submissions.length) {
        filesAreTranscribed = true;
      }
    }
    return filesAreTranscribed;
  }

  componentDidMount() {
    this.populateEngineDropdown();
    this.populateCategoryDropdown();
    document.title = this.props.t('documentTitle');
    setTimeout(
      () => this.setState({ announceTitle: this.props.t('documentTitle') }),
      30
    );
    //keep sending request every 15 seconds to check if something changed in the backend
    this.interval = setInterval(() => {
      this.getStatus();
    }, 15000);

    if (this.props.location.state && this.props.location.state.triggerInput) {
      if (dropRef.current) {
        dropRef.current.open();
      }
    } else if (
      this.props.location.state &&
      this.props.location.state.acceptedFiles
    ) {
      this.props.setUploading(this.props.location.state.acceptedFiles);
      this.setState(
        {
          open: true,
          announceExitModal: false,
          showUnsupportedFileErrorInModal:
            this.props.location.state.rejectedFiles.length >= 1 ? true : false,
        },
        () => {
          setTimeout(() => {
            this.setState({ showUnsupportedFileErrorInModal: false });
          }, 5000);
        }
      );
    }
    else if (!this.x) {
      this.getStatus();
      let interv = setInterval(() => {
        this.getStatus();
      }, UPLOADED_FILE_CHECK_TIME);
      setTimeout(() => (this.x = interv), 1000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
        (prevProps.store.globalOptions.sourceLanguage !==
            this.props.store.globalOptions.sourceLanguage) ||
        (prevProps.store.globalOptions.targetLanguage !==
            this.props.store.globalOptions.targetLanguage)
    ) {
      this.filterEnginesByLang(); // in case src or tgt change, we need to look for the engines of the new combination
    }
    if (prevState.open !== this.state.open) {
      document.querySelector('body').classList.toggle('u-lock-scroll');
      if (prevState.open !== this.state.open) {
        if (this.state.open) { // showing scrolling bar when modal is closed
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = '';
        }
      }
    }
    if (prevState !== this.state && this.state.checkStatus && !this.x) {
      this.getStatus();
      this.x = setInterval(() => {
        this.getStatus();
      }, UPLOADED_FILE_CHECK_TIME);
    } else if (prevState !== this.state && !this.state.checkStatus) {
      clearInterval(this.x);
      this.x = null;
    }
  }

  componentWillUnmount() {
    clearInterval(this.x);
    this.x = null;
    //do not place request when switching tabs
    clearInterval(this.interval);
  }

  filterEnginesByLang = (categoryAndTextAreReady) => {
    let filteredProfiles, filteredEngines = [];
    if(this.props.store.globalOptions.sourceLanguage === 'auto' || this.props.store.globalOptions.sourceLanguage === 'multi') {
      this.setState({disabledEngineDropdown: true}); // in case the src lang is set to "Detect language" or "multilingual" the dropdown will be disabled
      this.setState({selectedEngine: ""})
    } else {
      if (this.props.store.globalOptions.engines) {
        this.setState({disabledEngineDropdown: false});
        filteredProfiles = searchForProfilesFilteredByLangs(this.props.store.globalOptions.engines, this.props.store.globalOptions);
        filteredEngines = searchForEnginesFilteredByLangs(this.props.store.globalOptions.engines, this.props.store.globalOptions);

        if(this.props.store.user.organization.uiAssets.engineDropdownShowAll) { //showing both engines and profiles option selected
          if(filteredProfiles.length > 0) { // in case there are profiles we add them to the engines
            const profilesAndEnginesListNotFiltered = filteredProfiles.concat(filteredEngines);
            const profilesAndEnginesList = eliminateDuplicates(profilesAndEnginesListNotFiltered, "key");
            this.setState({engineListState: profilesAndEnginesList});
            this.setState({selectedEngine: profilesAndEnginesList[0]}) // selected engine is the first one in the list
          } else {  // in case there are no profiles we only show engines
            this.setState({engineListState: filteredEngines});
            this.setState({selectedEngine: filteredEngines[0]}) // selected engine is the first one in the list
          }
        } else if (this.props.store.user.organization.uiAssets.engineDropdownShowProfilesOnly) { //showing only profiles option selected
          if(filteredProfiles.length > 0) {
            this.setState({engineListState: filteredProfiles});
            this.setState({selectedEngine: filteredProfiles[0]}) // selected engine is the first one in the list
          } else {
            this.setState({disabledEngineDropdown: true});
          }
        }
      }
    }
  }

  populateEngineDropdown = () => {
    getEnginesByOrg(this.props.store.user.organizationId)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          if (json) {
            this.setState({loadingEngines: false});
            this.filterEnginesByLang();
          }
        })
        .catch((error) => {
          console.error(error);
        })
  }

  populateCategoryDropdown = () => {
    let categories = this.props.store.user.organization.categories;
    let categoriesList = [];
    this.setState({loadingCategories: false});
    if(categories) {
      for (let i = 0; i < categories.length; i++) { // building object for select
        categoriesList.push({
          key: categories[i],
          value: categories[i],
          label: categories[i],
        })
      }
      this.setState({categoriesListState: categoriesList});
    }
  }

  handleEngineChange = (option) => {
    this.setState({ selectedEngine: option });
    this.setState({ translationPropertiesChanged: true });
  }

  closeModal = () => {
    this.setState(
      { open: false, checkStatus: true, announceExitModal: true },
      () => this.props.rmUploading(this.props.store.userStatus.uploading)
    );
  };

  reverseIcon = () => this.setState({ reversIcon: !this.state.reversIcon });

  startCheckStatus = () => this.setState({ checkStatus: true });
  stopCheckStatus = () => this.setState({ checkStatus: false });

  onDrop = (acceptedFiles, rejectedFiles) => {
    acceptedFiles.length >= 1 &&
      this.setState({
        open: true,
        checkStatus: false,
        announceExitModal: false,
      });
    this.props.setUploading(acceptedFiles);
    rejectedFiles.length >= 1 &&
      this.setState({ showUnsupportedFileErrorInModal: true }, () => {
        setTimeout(() => {
          this.setState({ showUnsupportedFileErrorInModal: false });
        }, 5000);
      });
    acceptedFiles.length < 1 &&
      rejectedFiles.length >= 1 &&
      this.setState({ showUnsupportedFileError: true }, () => {
        setTimeout(() => {
          this.setState({ showUnsupportedFileError: false });
        }, 5000);
      });
  };

  render() {
    return (
              <React.Fragment>
                <Helmet>
                  <title>{webTitle}</title>
                </Helmet>
                <Announcements message={this.state.announceTitle} />
                <Dropzone ref={dropRef} accept={this.accept()} onDrop={this.onDrop}>
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <DropzoneContent getRootProps={getRootProps} isDragActive={isDragActive} props={this.props} getInputProps={getInputProps} state={this.state} accept={this.accept}/>
                  )}
                </Dropzone>
                <div className="table-outer-container">
                  <div className="table-outer-container-child">
                    {this.props.store.userStatus.submissions.length > 0 && !this.checkIfSubmissionContainsOnlyTranscriptions() && (
                      <h2 className="h">{this.props.t('documentH2')}</h2>
                    )}
                  </div>
                  {this.props.store.userStatus.submissions.length > 0 && (
                    <DocumentTable getStatus={this.getStatus} toggleConfirmation={this.toggleConfirmation} props={this.props} showCategory={this.state.showCategory} checkIfSubmissionContainsOnlyTranscriptions={this.checkIfSubmissionContainsOnlyTranscriptions}/>
                  )}
                </div>
                {this.state.open && <Modal
                  open={this.state.open}
                  onClose={() => this.closeModal()}
                  files={this.props.store.userStatus.uploading}
                  dropRef={dropRef}
                  startCheckStatus={this.startCheckStatus}
                  stopCheckStatus={this.stopCheckStatus}
                  showUnsupportedFileErrorFromDocument={
                    this.state.showUnsupportedFileErrorInModal
                  }
                  announceExitModal={this.state.announceExitModal}
                  orgId={this.props.store.user.organizationId}
                  selectedEngine={this.state.selectedEngine}
                  engineListState={this.state.engineListState}
                  handleEngineChange={this.handleEngineChange}
                  loadingEngines={this.state.loadingEngines}
                  disabledEngineDropdown={this.state.disabledEngineDropdown}
                  categoriesListState={this.state.categoriesListState}
                  loadingCategories={this.state.loadingCategories}
                  disabledCategoryDropdown={this.state.disabledCategoryDropdown}
                  isCategoryMandatory={this.state.isCategoryMandatory}
                  showCategory={this.state.showCategory}
                  uiAssets={this.props.store.user.organization.uiAssets}
                ></Modal>}
                {this.state.showConfirmation && (
                  <ConfirmDelete
                    toggle={this.toggleConfirmation}
                    confId={this.state.confId}
                    getStatus={this.getStatus}
                    colors={{
                      background:
                        this.props.store.user.organization.uiAssets
                          .basicButtonBackground,
                      text: this.props.store.user.organization.uiAssets.basicButtonText,
                      hover:
                        this.props.store.user.organization.uiAssets
                          .basicButtonBackgroundHover,
                    }}
                  ></ConfirmDelete>
                )}
              </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ store: state });

const mapDispatchToProps = (dispatch) => ({
  setSource: (source) => dispatch(setSource(source)),
  setTarget: (target) => dispatch(setTarget(target)),
  setUserStatus: (status) => dispatch(setUserStatus(status)),
  setUploading: (files) => dispatch(setUploading(files)),
  rmUploading: (files) => dispatch(rmUploading(files)),
  selectFile: (fileId) => dispatch(selectFile(fileId)),
  deselectFile: (fileId) => dispatch(deselectFile(fileId)),
  rmSelected: () => dispatch(rmSelected()),
  setSubmissions: (submissions) => dispatch(setSubmissions(submissions)),
  selectAll: () => dispatch(selectAll()),
  deselectAll: () => dispatch(deselectAll()),
  setDone: (done) => dispatch(setDone(done)),
  setInProgress: (inpr) => dispatch(setInProgress(inpr)),
  rmPid: (pid) => dispatch(rmPid(pid)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Document)
);
