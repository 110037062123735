import {Form, Input } from "antd";
import React from "react";

const ClientsIdField = ({org}) => {
    return (
        <Form.Item
            label="Project A Clients ID"
            name="projectAClientID"
            initialValue={org.projectAClientID}
        >
            <Input/>
        </Form.Item>
    );
};

export default ClientsIdField;