const DownloadAllIconComponent = ({accessibility, disabled}) => {
	let fill = "#323232";
	if (disabled) {fill = "#8A8A8A"}
	
	return(
		<svg title={accessibility} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<desc>{accessibility}</desc>
			<path d="M20.54 5.23L19.15 3.55C18.88 3.21 18.47 3 18 3H6C5.53 3 5.12 3.21 4.84 3.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V6.5C21 6.02 20.83 5.57 20.54 5.23ZM6.24 5H17.76L18.57 5.97H5.44L6.24 5ZM5 19V8H19V19H5ZM13.45 10H10.55V13H8L12 17L16 13H13.45V10Z" fill={fill}/>
		</svg>
)
};

export default DownloadAllIconComponent;