import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './quickLinks.css';

class QuickLinks extends Component {

  makeQuickLink = (quickLink) => {
    let ql
    let currentLang = this.props.i18n.language.substring(0,2)
    if(!!quickLink.label){
      if(!!quickLink.label[currentLang]){
        ql = (
          <a href={quickLink.link} target="_blank" rel="noopener noreferrer">
            {quickLink.label[currentLang]}
          </a>
        )
      }
      else if('string' === typeof quickLink.label ) {
        ql = (
          <a href={quickLink.link} target="_blank" rel="noopener noreferrer">
            {quickLink.label}
          </a>
        )
      } else if (!!quickLink.label["en"]){
        ql = (
          <a href={quickLink.link} target="_blank" rel="noopener noreferrer">
            {quickLink.label["en"]}
          </a>
        )
      }
      else {
        return <></>
      }
    } else {
      return <></>
    }
    if(!!quickLink.tippy){
      if(!!quickLink.tippy[currentLang]){
        return (
          <Tippy placement="bottom" content={quickLink.tippy[currentLang] }>
            {ql}
          </Tippy>
        )
      } else if('string' === typeof quickLink.tippy) {
        return (
          <Tippy placement="bottom" content={quickLink.tippy}>
            {ql}
          </Tippy>
        )
      } else if(!!quickLink.tippy["en"]) {
        return (
          <Tippy placement="bottom" content={quickLink.tippy["en"] }>
            {ql}
          </Tippy>
        )
      } else {
        return ql
      }
    } else {
      return ql
    }
  }

  render() {
    return (
      <div className="quickLinksContainer"
        style={{
          backgroundColor: this.props.quickLinksBackground,
          color: this.props.quickLinksForeground,
        }}>
        <div className="quickLinks">
          <span className="quickLinksLabel">{this.props.t("quickLinksLabel")}</span>
          <ul className="quickLinksList">
            {this.props.quickLinks.map( (quickLink, i) => {
              return <li key={i}>
                {this.makeQuickLink(quickLink)}
              </li>
            })}
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({ store: state });

export default withTranslation()(
  connect(mapStateToProps)(QuickLinks)
);
