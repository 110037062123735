import {Form, Input } from "antd";
import React from "react";

const SalesTicketField = ({org}) => {
    return (
        <Form.Item
            label="Sales ticket"
            name="salesTicket"
            initialValue={org.salesTicket}
        >
            <Input placeholder="https://techqa1.translations.com/browse/SALE-XXXX"/>
        </Form.Item>
    );
};

export default SalesTicketField;