import React from "react";
import EllipseTranslation from "../../icons/EllipseTranslationComponent";

const TranslationInProgressMessage = ({translate}) => {
    return (
        <>
            <div><EllipseTranslation accessibility={translate('documentSubRowChildStatusTranslating')} color={"#5F5F5F"}/></div>
            <p className="translation-successful-text">{translate('documentSubRowChildStatusTranslating')}</p>
        </>
    );
};

export default TranslationInProgressMessage;