import modifyOrganizationById from "../../../apiCalls/Usermanagement/EditOrgPage/modifyOrganizationById";

function RGBToHex(r, g, b) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);

    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;

    return '#' + r + g + b;
}

export const loadStyle = (parsed) => {
    const RGBA_REGEX = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/g;
    const HEX_REGEX = /#([A-Fa-f0-9]{3}){1,2}/g;

    for (const [key, value] of Object.entries(parsed)) {
        if (
            !!value &&
            (typeof value === 'string' || value instanceof String)
        ) {
            let valueNoWS = value.replace(/\s+/g, '');
            let hexMatch = valueNoWS.match(HEX_REGEX);
            if (!!hexMatch) {
                if (hexMatch.length === 1) {
                    parsed[key] = hexMatch[0];
                } else {
                    parsed[key] = value;
                }
            } else {
                let rgba = valueNoWS.match(RGBA_REGEX);
                if (!!rgba && rgba.length >= 3) {
                    parsed[key] = RGBToHex(rgba[0], rgba[1], rgba[2]);
                } else {
                    parsed[key] = value;
                }
            }
        }
    }
}

export const updateOrgStyle = (org, uiAssets, props) => {
    new Promise((resolve, reject) => {
        let tempOrg = { ...org };
        let tempUiAssets = { ...uiAssets };
        tempUiAssets.navbarBottomBorder = `5px solid ${tempUiAssets.navbarBottomBorder}`;
        tempOrg.uiAssets = JSON.stringify(JSON.stringify(tempUiAssets));
        let body = JSON.stringify(tempOrg);
        modifyOrganizationById(body, props.match.params.orgId)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Request failed');
                }
            })
            .then((json) => {
                if (json.success) {
                    resolve(json);
                } else {
                    throw new Error('Request failed');
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    })
}