import React, { Component } from 'react';
import { connect } from 'react-redux';
import he from 'he';
import { withTranslation } from 'react-i18next';
import { textAreaStyle } from './selectStyle';
import {setEngines, setSource, setTarget} from '../../redux/actions';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './style.css';
import Announcements from '../../components/Announcements';
import { rtl, scriptMap } from '../../components/Languages/LanguagesList';
import { Helmet } from 'react-helmet';
import { ReactComponent as TransliterateBtnIcon } from './icons/transliterate.svg';
import TextareaAutosize from 'react-textarea-autosize';
import LanguageSelection from '../../components/LanguageSelection';
import Spinner from '../../components/spinner';
import { Prompt } from 'react-router';
import downloadFile from "../../apiCalls/Text/downloadFile";
import getTranslatedText from "../../apiCalls/Text/getTranslatedText";
import getTransliteratedText from "../../apiCalls/Usermanagement/Forms/getTransliteratedText";
import getTranscribedText from "../../apiCalls/Text/getTranscribedText";
import uploadFile from "../../apiCalls/Text/uploadFile";
import {GLAI_URL, webTitle} from "../../utils/config";
import EngineSelection from "../../components/EngineSelection";
import getEnginesByOrg from "../../apiCalls/components/EngineSelection/getEnginesByOrg";
import {
  searchForEnginesFilteredByLangs,
  searchForProfilesFilteredByLangs
} from "../../components/EngineSelection/searchForProfilesAndEngines";
import {eliminateDuplicates} from "../../utils/eliminateDuplicates";
import SwapIconComponent from '../../icons/SwapIconComponent';
import CopyTextIconComponent from "../../icons/CopyTextIconComponent"
import TrashCanIcon from '../../icons/TrashCanIconComponent';
import CategorySelection from "../../components/CategorySelection";
import CopyTransactionRecordBtnIcon from '../../icons/CopyTransactionRecord';
import InformationIconComponent from "../../icons/InformationIconComponent";
import QualityScorePositiveFace from "../../icons/QualityScorePositiveFace";
import QualityScoreNegativeFace from "../../icons/QualityScoreNegativeFace";
import QualityScoreNeutralFace from "../../icons/QualityScoreNeutralFace";
import QualityControlModal from "./qualityControlModal";

class Text extends Component {
  componentDidMount() {
      document.title = this.props.t('textTitle');
      if (this.props.store.globalOptions.sourceLanguage === 'auto') {
        this.setState({ wasAuto: true });
      }
      setTimeout(
        () => this.setState({ announceTitle: this.props.t('textTitle') }),
        30
      );
      if (!this.props.store.user.id) {
        this.props.history.push('/login');
      }

      if (
        this.props.store.user.id &&
        this.props.store.globalOptions.sourceLanguage === ''
      ) {
        this.props.setSource(this.props.store.user.organization.defaultSource);
        this.props.setTarget(this.props.store.user.organization.defaultTarget);
      }

      this.populateEngineDropdown();
      this.populateCategoryDropdown();

      let categoryAndTextAreReady = this.state.textToTranslate !== '' && (!this.state.isCategoryMandatory || (this.state.isCategoryMandatory && !!this.state.selectedCategory.value));
      //checking if source and target are the same
      if(!!this.props.store.globalOptions.sourceLanguage && !!this.props.store.globalOptions.targetLanguage && (this.props.store.globalOptions.sourceLanguage === this.props.store.globalOptions.targetLanguage)){ //source and target cannot be the same
        this.setState({
          srcTrgEqual: true,
        });
      } else {
        this.setState({
          srcTrgEqual: false,
          sameSrcTarError: ""
        });
        this.filterEnginesByLang(categoryAndTextAreReady); // in case src or tgt change, we need to look for the engines of the new combination
        let filteredEngines = [], filteredProfiles=[];
        if(this.props.store.globalOptions.sourceLanguage === 'auto' || this.props.store.globalOptions.sourceLanguage === 'multi') {
          this.setState({disabledEngineDropdown: true,
            selectedEngine: ""}); // in case the src lang is set to "Detect language" or "multilingual" the dropdown will be disabled
        } else {
          if (this.props.store.globalOptions.engines) {
            this.setState({disabledEngineDropdown: false});
            filteredProfiles = searchForProfilesFilteredByLangs(this.props.store.globalOptions.engines, this.props.store.globalOptions);
            filteredEngines = searchForEnginesFilteredByLangs(this.props.store.globalOptions.engines, this.props.store.globalOptions);
            if (this.props.organization.uiAssets.engineDropdownShowAll) { //showing both engines and profiles option selected
              if (filteredProfiles.length > 0) { // in case there are profiles we add them to the engines
                const profilesAndEnginesListNotFiltered = filteredProfiles.concat(filteredEngines);
                const profilesAndEnginesList = eliminateDuplicates(profilesAndEnginesListNotFiltered, "key");
                // selected engine is the first one in the list
                this.setState({engineListState: profilesAndEnginesList,selectedEngine: profilesAndEnginesList[0]});
              } else {  // in case there are no profiles we only show engines
                this.setState({engineListState: filteredEngines,selectedEngine: filteredEngines[0]});
              }
            } else if (this.props.organization.uiAssets.engineDropdownShowProfilesOnly) { //showing only profiles option selected
              if (filteredProfiles.length > 0) {
                this.setState({engineListState: filteredProfiles,selectedEngine: filteredProfiles[0]});
              } else {
                this.setState({disabledEngineDropdown: true});
              }
            } else if (!this.props.organization.uiAssets.engineDropdownShowAll && !this.props.organization.uiAssets.engineDropdownShowProfilesOnly){ // in case the engine dropdown is not set in the user management not to be displayed the dropdown will be disabled
              this.setState({disabledEngineDropdown: true,
                selectedEngine: ""});
            }
          }
        }
      }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.t !== prevProps.t ){
      this.populateCategoryDropdown();
      if(!this.state.selectedCategory.value) {
        this.setState({ selectedCategory: '' }); // resetting the selected category to none, so the placeholder in the app language will be displayed
      }
    }
    if(this.state.selectedCategory.value && prevState.selectedCategory !== this.state.selectedCategory) { //in case category is selected, error is resetted
      this.setState({ mandatoryCategoryError: '' });
    }

    if (
        (prevProps.store.globalOptions.targetLanguage !== this.props.store.globalOptions.targetLanguage) //in case tgt changes
    ) {
      let categoryAndTextAreReady = this.state.textToTranslate !== '' && (!this.state.isCategoryMandatory || (this.state.isCategoryMandatory && !!this.state.selectedCategory.value));
      //checking if source and target are the same
        if(this.props.store.globalOptions.sourceLanguage !== this.props.store.globalOptions.targetLanguage) {
          this.filterEnginesByLang(categoryAndTextAreReady); // in case src or tgt change, we need to look for the engines of the new combination
        }
    }
    if (
        (prevProps.store.globalOptions.targetLanguage !== this.props.store.globalOptions.targetLanguage || //in case src or tgt change
            prevProps.store.globalOptions.sourceLanguage !== this.props.store.globalOptions.sourceLanguage)
    ) {
      if (this.props.store.globalOptions.sourceLanguage === this.props.store.globalOptions.targetLanguage) { //source and target cannot be the same
        this.setState({
          srcTrgEqual: true,
        });
      } else {
        this.setState({
          srcTrgEqual: false,
          sameSrcTarError: ""
        },() => this.filterEnginesByLang())
      }
    }

      if (
        prevState.textToTranslate !== this.state.textToTranslate &&
        this.state.textToTranslate === ''
      ) {
        this.props.setSource(this.props.store.user.organization.defaultSource);
      }
      if (!this.props.store.user.id) {
        this.props.history.push('/login');
      }
      if (
        this.state.srcTrgEqual &&
        (!prevState.srcTrgEqual || this.props.t !== prevProps.t)
      ) {
        this.setState({
          sameSrcTarError: this.props.t('WFM-04-SRCTRGSAME'),
        });
      } else if (prevState.srcTrgEqual && !this.state.srcTrgEqual) {
        this.setState({ unsupportedFileMessage: '' });
      } else if ( prevProps.store.globalOptions.targetLanguage !==
          this.props.store.globalOptions.targetLanguage &&
          this.state.textToTranslate !== '' && (this.state.isCategoryMandatory && !this.state.selectedCategory.value)) { //show category error in case there is no category selected when user selects target lang
        this.showCategoryError();
      }

    if (prevProps.store.globalOptions.sourceLanguage !== this.props.store.globalOptions.sourceLanguage) { //when source changes
      if(this.props.store.globalOptions.sourceLanguage === 'auto' || this.props.store.globalOptions.sourceLanguage === 'multi') { //in this case no engine dropdown should be displayed
        this.setState({
          disabledEngineDropdown: true,
        });
      } else {
        this.setState({
          disabledEngineDropdown: false,
        });
      }
    }
  }

  state = {
    sourceLanguage: '',
    textToTranslate: '',
    targetLanguage: '',
    textTranslated: '',
    textTransliterated: '',
    sourceTextAreaRef: '',
    textType: 'text',
    matchedProfile: '',
    processing: false,
    tm: 'none',
    glossary: 'none',
    loading: false,
    loadingEngines: true,
    done: false,
    swapMessage: '',
    rows: 0,
    announceTextCleared: false,
    hover: false,
    copyHover: false,
    unsupportedFileMessage: '',
    wasAuto: false,
    translationPropertiesChanged: false, //Determines if translation will be triggered and translation button is enabled, is set to true if either text content (not counting new lines), source or target language were changed and false on translation
    srcTrgEqual: false,
    draggingFileOverSource: false,
    uploadingWAVFile: false,
    WAVError: undefined,
    WAVFileName: '',
    disabledEngineDropdown: false,
    showCopiedTransactionRecordsMessage: false,
    showCopiedTranslatedText: false,
    showCopiedSourceText:false,
    engineListState: [],
    selectedEngine: "",
    reachedQuotaLimitError: "",
    sameSrcTarError: "",
    orgExpiredError: "",
    mandatoryCategoryError: "",
    categoriesListState: [],
    selectedCategory: {},
    loadingCategories: true,
    isCategoryMandatory:  this.props.store.user.organization.uiAssets.mandatoryCategory,
    showCategory: this.props.store.user.organization.uiAssets.showCategory,
    workflowId: "",
    textToTranslateLength: 0,
    maxCharsAllowed: 10000,
    score: 0,
    indicator: "",
    isInfoQualityModalOpen: false
  };

  filterEnginesByLang = (categoryAndTextAreReady) => {
    let filteredEngines = [], filteredProfiles=[];
    if(this.props.store.globalOptions.sourceLanguage === 'auto' || this.props.store.globalOptions.sourceLanguage === 'multi') {
      this.setState({disabledEngineDropdown: true,
        selectedEngine: ""},() => {
          if (categoryAndTextAreReady) {
            this.translateText();
          }
        }); // in case the src lang is set to "Detect language" or "multilingual" the dropdown will be disabled
      
    } else {
      if (this.props.store.globalOptions.engines) {
        this.setState({disabledEngineDropdown: false});
        filteredProfiles = searchForProfilesFilteredByLangs(this.props.store.globalOptions.engines, this.props.store.globalOptions);
        filteredEngines = searchForEnginesFilteredByLangs(this.props.store.globalOptions.engines, this.props.store.globalOptions);
        if (this.props.organization.uiAssets.engineDropdownShowAll) { //showing both engines and profiles option selected
          if (filteredProfiles.length > 0) { // in case there are profiles we add them to the engines
            const profilesAndEnginesListNotFiltered = filteredProfiles.concat(filteredEngines);
            const profilesAndEnginesList = eliminateDuplicates(profilesAndEnginesListNotFiltered, "key");
            // selected engine is the first one in the list
            this.setState({engineListState: profilesAndEnginesList,selectedEngine: profilesAndEnginesList[0]},() => {
              if (categoryAndTextAreReady) {
                this.translateText();
              }});
          } else {  // in case there are no profiles we only show engines
            this.setState({engineListState: filteredEngines,selectedEngine: filteredEngines[0]},() => {
              if (categoryAndTextAreReady) {
                this.translateText();
              }});
          }
        } else if (this.props.organization.uiAssets.engineDropdownShowProfilesOnly) { //showing only profiles option selected
          if (filteredProfiles.length > 0) {
            this.setState({engineListState: filteredProfiles,selectedEngine: filteredProfiles[0]},() => {
              if (categoryAndTextAreReady) {
                this.translateText();
              }});
          } else {
            this.setState({disabledEngineDropdown: true,selectedEngine:filteredEngines[0],engineListState:filteredEngines},() => {
              if (categoryAndTextAreReady) {
                this.translateText();
              }});
          }
        } else { // in case the engine dropdown is set in the user management not to be displayed the dropdown will be disabled
          this.setState({disabledEngineDropdown: true,
            selectedEngine: ""},() => {
            if (categoryAndTextAreReady) {
              this.translateText();
            }
          });
        }
      }
    }
  }

  populateEngineDropdown = () => {
    getEnginesByOrg(this.props.store.user.organizationId)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          if (json) {
            this.props.setEngines(json); //saving engines in globalOptions
            this.setState({loadingEngines: false});
            this.filterEnginesByLang();
          }
        })
        .catch((error) => {
          console.error(error);
        })
  }

  populateCategoryDropdown = () => {
    if(this.props.store.user.organization.categories){
      let categories = this.props.store.user.organization.categories;
      let categoriesList = [{key: "", //first element is "no category"
          value: "",
          label: this.props.t('categoryPlaceholder')}];
      this.setState({loadingCategories: false});
      for (let i = 0; i < categories.length; i++) { // building object for select
        categoriesList.push({
          key: categories[i],
          value: categories[i],
          label: categories[i],
        })
      }
      this.setState({categoriesListState: categoriesList});
   }
  }

  openInfoQualityControlModal = () => {
    this.setState({isInfoQualityModalOpen: true});
  }

  closeInfoQualityControlModal = () =>  {
    this.setState({isInfoQualityModalOpen: false});
  }

  clearSourceText = () => {
    this.setState({
      textToTranslate: '',
      textTranslated: '',
      textTransliterated: '',
      announceTextCleared: true,
      unsupportedFileMessage: '',
      score: '',
      indicator: ''
    });
  };

  handleChange = (e) => {
    if (e.target.value.trim() !== this.state.textToTranslate.trim()) {
      this.setState({ translationPropertiesChanged: true });
    }
    //if textarea has no content, reset UI
    if (e.target.value.trim().length === 0) this.clearSourceText();
    this.setState({
      textToTranslate: e.target.value,
      announceTextCleared: false,
      unsupportedFileMessage: '',
    });
    //counting chars
    let letterCount = e.target.value.length;
    this.setState({
      textToTranslateLength: letterCount,
    });
  };

 handleEngineChange = (option) => {
   this.setState({ selectedEngine: option });
   this.setState({ translationPropertiesChanged: true });
  }

  handleCategoryChange = (option) => {
    this.setState({ selectedCategory: option });
    this.setState({ translationPropertiesChanged: true });
  }

  modifyText = (e) => {
    this.setState({textTranslated: e.target.value});
  }

  handleKey = (e) => {
   //enter key will send translation only if category is mandatory and selected or if not mandatory
    return e.keyCode === 13 &&
      this.state.translationPropertiesChanged && ((this.state.isCategoryMandatory && this.state.selectedCategory.value) || !this.state.isCategoryMandatory) &&
      !this.state.textToTranslate.split('\n').every(this.block)
      ? this.translateText()
      : null;
  };

  block = (el) => el === '';

  showCategoryError = () => {
    this.setState({mandatoryCategoryError: this.props.t('mandatoryCategoryError')})
  }

  transliterateText = () => {
    let url = `${GLAI_URL}/apigateway/transliteration?`;
    let params = new URLSearchParams();
    params.append('language', this.props.store.globalOptions.targetLanguage);
    params.append(
      'fromScript',
      scriptMap[this.props.store.globalOptions.targetLanguage]
    );
    params.append('toScript', 'latn');
    params = params.toString();
    url = url + params;
    let body = JSON.stringify([{ Text: this.state.textTranslated }]);
    getTransliteratedText(url, body)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        this.setState({ textTransliterated: json.message[0]['Text'] });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  translateText = () => {
    let category = "";
    if (!this.state.srcTrgEqual) {
      this.setState({
        translationPropertiesChanged: false,
        processing: true,
        textTranslated: '',
        unsupportedFileMessage: '',
        sameSrcTarError: '',// resetting error to 0 when langugages are different
        mandatoryCategoryError: ''
      });
      if(this.state.selectedCategory.value){
        category = this.state.selectedCategory.value
      }
      getTranslatedText(this.props.store.globalOptions.sourceLanguage, this.props.store.globalOptions.targetLanguage, this.state.textType, this.state.textToTranslate, this.state.selectedEngine, category)
          .then((res) => {
            return res.json();
          })
          .then((json) => {
          if (json.success) {
            let decoded = he.decode(json.message[0]['Text'])
            this.setState({
              textTranslated: decoded,
              textTransliterated: '',
              workflowId : json.workflowId,
            });

            if(json.message[0].score) { //in case the backend returns score
              this.setState({
                score: json.message[0].score,
                indicator: json.message[0].indicator
              });
            } else { //resetting score if none is got from backend
              this.setState({
                score: "",
                indicator: ""
              });
            }

            let detectedLanguage = json.message[0]['detectedLanguage'];
            if (!detectedLanguage) {
              this.alertUnsupportedSource()
            }
            else if (this.props.store.globalOptions.sourceLanguage !== "multi") {
              this.props.setSource(json.message[0]['detectedLanguage'])
            }
            this.setState({ processing: false });
          } else {
            if (json.message === "organization reached translation limit" || json.message === "user reached translation limit") {
              this.setState({reachedQuotaLimitError: this.props.t('reachedQuotaLimitError')});
            } else if (json.message === "organization " + this.props.store.user.organization.id + " expired") {
              this.setState({orgExpiredError: this.props.t('orgExpiredError')});
            } else if (json.errorCode === "WFM-04-SRCTRGSAME") {
              this.setState({
                sameSrcTarError: this.props.t('WFM-04-SRCTRGSAME'),
              });
            } else {
              this.setState({
                unsupportedFileMessage: this.props.t('textTranslationError'),
              })
            }
            throw new Error(json.errorCode);
          }
        })
        .catch((error) => {
          console.error(error);
          let errorMessage = this.props.t(error.message);
          if (errorMessage === 'common error') {
            errorMessage = this.props.t('textTranslationError');
          }

          this.setState({
            unsupportedFileMessage: errorMessage,
            translationPropertiesChanged: true,
          });
        })
        .finally(() => {
          this.setState({ processing: false });
        });
    }
  };

  copyText = async (val) => {
    try {
      await navigator.clipboard.writeText(val);
      this.setState({ showCopiedSourceText: true })
    } catch (e) {
      console.error('failed to copy source text: ', e);
    }
  };

  copyTransactionRecord  = async() => {
    try {
      const transactionRecord = 
      `[` + this.state.workflowId + `#` + this.props.store.user.email + `#` + this.props.globalOptions.sourceLanguage + `#` 
      + this.props.globalOptions.targetLanguage + `#` +  this.state.textToTranslate + `#` + this.state.textTranslated + `]`
        
      await navigator.clipboard.writeText(transactionRecord);
      this.setState({ showCopiedTransactionRecordsMessage: true });      
    
    } catch (e) {
      console.error('failed to copy transcription record, ', e);
    }
  };

  copyTranslatedText  = async() => {
    try {
        
      await navigator.clipboard.writeText(this.state.textTranslated);
      this.setState({ showCopiedTranslatedText: true });      
    
    } catch (e) {
      console.error('failed to copy translated text, ', e);
    }
  };

  swap = () => {
    if (this.props.store.globalOptions.sourceLanguage !== 'auto' && this.props.store.globalOptions.sourceLanguage !== 'multi') {
      this.setState({ swapMessage: this.props.t('modalTippySwap') }, () =>
        setTimeout(() => this.setState({ swapMessage: '' }), 200)
      );
      if (
        this.state.textToTranslate === '' &&
        this.state.textTranslated === ''
      ) {
        let src = this.props.store.globalOptions.sourceLanguage,
          tgt = this.props.store.globalOptions.targetLanguage;

        this.props.setSource(tgt);
        this.props.setTarget(src);
      } else if (
        this.state.textToTranslate !== '' &&
        this.state.textTranslated === ''
      ) {
        let src = this.props.store.globalOptions.sourceLanguage,
          tgt = this.props.store.globalOptions.targetLanguage;
        this.setState({ textToTranslate: '' });
        this.props.setSource(tgt);
        this.props.setTarget(src);
      } else if (
        this.state.textToTranslate !== '' &&
        this.state.textTranslated !== ''
      ) {
        //do the trick
        let src = this.props.store.globalOptions.sourceLanguage,
          tgt = this.props.store.globalOptions.targetLanguage;
        this.props.setSource(tgt);
        this.props.setTarget(src);
        this.setState({
          textToTranslate: this.state.textTranslated,
          textTranslated: '',
        });
      }
    }
  };

  selectSourceCheckauto = (e) => {
    this.props.setSource(e);
    this.setState({
      translationPropertiesChanged: true,
      unsupportedFileMessage: '',
    });
    e === 'auto'
      ? this.setState({ wasAuto: true })
      : this.setState({ wasAuto: false });
  };

  alertUnsupportedSource = () => {
    this.setState(
      {
        unsupportedFileMessage: 'unsupportedSourceLang',
        textTranslated: '',
      },
      () => {
        setTimeout(() => this.setState({ unsupportedFileMessage: '' }), 5000);
      }
    );
  };

  uploadWAV = async (file, cb) => {
    let body = new FormData();
    body.append('files', file);
    uploadFile(body, this.props.globalOptions.sourceLanguage,this.state.selectedCategory.value)
      .then((res) => {
        if (!res.ok) throw new Error('Request failed');
        return res.json();
      })
      .then((json) => {
        if (!json.success) throw new Error('Unsuccessful');
        cb(json.fileIds[0].fileID);
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          unsupportedFileMessage: 'Upload failed',
          uploadingWAVFile: false,
        });
      });
  };

  waitUntilTranscribedAndGetBlob = async (fileId, cb) => {
    setTimeout(async () => {
      getTranscribedText(fileId)
        .then((res) => {
          if (!res.ok) {
            throw new Error('Request failed');
          } else {
            return res.json();
          }
        })
        .then((json) => {
          if (
            json.status === 'TextTranscribing' ||
            json.status === 'Uploading'
          ) {
            this.waitUntilTranscribedAndGetBlob(fileId, cb);
          } else {
            if (json.status === 'TextTranscribed') {
              // Download file
              downloadFile(fileId)
                .then((res) => {
                  if (res.ok) {
                    return res.blob();
                  }
                })
                .then((blob) => {
                  cb(blob);
                })
                .catch((err) => {
                  console.error(err);
                  this.setState({
                    unsupportedFileMessage: 'Request failed',
                    uploadingWAVFile: false,
                  });
                });
            } else {
              throw new Error('Request failed');
            }
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            unsupportedFileMessage: 'Request failed',
            uploadingWAVFile: false,
          });
        });
    }, 7000);
  };

  handleFile = (file) => {
    try {
      if (!file.name.toLowerCase().endsWith('.wav')) {
        throw new Error('TT_WAV_ERROR_FILE_TYPE');
      }
      if (file.size > 200000000) {
        throw new Error('TT_WAV_ERROR_FILE_SIZE');
      }
      if (this.props.globalOptions.sourceLanguage === 'auto') {
        throw new Error('TT_WAV_ERROR_AUTO_DETECT');
      }
      this.setState({ WAVFileName: file.name, uploadingWAVFile: true });

      this.uploadWAV(file, (fileId) =>
        this.waitUntilTranscribedAndGetBlob(
          fileId,
          this.convertBlobToTxtAndAddToSourceField
        )
      );
    } catch (e) {
      console.error(e);
      this.setState({
        unsupportedFileMessage: e.message,
        uploadingWAVFile: false,
      });
    }
  };

  addToSourceField = (txt) =>
    this.setState({
      textToTranslate: this.state.textToTranslate + txt,
      uploadingWAVFile: false,
      translationPropertiesChanged: true,
    });

  convertBlobToTxtAndAddToSourceField = (blob) => {
    let myReader = new FileReader();
    let addTxt = this.addToSourceField;
    myReader.addEventListener('loadend', function (e) {
      addTxt(e.target.result);
    });
    myReader.readAsText(blob);
  };

  checkWAVEnabled = (cb, e) => {
    if (
      !!this.props.organization.uiAssets.extraFormats &&
      this.props.organization.uiAssets.extraFormats.some(
        (eF) => eF.trim() === '.wav'
      )
    ) {
      cb(e);
    }
  };

  fileDroppedIntoSource = (e) => {
    e.preventDefault();

    this.setState({
      unsupportedFileMessage: '',
      draggingFileOverSource: false,
    });

    try {
      if (e.dataTransfer.items) {
        let files = [];
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          if (e.dataTransfer.items[i].kind === 'file') {
            files.push(e.dataTransfer.items[i]);
          }
        }
        if (this.state.isCategoryMandatory && !this.state.selectedCategory.value) {
          throw new Error('mandatoryCategoryError');
        }
        if (files.length > 1) {
          throw new Error('TT_WAV_ERROR_MULTIPLE_FILES');
        }
        if (files.length === 0) {
          let textContent = e.dataTransfer.getData('Text');
          if (!!textContent) {
            this.addToSourceField(textContent);
          } else {
            throw new Error('TT_WAV_ERROR_NO_FILES_DETECTED');
          }
        }
        if (e.dataTransfer.items[0].kind === 'file') {
          this.handleFile(e.dataTransfer.items[0].getAsFile());
        }
      } else {
        if (e.dataTransfer.files.length > 1) {
          throw new Error('TT_WAV_ERROR_MULTIPLE_FILES');
        }
        this.handleFile(e.dataTransfer.files[0]);
      }
    } catch (e) {
      console.error(e.message);
      this.setState({
        unsupportedFileMessage: e.message,
        uploadingWAVFile: false,
      });
    }
  };

  onDragEnterSource = (e) => {
    e.preventDefault();
    this.setState({
      draggingFileOverSource: true,
    });
  };

  onDragLeaveSource = (e) => {
    e.preventDefault();
    this.setState({
      draggingFileOverSource: false,
    });
  };

  sourceTextArea = () => (
    <>
      <TextareaAutosize
        aria-label={this.props.t('textAreaAnnouncementBox1')}
        name="txtR"
        minRows="14"
        maxLength={this.state.maxCharsAllowed}
        ref={this.state.sourceTextAreaRef}
        dir={
          rtl.includes(this.props.store.globalOptions.sourceLanguage)
            ? 'rtl'
            : 'ltr'
        }
        lang={this.props.store.globalOptions.sourceLanguage}
        onKeyUp={this.handleKey}
        onChange={this.handleChange}
        value={this.state.textToTranslate}
        placeholder={this.props.t('textSourceTextareaPlaceholder')}
        style={{
          ...textAreaStyle,
        }}
      />
      {this.state.textToTranslate !== '' && (
          <div style={{ display: "flex", justifyContent: "inherit"}}>
            <div style={{ display: "flex"}}>
              {this.state.textToTranslateLength >= this.state.maxCharsAllowed &&
                    (
                        <p style={{color: "#0E23E4", fontWeight: "bold", paddingLeft: "24px"}}>{this.props.t('textTranslationReachedLimit')}</p>
                    )
              }
            </div>
            <div className="sourceTextActionBtns">
              <p style={{ fontWeight: "bold", color: this.state.textToTranslateLength >= this.state.maxCharsAllowed && "#0E23E4"}}>{this.state.textToTranslateLength}/{this.state.maxCharsAllowed}</p>
              <Tippy hideOnClick={false} content={!this.state.showCopiedSourceText ? this.props.t('textCopyTippy'): this.props.t('AnnounceCopiedToClipboard')}>
                <button
                  className="coffeeButton"
                  aria-label={this.props.t('AnnounceCopyTranslatedText')}
                  style={{paddingBottom: "16px", margin: 0}}
                  onClick={() => {
                    this.copyText(this.state.textToTranslate);
                  }}
                  onMouseEnter={() => this.setState({ showCopiedSourceText: false })}
                >
                  <CopyTextIconComponent accessibility={this.props.t('AnnounceCopyTranslatedText')}/>
                </button>
              </Tippy>
              <Tippy content={this.props.t('ClearTextLabelandTippy')}>
                <button
                    className="coffeeButton"
                    style={{paddingBottom: "16px", marginRight: "24px", marginLeft: 0}}
                    aria-label={this.props.t('ClearTextLabelandTippy')}
                    onClick={() => this.clearSourceText()}
                >
                <TrashCanIcon accessibility={this.props.t('ClearTextLabelandTippy')} color={'#333333'}/>
                </button>
              </Tippy>
            </div>
          </div>
      )}
    </>
  );

  render() {
    const { t } = this.props;

    return this.state.loading ? (
      <p>loading...</p>
    ) : (
      <React.Fragment>
        <Prompt
          when={this.state.uploadingWAVFile}
          message={t('TT_WAV_TRANSCRIPTION_STILL_PROCESSING_ALERT')}
        />
        <Helmet>
          <title>{webTitle}</title>
        </Helmet>
        <Announcements message={this.state.announceTitle} />
        <div className="languagesInputs">
          <div className="source">
            <h2 className="visuallyhidden">
              {this.props.t('textH2NavigateLanguageTranslationDropDown')}
            </h2>
            <div className="sourceOr">
              <LanguageSelection source error={this.state.srcTrgEqual} />
            </div>
          </div>
          <Tippy
            content={
              this.props.store.globalOptions.sourceLanguage === 'auto' && t('textTippySwapBlocked') || this.props.store.globalOptions.sourceLanguage === 'multi' && t('textTippySwapBlockedMulti')
                || t('modalTippySwap')
            }
          >
            <button
              className="coffeeButton swapButton"
              onClick={() => this.swap()}
            >
              <SwapIconComponent accessibility={t('modalTippySwap')} />
            </button>
          </Tippy>

          <Announcements message={this.state.swapMessage} />

          <div className="targetLang-dropdown">
              <LanguageSelection target error={this.state.srcTrgEqual} />
          </div>
        </div>

        <div className="textareaWrapper">
          <div
            className="textArea shape"
            style={{
              position: 'relative',
            }}
          >
            <h2 className="visuallyhidden">
              {this.props.t('textH2NavigateTextTranslationArea')}
            </h2>
            <div
              className={`sourceText${
                  (this.state.draggingFileOverSource || this.state.textToTranslateLength >= this.state.maxCharsAllowed) ? ' highlightForDnD' : ''
              }`}
              onDragEnter={(e) =>
                this.checkWAVEnabled(this.onDragEnterSource, e)
              }
              onDragOver={(e) =>
                this.checkWAVEnabled((e) => e.preventDefault(), e)
              }
              onDragLeave={(e) =>
                this.checkWAVEnabled(this.onDragLeaveSource, e)
              }
              onDrop={(e) =>
                this.checkWAVEnabled(this.fileDroppedIntoSource, e)
              }
            >
              {this.sourceTextArea()}
              {this.state.uploadingWAVFile && (
                <div className="uploadingWAVArea">
                  <>
                    <Spinner width="24px" height="24px" margin="0" />
                    Transcribing {this.state.WAVFileName}
                  </>
                </div>
              )}
            </div>
            {/*showing border color for quality score*/}
            <div className="targetText" style={{border: this.props.organization.uiAssets.qualityScore && (this.state.indicator === "green" ? 'solid 3px #36A93F': this.state.indicator === "red" ? 'solid 3px #E4100E': this.state.indicator === "orange" ? 'solid 3px #F6610C': 'solid 1px #dadee1')}}>
              <TextareaAutosize
                aria-label={this.props.t('textAreaAnnouncementBox2')}
                lang={this.props.store.globalOptions.targetLanguage}
                style={{
                  ...textAreaStyle,
                }}
                dir={
                  rtl.includes(this.props.store.globalOptions.targetLanguage)
                    ? 'rtl'
                    : 'ltr'
                }
                value={this.state.textTranslated}
                onChange={this.modifyText}
                placeholder={
                  this.state.processing
                    ? this.props.t('processingRequestMsg')
                    : ''
                }
              />
              {this.state.textTransliterated !== '' && (
                <TextareaAutosize
                  className="targetTransliteration"
                  style={{
                    ...textAreaStyle,
                  }}
                  value={this.state.textTransliterated}
                  readOnly
                />
              )}

              {this.state.textTranslated && (
                  <div>
                    {this.props.organization.uiAssets.qualityScore && this.state.indicator !== "" && (<div style={{display: "flex", paddingLeft: "16px"}}>
                      <div>
                        <Tippy
                            interactive={true}
                            content={<div><p style={{textAlign: "left"}}>{this.props.t('infoQualityControlFeatureTooltip')}</p><button className="infoQualityControlBtn" onClick={this.openInfoQualityControlModal}>{this.props.t('infoQualityControlFeatureTooltipLearnMore')}</button></div>}>
                          <button className="coffeeButton infoTextIcon">
                            {
                              <InformationIconComponent accessibility={"Information"} size={24}/>
                            }
                          </button>
                        </Tippy>
                      </div>

                      <QualityControlModal isInfoQualityModalOpen={this.state.isInfoQualityModalOpen} closeInfoQualityControlModal={this.closeInfoQualityControlModal} translate={this.props.t}/>
                      <button className="coffeeButton faceIcon">
                          {
                            this.state.indicator === "green" &&
                            <QualityScorePositiveFace accessibility={this.props.t('translationQualityGood')} color={"#36A93F"} size={24}/>
                          }
                          {this.state.indicator === "red" &&
                              <QualityScoreNegativeFace accessibility={this.props.t('translationQualityBad')} color={"#E4100E"} size={24}/>
                          }
                          {this.state.indicator === "orange" &&
                              <QualityScoreNeutralFace accessibility={this.props.t('translationQualityAverage')} color={"#F6610C"} size={24}/>
                          }
                        </button>
                      {this.state.indicator === "green" &&
                          <p className="translationQualityScoreText translationQualityScoreGood">{this.props.t('translationQualityGood')}</p>
                      }
                      {this.state.indicator === "red" &&
                          <p className="translationQualityScoreText translationQualityScoreBad">{this.props.t('translationQualityBad')}</p>
                      }
                      {this.state.indicator === "orange" &&
                          <p className="translationQualityScoreText translationQualityScoreNeutral">{this.props.t('translationQualityAverage')}</p>
                      }
                    </div>)}
                    <div className="targetTextActionBtns">
                      <Tippy
                          hideOnClick={false}
                          content={!this.state.showCopiedTranslatedText
                              ? this.props.t('textCopyTippy') : this.props.t('AnnounceCopiedToClipboard')
                          }>
                        <button
                            className="coffeeButton copyTextIcon"
                            aria-label={t('AnnounceCopyTranslatedText')}
                            onClick={() => {
                              this.copyTranslatedText();
                            }}
                            onMouseEnter={() => this.setState({ showCopiedTranslatedText: false})}

                        >
                          {
                            <CopyTextIconComponent accessibility={this.props.t('AnnounceCopyTranslatedText')}/>
                          }
                        </button>
                      </Tippy>
                      {!!this.props.organization.uiAssets.hasTransactionRecord && (
                          <Tippy
                              hideOnClick={false}
                              content={!this.state.showCopiedTransactionRecordsMessage
                                  ? this.props.t('AnnounceCopyTransactionRecordText') : this.props.t('AnnounceCopiedToClipboard')
                              }>
                            <button
                                className="coffeeButton copyTransactionRecordIcon"
                                aria-label={this.props.t('CopyTransactionRecordBtnIconText')}
                                onClick={() => {
                                  this.copyTransactionRecord();
                                }}
                                onMouseEnter={() => this.setState({ showCopiedTransactionRecordsMessage: false})}
                            >
                              <CopyTransactionRecordBtnIcon accessibility={this.props.t('CopyTransactionRecordBtnIconText')}/>
                            </button>
                          </Tippy> )
                      }
                    </div>
                  </div>
              )}
            </div>
            <Announcements
              message={
                this.state.textTranslated !== ''
                  ? `${this.props.t('AnnounceTranslatedText')} ${
                      this.state.textTranslated
                    }`
                  : ''
              }
            ></Announcements>
          </div>

          <div className="flex-container-buttons-wrapper">
            <div
                className="flex-container-buttons-left">
              <div className="engine">
                <div className="sourceOr">
                  {!this.state.loadingEngines && this.props.organization.uiAssets.engineSelectionDropdown && !this.state.disabledEngineDropdown &&
                      <div>
                      <p className="dropdownTitle">Engine</p>
                      <EngineSelection selectedEngine={this.state.selectedEngine} engineListState={this.state.engineListState} handleEngineChange={this.handleEngineChange} isDropdownInTextTranslation={true}/>
                      </div>
                  }
                  {!!this.state.showCategory ?
                      <>
                      {this.props.organization.uiAssets.engineSelectionDropdown && !this.state.disabledEngineDropdown
                            ? <div style={{paddingLeft: "24px"}}>
                             <p className="dropdownTitle">{this.props.t('categoryHeader')} {!!this.state.isCategoryMandatory ?
                                    <span style={{color: "red"}}>*</span> : ""}</p>
                                <CategorySelection selectedCategory={this.state.selectedCategory}
                                                   categoriesListState={this.state.categoriesListState}
                                                   handleCategoryChange={this.handleCategoryChange}
                                                   loadingCategories={this.state.loadingCategories}
                                                   mandatoryCategoryError={this.state.mandatoryCategoryError}/>
                              </div>
                            : <div>
                            <p className="dropdownTitle">{this.props.t('categoryHeader')} {!!this.state.isCategoryMandatory ?
                                <span style={{color: "red"}}>*</span> : ""}</p>
                            <CategorySelection selectedCategory={this.state.selectedCategory}
                                               categoriesListState={this.state.categoriesListState}
                                               handleCategoryChange={this.handleCategoryChange}
                                               loadingCategories={this.state.loadingCategories}
                                               mandatoryCategoryError={this.state.mandatoryCategoryError}/>
                          </div>
                      }
                      </>
                      :
                      (<div></div>)
                  }
                </div>
              </div>
            </div>
            <div
              className="flex-container-buttons-right">
              {this.state.textToTranslate && (
                <button
                  className="standardBtn"
                  disabled={
                    !this.state.translationPropertiesChanged ||
                    this.state.srcTrgEqual
                  }
                  style={{
                    backgroundColor:
                    (this.state.isCategoryMandatory && !this.state.selectedCategory.value) ? "#C4C4C4" : this.props.store.user.organization.uiAssets
                            .translationButtonBackground,
                    color:
                      this.props.store.user.organization.uiAssets
                        .translationButtonText,
                  }}
                  onClick={!this.state.isCategoryMandatory || (this.state.isCategoryMandatory && !!this.state.selectedCategory.value)? this.translateText : this.showCategoryError}
                >
                  {this.props.t('textTranslateButton')}
                </button>
              )}
            </div>
            {this.props.store.user.organization.uiAssets
              .transliteratableTargetLangs &&
              this.state.textTranslated &&
              this.props.store.user.organization.uiAssets.transliteratableTargetLangs.includes(
                this.props.store.globalOptions.targetLanguage
              ) && (
                <div className="targetTextActionBtns">
                  <Tippy content={this.props.t('transliterationTippy')}>
                    <button
                      className="targetTextActionBtn"
                      onClick={this.transliterateText}
                      style={{
                        backgroundColor:
                          this.props.organization.uiAssets.navbarActive,
                      }}
                    >
                      <TransliterateBtnIcon
                        fill={this.props.organization.uiAssets.navbarActiveText}
                      />
                    </button>
                  </Tippy>
                </div>
              )}
          </div>

          {this.state.unsupportedFileMessage !== '' && !this.state.reachedQuotaLimitError && !this.state.sameSrcTarError && !this.state.orgExpiredError && (
            <p className="format-alert-text-component" role="alert">
              {this.props.t(this.state.unsupportedFileMessage) !==
              'common error'
                ? this.props.t(this.state.unsupportedFileMessage)
                : this.state.unsupportedFileMessage}
            </p>
          )}
          {this.state.reachedQuotaLimitError !== '' && (
              <p className="format-alert-text-component" role="alert">
                {this.props.t('reachedQuotaLimitError') !==
                'common error'
                    ? this.props.t('reachedQuotaLimitError')
                    : this.state.reachedQuotaLimitError}
              </p>
          )}

          {this.state.sameSrcTarError !== '' && (
              <p className="format-alert-text-component" role="alert">
                {this.props.t('WFM-04-SRCTRGSAME') !==
                'common error'
                    ? this.props.t('WFM-04-SRCTRGSAME')
                    : this.state.sameSrcTarError}
              </p>
          )}

          {this.state.orgExpiredError !== '' && (
              <p className="format-alert-text-component" role="alert">
                {this.props.t('orgExpiredError') !==
                'common error'
                    ? this.props.t('orgExpiredError')
                    : this.state.orgExpiredError}
              </p>
          )}

          {this.state.mandatoryCategoryError !== '' && (
              <p className="format-alert-text-component" role="alert">
                {this.props.t('mandatoryCategoryError') !==
                'common error'
                    ? this.props.t('mandatoryCategoryError')
                    : this.state.mandatoryCategoryError}
              </p>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
  profilePairs: state.user.profiles,
  organization: state.user.organization,

  globalOptions: state.globalOptions,
});

const mapDispatchToProps = (dispatch) => ({
  setSource: (source) => dispatch(setSource(source)),
  setTarget: (target) => dispatch(setTarget(target)),
  setEngines: (engines) => dispatch(setEngines(engines)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Text)
);
