import {Form, Switch} from "antd";
import React from "react";

const TextTranslationSwitch = ({handleTextAndDocumentView, hideTT}) => {
    return (
        <Form.Item
            label="Hide Text Translation view"
            name="hideTT"
            initialValue={hideTT}
        >
            <Switch
                className="switchButton"
                checked={hideTT}
                onChange={event => handleTextAndDocumentView(event, "hideTextView")}
            ></Switch>
        </Form.Item>
    );
};

export default TextTranslationSwitch;