import {Form, Switch} from "antd";
import React from "react";
import Editor from "../Editor";

const StaticMessageField = ({org, handleFooterMessage, setOrg, setFormChanged, staticMessageContent, staticMessage}) => {
    return (
        <>
        <Form.Item
            label={
                <div>
                    <label>Static message</label>{' '}
                    <p
                        style={{
                            fontSize: '12px',
                            color: 'grey',
                            textAlign: 'left',
                        }}
                    >
                        {
                            'A personalized message for the org to display on the bottom of its portal'
                        }
                    </p>
                </div>
            }
            name="footerPermanentMessage"
            style={{ width: '100%' }}
            initialValue={staticMessage}
            htmlFor={undefined}
        >
            <Switch
                checked={staticMessage}
                onChange={handleFooterMessage}
            ></Switch>
        </Form.Item>
        <Form.Item name="footerPermanentMessageContent" value={staticMessageContent} initialValue={staticMessageContent}>
            <Editor org={org} setOrg={setOrg} value={staticMessageContent} setFormChanged={setFormChanged} />
        </Form.Item>
        </>
    );
};

export default StaticMessageField;