const ZipAndDownloadFolderIconComponent = ({accessibility, disabled}) => {
	let fill = "#323232";
	if (disabled) {fill = "#8A8A8A"}
	return(
		<svg title={accessibility} width="24" height="24" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<desc>{accessibility}</desc>
			<polygon points="16,9.9 13.4,9.9 13.4,12.6 11.2,12.6 14.7,16.1 18.2,12.6 16,12.6 " fill={fill}/>
			<path d="M20,5.3h-8l-2-2.2H4c-1.1,0-2,1-2,2.2v13.3c0,1.2,0.9,2.2,2,2.2h16c1.1,0,2-1,2-2.2V7.6
				C22,6.3,21.1,5.3,20,5.3z M20,18.7H4V5.3h5.2l2,2.2H20V18.7z" fill={fill}/>
		</svg>
);
}

export default ZipAndDownloadFolderIconComponent;