const CharCountComponent = ({accessibility, color, size}) => {
    const viewBox = "0 0 "  + size + " " + size;
    return (
        <svg title={accessibility} width={size} height={size} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <desc>{accessibility}</desc>
            <path d="M12.45 16H14.54L9.42996 3H7.56996L2.45996 16H4.54996L5.66996 13H11.31L12.45 16ZM6.42996 11L8.49996 5.48L10.57 11H6.42996ZM21.59 11.59L13.5 19.68L9.82996 16L8.41996 17.41L13.51 22.5L23 13L21.59 11.59Z" fill={color}/>
        </svg>
    );
};

export default CharCountComponent;