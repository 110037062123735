import {Form, Select} from "antd";
import React from "react";

const DefaultSourceLanguageDropdown = ({defaultSourceLanguage, orgSourceLanguages, translate}) => {
    return (
        <Form.Item
            label="Default Source Language"
            name="defaultSource"
            initialValue={defaultSourceLanguage()}
            rules={[
                {
                    required: true,
                    message: 'Default Source is required',
                },
            ]}
        >
            <Select
                showSearch
                placeholder="Select default source language"
                style={{ width: '100%' }}
                optionFilterProp="label"
            >
                {orgSourceLanguages.map((item) => (
                    <Select.Option
                        key={item}
                        value={item}
                        label={translate(item)}
                    >
                        {translate(item)}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default DefaultSourceLanguageDropdown;