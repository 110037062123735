import React from "react";
import Tippy from "@tippyjs/react";
import Spinner from "../../../icons/SpinnerComponent";
import WordCountComponent from "../../../icons/WordCountComponent";
import CharCountComponent from "../../../icons/CharCountComponent";


const TransportRow = ({file, startHumanTraslationRequest, props, state}) => {
    return (
        <>
            <tr className="subChild boxWithArrowLeft" style={{ backgroundColor: '#F6F6F6'}}>
                <td style={{ width: '15%' }}>
                    <div className="centered-text-cell">
                        <WordCountComponent accessibility={props.t("documentSubHeaderWCount")} color={"#333333"} size={24} />
                        <p style={{paddingLeft: "5px"}}><span style={{fontWeight: 'bold'}}>{props.t('documentSubHeaderWCount')}</span> {file.words}</p>
                    </div>
                </td>
                <td style={{ width: '15%' }}>
                    <div className="centered-text-cell">
                        <CharCountComponent accessibility={props.t("documentSubHeaderCCount")} color={"#333333"} size={24} />
                        <p style={{paddingLeft: "5px"}}><span style={{fontWeight: 'bold'}}>{props.t('documentSubHeaderCCount')}</span> {file.chars}</p>
                    </div>
                </td>

                <td style={{ width: '70%' }}>
                    <div className="right-text-cell">
                        {state.transportLoading ? (
                            <Tippy content={props.t('documentSubRowChildRequestHumanTranslationLoadingTippy')}>
                                <span style={{marginRight: "4%"}}><Spinner accessibility={props.t("documentSubRowChildRequestHumanTranslationLoadingTippy")} size={24} color={"black"}/></span>
                            </Tippy>
                        ) : state.transportStatus === 'QuoteInitiated' ? (
                                <Tippy content={props.t('documentSubRowChildRequestHumanTranslationOpenTransportTippy')}>
                                    <p style={{fontWeight: 'bold'}}>
                                        <span style={{fontStyle: 'italic'}}>{props.t('documentSubRowChildHumanTranslationRequestInProcess')} </span>
                                        <a
                                            href={state.transportLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                fontStyle: 'none!important',
                                                color: '#1C335F',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            {props.t('documentSubRowChildHumanTranslationRequestFinishOnTransport')}
                                        </a>
                                    </p>
                                </Tippy>
                            )
                            : state.showTransportCreate ? (
                                <Tippy content={props.t('documentSubRowChildRequestHumanTranslationOpenTransportTippy')}>
                                    <p style={{fontWeight: 'bold'}}>
                                        <span
                                            onClick={() => startHumanTraslationRequest(file)}
                                            rel="noopener noreferrer"
                                            style={{
                                                fontStyle: 'none!important',
                                                color: '#1C335F',
                                                textDecoration: 'underline',
                                                cursor:'pointer'
                                            }}
                                        >
                                            {props.t('documentSubRowChildHumanTranslationRequest')}
                                        </span>
                                        <span style={{fontStyle: 'italic'}}>{props.t('documentSubRowChildHumanTranslationRequestAdditionalCost')}</span>
                                    </p>
                                </Tippy>
                            ) : state.transportStatus !== '' ? (
                                <Tippy content={props.t('documentSubRowChildRequestHumanTranslationOpenTransportTippy')}>
                                    <p style={{fontWeight: 'bold'}}>
                                        <span style={{fontStyle: 'italic'}}>{props.t('documentSubRowChildHumanTranslationRequested')} </span>
                                        <a
                                            href={state.transportLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                fontStyle: 'none!important',
                                                color: '#1C335F',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            {props.t('documentSubRowChildHumanTranslationRequestCheckStatusOnTransport')}
                                        </a>
                                    </p>
                                </Tippy>
                            ) : (
                                <Tippy content={props.t('documentSubRowChildRequestHumanTranslationOpenTransportTippy')}>
                                    <p style={{ color: 'red', fontWeight: 'bold' }}>
                                        <span>{state.transportCheckStatusResponse}. </span>
                                        <a
                                            href={state.transportLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                fontStyle: 'none!important',
                                                color: '#1C335F',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            {props.t('documentSubRowChildHumanTranslationRequestGoToTransport')}
                                        </a>
                                    </p>
                                </Tippy>
                            )}
                    </div>
                </td>
            </tr>
        </>
    );
};

export default TransportRow;