import React from "react";
import Tippy from "@tippyjs/react";
import DeleteCell from "./deleteCell";
import Face from "../../../icons/FaceComponent";
import CloseCrossIconComponent from "../../../icons/CloseCrossIconComponent"
import EditFile from "../../../icons/EditFileComponent";
import MoreActions from "../../../icons/MoreActionsComponent";
import {Dropdown, Menu} from "antd";
import {DownloadButton, DownloadButtonWithColors} from "../Styles";
import { connect } from "react-redux";


const readFilesExtension = (fileName) => {
    let isFileExtensionValid = true;
    let fileExtension = fileName.split(".").pop(); // retrieving the extension from the file name
    if (fileExtension === "srt") {
        isFileExtensionValid = false;
    }
    return isFileExtensionValid;
};

const ActionsCell = ({file, state, checkIfTransportIsAvailable, editFileOnTSCloud, openActionsDropdown, ...props}) => {
    let fileExtensionIsValid = readFilesExtension(file.fileName);
    return (
        <>
            <td
                className="deleteTd"
                tabIndex="-1"
            >
                    {((props.userClaims && props.userClaims.clients.includes('TransPort')) &&
                        (props.userClaims.directory !== 'TPNYC') && (!props.colors.hideTransport)) ?
                        (<div className="centered-text-cell" style={{display: "flex", justifyContent: "start"}}>
                            <DownloadButton
                                className="downloadSource"
                                colors={props.colors}
                                aria-label={props.t('documentSubRowChildDownloadTippy')}
                                alt={props.t('documentSubRowChildDownloadTippy')}

                            >
                                    <Dropdown.Button
                                        icon={
                                            !state.transportOpen ?
                                           (<Tippy content={"More actions"}>
                                                <div className="svgStyledOnHover" onClick={() => openActionsDropdown()}>
                                                    <MoreActions accessibility={"More actions"}/>
                                                </div>
                                            </Tippy>) :
                                                (<Tippy content={"Close"}>
                                                    <div className="svgStyledOnHover" onClick={() => checkIfTransportIsAvailable(file, true)}>
                                                        <CloseCrossIconComponent accessibility={props.t("documentSubRowChildFailedTranslationHelpCloseTippy")} size={24} viewBox={"0 0 24 24"} color={"#323232"}/>
                                                    </div>
                                                </Tippy>)
                                        }
                                        overlay={
                                           (!state.transportOpen && !state.hideActionsDropdown) ? (
                                                <Menu
                                                items={[
                                                    {
                                                        key: '1',
                                                        label: (
                                                            <DownloadButtonWithColors className="extra-actions-btn"
                                                                                      colors={props.colors}
                                                                                      onClick={() => checkIfTransportIsAvailable(file)}>
                                                                <Face
                                                                    accessibility={props.t("documentSubRowChildHumanTranslationRequest")}
                                                                    size={24}/> <span
                                                                style={{paddingLeft: "5px"}}>{props.t("documentSubRowChildHumanTranslationRequest")}</span>
                                                            </DownloadButtonWithColors>
                                                        ),
                                                    },
                                                    props.colors.showTsCloud && fileExtensionIsValid && Number(props.roleId) >= 2 && 
                                                    ((file.status !== "Error") ? (
                                                            {
                                                                key: '2',
                                                                label: (
                                                                    <DownloadButtonWithColors className="extra-actions-btn"
                                                                                              colors={props.colors}
                                                                                              onClick={(file) => editFileOnTSCloud(file)}>
                                                                        <EditFile accessibility={props.t("documentSubRowChildEditTranslatedFile")}/> <span
                                                                        style={{paddingLeft: "5px"}}>{props.t("documentSubRowChildEditTranslatedFile")}</span>
                                                                    </DownloadButtonWithColors>
                                                                ),
                                                                disabled: true,
                                                            }) : (
                                                            {
                                                                key: '2',
                                                                label: (
                                                                    <DownloadButtonWithColors className="extra-actions-btn-disabled"
                                                                                              colors={props.colors}>
                                                                        <EditFile accessibility={props.t("documentSubRowChildEditTranslatedFile")}/> <span
                                                                        style={{paddingLeft: "5px"}}>{props.t("documentSubRowChildEditTranslatedFile")}</span>
                                                                    </DownloadButtonWithColors>
                                                                ),
                                                                disabled: true,
                                                            }
                                                        ))
                                                ]}
                                            />
                                            ) : (<></>)
                                        }
                                    >
                                        <DeleteCell file={file} props={props}/>
                                    </Dropdown.Button>
                            </DownloadButton>
                        </div>)
                        :
                        //for users that do not have TransPort option
                        (
                            <div>
                                <div className="centered-text-cell" style={{float: "left"}}>
                                    <DeleteCell file={file} props={props} />
                                </div>
                                {/*for users with TS cloud option*/}
                                {props.colors.showTsCloud && fileExtensionIsValid && Number(props.roleId) >= 2 &&
                                    <div>
                                        <div className="centered-text-cell">
                                            <Tippy content={props.t('documentSubRowChildEditTranslatedFile')}>
                                                {(file.status === "Translated" || file.status === "TranslatedConverted") ? //in case translation failed
                                                    <div
                                                        tabIndex="0"
                                                        className="svgStyledOnHover edit-file-btn"
                                                        onClick={(file) => editFileOnTSCloud(file)}
                                                    >
                                                        <EditFile
                                                            accessibility={props.t('documentSubRowChildEditTranslatedFile')}/>
                                                    </div> :
                                                    <div
                                                        tabIndex="0"
                                                        className="svgStyledOnHover edit-file-btn-disabled"
                                                    >
                                                        <EditFile
                                                            accessibility={props.t('documentSubRowChildEditTranslatedFile')}/>
                                                    </div>
                                                }
                                            </Tippy>
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    }

            </td>
        </>
    );
};
const mapStateToProps = (state) => ({ roleId: state.user.roleid});
export default connect(mapStateToProps)(ActionsCell) ;