import React, {Suspense} from 'react';
import PrivateRoute from './components/PrivateRoute';
import { connect } from 'react-redux';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Text from './containers/Text';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Login from './containers/Login/Login';
import Callback from './components/Callback';
import Refresh from './components/Refresh';
import Logout from './components/Logout';
import Footer from './components/Footer';
import Document from './containers/Document';
import LandingScreen from './containers/Terms';
import SigninComponent from './components/SigninComponent';
import { logout } from './redux/actions/index';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import WebsiteTranslator from './containers/WebsiteTranslator';
import WebsiteTranslationLoader from './containers/WebsiteTranslator/WebsiteTranslationLoader';
import InactivityLogout from './components/InactivityLogout/InactivityLogout';
import EditOrgPage from './containers/Usermanagement/EditOrgPage';
import EditStyle from './containers/Usermanagement/EditOrgPage/EditStyle';
import EditProfiles from './containers/Usermanagement/EditOrgPage/EditProfiles';
import { CN_FOOTER } from './utils/config';
const Statistics = React.lazy(() => import("./containers/Statistics"));
const Usermanagement = React.lazy(() => import("./containers/Usermanagement"));


export function App(props) {
  const a = props.a;

  return (
    <div
      className="AppContainer"
      style={{
        backgroundColor: props.store.user.organization.uiAssets.appBackground,
      }}
    >
        <Suspense fallback={<div>Loading</div>}>
          <Router>
            <Helmet>
              <html lang={props.i18n.language}></html>
            </Helmet>
            <Header domain={props.domain} auth={props.a} role="banner" />
            <Sidebar auth={props.a} />
            <div role="main" className="App">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'spaceEvenly',
                  flex: 1,
                  maxHeight: '200%',
                }}
              >
                {props.a && (
                  <Switch>
                    <Route
                      exact
                      path="/login"
                      render={(props) => <>
                      <Login {...props} a={a}></Login>
                      {CN_FOOTER !== "" && <p style={{position: "fixed", bottom:"20px", display:"flex", justifyContent:"center"}}>{CN_FOOTER}</p>}
                      </>}
                    ></Route>
                    <Route
                      exact
                      path="/callback"
                      render={(props) => <Callback {...props} a={a}></Callback>}
                    ></Route>
                    <Route
                      exact
                      path="/login-tpauth"
                      render={(props) => (
                        <SigninComponent {...props} a={a}></SigninComponent>
                      )}
                    ></Route>
                    <Route
                      exact
                      path="/refresh"
                      render={(props) => <Refresh {...props} a={a}></Refresh>}
                    ></Route>
                    <Route
                      exact
                      path="/logout"
                      render={(props) => <>
                      <Logout {...props} a={a}></Logout>
                      {CN_FOOTER !== "" && <p style={{position: "fixed", bottom:"20px", display:"flex", justifyContent:"center"}}>{CN_FOOTER}</p>}
                      </>
                      }
                    ></Route>
                    <Route
                      exact
                      path="/inactivity-logout"
                      render={(props) => <>
                        <InactivityLogout {...props} a={a}></InactivityLogout>
                        {CN_FOOTER !== "" && <p style={{position: "fixed", bottom:"20px", display:"flex", justifyContent:"center"}}>{CN_FOOTER}</p>}
                      </>}
                    ></Route>
                          <PrivateRoute
                              exact
                              path="/"
                              component={!props.store.user.organization.uiAssets.hideTT ? Text : Document} //in case TT is hidden, document will be the default view
                              a={a}
                              termsPage={props.store.user.organization.uiAssets.termsPage}
                              termsAcknowledged={
                                  props.store.globalOptions.termsAcknowledged
                              }
                              notifications={true}
                          ></PrivateRoute>
                          <PrivateRoute
                              exact
                              path="/document"
                              component={!props.store.user.organization.uiAssets.hideDT ? Document : Text}  //in case DT is hidden, text will be the default view
                              a={a}
                              termsPage={props.store.user.organization.uiAssets.termsPage}
                              termsAcknowledged={
                                  props.store.globalOptions.termsAcknowledged
                              }
                              notifications={true}
                          ></PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/statistics"
                      component={Statistics}
                      a={a}
                      termsPage={props.store.user.organization.uiAssets.termsPage}
                      termsAcknowledged={
                        props.store.globalOptions.termsAcknowledged
                      }
                      notifications={true}
                    ></PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/website"
                      component={WebsiteTranslator}
                      a={a}
                      termsPage={props.store.user.organization.uiAssets.termsPage}
                      termsAcknowledged={
                        props.store.globalOptions.termsAcknowledged
                      }
                      notifications={true}
                    ></PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/website/translation-loader"
                      component={WebsiteTranslationLoader}
                      a={a}
                      termsPage={props.store.user.organization.uiAssets.termsPage}
                      termsAcknowledged={
                        props.store.globalOptions.termsAcknowledged
                      }
                    ></PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/terms"
                      component={LandingScreen}
                      a={a}
                    ></PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/usermanagement"
                      component={Usermanagement}
                      a={a}
                      termsPage={props.store.user.organization.uiAssets.termsPage}
                      termsAcknowledged={
                        props.store.globalOptions.termsAcknowledged
                      }
                    ></PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/usermanagement/org/:orgId/edit"
                      component={EditOrgPage}
                      a={a}
                      termsPage={props.store.user.organization.uiAssets.termsPage}
                      termsAcknowledged={
                        props.store.globalOptions.termsAcknowledged
                      }
                      {...props}
                    ></PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/usermanagement/org/:orgId/edit/style"
                      component={EditStyle}
                      a={a}
                      termsPage={props.store.user.organization.uiAssets.termsPage}
                      termsAcknowledged={
                        props.store.globalOptions.termsAcknowledged
                      }
                      {...props}
                    ></PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/usermanagement/org/:orgId/edit/profiles"
                      component={EditProfiles}
                      a={a}
                      termsPage={props.store.user.organization.uiAssets.termsPage}
                      termsAcknowledged={
                        props.store.globalOptions.termsAcknowledged
                      }
                      {...props}
                    ></PrivateRoute>
                    <Route render={() => <Redirect to={{ pathname: '/' }} />} />
                  </Switch>
                )}
              </div>
            </div>
            {document.domain !== 'traductor.uoc.edu' && (
              <Footer
                backgroundColor={
                  props.store.user.organization.uiAssets.appBackground
                }
              ></Footer>
            )}
          </Router>
        </Suspense>
    </div>
  );
}

const mapStateToProps = (state) => ({
  store: state,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(App)
);
