import React from "react";
import {Button, Modal} from "antd";
import QualityControlTitle from "../../icons/QualityControlTitle";
import QualityScorePositiveFace from "../../icons/QualityScorePositiveFace";
import QualityScoreNeutralFace from "../../icons/QualityScoreNeutralFace";
import QualityScoreNegativeFace from "../../icons/QualityScoreNegativeFace";

const QualityControlModal = ({ isInfoQualityModalOpen, closeInfoQualityControlModal, translate }) => {

    return (
        <>
            <Modal className="qualityControlModal" visible={isInfoQualityModalOpen} onOk={closeInfoQualityControlModal} onCancel={closeInfoQualityControlModal} destroyOnClose={true} width={"910px"} footer={false}>
                <div style={{padding: "40px"}}>
                    <QualityControlTitle accessibility="Transperfect quality control"/>
                    <p style={{paddingTop: "32px", paddingBottom: "32px"}}>
                        {translate('infoQualityControlFeature')}
                    </p>
                    <table style={{textAlign: "left"}} className="qualityControlTable">
                        <tr>
                            <th className="qualityControlModalTableLeft">
                                <p>
                                    <span><QualityScorePositiveFace accessibility={translate('translationQualityGood')} color={"#36A93F"} size={24}/></span>
                                    <span className="qualityControlModalTableLeftText">{translate('translationQualityGoodModal')}</span>
                                </p>
                            </th>
                            <th className="qualityControlModalTableRight">
                                <p>{translate('translationQualityGoodModalDescription')}</p>
                            </th>
                        </tr>
                        <tr>
                            <th className="qualityControlModalTableLeft">
                                <p>
                                    <span><QualityScoreNeutralFace accessibility={translate('translationQualityAverage')} color={"#F6610C"} size={24}/></span>
                                    <span className="qualityControlModalTableLeftText">{translate('translationQualityAverageModal')}</span>
                                </p>
                            </th>
                            <th className="qualityControlModalTableRight">
                                <p>{translate('translationQualityAverageModalDescription')}</p>
                            </th>
                        </tr>
                        <tr>
                            <th className="qualityControlModalTableLeft">
                                <p>
                                    <span><QualityScoreNegativeFace accessibility={translate('translationQualityBad')} color={"#E4100E"} size={24}/></span>
                                    <span className="qualityControlModalTableLeftText">{translate('translationQualityBadModal')}</span>
                                </p>
                            </th>
                            <th className="qualityControlModalTableRight">
                                <p>{translate('translationQualityBadModalDescription')}</p>
                            </th>
                        </tr>
                        {/*<button>Close</button>*/}
                    </table>

                    <Button key="submit" type="primary" onClick={closeInfoQualityControlModal} className="qualityControlModalCloseBtn">
                        Close
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default QualityControlModal;