import {GLAI_URL} from "../../../utils/config";

const getOrganizationByIdFromMtengine =  (orgId) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
        };

        const url = `${GLAI_URL}/apigateway/mtengine/organization/id/${orgId}`;

        return fetch(url, config);
    } catch (error) {
        console.error(error);
    }
};

export default getOrganizationByIdFromMtengine;