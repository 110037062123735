import styled from "styled-components";

export const DownloadButton = styled.button`
  border-radius: 3px;
  width: 30%;
  max-width: 90px !important ;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

export const DownloadButtonWithColors = styled.button`
  width: 100%;
  margin: 0px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  ${(props) => `background-color: white;
    color:#333333;
&:hover {
  background-color: ${props.colors.basicButtonBackground};
  color: ${props.colors.basicButtonText};
}
`}
`;

export const style = {
    colSizeS: {
        width: "8.35%",
        padding: "8px 16px 8px 16px"
    },
    colSizeM: {
        width: "25%",
        padding: "8px 16px 8px 16px"
    },
    colSizeL: {
        width: "33.35%",
        padding: "8px 16px 8px 16px"
    },
};
