import {Form, Input } from "antd";
import React from "react";

const SalesPersonContactField = ({org}) => {
    return (
        <Form.Item
            label="Account Manager email"
            name="accountManager"
            initialValue={org.accountManager}
        >
            <Input/>
        </Form.Item>
    );
};

export default SalesPersonContactField;