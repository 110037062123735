import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Tippy from "@tippyjs/react";
import { setSource, setTarget } from '../../redux/actions';
import { ReactComponent as SWITCH_LANGS_SVG } from "./switchLangsButton.svg";
import LangSelect from "../../components/LanguageSelection";

class LanguageSelection extends Component {
    state = {
        swapMessage: '',
    }

    selectSourceCheckauto = (e) => {
        this.props.setSource(e);
        this.setState({translationPropertiesChanged: true})
        e === 'auto'
          ? this.setState({ wasAuto: true })
          : this.setState({ wasAuto: false });
      };

    swap = () => {
        if (this.props.store.globalOptions.sourceLanguage !== 'auto' && this.props.store.globalOptions.sourceLanguage !== 'multi') {
          this.setState({ swapMessage: this.props.t('modalTippySwap') }, () =>
            setTimeout(() => this.setState({ swapMessage: '' }), 200)
          );
          if (
            this.state.textToTranslate === '' &&
            this.state.textTranslated === ''
          ) {
            let src = this.props.store.globalOptions.sourceLanguage,
              tgt = this.props.store.globalOptions.targetLanguage;
    
            this.props.setSource(tgt);
            this.props.setTarget(src);
          } else if (
            this.state.textToTranslate !== '' &&
            this.state.textTranslated === ''
          ) {
            let src = this.props.store.globalOptions.sourceLanguage,
              tgt = this.props.store.globalOptions.targetLanguage;
            this.setState({ textToTranslate: '' });
            this.props.setSource(tgt);
            this.props.setTarget(src);
          } else if (
            this.state.textToTranslate !== '' &&
            this.state.textTranslated !== ''
          ) {
            //do the trick
            let src = this.props.store.globalOptions.sourceLanguage,
              tgt = this.props.store.globalOptions.targetLanguage;
            this.props.setSource(tgt);
            this.props.setTarget(src);
            this.setState(
              {
                textToTranslate: this.state.textTranslated,
                textTranslated: '',
              }
            );
          }
        }
        
        this.props.validateURL()
      };

    render() {
        return (
          <div className="websitetranslator-language-selection">
                      <LangSelect
                        source
                        error={this.props.srcTrgEqual}
                        onSelect={this.props.validateURL}
                      />
                      <Tippy
                        content={
                          this.props.store.globalOptions.sourceLanguage === "auto" && this.props.t("textTippySwapBlocked") ||  this.props.store.globalOptions.sourceLanguage === "multi" && this.props.t("textTippySwapBlockedMulti")
                            || this.props.t("modalTippySwap")
                        }
                      >
                        <button
                          className="coffeeButton"
                          id="c-modal__swap-btn"
                          onClick={
                            this.props.store.globalOptions.sourceLanguage === "auto" ||  this.props.store.globalOptions.sourceLanguage === "multi"
                              ? undefined
                              : this.swap
                          }
                        >
                          <SWITCH_LANGS_SVG
                            fill={
                              this.props.store.user.organization.uiAssets.navbarActive
                            }
                          />
                        </button>
                      
                      </Tippy>
                      <LangSelect
                        target
                        error={this.props.srcTrgEqual}
                        onSelect={this.props.validateURL}
                      />

                    </div>  
        )
    }
}

  
const mapStateToProps = (state) => ({
    store: state,
});

const mapDispatchToProps = (dispatch) => ({
    setSource: (source) => dispatch(setSource(source)),
    setTarget: (target) => dispatch(setTarget(target)),
});

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(LanguageSelection)
);