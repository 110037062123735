import {GLAI_URL} from "../../../utils/config";

const saveNewOrgMtengine =  (body) => {
    try {
        const config = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
            body: body
        }

        const url = `${GLAI_URL}/apigateway/mtengine/organization`;

        return fetch(url, config);
    } catch (error) {
        console.error(error);
    }
};

export default saveNewOrgMtengine;