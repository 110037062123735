
let _store = {}

export function initEngineNames(store){
    _store = store
}


export function getEngineName(key) {
    let customLangNamesObj = _store.getState().user.organization.uiAssets.customEnginesNames;
    let customLangName = undefined
    if(!!customLangNamesObj){
        customLangName = customLangNamesObj[key] || undefined
    }
    return customLangName;
}