import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/index';
import Spinner from '../spinner';
import './style.css';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

export class Logout extends Component {
  btnMedium = {
    width: 120,
    border: 0,
    height: 40,
    marginTop: '2em',
    borderRadius: 2,
    fontSize: 16,
    fontWeight: '600',
    fontStyle: 'normal',
    textAlign: 'center',
  };

  constructor(props) {
    super(props);
    let loggingOut = false;
    if (!props.a) {
      if (!props.showLogoutScreen) {
        if (!!props.logoutRedirectURL) {
          window.location.href = props.logoutRedirectURL;
        } else {
          this.props.history.push('/login');
        }
      }
    } else {
      if (!props.a.isAuthenticated()) {
        props.a.signoutRedirectCallback(props.logout);
        if (!props.showLogoutScreen) {
          if (!!props.logoutRedirectURL) {
            window.location.href = props.logoutRedirectURL;
          } else {
            this.props.history.push('/login');
          }
        }
      } else {
        loggingOut = true;
        props.a
          .querySessionStatus()
          .then((e) => {
            props.a.logout();
            props.logout();
          })
          .catch((err) => {
            console.error(err);
            props.a.inactivityLogout().then((e) => {
              this.setState({ loggingOut: false });
            });
          });
      }
    }

    this.state = {
      loggingOut: loggingOut,
    };
  }

  render() {
    return this.state.loggingOut ? (
      <Spinner />
    ) : !this.props.showLogoutScreen ? (
      <></>
    ) : (
      <>
        <Helmet>
          <title>{this.props.t('inactivityLogoutTitle')}</title>
        </Helmet>
        {this.state.stillLoggedIn && <Redirect to="/" />}
        <div className="logout" style={{padding: "24px"}}>
          <h2>{this.props.t('logoutScreenMessage')}</h2>
          <button
            aria-label="Log back in"
            style={{
              ...this.btnMedium,
              color: this.props.uiAssets.basicButtonText,
              backgroundColor: this.props.uiAssets.basicButtonBackground,
            }}
            onClick={() => {
              window.location.href = this.props.logoutRedirectURL;
            }}
          >
            {this.props.t('logoutScreenLogoutButtonLabel')}
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
  showLogoutScreen: state.user.organization.uiAssets.showLogoutScreen,
  logoutRedirectURL: state.user.organization.uiAssets.logoutRedirectURL,
  uiAssets: state.user.organization.uiAssets,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Logout)
);
