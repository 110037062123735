export const calculateExpirationDate = (date) => {
    let tempd = date.setUTCHours(0,0,0,0);
    let UTCDate = new Date(tempd);
    let newDatePlus16 = addDays(UTCDate, 16); //adding 16 days from today
    return newDatePlus16;
};

export const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}