import {Form, Input, Switch} from "antd";
import React from "react";

const UserQuota = ({ counterLimitUser, handleCounterLimitUser, hasUserQuota, handleUserQuota, limitType, counterLimit }) => {
    return (
        <>
            {/*user level quota*/}
            <div className="quotaFormWrapper quotaFormWrapperWithSwitchLongText" style={{height: "45px"}}>
                <Form.Item
                    label="User level quota"
                    name="hasUserQuota"
                    initialValue={hasUserQuota}
                >
                    <Switch style={{marginBottom: "5px"}} checked={hasUserQuota} onChange={handleUserQuota}/>
                </Form.Item>
            </div>

            {hasUserQuota &&
                <>
                    <Form.Item name="counterLimitUser" style={{width: "180px"}} initialValue={counterLimitUser}>
                        <div style={{display: "flex"}}>
                            <Input type={"number"} style={{width: "90px"}} onChange={(e) => handleCounterLimitUser(e.target.value)} value={counterLimitUser} min={0} max={9223372036854775807}/>
                            <span style={{paddingLeft: "5px", paddingTop: "5px"}}>{limitType === 1 ? "words" : limitType === 2 ? "characters" : "pages"}</span>
                        </div>
                    </Form.Item>
                    {counterLimitUser > counterLimit && <p style={{color: "red", marginTop: "-14px", marginBottom: "14px", fontSize: "12px"}}>User level quota cannot be higher than organization quota</p>}
                </>
            }
        </>
    );
};

export default UserQuota;
