import React, {useEffect, useMemo, useState} from 'react';
import { Select, Col, Row } from 'antd';
import { connect } from 'react-redux';
import './style.css';
import { withTranslation } from 'react-i18next';
import {
    addNewOrgEngine,
    deleteOrgEngine,
    handleEngineNameChange,
} from "./CustomEngineInputHelper";
import Input from "antd/es/input/Input";
import CloseCrossIconComponent from "../../icons/CloseCrossIconComponent";

const CustomEngineNameInput = ({
  availableEngines,
    setFormChanged,
    onChange,
    org,
                                 customizedEngines, setCustomizedEngines,
                                 orgEngines, setDeletingEngines, updateEngines
}) => {

  const [tempEngineObject, setTempEngineObject] = useState({});


  useEffect(() => {
    if (tempEngineObject && Object.keys(tempEngineObject).length !== 0) {
      //saving new e.target.value in uiAssets
      onChange({
            ...org.uiAssets.customEnginesNames,
            ...tempEngineObject
          },
      );
    }
  }, [tempEngineObject, orgEngines]);

    useMemo(()=>{
        //called before rendering
        //not showing selected engine as selected (highlighted in blue)
        if(orgEngines.length > 0) {
            availableEngines.map((engine) => {
                for (let i=0; i < orgEngines.length; i++) {
                    if (engine.id === orgEngines[i]) {
                        engine["selected"] = true; //adding property selected to object
                    }
                }
            })
        }
    }, [tempEngineObject, orgEngines])

  return (
      <div className="enginesTable">
        <Row gutter={[16, 16]}>
          <Col span={6} className="editEnginesColumnFirst">
            Provider
          </Col>
          <Col span={8} className="editEnginesColumnMiddle">
            Engine
          </Col>
          <Col span={10} className="editEnginesColumn">
            Displayed name
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={6} className="editEnginesColumnEmpty">
          </Col>
          <Col span={8} className="editEnginesColumnSelector">
            <Select
                className="engineSelector"
                value={
                  ['Add more...']
                }
                onChange={(engine) => {addNewOrgEngine(engine, availableEngines, customizedEngines, setCustomizedEngines, updateEngines); setFormChanged(true);}}
                style={{ textAlign: 'center' }}
            >
              {availableEngines.map((engine) => (
                  <Select.Option key={engine.id} value={engine.id} disabled={engine.category !== 'general'} className={engine.selected ? "ant-select-item-option-selected" : ""}>
                    {engine.alias}
                  </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={10} className="editEnginesColumn">
          </Col>
        </Row>
        {customizedEngines.map((engine) => (
            <Row gutter={[16, 16]} key={engine.id}>
              <Col span={6} className="editEnginesColumnFirst">
                {engine.provider}
              </Col>
              <Col span={8} className="editEnginesColumnMiddle">
                <div style={{backgroundColor: "#EEEEEE", display: "inline-block", marginTop: "5px"}}>
                  {engine.alias}
                  <span style={{ cursor: 'pointer', height: "14px" }}
                        onClick={() => {
                            deleteOrgEngine(engine.id, engine.alias, availableEngines, customizedEngines, setCustomizedEngines, setFormChanged, org, onChange, tempEngineObject, setTempEngineObject, setDeletingEngines, updateEngines, orgEngines);
                        }}>
                                <CloseCrossIconComponent accessibility={""} size={14} viewBox={"0 0 24 24"} color={"#323232"}/>
                              </span>
                </div>
              </Col>
              <Col span={10} className="editEnginesColumn">
                <Input id="engine" defaultValue={!!engine.customizedName ? engine.customizedName : engine.alias } onChange={(e) =>
                    handleEngineNameChange(engine.id, e.target.value, onChange, org, tempEngineObject, setTempEngineObject)
                }/>
              </Col>
            </Row>
        ))}
      </div>
  );
};

const mapStateToProps = (state) => ({
  uiAssets: state.user.organization.uiAssets,
});

export default withTranslation()(
  connect(mapStateToProps, undefined)(CustomEngineNameInput)
);
