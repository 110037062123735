import React from "react";
import Tippy from "@tippyjs/react";
import LanguageSelection from "../../LanguageSelection";
import {ReactComponent as SWITCH_LANGS_SVG} from "../switchLangsButton.svg";

const TranslationsOptions = ({ocr, srcTrgEqual, wavUploaded, translate, swapLanguages, props}) => {
    return (
        <>
            <div className="translationOptions">
                <div className="language-selection">
                    <div>
                        <p>{translate('dropdownsourceLang')}</p>
                        <LanguageSelection
                            source
                            error={
                                ((props.pdfUploaded || props.imagesUploaded) &&
                                    ocr &&
                                    (props.store.globalOptions.sourceLanguage ===
                                    'auto' || props.store.globalOptions.sourceLanguage ===
                                        'multi') || (props.msgOrEmlUploaded &&
                                        (props.store.globalOptions.sourceLanguage ===
                                            'auto' || props.store.globalOptions.sourceLanguage ===
                                            'multi')) ||
                                srcTrgEqual ||
                                (wavUploaded &&
                                    (props.store.globalOptions.sourceLanguage ===
                                        'auto' || props.store.globalOptions.sourceLanguage ===
                                        'multi')))
                            }
                        />
                    </div>
                    <Tippy
                        content={
                            props.store.globalOptions.sourceLanguage === 'auto' && translate('textTippySwapBlocked') || props.store.globalOptions.sourceLanguage === 'multi' && translate('textTippySwapBlockedMulti')
                                || translate('modalTippySwap')
                        }
                    >
                        <button
                            className="coffeeButton"
                            id="c-modal__swap-btn"
                            onClick={
                                props.store.globalOptions.sourceLanguage ===
                                'auto' || props.store.globalOptions.sourceLanguage ===
                                'multi'
                                    ? undefined
                                    : swapLanguages
                            }
                        >
                            <SWITCH_LANGS_SVG
                                fill={
                                    props.store.user.organization.uiAssets
                                        .navbarActive
                                }
                            />
                        </button>
                    </Tippy>
                    <div>
                        <p>{translate('dropdowntargetLang')}</p>
                        <LanguageSelection
                            target
                            error={srcTrgEqual}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default TranslationsOptions;