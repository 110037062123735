export const settingUpAllOrgValues = (updateOrg, org, values, orgSourceLanguages, orgTargetLanguages,
                                      engineSelectionDropdown, showCategory, mandatoryCategory, hideDT, hideTT, hideTransport, showTsCloud,
                                      hasTransactionRecord, deleteAfterDownload, customErrorContact, orgLinkedProducts, showMaxWordsPerDocument, customizedEngines, deletingEngines, quotaType) => {

    let tempEngineObject = {};

    if(deletingEngines){ // in case the engines are being deleted, we go through the list
        for (let i=0; i < customizedEngines.length; i++) { //looping through remaning elements
            tempEngineObject = {...tempEngineObject, [customizedEngines[i].id]: customizedEngines[i].customizedName}; //add every id and name to object
        }
    }

    updateOrg.name = values.name;
    updateOrg.defaultSource = values.defaultSource;
    updateOrg.defaultTarget = values.defaultTarget;
    updateOrg.defaultEngine = 'N/A';
    updateOrg.sourceLanguages = orgSourceLanguages;
    updateOrg.targetLanguages = orgTargetLanguages;
    updateOrg.categories = values.categories;
    updateOrg.uiAssets.engineSelectionDropdown = engineSelectionDropdown;
    updateOrg.uiAssets.showCategory = showCategory;
    updateOrg.uiAssets.mandatoryCategory = mandatoryCategory;
    updateOrg.uiAssets.hideDT = hideDT;
    updateOrg.uiAssets.hideTT = hideTT;
    updateOrg.uiAssets.hideTransport = hideTransport;
    updateOrg.uiAssets.showTsCloud = showTsCloud;
    updateOrg.uiAssets.hasTransactionRecord = hasTransactionRecord;
    updateOrg.uiAssets = { ...org.uiAssets };
    updateOrg.uiAssets.localizations = values.localizations;
    updateOrg.uiAssets.websiteTranslator = updateOrg.uiAssets.websiteTranslator;
    updateOrg.uiAssets.hideTransport = values.hideTransport;
    updateOrg.uiAssets.showQuotas = quotaType;
    updateOrg.uiAssets.showMaxWordsPerDocument = showMaxWordsPerDocument;
    updateOrg.uiAssets.deleteAfterDownload = deleteAfterDownload;
    updateOrg.uiAssets.footerPermanentMessage = values.footerPermanentMessage;
    updateOrg.uiAssets.footerPermanentMessageContent = values.footerPermanentMessageContent;
    updateOrg.uiAssets.extraFormats = values.extraFormats;
    updateOrg.uiAssets.customErrorContact = customErrorContact;
    updateOrg.uiAssets.supportEmailTo = values.supportEmailTo;
    updateOrg.uiAssets.supportEmailCc = values.supportEmailCc;
    updateOrg.uiAssets.customLangNames = values.customLangNames;
    if(deletingEngines) {
        updateOrg.uiAssets.customEnginesNames = tempEngineObject;
    } else {
        updateOrg.uiAssets.customEnginesNames = values.customEnginesNames;
    }
    updateOrg.uiAssets.transliteratableTargetLangs =
        values.transliterationLanguages;
    updateOrg.projectAClientID = values.projectAClientID;
    updateOrg.salesTicket = values.salesTicket;
    updateOrg.accountManager = values.accountManager;
    updateOrg.clientContact = values.clientContact;
    updateOrg.linkedProducts = orgLinkedProducts;
    updateOrg.notes = values.notes;
}

export const prepareCreation = (values, org, orgSourceLanguages, orgTargetLanguages,
                                engineSelectionDropdown, showCategory, mandatoryCategory, hideDT, hideTT, hideTransport, showTsCloud,
                                hasTransactionRecord, deleteAfterDownload, customErrorContact, orgLinkedProducts, showMaxWordsPerDocument, customizedEngines, deletingEngines, uiAssetsStyle, quotaType) => {
    let updateOrg = { ...org };

    let tempEngineObject = {};

    if(deletingEngines){ // in case the engines are being deleted, we go through the list
        for (let i=0; i < customizedEngines.length; i++) { //looping through remaning elements
            tempEngineObject = {...tempEngineObject, [customizedEngines[i].id]: customizedEngines[i].customizedName}; //add every id and name to object
        }
    }

    let tempOrg = { ...org };
    tempOrg.uiAssets = { ...uiAssetsStyle };

    updateOrg.name = values.name;
    updateOrg.defaultSource = values.defaultSource;
    updateOrg.defaultTarget = values.defaultTarget;
    updateOrg.defaultEngine = 'N/A';
    updateOrg.sourceLanguages = orgSourceLanguages;
    updateOrg.targetLanguages = orgTargetLanguages;
    updateOrg.categories = values.categories;
    updateOrg.uiAssets.engineSelectionDropdown = engineSelectionDropdown;
    updateOrg.uiAssets.showCategory = showCategory;
    updateOrg.uiAssets.mandatoryCategory = mandatoryCategory;
    updateOrg.uiAssets.hideDT = hideDT;
    updateOrg.uiAssets.hideTT = hideTT;
    updateOrg.uiAssets.hideTransport = hideTransport;
    updateOrg.uiAssets.showTsCloud = showTsCloud;
    updateOrg.uiAssets.hasTransactionRecord = hasTransactionRecord;
    if(values.engineDropdownShowAll) {
        updateOrg.uiAssets.engineDropdownShowAll = true;
        updateOrg.uiAssets.engineDropdownShowProfilesOnly = false;
    } else if(values.engineDropdownShowProfilesOnly) {
        updateOrg.uiAssets.engineDropdownShowAll = false;
        updateOrg.uiAssets.engineDropdownShowProfilesOnly = true;
    }
    updateOrg.uiAssets = { ...tempOrg.uiAssets };
    updateOrg.uiAssets.localizations = values.localizations;
    updateOrg.uiAssets.websiteTranslator = values.websiteTranslator;
    updateOrg.uiAssets.hideTransport = values.hideTransport;
    updateOrg.uiAssets.showQuotas = quotaType;
    updateOrg.uiAssets.showMaxWordsPerDocument = showMaxWordsPerDocument;
    updateOrg.uiAssets.deleteAfterDownload = deleteAfterDownload;
    updateOrg.uiAssets.footerPermanentMessage = values.footerPermanentMessage;
    updateOrg.uiAssets.footerPermanentMessageContent = values.footerPermanentMessageContent;
    updateOrg.uiAssets.extraFormats = values.extraFormats;
    updateOrg.uiAssets.customErrorContact = customErrorContact;
    updateOrg.uiAssets.supportEmailTo = values.supportEmailTo;
    updateOrg.uiAssets.supportEmailCc = values.supportEmailCc;
    updateOrg.uiAssets.customLangNames = values.customLangNames;
    if(deletingEngines) {
        updateOrg.uiAssets.customEnginesNames = tempEngineObject;
    } else {
        updateOrg.uiAssets.customEnginesNames = values.customEnginesNames;
    }
    updateOrg.uiAssets.transliteratableTargetLangs =
        values.transliterationLanguages;
    updateOrg.projectAClientID = values.projectAClientID;
    updateOrg.salesTicket = values.salesTicket;
    updateOrg.accountManager = values.accountManager;
    updateOrg.clientContact = values.clientContact;
    updateOrg.linkedProducts = orgLinkedProducts;
    updateOrg.notes = values.notes;
    updateOrg.uiAssets = JSON.stringify(JSON.stringify(updateOrg.uiAssets)); //TO DO: HAVE UIASSETS ONLY STRINGIFIED ONE LEVEL
    updateOrg.userLimit = 100;
    return JSON.stringify(updateOrg);
};