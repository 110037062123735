import {GLAI_URL} from "../../utils/config";

const uploadFile =  (body, sourceLanguage,category) => {
    if (category === undefined) {
        category = ""
    }
    try {
        let url = `${GLAI_URL}/uploadapigateway/upload/documentTranslate?from=${sourceLanguage}&to=fr&ocr=false&type=sttText&category=${category}`;

        const config = {
            headers: {
                authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
            method: 'POST',
            body: body,
        };

        return fetch(url, config)
    } catch (error) {
        console.error(error);
    }
};

export default uploadFile;