import {Form, Select } from "antd";
import React from "react";
import {TRANSLITERATABLE_LANGS} from "../settingValues";

const TransliterationLanguagesSelection = ({org, isNew, orgSourceLanguages, orgTargetLanguages, translate}) => {
    return (
        <Form.Item
            label={
                <div>
                    <label>Transliteration Languages</label>{' '}
                    <p
                        style={{
                            fontSize: '12px',
                            color: 'grey',
                            textAlign: 'left',
                        }}
                    >
                        {
                            'Activating this means that, for languages that are in a different alphabet, apart from a translation users can get the pronunciation of it in our alphabet.'
                        }
                    </p>
                </div>
            }
            name="transliterationLanguages"
            initialValue={
                isNew ? [] : org.uiAssets.transliteratableTargetLangs
            }
        >
            <Select
                mode="multiple"
                placeholder="Select transliteratable languages"
                //onChange={() => {}}
                style={{ width: '100%' }}
                optionFilterProp="label"
            >
                {orgSourceLanguages
                    .concat(
                        orgTargetLanguages.filter(
                            (trgLang) => !orgSourceLanguages.includes(trgLang)
                        )
                    )
                    .filter((lang) => TRANSLITERATABLE_LANGS.includes(lang))
                    .map((item) => (
                        <Select.Option
                            key={item}
                            value={item}
                            label={translate(item)}
                        >
                            {translate(item)}
                        </Select.Option>
                    ))}
            </Select>
        </Form.Item>
    );
};

export default TransliterationLanguagesSelection;