import {Form, Switch} from "antd";
import React from "react";

const DeleteAfterDownloadSwitch = ({handleDeleteAfterDownload, deleteAfterDownload}) => {
    return (
        <div className="quotaFormWrapper" style={{height: "60px"}}>
        <Form.Item
            label={
                <div>
                    <label>Delete After Download</label>{' '}
                    <p
                        style={{
                            fontSize: '12px',
                            color: 'grey',
                            textAlign: 'left',
                        }}
                    >
                        {
                            'All translated docs are immediately deleted from the UI after download.'
                        }
                    </p>
                </div>
            }
            name="deleteAfterDownload"
            style={{ width: '100%' }}
            initialValue={deleteAfterDownload}
            htmlFor={undefined}
        >
            <Switch
                className="switchButton"
                checked={deleteAfterDownload}
                onChange={handleDeleteAfterDownload}
            ></Switch>
        </Form.Item>
        </div>
    );
};

export default DeleteAfterDownloadSwitch;