import React, { Component } from 'react';
import './style.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import { CN_FOOTER } from '../../utils/config';

class Footer extends Component {

  state = {
    showFooter: false
  }

  // this is run only the first time once the component is rendered
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      switch (this.props.location.pathname) {
        case '/':
          this.setState({
            showFooter: true
          });
          break;
        case '/document':
          this.setState({
            showFooter: true
          });
          break;
        case '/statistics':
          this.setState({
            showFooter: true
          });
          break;
        case '/website':
          this.setState({
            showFooter: true
          });
          break;
        default:
          this.setState({
            showFooter: false
          });
      }
    }
  }

  // this is to prevent that the footer disappears if the path is the same (the same page is refreshed)
   componentDidMount() {
      switch (this.props.location.pathname) {
      case '/':
          this.setState({
            showFooter: true
          });
          break;
        case '/document':
          this.setState({
            showFooter: true
          });
          break;
        case '/statistics':
          this.setState({
            showFooter: true
          });
          break;
        case '/website':
          this.setState({
            showFooter: true
          });
          break;
        default:
          this.setState({
            showFooter: false
          });
      }
  }

  stripHtml(html){
      return <div style={{textAlign: 'center' }} dangerouslySetInnerHTML={{__html: html}}></div>
  }

  render() {
    return this.state.showFooter && (
      <div
        className="footerDiv"
        style={{
          width: '100%',
          height: '120px',
          backgroundColor: this.props.backgroundColor,
          marginTop: 'auto',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div>
           {this.props.uiAssets.footerPermanentMessage === true ? this.stripHtml(this.props.uiAssets.footerPermanentMessageContent) : ""}
        </div>

        <div style={{marginTop: '15px'}}>
          <p>
            {!this.props.uiAssets.copyright ? "© " + new Date().getFullYear() + this.props.t("copyright") : (
              this.props.uiAssets.copyright === '0'
              || this.props.uiAssets.copyright === 'none'
              || this.props.uiAssets.copyright === 'hide' ? "" : (
                !!this.props.uiAssets.copyright[i18next.language.substr(0,2)]
                ? this.props.uiAssets.copyright[i18next.language.substr(0,2)]
                : "© " + new Date().getFullYear() + this.props.t("copyright")
              )
            )}
          </p>
          {CN_FOOTER !== "" ?
          <p style={{display:"flex", justifyContent:"center"}}>{CN_FOOTER}</p>
          : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  uiAssets: state.user.organization.uiAssets,
});

export default withTranslation()(
  connect(mapStateToProps, undefined)(withRouter(Footer))
);
