import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import './style.css'
import { setSource, setTarget } from '../../redux/actions';
import Select, { components } from 'react-select';

const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
        <span className="triggerIcon"
            role="presentation"
            aria-hidden="true"
        />
    </components.DropdownIndicator>
  );

class LanguageSelection extends Component {
    constructor(props){
        super(props)

        this.state = {
            source: !!this.props.source,
            options: this.computeOptions()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.t !== this.props.t) {
            let newComputedOptions = this.computeOptions()
            this.setState({options:newComputedOptions})
        }
    }

    getLangName = (key) => {
        let inLanguage = this.props.i18n.language.substring(0,2)
        let customLangNamesObj = this.props.customLangNames
        let customLangName = undefined
        if(!!customLangNamesObj){
          let langList = customLangNamesObj[inLanguage]
          if(!!langList){
            customLangName = langList[key] || undefined
          }
        }
        return !customLangName ? this.props.t(key) : customLangName
    }
    computeOptions(){
        let options
        let internationalComparator = new Intl.Collator(this.props.i18n.language)
        
        if(!!this.props.source){
            options=!!this.props.orgSrcLangs && this.props.orgSrcLangs
                .map(lang => this.makeOption(lang))
                .sort((a, b) => a.value === 'multi' ? -1 : b.value === 'multi' ? 1 : internationalComparator.compare(a.label, b.label))
                .sort((a, b) => a.value === 'auto' ? -1 : b.value === 'auto' ? 1 : "")
                .filter(option => option.label !== 'common error') //do not display languages if they have "common error" as label

        } else {
            options=!!this.props.orgTrgLangs && this.props.orgTrgLangs
                .map(lang => this.makeOption(lang))
                .sort((a, b) => a.value === 'auto' ? -1 : b.value === 'auto' ? 1 : internationalComparator.compare(a.label, b.label))
                .filter(option => option.label !== 'common error')
        }
        
    
        return options
    }

    makeOption(langKey, o){
        if (o) {
            let option = o.find(el => el.key === langKey);
            if (option) {
                option.label = this.getLangName(langKey)
                return option
            }
        }
       return {
            key: langKey,
            value: langKey,
            label: this.getLangName(langKey)}
        
    }

    handleChange=(option)=>{
        if(this.state.source){
            this.props.setSource(option.value)
        } else {
            this.props.setTarget(option.value)
        }
        if(!!this.props.onSelect && {}.toString.call(this.props.onSelect) === '[object Function]'){
            this.props.onSelect(option.value)
        }
    }

    render(){
        return (
            <>
                <Select
                    className="langSelection"
                    options={!!this.props.t ? this.state.options : {}} // this.props.t triggers rerender on language change
                    value={this.makeOption(!!this.state.source ? this.props.sourceLanguage : this.props.targetLanguage,this.state.options)}
                    name="language"
                    onChange={this.handleChange}
                    menuShouldScrollIntoView={false}
                    components={{DropdownIndicator}}
                    noOptionsMessage={() => this.props.t('langSelect_NoResultsMessage')}
                    menuPlacement={!this.props.menuPlacement ? "auto" : this.props.menuPlacement}
                    //menuIsOpen
                    aria-label={
                        !!this.state.source
                            ? this.props.t('dropdownsourceLang') + " " + this.getLangName(this.props.sourceLanguage) + " " + this.props.t('dropdownSelected')
                            : this.props.t('dropdowntargetLang') + " " + this.getLangName(this.props.targetLanguage) + " " + this.props.t('dropdownSelected')
                    }
                    theme={theme => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: this.props.dropdownHover,
                          primary: this.props.dropdownSelected,
                          neutral0: "#ffffff",
                        },
                      })}
                    styles={{
                        control: (provided, state) => ({
                            ...provided,
                            height: '100%',
                            border: (!!this.props.error ? '2px solid #ff0000' : '1px solid rgb(179, 179, 179)'),
                            borderRadius: '3px',
                        }),
                        valueContainer: (provided, state) => ({
                            paddingLeft: '5px'
                        }),
                        indicatorSeparator: (provided, state) => ({
                            ...provided,
                            display: 'none',
                        }),
                        menu: (provided, state) => ({
                            ...provided,
                            marginTop: "3px"
                        }),
                        menuList: (provided, state) => ({
                            ...provided,
                            borderRadius: "5px",
                            padding: 0
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            fontSize: "16px",
                            height: "40px",
                            padding: "10px",
                            fontWeight: "400"
                        }),
                    }}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    store: state,
    sourceLanguage: state.globalOptions.sourceLanguage,
    targetLanguage: state.globalOptions.targetLanguage,
    orgSrcLangs: state.user.organization.sourceLanguages,
    orgTrgLangs: state.user.organization.targetLanguages,
    customLangNames: state.user.organization.uiAssets.customLangNames,
    dropdownSelected: state.user.organization.uiAssets.dropdownSelected,
    dropdownTitleColor: state.user.organization.uiAssets.dropdownTitleColor,
    dropdownHover: state.user.organization.uiAssets.dropdownHover,
});

const mapDispatchToProps = (dispatch) => ({
  setSource: (source) => dispatch(setSource(source)),
  setTarget: (target) => dispatch(setTarget(target)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LanguageSelection)
);
