import React, {useEffect, useState} from "react";
import {Collapse} from "antd";
import DownArrowHeadIconComponent from "../../../icons/DownArrowHeadIconComponent"
import UpArrowHeadIconComponent from "../../../icons/UpArrowHeadIconComponent"
import SubmissionChildren from '../../../components/CHSubChildren';
import SubmissionContent from "./submissionContent";
import zipSubmissionFiles from "../../../apiCalls/Document/zipSubmissionFiles";
import getSubmission from "../../../apiCalls/Document/getSubmission";
import downloadSubmissionFiles from "../../../apiCalls/Document/downloadSubmissionFiles";
import {downloadFileFromBrowser} from "../../../utils/downloadFileFromBrowser";
import Tippy from "@tippyjs/react";
import {UPLOADED_FILE_CHECK_TIME} from "../../../utils/config";

const { Panel } = Collapse;


const SubmissionRow = ({submission, props, showCategory, getStatus, toggleConfirmation, filesAreTranslated, submissionName}) => {
    const [loadingDownloadSubmission, setLoadingDownloadSubmission] = useState(false);
    const [submissionsStatus, setSubmissionsStatus] = useState("");
    const [submissionContainsTranscribedFiles, setSubmissionContainsTranscribedFiles] = useState(false);

    useEffect(() => {
        let filesAreTranscribed = checkIfSubmissionContainsTranscriptions(submission); //transcriptions must not be included in the table
        setSubmissionContainsTranscribedFiles(filesAreTranscribed)
        setSubmissionsStatus(submission.zipStatus)
    }, [submission]);

    const checkIfSubmissionContainsTranscriptions = (submission) => {
        let filesAreTranscribed = true;
        let notTranscribedFiles = submission.files.filter(
            (file) =>
                file.status !== 'TextTranscribed' &&
                file.status !== 'TextTranscribing'
                &&
                !file.errorCode.startsWith('STT-TXT')
        )
        if(notTranscribedFiles.length > 0) {
            filesAreTranscribed = false;
        }
        return filesAreTranscribed;
    }



        const showFiles = (files, props, showCategory, getStatus, toggleConfirmation) => {
        let tableRows = [];
        if(files) {
            let filteredFiles = files.filter(
                (file) =>
                    file.status !== 'TextTranscribed' &&
                    file.status !== 'TextTranscribing'
                    &&
                    !file.errorCode.startsWith('STT-TXT')
            )

            for (let i = 0; i <= filteredFiles.length; i++) {
                if (filteredFiles[i]) {
                    tableRows.push(
                        <SubmissionChildren
                            getStatus={getStatus}
                            toggleConfirmation={toggleConfirmation}
                            file={filteredFiles[i]}
                            key={filteredFiles[i]['fileId']}
                            subInfo={{...filteredFiles[i]['subInfo']}}
                            colors={props.store.user.organization.uiAssets}
                            userClaims={props.store.user.user_claims}
                            showCategory={showCategory}
                        ></SubmissionChildren>
                    )
                }
            }
        }
        return tableRows;
    };

    const downloadSubmission = (submission) => {
        downloadSubmissionFiles(submission.zipFileId)
            .then(async (res) => {
                if (!res.ok) throw new Error('Download request failed');
                let blob = await res.blob();

                downloadFileFromBrowser(res, blob, submission);
                getStatus();
            })
    }

    const zipAndDownloadSubmission = (submission) => {
        // sending request to zip file
        setLoadingDownloadSubmission(true);
        zipSubmissionFiles(submission.submissionId)
            .then(async (res) => {
                if (!res.ok) throw new Error('Zip request failed');
                // keep checking if submission is zipped
                const interval = setInterval(() => {
                    // checking all submissions
                    getSubmission()
                        .then((res) => res.json())
                        .then((json) => {
                            if (json.submissions) {
                                json.submissions.map((sub) => { // looping through submissions
                                    if(sub.submissionId === submission.submissionId) { //looking for submission selected
                                        if (sub.zipStatus === 'zipped') {
                                            downloadSubmission(sub);
                                            setLoadingDownloadSubmission(false);
                                            clearInterval(interval)
                                        } else if (sub.zipStatus === 'error') {
                                            setLoadingDownloadSubmission(false);
                                            clearInterval(interval)
                                        }
                                        setSubmissionsStatus(sub.zipStatus)
                                    }
                                })
                            }
                        })

                }, UPLOADED_FILE_CHECK_TIME);
                return () => clearInterval(interval);
            })
            .catch((err) => {
                console.error(err);
            }).finally(() => {
        });
    };

    return (
        !submissionContainsTranscribedFiles ?
            (<>
                <Collapse key={submission.submissionId}   defaultActiveKey={['1']} expandIconPosition="end" expandIcon={({isActive}) => isActive ? 
                                        
                                        
                    <Tippy  content={props.t('documentSubRowChildCollapse')}>

                        <div className="svgStyledOnHoverSubmissionName" style={{right: "50px", top: "5px", position: "absolute"}}>
                            <UpArrowHeadIconComponent accessibility={props.t('documentSubRowChildCollapse')} color="#323232"/>
                        </div> 
                        </Tippy>
                    :                 
                    
                    <Tippy  content={props.t('documentSubRowChildExpand')}>
                    <div className="svgStyledOnHoverSubmissionName" style={{right: "50px", top: "5px",     position: "absolute"}}>
                        <DownArrowHeadIconComponent accessibility={props.t('documentSubRowChildExpand')} color="#323232"/>
                    </div>
                    </Tippy>}>





                    <Panel header={<SubmissionContent submission={submission} props={props}
                                                        zipAndDownloadSubmission={zipAndDownloadSubmission}
                                                        downloadSubmission={downloadSubmission}
                                                        loadingDownloadSubmission={loadingDownloadSubmission}
                                                        filesAreTranslated={filesAreTranslated}
                                                        submissionName={submissionName}
                                                        submissionsStatus={submissionsStatus}
                                                        showCategory={showCategory}/>}
                                                        key={submission.submissionId}>
                        {showFiles(submission.files, props, showCategory, getStatus, toggleConfirmation)}
                    </Panel>
                </Collapse>
            </>) : (<></>)
    );
};

export default SubmissionRow;