export const ErrorCode = (errorCode, t) => {
    if (errorCode.split('-')[0] === 'MT') {
        return t('MT-error');
    } else if (errorCode.split('-')[0] === 'WFM') {
        return t('WFM-error');
    }
    return t('documentSubRowChildStatusFailed');
};

export const ErrorCodeDesc = (errorCode, t) => {
    if (errorCode.split('-')[0] === 'MT') {
        return t('MT-error-desc');
    } else if (errorCode.split('-')[0] === 'WFM') {
        return t('WFM-error-desc');
    } else if (errorCode === 'AP-01-003') {
        return t('AP-01-003-desc');
    }
    return t('documentSubRowChildStatusFailed');
};