import {Form, Switch} from "antd";
import React from "react";

const DocumentTranslationSwitch = ({handleTextAndDocumentView, hideDT}) => {
    return (
        <Form.Item
            label="Hide Document Translation view"
            name="hideDT"
            initialValue={hideDT}
        >
            <Switch
                className="switchButton"
                checked={hideDT}
                onChange={event => handleTextAndDocumentView(event, "hideDocView")}
            ></Switch>
        </Form.Item>
    );
};

export default DocumentTranslationSwitch;