import {GLAI_URL} from "../../utils/config";

const getTranscribedText =  (fileId) => {
    try {
        let url = `${GLAI_URL}/apigateway/storage/info/file/${fileId}`;

        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
                fileType: 'source_file',
            },
        };

        return fetch(url, config)
    } catch (error) {
        console.error(error);
    }
};

export default getTranscribedText;