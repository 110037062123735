import {GLAI_URL} from "../../utils/config";

const downloadSubmissionFiles =  (submissionId) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem(
                    'access_token'
                )}`,
            },
        };

        const url = `${GLAI_URL}/apigateway/storage/${submissionId}?fileType=submission_zip`;

        const result = fetch(url, config);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export default downloadSubmissionFiles;