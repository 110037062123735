import {GLAI_URL} from "../../../utils/config";

const modifyOrganizationByIdFromMtengine =  (body, orgId) => {
    try {

        const config = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
            body: body,
        }

        const url = `${GLAI_URL}/apigateway/mtengine/organization/id/${orgId}`;

        return fetch(url, config);
    } catch (error) {
        console.error(error);
    }
};

export default modifyOrganizationByIdFromMtengine;