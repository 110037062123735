import {GLAI_URL} from "../../utils/config";

const getTranslatedText =  async (sourceLanguage, targetLanguage, textType, textToTranslate, engineId, category) => {
    try {
        let params = new URLSearchParams();
        params.append('from', sourceLanguage);
        params.append('to', targetLanguage);
        params.append('textType', textType);
        if(engineId) {
            params.append('engineId', engineId.value);
        }
        params.append('category', category);
        params = params.toString();
        let url = `${GLAI_URL}/apigateway/texttranslator?`+ params;

        const config = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
            body: JSON.stringify([{ Text: textToTranslate }]),
        };

        const result = fetch(url, config)
        return result;
    } catch (error) {
        console.error(error);
    }
};

export default getTranslatedText;