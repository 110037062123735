import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './style.css';
import Spinner from '../../components/spinner';

import { Helmet } from 'react-helmet';

class WebsiteTranslationLoader extends Component {
  constructor(props) {
    super(props);
    window.injectTranslatedWebsite = this.injectTranslatedWebsite;
  }

  componentDidMount() {
    if (!!this.props.website && this.props.website !== '') {
      this.injectTranslatedWebsite(this.props.website);
    }
  }

  injectTranslatedWebsite = (website, translatedURLPath) => {
    while (window.document === undefined) {}

    try {
      this.props.a.UserManager.stopSilentRenew();
      this.props.a.UserManager._sessionMonitor._stop();
    } catch (e) {
      console.error(e);
    }

    try {
      window.document.open();
      window.document.write(website);
      window.document.close();

      if (
        !!translatedURLPath &&
        translatedURLPath !== null &&
        translatedURLPath !== ''
      ) {
        window.history.pushState({}, null, translatedURLPath);
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.t('wtLoadingCaption')}</title>
        </Helmet>
        <div className="wtLoadOverlay">
          <Spinner />
          <div>{this.props.t('wtLoadingCaption')}</div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state,
});

export default withTranslation()(
  connect(mapStateToProps)(WebsiteTranslationLoader)
);
