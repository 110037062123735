import {Button, PageHeader, Tooltip} from "antd";
import React from "react";
import {ArrowLeftOutlined, CheckOutlined, DeleteOutlined} from "@ant-design/icons";

const EditOrgHeader = ({isNew, org, breadCrumbItemRender, formChanged, onReset, form, props}) => {
    return (
        <PageHeader
            title={
                isNew
                    ? `Create New Organization`
                    : `Edit Organization "${org.name}"`
            }
            className="site-page-header"
            size="small"
            breadcrumb={
                isNew
                    ? {
                        routes: [
                            {
                                path: 'usermanagement',
                                breadcrumbName: 'User Management',
                            },
                            {
                                path: `org/new/edit`,
                                breadcrumbName: `Create New`,
                            },
                        ],
                        itemRender: breadCrumbItemRender,
                    }
                    : {
                        routes: [
                            {
                                path: 'usermanagement',
                                breadcrumbName: 'User Management',
                            },
                            {
                                path: `org/${org.id}/edit`,
                                breadcrumbName: org.name,
                            },
                            {
                                path: '',
                                breadcrumbName: 'Edit',
                            },
                        ],
                        itemRender: breadCrumbItemRender,
                    }
            }
            extra={[
                <Tooltip
                    key={'a1'}
                    title={
                        formChanged
                            ? 'Reset to original values'
                            : 'No changes have been made'
                    }
                >
                    <Button
                        htmlType="button"
                        onClick={onReset}
                        disabled={!formChanged}
                        icon={<DeleteOutlined />}
                    >
                        Reset
                    </Button>
                </Tooltip>,
                <Tooltip
                    key={'a2'}
                    title={
                        formChanged
                            ? 'Save organization values'
                            : 'No changes have been made'
                    }
                >
                    <Button
                        type="primary"
                        onClick={() => form.submit()}
                        disabled={!formChanged}
                        icon={<CheckOutlined />}
                    >
                        Submit
                    </Button>
                </Tooltip>,
            ]}
            backIcon={
                <>
                    <Button icon={<ArrowLeftOutlined />} />
                </>
            }
            onBack={() => props.history.push('/usermanagement')}
        />
    );
};

export default EditOrgHeader;