import React, { useState, useEffect } from 'react';
import { Input, Select, Tabs } from 'antd';
import { connect } from 'react-redux';
import './style.css';
import { DeleteFilled } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import {addNew, createPanelsFromAppLocalization, deleteInput, handleLangNameChange} from "./CustomLangnameInputHelper";

const CustomLangnameInput = ({localizations,
  value = {},
  onChange,
  availableLangs,
  t,
  i18n,
}) => {

  const [panes, setPanes] = useState([]);
  const [activeKey, setActiveKey] = useState();

  useEffect(() => {
    if(localizations) {
      setPanes((() => { return createPanelsFromAppLocalization(localizations, t); })());
    }
  }, [localizations, t]);

  const handleChange = (key) => {
    setActiveKey(key);
  };

  return (
    <div>
      <Tabs
        hideAdd
        onChange={handleChange}
        activeKey={activeKey}
        type="editable-card"
      >
        {panes.map((pane) => (
          <Tabs.TabPane tab={pane.title} key={pane.key} closable={false}>
            <>
              {!!value[pane.key] && (
                <>
                  <div className="languageHeaderRow">
                    <div id="languageHeader">
                      Language
                    </div>
                    <div id="originalNameHeader">
                      Original name
                    </div>
                    <div id="customizedNameHeader">
                      Customized name
                    </div>
                  </div>
                  {Object.entries(value[pane.key]).map(
                    ([langKey, langValue]) => (
                      <div
                       className="languageRow"
                        key={langKey}
                      >
                        <div className="languageCell">
                          {t(langKey)}
                        </div>
                        <div className="languageCell">
                          {i18n.getResource(pane.key, 'translations', langKey)}
                        </div>
                        <Input
                          key={langKey}
                          value={langValue}
                          onChange={(e) =>
                            handleLangNameChange(
                              pane.key,
                              langKey,
                              e.target.value,
                                value,
                                onChange
                            )
                          }
                          addonAfter={
                            <DeleteFilled
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                deleteInput(pane.key, langKey, value, onChange);
                              }}
                            />
                          }
                        />
                      </div>
                    )
                  )}
                </>
              )}
              <Select
                value={
                  !value[pane.key]
                    ? ['Add new']
                    : ['Add new', ...Object.keys(value[pane.key])]
                }
                onChange={(lang) => addNew(pane.key, lang, value, i18n, onChange)}
                showSearch
                style={{ textAlign: 'center' }}
              >
                {availableLangs.map((lang) => (
                  <Select.Option key={lang} value={lang}>
                    {t(lang)}
                  </Select.Option>
                ))}
              </Select>
            </>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => ({
  uiAssets: state.user.organization.uiAssets,
});

export default withTranslation()(
  connect(mapStateToProps, undefined)(CustomLangnameInput)
);
