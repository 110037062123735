import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentUser, logout } from '../../redux/actions';
import { withTranslation } from 'react-i18next';
import './style.css';
import { LOCAL_STORAGE_KEY } from '../../utils/localStorageKeys';

const btnMedium = {
  width: 120,
  border: 0,
  height: 40,
  marginTop: '2em',
  borderRadius: 2,
  fontSize: 16,
  fontWeight: '600',
  fontStyle: 'normal',
  textAlign: 'center',
};

class Login extends Component {
  componentDidMount() {
    if (this.props.a.isAuthenticated()) {
      const redirectURI = localStorage.getItem(LOCAL_STORAGE_KEY.REDIRECT_URI);
      if (!!redirectURI) {
        this.props.history.push(redirectURI);
      } else {
        this.props.history.push('/');
      }
    } else {
      this.props.a.inactivityLogout();
    }
  }

  loginBtnClick = () => {
    if (
      !!this.props.logoutRedirectURL &&
      this.props.logoutRedirectURL !== '/login'
    ) {
      window.location.replace(this.props.logoutRedirectURL);
    } else {
      this.props.a.signinRedirect();
    }
  };

  render() {
    return (
      <div className="login">
        <h1>{this.props.t('loginh1')}</h1>
        <button
          aria-label="Log in"
          style={{
            ...btnMedium,
            color: this.props.uiAssets.basicButtonText,
            backgroundColor: this.props.uiAssets.basicButtonBackground,
          }}
          onClick={this.loginBtnClick}
        >
          {this.props.t('loginButton')}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
  userid: state.user.id,
  uiAssets: state.user.organization.uiAssets,
  logoutRedirectURL: state.user.organization.uiAssets.logoutRedirectURL,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (userData) => dispatch(setCurrentUser(userData)),
  logout: () => dispatch(logout()),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Login)
);
