import React, { Component } from 'react';

class Refresh extends Component {
  componentDidMount() {
    this.props.a.signinSilentCallback();
  }

  render() {
    return <div>refresh</div>;
  }
}

export default Refresh;
