import {Form, Select} from "antd";
import React from "react";

const LinkedProductsField = ({orgLinkedProducts, updateLinkedProducts, setFormChanged, linkedProducts}) => {
    return (
        <Form.Item
            label="Linked products"
        >
            <Select
                mode="multiple"
                placeholder="Select linked products"
                style={{ width: '100%' }}
                name="linkedProducts "
                value={orgLinkedProducts}
                onChange={(s) => {
                    updateLinkedProducts(s);
                    setFormChanged(true);
                }}
                optionFilterProp="label"
            >
                {Object.entries(linkedProducts).map((prod) => (
                    <Select.Option
                        key={prod[0]}
                        value={prod[1].value}
                        label={prod[1].value}
                    >
                        {prod[1].value}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default LinkedProductsField;