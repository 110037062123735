import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentUser } from '../../redux/actions';
import Spinner from '../spinner';
import './style.css';
import { GLAI_URL } from '../../utils/config';
import { LOCAL_STORAGE_KEY } from '../../utils/localStorageKeys';
import getUserData from "../../apiCalls/components/Callback/getUserData";

class Callback extends Component {
  componentDidMount() {
    this.props.a
      .getUser()
      .then((user) => {
        if (user === null) {
          return this.props.a.signinRedirectCallback();
        } else {
          return user;
        }
      })
      .then((user) => {
        let userId = user.profile.sub;
        if (user.profile.Sid) {
          userId = user.profile.Sid;
        }
        let url = `${GLAI_URL}/apigateway/user/${userId}?org=${user.profile.organization}&dir=${user.profile.directory}`;
        return getUserData(url);
      })
      .then((res) => res.json())
      .then((json) => {
        if (json.success === false) {
          this.props.a.logout();
        } else {
          this.props.setCurrentUser(json);
          localStorage.setItem(
            LOCAL_STORAGE_KEY.FIRST_TOKEN_REFRESH,
            Date.now() + this.props.initSessionDuration
          );
          const redirectURI = localStorage.getItem(
            LOCAL_STORAGE_KEY.REDIRECT_URI
          );
          if (!!redirectURI) {
            this.props.history.push(redirectURI);
          } else {
            this.props.history.push('/');
          }
        }
      })
      .catch((e) => {
        console.error(e);
        if (!window.injectTranslatedWebsite) this.props.history.push('/login');
      });
  }

  render() {
    return <Spinner></Spinner>;
  }
}

const mapStateToProps = (state) => ({
  store: state,
  initSessionDuration: state.user.organization.uiAssets.initialSessionDuration,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (userData) => dispatch(setCurrentUser(userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Callback);
