import {GLAI_URL} from "../../../utils/config";

const modifyQuotaByOrg =  (body, orgId) => {
    try {

        const config = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
            body: body,
        }

        const url = `${GLAI_URL}/apigateway/quotas/updateOrganization/${orgId}`;

        return fetch(url, config);
        //promise.then((response) => response)
    } catch (error) {
        console.error(error);
    }
};

export default modifyQuotaByOrg;